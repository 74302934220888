import { useEffect, useState } from "react";
import { Product } from "../../../../types/orders";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { fetchSearchableProducts } from "../../../../api/fetchSearchableProducts";

interface SingleOrderPopUpAddProductLineItemProps {
  availableProducts: Product[];
  selectedProducts: Product[];
  setSelectedProducts: (selectedProducts: Product[]) => void;
  product?: Product;
  index?: number;
}
const SingleOrderPopUpAddProductLineItem = ({
  availableProducts,
  selectedProducts,
  setSelectedProducts,
  product,
  index = 0,
}: SingleOrderPopUpAddProductLineItemProps) => {
  const [hasClickedSelectProduct, setHasClickedSelectProduct] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [filteredAvailableProducts, setFilteredAvailableProducts] =
    useState<Product[]>(availableProducts);
  const [isLoading, setIsLoading] = useState(false);
  const [inputWatcher, setInputWatcher] = useState("");

  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (
        e.target.title !== `product-${index}` &&
        e.target.className !== "cursor-pointer px-4 py-2 hover:bg-gray-200"
      ) {
        setHasClickedSelectProduct(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [index]);

  useEffect(() => {
    if (searchInputValue.length > 0) {
      const filteredProducts = availableProducts.filter((product) =>
        product.name.toLowerCase().includes(searchInputValue.toLowerCase())
      );
      setFilteredAvailableProducts(filteredProducts);
    } else {
      setFilteredAvailableProducts(availableProducts);
    }
  }, [searchInputValue, availableProducts]);
  return (
    <div className="flex flex-row items-center justify-between">
      {product && (
        <div>
          <span>{product.name}</span>
        </div>
      )}
      {!product && (
        <div className="relative w-full grow">
          {/* Input search field */}
          <div>
            <label className="sr-only" htmlFor={`product-${index}`}>
              Recherche d'un produit
            </label>
            <input
              value={searchInputValue}
              title={`product-${index}`}
              onInput={(e) => {
                setInputWatcher(e.currentTarget.value);
                if (e.currentTarget.value.length > 0) {
                  setIsLoading(true);
                  fetchSearchableProducts(e.currentTarget.value).then(
                    (fetchedProducts) => {
                      setFilteredAvailableProducts(fetchedProducts);
                      setIsLoading(false);
                    }
                  );
                }
                setSearchInputValue(e.currentTarget.value);
              }}
              type="text"
              className="w-full rounded border border-gray-300 py-2 px-4"
              placeholder="Recherche d'un produit..."
              onClick={() => setHasClickedSelectProduct(true)}
            />
          </div>
          {hasClickedSelectProduct && (
            <div className="absolute max-h-[110px] w-full overflow-y-scroll rounded bg-gray-100 shadow">
              {isLoading && (
                <div className="flex h-[110px] flex-row items-center justify-center">
                  <div className="h-4 w-4 animate-spin rounded-full border-t-2 border-b-2 border-gray-900"></div>
                </div>
              )}
              <ul>
                {/* {availableProducts.map((product, index) => ( */}
                {filteredAvailableProducts.length === 0 &&
                !isLoading &&
                inputWatcher !== "" ? (
                  <li className="flex h-[110px] items-center justify-center px-4 py-2">
                    Aucun produit trouvé
                  </li>
                ) : null}
                {filteredAvailableProducts.map((product, index) => (
                  <li
                    key={index}
                    className="cursor-pointer px-4 py-2 hover:bg-gray-200 "
                    onClick={() => {
                      setSelectedProducts([
                        ...selectedProducts,
                        {
                          ...product,
                          price: product.price,
                          // regularPrice: product.regularPrice,
                          regularPrice: product.regularPrice,
                          quantity: 1,
                        },
                      ]);
                      setHasClickedSelectProduct(false);
                      setSearchInputValue("");
                    }}
                  >
                    {product.name}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
      {selectedProducts.length > 0 && product ? (
        <div className="flex flex-row items-center gap-x-4">
          {product && (
            <div
              className="cursor-pointer rounded-full p-1 hover:bg-gray-200"
              onClick={() => {
                // Remove product from selectedProducts based on index
                const newSelectedProducts = selectedProducts.filter(
                  (product, i) => i !== index
                );
                setSelectedProducts(newSelectedProducts);
              }}
            >
              <XMarkIcon className="w-6" />
            </div>
          )}
          <div className="w-20">
            {product && (
              <div>
                {" "}
                <label className="sr-only" htmlFor={`quantity-${index}`}>
                  Quantité
                </label>
                <input
                  title={`quantity-${index}`}
                  type="number"
                  className="w-20 rounded border border-gray-300 py-2 px-4"
                  // defaultValue={1}
                  value={selectedProducts[index].quantity}
                  min={1}
                  onInput={(e) => {
                    const p = { ...selectedProducts[index] };
                    p.quantity = parseInt(e.currentTarget.value);
                    const newSelectedProducts = [...selectedProducts];
                    newSelectedProducts[index] = p;
                    // const newSelectedProducts = [...selectedProducts];
                    // newSelectedProducts[index].quantity = "10";
                    // newSelectedProducts[index].quantity = parseInt(
                    //   e.currentTarget.value
                    // ).toString();

                    setSelectedProducts(
                      newSelectedProducts.map((product) => {
                        return {
                          ...product,
                          price: product.regularPrice * product.quantity,
                          vat: product.vat * product.quantity,
                        };
                      })
                    );
                    // setSelectedProducts(newSelectedProducts);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SingleOrderPopUpAddProductLineItem;

// Path: src/features/orders/components/SingleOrderTable/PopupActionsContent/SingleOrderPopUpAddProductLineItem.tsx
