import { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { returnPriceFormattedCHF } from "../../../../../shared/utils/numbersManipulations";
import {
  ordersDataAtom,
  selectedOrderAtom,
  selectedOrderDiscountLineItemsAtom,
  selectedOrderIndexAtom,
  selectedOrderMainTaxRateAtom,
  selectedOrderSelectedDiscountLineItemAtom,
} from "../../../atoms/orders-atoms";
import { OrderData } from "../../../types/orders";

interface SingleOrderLineItemsTotalPriceTableProps {
  totalWithoutTaxesBeforeFees: number;
  discountTotal: number;
  totalFees: number;
  totalWithoutTaxesAfterFees: number;
  totalVat: number;
  totalOrder: number;
}
interface SingleOrderLineItemsTotalPriceTableItemProps {
  label: string;
  value: number;
  fontSize?: string;
  underline?: boolean;
  isTotal?: boolean;
}
const SingleOrderLineItemsTotalPriceTableItem = ({
  label,
  value,
  fontSize = "text-xl",
  underline = false,
  isTotal = false,
}: SingleOrderLineItemsTotalPriceTableItemProps) => {
  return (
    <div
      className={`flex flex-row justify-between gap-x-6 ${fontSize} ${isTotal ? "font-bold text-[#ffffff]" : "text-[#3C7179]"
        }`}
    >
      <span className={`${underline && "underline"}`}>{label} : </span>
      <div className="flex min-w-[135px] justify-end">
        {/* <span className="font-bold">{returnPriceFormattedCHF(value)}</span> */}
        <span className="font-bold">{returnPriceFormattedCHF(value)}</span>
      </div>
    </div>
  );
};
export const returnTaxRate = (taxRate: number) => {
  // Check if taxRate is greater than 8.1
  if (taxRate > 8.1) {
    taxRate = 8.1; // Set taxRate to 8.1
  }

  // Convert taxRate to string
  const taxRateString = taxRate.toString();
  // Replace "." by ","
  const taxRateStringWithComma = taxRateString.replace(".", ",");
  // Return taxRate with "%" at the end
  return `TOTAL TVA (${taxRateStringWithComma}%)`;
}
const SingleOrderLineItemsTotalPriceTable = ({
  totalWithoutTaxesBeforeFees,
  discountTotal,
  totalFees,
  totalWithoutTaxesAfterFees,
  totalVat,
  totalOrder,
}: SingleOrderLineItemsTotalPriceTableProps) => {
  const selectedOrderDiscountLineItems = useRecoilValue(
    selectedOrderDiscountLineItemsAtom
  );
  const ordersData = useRecoilValue(ordersDataAtom);
  const selectedOrderIndex = useRecoilValue(selectedOrderIndexAtom);
  const selectedOrder = useRecoilValue(selectedOrderAtom);
  const [selectedOrderMainTaxRate, setSelectedOrderMainTaxRate] = useRecoilState(selectedOrderMainTaxRateAtom)
  // const selectedOrder: OrderData | null = selectedOrderIndex
  //   ? ordersData[selectedOrderIndex]
  //   : null;


  return (
    // <div className="flex flex-col items-start pt-6 bg-white rounded-lg shadow-md gap-y-4">
    <div className="flex flex-col pt-6 bg-white rounded-lg shadow-md gap-y-4">
      <div className="flex flex-col gap-y-2">
        <div className="flex flex-col px-6 pb-4 gap-y-3">
          {/* {totalWithoutTaxesBeforeFees !== totalWithoutTaxesAfterFees && ( */}
          <SingleOrderLineItemsTotalPriceTableItem
            // label="Total HT Avant rabais"
            // label={`Sous-Total HT ${
            //   selectedOrder?.fees?.length > 0 ? "" : ""
            // }`}
            label="SOUS-TOTAL (Hors taxes)"
            fontSize="text-lg"
            value={totalWithoutTaxesBeforeFees}
          />
          {/* )} */}
          {/* {selectedOrder && selectedOrder?.fees?.length > 0 ? (
          <SingleOrderLineItemsTotalPriceTableItem
            // label="Total HT Avant rabais"
            // label={`Sous-Total HT ${
            //   selectedOrder?.fees?.length > 0 ? "" : ""
            // }`}
            label="SOUS-TOTAL (Hors taxes)"
            value={totalWithoutTaxesBeforeFees}
          />
        ) : (
          <></>
        )} */}
          {discountTotal !== 0 ? (
            <SingleOrderLineItemsTotalPriceTableItem
              fontSize="text-lg"
              // label="Total HT (Total avant rabais - rabais)"
              label="RABAIS (Hors taxes)"
              value={-discountTotal}
            />
          ) : null}
          {selectedOrder && totalFees > 0 ? (
            <SingleOrderLineItemsTotalPriceTableItem
              fontSize="text-lg"
              // label="Montant HT du rabais"
              // label="Total HT rabais"
              label="FRAIS SUPP. (Hors taxes)"
              value={totalFees}
            />
          ) : (
            <></>
          )}

          <SingleOrderLineItemsTotalPriceTableItem
            fontSize="text-lg"
            // label="Total HT (Total avant rabais - rabais)"
            label="TOTAL (Hors taxes)"
            value={totalWithoutTaxesAfterFees}
          />
          <SingleOrderLineItemsTotalPriceTableItem
            fontSize="text-lg"
            // label="Total TVA (calculé par rapport au total HT)"
            label={returnTaxRate(selectedOrderMainTaxRate)}
            value={totalVat}
          />
        </div>
        <div className="rounded-b-md bg-[#3c7179] px-6 py-4">
          <SingleOrderLineItemsTotalPriceTableItem
            fontSize="text-xl"
            label="TOTAL COMMANDE"
            value={totalOrder}
            isTotal={true}
          />
        </div>
        {/* <p>Total HT Avant rabais</p> */}
        {/* <p>Montant HT du rabais</p>
        <p>Total HT (Total avant rabais - rabais)</p>
        <p>Total TVA (calculé par rapport au total HT)</p>
        <p>Total de la commande</p>
        <SingleOrderLineItemsTotalPriceTableItem
          label="Sous Total HT"
          value={totalWithoutTaxesBeforeFees}
        />
        {selectedOrderDiscountLineItems.length > 0 && (
          <SingleOrderLineItemsTotalPriceTableItem
            label="Total avant rabais"
            fontSize="text-md"
            value={selectedOrder ? selectedOrder.total : 0}
          />
        )}
        <SingleOrderLineItemsTotalPriceTableItem
          label="Total HT"
          value={totalWithoutTaxesBeforeFees}
        />
        <SingleOrderLineItemsTotalPriceTableItem
          label="Total TVA"
          value={totalVat}
        />
        <SingleOrderLineItemsTotalPriceTableItem
          label="Total de la commande"
          value={totalOrder}
        /> */}
      </div>
    </div>
  );
};

export default SingleOrderLineItemsTotalPriceTable;

// Path: src/features/orders/components/SingleOrderLineItemsTotalPriceTable.tsx
