import { CheckIcon, PencilIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { useRef, useState } from "react";

interface SingleCareInfoCardsItemDataProps {
  title?: string;
  value?: any;
  setValue?: (arg0: string) => void;
  link?: string;
  isEditable?: boolean;
  itemsCenter?: boolean;
}
const SingleOrderInfoCardsItemData = ({
  title,
  value,
  setValue,
  link,
  isEditable = true,
  itemsCenter = false,
}: SingleCareInfoCardsItemDataProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  return link ? (
    <div className="flex flex-col items-start">
      <button
        type="button"
        className="border-none bg-none"
        onClick={() => console.log(link)}
      >
        <span className="underline">{title}</span>
      </button>
    </div>
  ) : (
    <div className={`flex flex-col ${itemsCenter && "items-center"}`}>
      {title && value && (
        <div className="flex flex-row items-center gap-x-4">
          <span className="font-bold text-[#3C7179]">{title}</span>
          {isEditable && (
            <div
              className={`flex cursor-pointer flex-row gap-x-2 ${
                isEditing && "cursor-auto opacity-0"
              }`}
            >
              <div
                className="p-[6px] text-gray-400 transition hover:text-gray-600"
                onClick={() => {
                  setIsEditing(true);
                  inputRef.current?.focus();
                }}
              >
                <PencilIcon className="w-5" />
              </div>
            </div>
          )}
        </div>
      )}
      <div className="flex flex-row items-center gap-x-4">
        {value && (
          <div className={`${isEditing ? "bg-gray-50" : ""} grow rounded`}>
            {!isEditing && <span>{value}</span>}
            {isEditing && (
              <textarea
                // disabled={!isEditing}
                readOnly={!isEditing}
                rows={6}
                ref={inputRef}
                className={`${
                  isEditing && "w-full"
                } w-full rounded border-none bg-transparent p-1 focus:outline-none`}
                defaultValue={value !== "-" ? value : ""}
                placeholder={value === "Non renseigné" ? value : ""}
                autoFocus={isEditing}
              />
            )}
          </div>
        )}
        {title && !value && (
          <span className="font-bold text-[#3C7179]">{title}</span>
        )}
        {/* Edit icons */}
        {isEditable && (
          <div>
            {isEditing && (
              <div className="flex flex-row items-center gap-x-2">
                <div
                  onClick={() => {
                    setIsEditing(false);
                    inputRef.current?.value !== undefined &&
                      setValue &&
                      setValue(inputRef.current?.value);
                  }}
                  className="cursor-pointer rounded-full p-1 text-green-400 transition hover:bg-green-100 hover:shadow"
                >
                  <CheckIcon className="w-6" />
                </div>
                <div className="cursor-pointer rounded-full p-1 text-red-400 transition hover:bg-red-100 hover:shadow">
                  <XMarkIcon
                    className="w-6"
                    onClick={() => setIsEditing(false)}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleOrderInfoCardsItemData;
