import { ArrowLeftCircleIcon } from "@heroicons/react/24/outline";
import { Fragment, useRef, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Dialog } from "@headlessui/react";
import {
  EyeIcon,
  PencilIcon,
  CheckCircleIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { createColumnHelper } from "@tanstack/react-table";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import CardWithData from "../../../shared/ui/components/CardWithData";
import Pill from "../../../shared/ui/components/Pill";
import Table from "../../../shared/ui/components/Table";
import PageLayout from "../../../shared/ui/layouts/PageLayout";
import { returnPriceFormattedCHF } from "../../../shared/utils/numbersManipulations";
import SingleOrderInfoCardsWithTitle from "../../orders/layouts/SingleOrderInfoCardsWithTitle";
import TableWithTitle from "../../orders/layouts/TableWithTitle";
import { OrderData } from "../../orders/types/orders";
import { selectedStandAtom } from "../atoms/stands-atoms";
// import SinglePostInfoCardsItemData from "../components/SinglePostInfoCardsItemData";
import axios from "axios";
import { returnRootUrlBasedOnAppLocation } from "../../../shared/utils/checkIfIsInlocal";
import ActionPopup from "../../../shared/ui/components/ActionPopup";
import CustomDropzone from "../../../shared/ui/components/ImageDropzone";
import Loader from "../../../shared/ui/components/Loader";
import SinglePostInfoCardsItemData from "../../../shared/ui/components/SinglePostInfoCardsItemData";
import { selectedPostAtom } from "../../../shared/atoms/single-data-atoms";
import { ConferenceFlagAtom } from "../../../shared/atoms/flag-atoms";
// import SingleExhibitionCards from "../layouts/SingleStandCards";

interface EditableDataActionButtonsProps {
  handleEdit: () => void;
  handleValidate: () => void;
  handleCancel: () => void;
  isEditing: boolean;
}

const EditableDataActionButtons = ({
  handleEdit,
  handleValidate,
  handleCancel,
  isEditing,
}: EditableDataActionButtonsProps) => {
  return (
    <div>
      {/* Default state */}
      {!isEditing && (
        <div className="flex flex-row gap-x-2">
          <div
            className="cursor-pointer p-[6px] text-gray-200 transition hover:text-gray-600"
            onClick={() => {
              handleEdit();
            }}
          >
            <PencilIcon className="w-5" />
          </div>
        </div>
      )}
      {/* Editing state  */}
      {isEditing && (
        <div className="flex flex-row items-center gap-x-2">
          <div
            onClick={() => {
              handleValidate();
            }}
            className="cursor-pointer rounded-full p-1 text-green-400 transition hover:bg-green-100 hover:shadow"
          >
            <CheckIcon className="w-6" />
          </div>
          <div className="cursor-pointer rounded-full p-1 text-red-400 transition hover:bg-red-100 hover:shadow">
            <XMarkIcon className="w-6" onClick={() => handleCancel()} />
          </div>
        </div>
      )}
    </div>
  );
};

interface CardWithHeaderProps {
  title: string;
  children: React.ReactNode;
  isEditable?: boolean;
  centerElements?: boolean;
  justifyCenter?: boolean;
  width?: string;
  headerChildren?: React.ReactNode;
}
const CardWithHeader = ({
  title,
  children,
  isEditable = false,
  centerElements = false,
  justifyCenter = true,
  width = "",
  headerChildren = null,
}: CardWithHeaderProps) => {
  return (
    <div className={`${width} flex grow flex-col rounded-lg bg-white shadow`}>
      <div className="flex w-full flex-row items-center justify-between rounded-lg rounded-b-none bg-[#14B8A6] px-6 py-3 font-bold text-white">
        <div className="flex flex-row items-center justify-between ">
          <span className="text-xl">{title}</span>
          {isEditable && <PencilIcon className="w-5" />}
        </div>
        <>{headerChildren}</>
      </div>
      <div
        className={`flex grow flex-col ${
          justifyCenter && "justify-center"
        } px-6 py-4 ${centerElements && "mx-auto"}`}
      >
        {children}
      </div>
    </div>
  );
};

interface StandStatus {
  id: number;
  name: string;
}
const SingleStandScreen = () => {
  let token = "";
  if (localStorage.getItem("token-dashboard")) {
    token = JSON.parse(localStorage.getItem("token-dashboard") as string);
  }
  const standId = window.location.href.split("/").pop();
  const [selectedStandData, setSelectedStandData] =
    useRecoilState(selectedStandAtom);
  const [standStatuses, setStandStatuses] = useState<StandStatus[]>([
    {
      id: 0,
      name: "-",
    },
  ]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedStandStatus, setSelectedStandStatus] = useState<StandStatus>(
    standStatuses[0]
  );
  const selected = useRecoilValue(ConferenceFlagAtom);
  const [isUpdatingStand, setIsUpdatingStand] = useState(false);
  const [isDeletingStands, setIsDeletingStands] = useState(false);
  const [selectedPost, setSelectedPost] = useRecoilState(selectedPostAtom);
  const [isOpen, setIsOpen] = useState(false);

  const [isEditingStandNumber, setIsEditingStandNumber] = useState(false);
  const [isEditingExhibition, setIsEditingExhibition] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isConfirmDeletionPopupVisible, setIsConfirmDeletionPopupVisible] =
    useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const [files, setFiles] = useState<Object[]>();

  const handleEdit = () => {
    setIsEditingStandNumber(true);
    inputRef.current?.focus();
  };
  const handleValidate = () => {
    setIsEditingStandNumber(false);

    if (selectedStandData && inputRef.current?.value) {
      setSelectedStandData({
        ...selectedStandData,
        orderAndStandInfo: {
          ...selectedStandData.orderAndStandInfo,
          standNumber: inputRef.current?.value,
        },
      });
    }
  };
  const handleCancel = () => {
    setIsEditingStandNumber(false);
  };

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const fetchStandsAndStatusesData = async () => {
    setIsLoading(true);
    await axios
      .get(
        `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v1/stands?id=${standId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setSelectedStandData(res.data[0]);
        setSelectedPost(res.data[0]);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.code.includes("jwt")) {
          console.log(err);
          localStorage.removeItem("token-dashboard");
          window.location.replace("/");
        }
      });
    // Fetch admin-dashboard/v1/stands-statuses
    axios
      .get(
        `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v1/stands-statuses`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setStandStatuses(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.code.includes("jwt")) {
          console.log(err);
          localStorage.removeItem("token-dashboard");
          window.location.replace("/");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (selectedStandData) {
      setIsLoading(false);
    }
    fetchStandsAndStatusesData();
  }, []);

  useEffect(() => {
    if (standStatuses)
      selectedStandData &&
        setSelectedStandStatus(
          standStatuses.find(
            (standStatus) =>
              standStatus.name ===
              selectedStandData.orderAndStandInfo.standStatus
          ) as StandStatus
        );
  }, [standStatuses]);
  const popups = [
    <ActionPopup
      actionRunning={isDeletingStands}
      key={Math.random()}
      title="Supprimer le stand ?"
      children={
        <p>Voulez-vous vraimer supprimer le stand {selectedStandData?.id} ?</p>
      }
      isVisible={isConfirmDeletionPopupVisible}
      setIsVisible={() => {
        setIsConfirmDeletionPopupVisible(!isConfirmDeletionPopupVisible);
      }}
      action={() => {
        // !isDeletingStands && deleteWorkshop();
        !isDeletingStands && deleteStand();
      }}
      cancelAction={() => {
        setIsConfirmDeletionPopupVisible(false);
      }}
      actionButtonDisabled={false}
      actionButtonTitle={
        <div
          className={`flex flex-row items-center ${
            isDeletingStands && "cursor-wait"
          }`}
        >
          {isDeletingStands && (
            <svg
              className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}
          {isDeletingStands ? "Suppression..." : "Supprimer"}
        </div>
      }
      cancelButtonVisible={true}
      actionButtonBackgroundColor="#f87171"
    />,
    <ActionPopup
      key={Math.random()}
      title="Stand mis à jour"
      children={
        <p>Le stand {selectedStandData?.id} a été mis à jour avec succès</p>
      }
      isVisible={isPopupVisible}
      setIsVisible={() => {
        setIsPopupVisible(!isPopupVisible);
        window.location.reload();
      }}
      action={() => {
        setIsPopupVisible(false);
        window.location.reload();
      }}
      cancelAction={() => {
        setIsPopupVisible(false);
        window.location.reload();
      }}
      actionButtonDisabled={false}
      actionButtonTitle="Fermer"
      cancelButtonVisible={false}
    />,
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-[1200px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="mb-4 flex justify-end">
                  <button
                    type="button"
                    // className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={closeModal}
                  >
                    <XCircleIcon className="w-8 text-[#0F766E]" />
                  </button>
                </div>
                <div className="flex items-center justify-center">
                  <img src={selectedStandData?.adsProduct?.imageUrl} alt="" />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>,
  ];
  const deleteStand = () => {
    if (selectedStandData) {
      setIsDeletingStands(true);
      axios
        .post(
          `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v1/delete-stand/`,
          {
            id: selectedStandData?.id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((data) => {
          console.log("data", data);
          navigate(`/stands`);
          window.location.reload();
        })
        .catch((error) => {
          console.log("error", error);
          if (error.code.includes("jwt")) {
            localStorage.removeItem("token-dashboard");
            window.location.replace("/");
          }
        })
        .finally(() => {
          setIsDeletingStands(false);
          // setIsPopupVisible(true);
        });
    }
  };

  const phoneWithPrefix = selectedStandData?.exhibitorInfo?.phone;

  const phoneWithoutPrefix = phoneWithPrefix?.replace(/^\+\d+/, "");

  useEffect(() => {
    console.log("------------PHONE WITH PREFIX----------", phoneWithPrefix);
    console.log(
      "------------PHONE WITHOUT PREFIX----------",
      phoneWithoutPrefix
    );
  }, [phoneWithoutPrefix]);
  return (
    <PageLayout>
      <>
        {popups}
        {isLoading ? (
          <Loader />
        ) : (
          <div className="flex flex-col gap-y-16">
            <div className="flex w-full justify-between">
              <Link
                className="flex flex-row items-center gap-x-4 text-2xl text-[#3c7179]"
                to={`/stands`}
                onClick={() => {
                  setSelectedStandData(null);
                }}
              >
                <ArrowLeftCircleIcon className="color-[#3C7179] w-10" /> Retour
                à la liste des stands
              </Link>
              <div className="flex flex-row items-center gap-x-8">
                <button
                  disabled={isDeletingStands}
                  // className="flex flex-row items-center gap-x-4 rounded border-none bg-[#14b8a6] px-4 py-2 text-lg text-gray-50 shadow"
                  className={`${
                    isDeletingStands && "opacity-40"
                  } flex flex-row items-center rounded-md border-none bg-red-400 px-8 py-4 text-lg font-semibold text-white shadow`}
                  onClick={() => {
                    // if (selectedStandData) {
                    //   setIsDeletingStands(true);
                    //   axios
                    //     .post(
                    //       `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v1/delete-stand/`,
                    //       {
                    //         id: selectedStandData.id,
                    //       },
                    //       {
                    //         headers: {
                    //           Authorization: `Bearer ${token}`,
                    //         },
                    //       }
                    //     )
                    //     .then((data) => {
                    //       console.log("data", data);
                    //       navigate(`/conferences`);
                    //       window.location.reload();
                    //     })
                    //     .catch((error) => {
                    //       console.log("error", error);
                    //       if (error.code.includes("jwt")) {
                    //         localStorage.removeItem("token-dashboard");
                    //         window.location.replace("/");
                    //       }
                    //     })
                    //     .finally(() => {
                    //       setIsDeletingStands(false);
                    //       // setIsPopupVisible(true);
                    //     });
                    // }
                    setIsConfirmDeletionPopupVisible(true);
                  }}
                  type="button"
                >
                  Supprimer
                  {/* {isDeletingStands && (
                    <svg
                      className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                  {isDeletingStands ? "Suppression..." : "Supprimer"} */}
                </button>
                <button
                  disabled={isUpdatingStand}
                  // className="flex flex-row items-center gap-x-4 rounded border-none bg-[#14b8a6] px-4 py-2 text-lg text-gray-50 shadow"
                  className={`${
                    isUpdatingStand && "opacity-40"
                  } flex flex-row items-center rounded-md border-none bg-[#14b8a6] px-8 py-4 text-lg font-semibold text-white shadow`}
                  onClick={() => {
                    if (selectedStandData) {
                      setIsUpdatingStand(true);
                      console.log("SELECTED ", selectedStandData);
                      axios
                        .post(
                          `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v1/stands/`,
                          {
                            standId: selectedStandData.id,
                            standStatus: selectedStandStatus.name,
                            standNumber:
                              selectedStandData.orderAndStandInfo.standNumber,
                            adminComments: selectedStandData.adminComments,
                            orderId:
                              selectedStandData.orderAndStandInfo.orderNumber,
                            userId: selectedStandData.standGeneralInfo.userId,
                            standsAds: selectedStandData.adsProduct,
                            topCardsInfo: {
                              company:
                                selectedStandData.standGeneralInfo.company,
                              firstName:
                                selectedStandData.standGeneralInfo.firstName,
                              lastName:
                                selectedStandData.standGeneralInfo.lastName,
                              email: selectedStandData.exhibitorInfo.email,
                              phone: selectedStandData.exhibitorInfo.phone,
                              website: selectedStandData.exhibitorInfo.website,
                              activityType:
                                selectedStandData.exhibitorInfo.activityType,
                              hasAllreadyBeenToExhibition:
                                selectedStandData.standGeneralInfo
                                  .hasAllreadyBeenToExhibition,
                            },
                            billing_country_code: selected,
                          },
                          {
                            headers: {
                              Authorization: `Bearer ${token}`,
                            },
                          }
                        )
                        .then((data) => {
                          console.log("data", data);
                        })
                        .catch((error) => {
                          console.log("error", error);
                          if (error.code.includes("jwt")) {
                            localStorage.removeItem("token-dashboard");
                            window.location.replace("/");
                          }
                        })
                        .finally(() => {
                          setIsUpdatingStand(false);
                          setIsPopupVisible(true);
                        });
                    }
                  }}
                  type="button"
                >
                  {isUpdatingStand && (
                    <svg
                      className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                  {isUpdatingStand ? "Mise à jour..." : "Mettre à jour"}
                </button>
              </div>
            </div>
            <>
              {/* Content */}
              <div className="flex flex-col gap-y-11">
                <div className="grid grid-cols-3 gap-x-16">
                  {/* Left */}
                  <div className="col-span-2 flex flex-col gap-y-11">
                    {/* Top */}
                    <div className="flex flex-row gap-x-6">
                      <CardWithData width="33%">
                        <SinglePostInfoCardsItemData
                          isEditable={true}
                          title="Raison sociale"
                          value={
                            selectedStandData?.standGeneralInfo?.company || "-"
                          }
                          setValue={(value) => {
                            setSelectedStandData((prevSelectedPostData) => {
                              if (!prevSelectedPostData) {
                                return null;
                              }
                              return {
                                ...prevSelectedPostData,
                                standGeneralInfo: {
                                  ...prevSelectedPostData.standGeneralInfo,
                                  company: value,
                                },
                              };
                            });
                          }}
                        />
                        <SinglePostInfoCardsItemData
                          isEditable={true}
                          title="Prénom"
                          value={
                            selectedStandData?.standGeneralInfo?.firstName ||
                            "-"
                          }
                          setValue={(value) => {
                            setSelectedStandData((prevSelectedPostData) => {
                              if (!prevSelectedPostData) {
                                return null;
                              }
                              return {
                                ...prevSelectedPostData,
                                standGeneralInfo: {
                                  ...prevSelectedPostData.standGeneralInfo,
                                  firstName: value,
                                },
                              };
                            });
                          }}
                        />
                        <SinglePostInfoCardsItemData
                          isEditable={true}
                          title="Nom"
                          value={
                            selectedStandData?.standGeneralInfo?.lastName || "-"
                          }
                          setValue={(value) => {
                            setSelectedStandData((prevSelectedPostData) => {
                              if (!prevSelectedPostData) {
                                return null;
                              }
                              return {
                                ...prevSelectedPostData,
                                standGeneralInfo: {
                                  ...prevSelectedPostData.standGeneralInfo,
                                  lastName: value,
                                },
                              };
                            });
                          }}
                        />
                        <div className="mt-5"></div>
                        <SinglePostInfoCardsItemData
                          isEditable={true}
                          isSelect={true}
                          title="Déjà venu au salon"
                          value={
                            selectedStandData?.standGeneralInfo
                              ?.hasAllreadyBeenToExhibition
                              ? "Oui"
                              : "Non"
                          }
                          setValue={(value) => {
                            setSelectedStandData((prevSelectedPostData) => {
                              if (!prevSelectedPostData) {
                                return null;
                              }
                              return {
                                ...prevSelectedPostData,
                                standGeneralInfo: {
                                  ...prevSelectedPostData.standGeneralInfo,
                                  hasAllreadyBeenToExhibition:
                                    value === "true" ? true : false,
                                },
                              };
                            });
                          }}
                        />
                      </CardWithData>
                      <CardWithData width="33%">
                        <SinglePostInfoCardsItemData
                          email={selectedStandData?.exhibitorInfo?.email || "-"}
                          isEditable={true}
                          title="Email"
                          value={selectedStandData?.exhibitorInfo?.email || "-"}
                          // value={
                          //   selectedStandData?.exhibitorInfo?.email ? (
                          //     <a
                          //       className="underline"
                          //       href={`mailto:${selectedStandData?.exhibitorInfo?.email}`}
                          //     >
                          //       {selectedStandData?.exhibitorInfo?.email}
                          //     </a>
                          //   ) : (
                          //     "-"
                          //   )
                          // }
                          setValue={(value) => {
                            setSelectedStandData((prevSelectedPostData) => {
                              if (!prevSelectedPostData) {
                                return null;
                              }
                              return {
                                ...prevSelectedPostData,
                                exhibitorInfo: {
                                  ...prevSelectedPostData.exhibitorInfo,
                                  email: value,
                                },
                              };
                            });
                          }}
                        />
                        <SinglePostInfoCardsItemData
                          isEditable={true}
                          phone={phoneWithPrefix}
                          title="Téléphone"
                          value={phoneWithoutPrefix}
                          setValue={(value) => {
                            setSelectedStandData((prevSelectedPostData) => {
                              if (!prevSelectedPostData) {
                                return null;
                              }
                              return {
                                ...prevSelectedPostData,
                                exhibitorInfo: {
                                  ...prevSelectedPostData.exhibitorInfo,
                                  phone: value,
                                },
                              };
                            });
                          }}
                        />
                        <SinglePostInfoCardsItemData
                          isEditable={true}
                          title="Site internet"
                          website={
                            selectedStandData?.exhibitorInfo?.website || "-"
                          }
                          value={
                            selectedStandData?.exhibitorInfo?.website || "-"
                          }
                          setValue={(value) => {
                            setSelectedStandData((prevSelectedPostData) => {
                              if (!prevSelectedPostData) {
                                return null;
                              }
                              return {
                                ...prevSelectedPostData,
                                exhibitorInfo: {
                                  ...prevSelectedPostData.exhibitorInfo,
                                  website: value,
                                },
                              };
                            });
                          }}
                          // value={
                          //   selectedStandData?.exhibitorInfo?.website &&
                          //   selectedStandData?.exhibitorInfo?.website !==
                          //     "-" ? (
                          //     <a
                          //       target="_blank"
                          //       href={selectedStandData?.exhibitorInfo?.website}
                          //       title="Site internet"
                          //       className="underline"
                          //     >
                          //       {selectedStandData?.exhibitorInfo?.website}
                          //     </a>
                          //   ) : (
                          //     "-"
                          //   )
                          // }
                        />
                        <div className="mt-5"></div>
                        <SinglePostInfoCardsItemData
                          isEditable={true}
                          title="Type d'activité"
                          value={
                            selectedStandData?.exhibitorInfo?.activityType ||
                            "-"
                          }
                          setValue={(value) => {
                            setSelectedStandData((prevSelectedPostData) => {
                              if (!prevSelectedPostData) {
                                return null;
                              }
                              return {
                                ...prevSelectedPostData,
                                exhibitorInfo: {
                                  ...prevSelectedPostData.exhibitorInfo,
                                  activityType: value,
                                },
                              };
                            });
                          }}
                        />
                      </CardWithData>
                      <CardWithData width="33%">
                        <SinglePostInfoCardsItemData
                          isEditable={false}
                          title="Numéro de commande"
                          value={
                            // selectedStandData?.orderAndStandInfo?.orderNumber || "-"
                            selectedStandData?.orderAndStandInfo?.orderNumber &&
                            selectedStandData.orderAndStandInfo?.orderNumber !==
                              "-" ? (
                              <Link
                                to={`/commandes/${selectedStandData?.orderAndStandInfo?.orderNumber}`}
                                onClick={() =>
                                  localStorage.setItem("selectedMenuIndex", "0")
                                }
                              >
                                <span className="underline">
                                  {selectedStandData?.orderAndStandInfo
                                    ?.orderNumber || "-"}
                                </span>
                              </Link>
                            ) : (
                              "-"
                            )
                          }
                        />
                        <SinglePostInfoCardsItemData
                          isEditable={false}
                          title="Statut de commande"
                          value={
                            selectedStandData?.orderAndStandInfo?.orderStatus ||
                            "-"
                          }
                        />
                        <SinglePostInfoCardsItemData
                          isEditable={false}
                          title="Statut du stand"
                          // value={selectedStandData?.orderAndStandInfo?.standStatus}
                          value={
                            <Listbox
                              value={selectedStandStatus}
                              onChange={setSelectedStandStatus}
                            >
                              <div className="relative mt-1">
                                <Listbox.Button className="relative w-full cursor-pointer rounded-lg border bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                  <span className="block truncate">
                                    {selectedStandStatus &&
                                      selectedStandStatus.name}
                                  </span>
                                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronUpDownIcon
                                      className="h-5 w-5 text-gray-400"
                                      aria-hidden="true"
                                    />
                                  </span>
                                </Listbox.Button>
                                <Transition
                                  as={Fragment}
                                  leave="transition ease-in duration-100"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                >
                                  <Listbox.Options className="absolute z-[1] mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {standStatuses.map((person, personIdx) => (
                                      <Listbox.Option
                                        key={personIdx}
                                        className={({ active }) =>
                                          `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                                            active
                                              ? "bg-amber-100 text-amber-900"
                                              : "text-gray-900"
                                          }`
                                        }
                                        value={person}
                                      >
                                        {({ selected }) => (
                                          <>
                                            <span
                                              className={`block truncate ${
                                                selected
                                                  ? "font-medium"
                                                  : "font-normal"
                                              }`}
                                            >
                                              {person.name}
                                            </span>
                                            {selected ? (
                                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                <CheckIcon
                                                  className="h-5 w-5"
                                                  aria-hidden="true"
                                                />
                                              </span>
                                            ) : null}
                                          </>
                                        )}
                                      </Listbox.Option>
                                    ))}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            </Listbox>
                          }
                        />
                        <div className="mt-5"></div>
                        <div>
                          {selectedStandData?.orderAndStandInfo
                            ?.badgeExhibitorUrl ? (
                            <SinglePostInfoCardsItemData
                              isEditable={false}
                              title="Télécharger le badge exposant"
                              link={
                                selectedStandData?.orderAndStandInfo
                                  ?.badgeExhibitorUrl || "#"
                              }
                            />
                          ) : null}
                          {selectedStandData?.orderAndStandInfo
                            ?.badgeParkingUrl ? (
                            <SinglePostInfoCardsItemData
                              isEditable={false}
                              title="Télécharger le badge parking"
                              link={
                                selectedStandData?.orderAndStandInfo
                                  ?.badgeParkingUrl || "#"
                              }
                            />
                          ) : null}
                        </div>
                      </CardWithData>
                    </div>
                    {/* Bottom */}
                    <CardWithHeader
                      title={selectedStandData?.standGeneralInfo.company || "-"}
                      headerChildren={
                        <div className="flex flex-row items-center gap-x-8">
                          {selectedStandData?.standGeneralInfo?.exhibition
                            .name ? (
                            <Pill
                              label={
                                selectedStandData?.standGeneralInfo?.exhibition
                                  .name
                              }
                              bgColor={
                                selectedStandData?.standGeneralInfo.exhibition
                                  .primaryColor
                              }
                              textColor={
                                selectedStandData?.standGeneralInfo.exhibition
                                  .secondaryColor
                              }
                            />
                          ) : (
                            "-"
                          )}
                          {/* <div className="mx-auto rounded-full bg-[#f7f5ef] px-4 py-1 text-center">
                          <div className="flex flex-row items-center text-base font-bold gap-x-2">
                            <div className="text-[#0F766E]">Stand n°</div>
                            <div className="text-[#0F766E]">
                              {selectedStandData?.orderAndStandInfo
                                .standNumber || "-"}
                            </div>
                          </div>
                        </div> */}
                          <div className="flex flex-row items-center gap-x-2">
                            {!isEditingStandNumber && (
                              <div className="mx-auto rounded-full bg-[#f7f5ef] px-4 py-1 text-center">
                                <div className="flex flex-row items-center gap-x-2 text-base font-bold">
                                  <span className="text-[#0F766E]">
                                    Stand n°
                                    {
                                      selectedStandData?.orderAndStandInfo
                                        .standNumber
                                    }
                                  </span>
                                </div>
                              </div>
                            )}
                            {isEditingStandNumber && (
                              <input
                                type="text"
                                className="max-h-[32px] w-full max-w-[100px] grow rounded-full border-2 bg-gray-50 p-1 text-center text-[#0F766E] focus:outline-none"
                                placeholder={
                                  selectedStandData?.orderAndStandInfo
                                    .standNumber !== "-"
                                    ? selectedStandData?.orderAndStandInfo
                                        .standNumber
                                    : ""
                                }
                                ref={inputRef}
                                onChange={(e) => {
                                  // selectedStandData &&
                                  //   setSelectedStandData({
                                  //     ...selectedStandData,
                                  //     orderAndStandInfo: {
                                  //       ...selectedStandData.orderAndStandInfo,
                                  //       standNumber: e.target.value,
                                  //     },
                                  //   });
                                }}
                              />
                            )}
                            <EditableDataActionButtons
                              isEditing={isEditingStandNumber}
                              handleEdit={handleEdit}
                              handleValidate={handleValidate}
                              handleCancel={handleCancel}
                            />
                            {/* <div
              className={`flex cursor-pointer flex-row gap-x-2 ${
                isEditingStandNumber && ""
              }`}
            >
              <div
                className="p-[6px] text-gray-400 transition hover:text-gray-600"
                onClick={() => {
                  setIdEditingStandNumber(!isEditingStandNumber);
                  // inputRef.current?.focus();
                }}
              >
                <PencilIcon className="w-5" />
              </div>
            </div> */}
                          </div>
                        </div>
                      }
                    >
                      <div className="flex flex-col gap-y-8">
                        {/* Stand */}
                        <div className="flex flex-row items-center justify-between">
                          <SinglePostInfoCardsItemData
                            isEditable={false}
                            title="Type de stand"
                            value={
                              selectedStandData?.standGeneralInfo.standType ? (
                                <span>
                                  {
                                    selectedStandData?.standGeneralInfo
                                      .standType
                                  }
                                </span>
                              ) : (
                                "-"
                              )
                            }
                          />
                          <div className="flex flex-row items-center gap-x-6">
                            <SinglePostInfoCardsItemData
                              itemsCenter={true}
                              isEditable={false}
                              title="Parking"
                              value={
                                selectedStandData?.parkingProduct ? (
                                  <CheckCircleIcon className="w-8 text-green-500" />
                                ) : (
                                  <XCircleIcon className="w-8 text-gray-400" />
                                )
                              }
                            />
                            <SinglePostInfoCardsItemData
                              itemsCenter={true}
                              isEditable={false}
                              title="Meilleure visibilité"
                              value={
                                selectedStandData?.betterVisibilityProduct ? (
                                  <CheckCircleIcon className="w-8 text-green-500" />
                                ) : (
                                  <XCircleIcon className="w-8 text-gray-400" />
                                )
                              }
                            />
                          </div>
                        </div>
                        {/* Comment exhibitor */}
                        <SinglePostInfoCardsItemData
                          isEditable={false}
                          title="Commentaire exposant"
                          value={selectedStandData?.exhibitorComments || "-"}
                        />
                        {/* Comment admin */}
                        <SinglePostInfoCardsItemData
                          isEditable={true}
                          title="Commentaire admin"
                          value={selectedStandData?.adminComments || "-"}
                          setValue={(value) => {
                            selectedStandData &&
                              setSelectedStandData({
                                ...selectedStandData,
                                adminComments: value,
                              });
                          }}
                        />
                      </div>
                    </CardWithHeader>
                  </div>
                  {/* Right */}
                  <div className="col-span-1 flex flex-col">
                    <CardWithHeader
                      title="Publicité dans le programme"
                      justifyCenter={false}
                    >
                      {selectedStandData?.adsProduct ? (
                        <div className="flex flex-col gap-y-4">
                          <div className="flex flex-col gap-y-0">
                            <div className="flex flex-row items-center gap-x-2">
                              <span className="font-bold text-[#3C7179]">
                                {selectedStandData?.adsProduct?.title}
                              </span>
                              {selectedStandData?.adsProduct.imageUrl && (
                                <div className="flex flex-col items-start">
                                  <button
                                    className="flex items-center justify-center rounded-full bg-[#14B8A6] p-2 text-white shadow"
                                    onClick={() => {
                                      openModal();
                                    }}
                                  >
                                    <EyeIcon className="w-5" />
                                  </button>
                                </div>
                              )}
                            </div>
                            <span className="">
                              {selectedStandData.adsProduct.dimensions}
                            </span>
                          </div>
                          <div className="flex w-full flex-col justify-between">
                            <div className="flex flex-row gap-x-2">
                              <CustomDropzone
                                files={
                                  selectedStandData?.adsProduct?.imageUrl
                                    ? [
                                        {
                                          name: selectedStandData?.adsProduct
                                            ?.imageUrl,
                                          preview:
                                            selectedStandData?.adsProduct
                                              ?.imageUrl,
                                        },
                                      ]
                                    : []
                                }
                                setFiles={setFiles}
                                setValues={(value) =>
                                  selectedStandData &&
                                  setSelectedStandData({
                                    ...selectedStandData,
                                    adsProduct: {
                                      ...selectedStandData.adsProduct,
                                      imageUrl: value,
                                    },
                                  })
                                }
                              />
                            </div>
                          </div>
                          {/* <div className="flex flex-row items-stretch gap-x-2">
                          <div className="">
                            <img
                              src={selectedStandData?.adsProduct?.imageUrl}
                              alt=""
                            />
                          </div>
                          <div className="flex flex-col justify-between">
                            <div className="flex flex-row gap-x-2">
                              <button className="flex items-center justify-center rounded-full bg-[#14B8A6] p-2 text-white shadow">
                                <PencilIcon className="w-5" />
                              </button>
                              <button className="flex items-center justify-center rounded-full bg-[#14B8A6] p-2 text-white shadow">
                                <TrashIcon className="w-5" />
                              </button>
                            </div>
                            <div className="flex flex-col items-start">
                              <button
                                className="flex items-center justify-center rounded-full bg-[#14B8A6] p-2 text-white shadow"
                                onClick={() => {
                                  openModal();
                                }}
                              >
                                <EyeIcon className="w-5" />
                              </button>
                            </div>
                          </div>
                        </div> */}
                        </div>
                      ) : (
                        <span className="text-xl font-semibold">
                          Option non souscrite
                        </span>
                      )}
                    </CardWithHeader>
                  </div>
                </div>
              </div>
            </>
            {/* )} */}
          </div>
        )}
      </>
    </PageLayout>
  );
};

export default SingleStandScreen;
