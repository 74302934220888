import { SortingState } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import SectionTitle from "../../../shared/ui/components/SectionTitle";
import Table, { TableProps } from "../../../shared/ui/components/Table";

interface TableWithTitleProps {
  title?: string;
  subtittle?: string;
  tableData: TableProps["data"];
  tableColumns: TableProps["columns"];
  filtersComponents?: JSX.Element;
  paginationButtons?: JSX.Element;
  dropdown?: JSX.Element;
  onSortingChange?: (sorting: SortingState) => void; // Add this line
  enableSorting?: boolean;
  isLoading?: boolean;
}
const TableWithTitle = ({
  title,
  subtittle,
  tableData,
  tableColumns,
  filtersComponents,
  paginationButtons,
  dropdown,
  enableSorting = true,
  isLoading = true,
  onSortingChange, // Add this prop
}: TableWithTitleProps) => {
  const [sorting, setSorting] = useState<SortingState>([]);

  useEffect(() => {
    onSortingChange && onSortingChange(sorting); // Call the callback function whenever sorting changes
  }, [sorting, onSortingChange]);

  return (
    <div className="flex flex-col gap-y-6">
      <div className="relative z-10 flex flex-col">
        <>
          {title && <SectionTitle title={title} />}
          {subtittle && <p className="text-sm text-gray-500">{subtittle}</p>}
        </>
        {/* {filters && <FiltersSelects />} */}
        {filtersComponents && <div className="">{filtersComponents}</div>}
        {dropdown && <div className="mb-4 text-right">{dropdown}</div>}
        <>{paginationButtons && paginationButtons}</>
      </div>
      {tableData && (
        <Table
          isLoading={isLoading}
          data={tableData}
          columns={tableColumns}
          sorting={sorting}
          setSorting={setSorting}
          enableSorting={enableSorting}
        />
      )}
    </div>
  );
};

export default TableWithTitle;

// Path: src/features/orders/layouts/TableWithTitle.tsx
