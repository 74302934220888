interface CardWithDataProps {
  children: React.ReactNode;
  width?: string;
  gapY?: string;
}

const CardWithData = ({
  children,
  width = "25%",
  gapY = "8px",
}: CardWithDataProps) => {
  return (
    <div
      style={{ width: width, rowGap: gapY }}
      className="flex flex-col rounded-lg bg-white p-6 shadow"
    >
      {children}
    </div>
  );
};

export default CardWithData;

// Path: src/shared/ui/components/CardWithData.tsx
