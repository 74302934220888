import apiFetch from "@wordpress/api-fetch";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { returnRootUrlBasedOnAppLocation } from "../../../shared/utils/checkIfIsInlocal";
import { filteredTicketsDataAtom, ticketsDataAtom } from "../atoms/tickets-atoms";
import FiltersSelectComponent from "../../../shared/ui/components/FiltersSelectComponent";
import { Ticket } from "../types/ticket";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { XCircleIcon as XCircleIconSolid } from "@heroicons/react/24/solid";
import clearLocalStorage from "../../../shared/utils/clearLocalStorage";
import ClearTableFilters from "../../../shared/ui/components/ClearTableFilters";

const exhibitions = ["Tous les salons", "Bulle", "Morges", "Aigle", "Genève"];
let years = ["Toutes les années"];

const FiltersSelects = () => {
  const [selectedExhibitions, setSelectedExhibitions] = useState(
    exhibitions[0]
  );
  const [ticketsData, setTicketData] = useRecoilState(ticketsDataAtom);
  const [filteredTicketsData, setFilteredTicketsData] = useRecoilState(
    filteredTicketsDataAtom
  );
  const [selectedExhibitionYear, setSelectedExhibitionYear] = useState(
    years[0]
  );

  let exhibitionsYears: (string | undefined)[] = [];
  if (ticketsData) {
    exhibitionsYears = ticketsData?.map((ticket) => ticket.exhibition?.year);
  }
  // Remove duplicates
  const exhibitionsYearsUnique = exhibitionsYears?.filter(
    (year, index) => exhibitionsYears.indexOf(year) === index
  ) as string[];

  // Merge the years array with the years from the exhibitions
  if (exhibitionsYearsUnique) {
    years = years.concat(exhibitionsYearsUnique);

    // Insert 'Toutes les années' at the beginning of the array
    exhibitionsYearsUnique.unshift("Toutes les années");
    // Sort the array in descending ticket
    exhibitionsYearsUnique.sort((a, b) => {
      return parseInt(b) - parseInt(a);
    });
  }

  useEffect(() => {
    if (ticketsData) {
      if (ticketsData.length > 0) {
        if (localStorage.getItem("ticket-selected-exhibition")) {
          setSelectedExhibitions(
            localStorage.getItem("ticket-selected-exhibition") as string
          );
        }
        if (localStorage.getItem("ticket-selected-year")) {
          setSelectedExhibitionYear(
            localStorage.getItem("ticket-selected-year") as string
          );
        }
      }
    }
  }, [ticketsData]);

  useEffect(() => {
    if (ticketsData) {
      if (selectedExhibitions === exhibitions[0]) {
        if (selectedExhibitionYear === exhibitionsYearsUnique[0]) {
          setFilteredTicketsData(null);
        } else {
          setFilteredTicketsData(
            ticketsData.filter(
              (ticket) => ticket.exhibition.year === selectedExhibitionYear
            )
          );
        }
      } else {
        if (selectedExhibitionYear === exhibitionsYearsUnique[0]) {
          setFilteredTicketsData(
            ticketsData.filter(
              (ticket) =>
                ticket.exhibition.name.includes(selectedExhibitions)
            )
          );
        } else {
          setFilteredTicketsData(
            ticketsData.filter(
              (ticket) =>
                ticket.exhibition.name.includes(selectedExhibitions) &&
                ticket.exhibition.year === selectedExhibitionYear
            )
          );
        }
      }
      console.log("--------");
      console.log("selectedExhibitions", selectedExhibitions);
      console.log("selectedExhibitionYear", selectedExhibitionYear);
      console.log("--------");
    }
  }, [selectedExhibitions, selectedExhibitionYear]);

  const resetFilters = () => {
    setSelectedExhibitions(exhibitions[0]);
    setSelectedExhibitionYear(exhibitionsYearsUnique[0]);
  };

  const localStorageFiltersKeys = [
    "ticket-selected-exhibition",
    "ticket-selected-year",
  ];

  return (
    <div className="grow">
      <div className="flex flex-row items-center justify-end gap-x-6">
        <ClearTableFilters
          resetFilters={resetFilters}
          localStorageFiltersKeys={localStorageFiltersKeys}
        />
        <FiltersSelectComponent
          options={exhibitionsYearsUnique}
          selected={selectedExhibitionYear}
          setSelected={setSelectedExhibitionYear}
          type="year"
          postType="ticket"
        />
        <FiltersSelectComponent
          options={exhibitions}
          selected={selectedExhibitions}
          setSelected={setSelectedExhibitions}
          type="exhibition"
          postType="ticket"
        />
      </div>
    </div>
  );
};

export default FiltersSelects;
