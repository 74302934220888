import { Combobox, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ChevronUpDownIcon,
  PencilIcon,
  XMarkIcon,
  PlusCircleIcon,
} from "@heroicons/react/20/solid";
import { Fragment, useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Exhibition } from "../../../shared/types/sharedTypes";
import Pill from "../../../shared/ui/components/Pill";
import SectionTitle from "../../../shared/ui/components/SectionTitle";
import {
  availableExhibitionsNamesAtom,
  selectedOrderAtom,
} from "../atoms/orders-atoms";
import ActivityAddress from "../components/SingleOrder/SingleOrderDetails/ActivityAddress";
import InvoiceAddress from "../components/SingleOrder/SingleOrderDetails/InvoiceAddress";
import MainOrderInfo from "../components/SingleOrder/SingleOrderDetails/MainOrderInfo";
import PaymentAndInvoiceInfo from "../components/SingleOrder/SingleOrderDetails/PaymentAndInvoiceInfo";
import { SingleOrderInfoCardsProps } from "../types/orders";

interface ExhibitionsSelectorProps {
  selected: Exhibition | null;
  setSelected: (exhibition: Exhibition) => void;
  query: string;
  setQuery: (query: string) => void;
  filteredExhibitions: Exhibition[];
}
const ExhibitionsSelector = ({
  selected,
  setSelected,
  query,
  setQuery,
  filteredExhibitions,
}: ExhibitionsSelectorProps) => {
  useEffect(() => {
    console.log("RENDER = ExhibitionsSelector");
  }, []);
  return (
    <div className="w-72">
      <Combobox defaultValue={selected} onChange={setSelected} nullable>
        <div className="relative mt-1">
          <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
            <Combobox.Input
              className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0 focus-visible:ring-0"
              displayValue={(person: Exhibition) => (person ? person.name : "")}
              placeholder="Sélectionnez un salon"
              onChange={(event) => setQuery(event.target.value)}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery("")}
          >
            <Combobox.Options className="absolute z-[1] mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredExhibitions.length === 0 && query !== "" ? (
                <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                  Aucune correspondance.
                </div>
              ) : (
                filteredExhibitions.map((exhibition) => (
                  <Combobox.Option
                    key={exhibition.id}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? "bg-teal-600 text-white" : "text-gray-900"
                      }`
                    }
                    value={exhibition}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {exhibition.name || ""}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? "text-white" : "text-teal-600"
                            }`}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
};

const SingleOrderInfoCardsWithTitle = ({
  isNewOrder = false,
  mainOrderInfo,
  paymentAndInvoiceInfo,
  invoiceAddress,
  activityAddress,
}: SingleOrderInfoCardsProps) => {
  const orderId = useRecoilValue(selectedOrderAtom)?.orderId;
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingStandNumber, setIdEditingStandNumber] = useState(false);
  const [selectedOrder, setSelectedOrder] = useRecoilState(selectedOrderAtom);

  // interface SelectableExhibition {
  //   id: number;
  //   name: string;
  // }
  // const people: SelectableExhibition[] = [
  //   { id: 1, name: "Wade Cooper" },
  //   { id: 2, name: "Arlene Mccoy" },
  //   { id: 3, name: "Devon Webb" },
  //   { id: 4, name: "Tom Cook" },
  //   { id: 5, name: "Tanya Fox" },
  //   { id: 6, name: "Hellen Schmidt" },
  // ];

  const [selected, setSelected] = useState<Exhibition | null>();
  const [query, setQuery] = useState("");
  const [availableExhibitionsNames, setAvailableExhibitionsNames] =
    useRecoilState(availableExhibitionsNamesAtom);

  const inputRef = useRef<HTMLInputElement>(null);
  const handleEdit = () => {
    setIdEditingStandNumber(true);
    inputRef.current?.focus();
  };
  const handleValidate = () => {
    setIdEditingStandNumber(false);

    if (selectedOrder) {
      setSelectedOrder({
        ...selectedOrder,
        standNumber: inputRef.current?.value || "",
      });
    }
  };
  const handleCancel = () => {
    setIdEditingStandNumber(false);
  };

  const filteredExhibitions =
    query === ""
      ? availableExhibitionsNames
      : availableExhibitionsNames.filter((exhibition) =>
          exhibition.name
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  interface EditableDataActionButtonsProps {
    handleEdit: () => void;
    handleValidate: () => void;
    handleCancel: () => void;
    isEditing: boolean;
  }

  const EditableDataActionButtons = ({
    handleEdit,
    handleValidate,
    handleCancel,
    isEditing,
  }: EditableDataActionButtonsProps) => {
    return (
      <div>
        {/* Default state */}
        {!isEditing && (
          <div className="flex flex-row gap-x-2">
            <div
              className="cursor-pointer p-[6px] text-gray-400 transition hover:text-gray-600"
              onClick={() => {
                handleEdit();
              }}
            >
              <PencilIcon className="w-5" />
            </div>
          </div>
        )}
        {/* Editing state  */}
        {isEditing && (
          <div className="flex flex-row items-center gap-x-2">
            <div
              onClick={() => {
                handleValidate();
              }}
              className="cursor-pointer rounded-full p-1 text-green-400 transition hover:bg-green-100 hover:shadow"
            >
              <CheckIcon className="w-6" />
            </div>
            <div className="cursor-pointer rounded-full p-1 text-red-400 transition hover:bg-red-100 hover:shadow">
              <XMarkIcon className="w-6" onClick={() => handleCancel()} />
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-y-4">
      <div className="flex flex-row items-center gap-x-8">
        <SectionTitle
          title={
            !isNewOrder
              ? `Détails Commande n°${orderId ? orderId : 0}`
              : `Nouvelle commande`
          }
        />
        {/* <ExhibitionsSelector /> */}
        <div>
          {!isEditing && (
            <div className="flex flex-row items-center gap-x-2">
              {selectedOrder &&
                (selectedOrder.exhibition.name !== "" &&
                selectedOrder.exhibition.name !== "-" ? (
                  <Pill
                    label={selectedOrder.exhibition.name}
                    bgColor={selectedOrder.exhibition.primaryColor}
                    textColor={selectedOrder.exhibition.secondaryColor}
                  />
                ) : (
                  <span>Salon non renseigné</span>
                ))}
              <div
                className={`flex cursor-pointer flex-row gap-x-2 ${
                  isEditing && "cursor-auto opacity-0"
                }`}
              >
                <div
                  className="p-[6px] text-gray-400 transition hover:text-gray-600"
                  onClick={() => {
                    setIsEditing(true);
                    // inputRef.current?.focus();
                  }}
                >
                  {selectedOrder &&
                  (selectedOrder.exhibition.name === "" ||
                    selectedOrder.exhibition.name === "-") ? (
                    <PlusCircleIcon className="w-5" />
                  ) : (
                    <PencilIcon className="w-5" />
                  )}
                </div>
              </div>
            </div>
          )}
          {isEditing && (
            <div className="flex flex-row items-center gap-x-4">
              <ExhibitionsSelector
                filteredExhibitions={filteredExhibitions}
                query={query}
                setQuery={setQuery}
                setSelected={setSelected}
                selected={selected ? selected : null}
              />
              <div className="flex flex-row items-center gap-x-2">
                <div
                  onClick={() => {
                    setIsEditing(false);
                    console.log(selected);
                    !selected &&
                      selectedOrder &&
                      setSelectedOrder({
                        ...selectedOrder,
                        exhibition: {
                          id: 0,
                          name: "",
                          primaryColor: "#ffffff",
                          secondaryColor: "#000000",
                        },
                      });
                    selectedOrder &&
                      selected &&
                      setSelectedOrder({
                        ...selectedOrder,
                        exhibition: {
                          // id: 0,
                          // name: "",
                          // primaryColor: "",
                          // secondaryColor: "",
                          id: selected?.id,
                          name: selected?.name,
                          primaryColor: selected?.primaryColor,
                          secondaryColor: selected?.secondaryColor,
                        },
                      });
                  }}
                  className="cursor-pointer rounded-full p-1 text-green-400 transition hover:bg-green-100 hover:shadow"
                >
                  <CheckIcon className="w-6" />
                </div>
                <div className="cursor-pointer rounded-full p-1 text-red-400 transition hover:bg-red-100 hover:shadow">
                  <XMarkIcon
                    className="w-6"
                    onClick={() => {
                      setIsEditing(false);
                      // selected?.name === "" &&
                      selectedOrder?.exhibition.name === "" &&
                        setSelected(null);
                      // setSelected(null);
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        {/* StandNumber */}
        <div>
          <div className="flex flex-row items-center gap-x-2">
            {!isEditingStandNumber && (
              <div className="mx-auto rounded-full bg-[#f7f5ef] px-4 py-1 text-center">
                <div className="flex flex-row items-center gap-x-2 text-base font-bold">
                  <div className="text-[#0F766E]">Stand n°</div>
                  <div className="text-[#0F766E]">
                    {selectedOrder?.standNumber}
                  </div>
                </div>
              </div>
            )}
            {isEditingStandNumber}
            {isEditingStandNumber && (
              <input
                type="text"
                className="w-full grow rounded border-2 bg-gray-50 p-1 focus:outline-none"
                // value={
                //   selectedOrder?.standNumber !== "-"
                //     ? selectedOrder?.standNumber
                //     : ""
                // }
                placeholder={
                  selectedOrder?.standNumber !== "-"
                    ? selectedOrder?.standNumber
                    : ""
                }
                ref={inputRef}
                // onChange={(e) => {
                //   // selectedOrder &&
                //   //   setSelectedOrder({
                //   //     ...selectedOrder,
                //   //     standNumber: e.target.value,
                //   //   });
                // }}
              />
            )}
            <EditableDataActionButtons
              isEditing={isEditingStandNumber}
              handleEdit={handleEdit}
              handleValidate={handleValidate}
              handleCancel={handleCancel}
            />
            {/* <div
              className={`flex cursor-pointer flex-row gap-x-2 ${
                isEditingStandNumber && ""
              }`}
            >
              <div
                className="p-[6px] text-gray-400 transition hover:text-gray-600"
                onClick={() => {
                  setIdEditingStandNumber(!isEditingStandNumber);
                  // inputRef.current?.focus();
                }}
              >
                <PencilIcon className="w-5" />
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="flex w-full flex-row justify-between gap-x-6">
        {mainOrderInfo && <MainOrderInfo {...mainOrderInfo} />}
        {paymentAndInvoiceInfo && (
          <PaymentAndInvoiceInfo {...paymentAndInvoiceInfo} />
        )}
        {invoiceAddress && <InvoiceAddress {...invoiceAddress} />}
        {activityAddress && <ActivityAddress {...activityAddress} />}
      </div>
    </div>
  );
};

export default SingleOrderInfoCardsWithTitle;

// Path: src/features/orders/layouts/SingleOrderInfoCardsWithTitle.tsx
