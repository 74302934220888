import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/20/solid";

import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  SortingState,
} from "@tanstack/react-table";
import { useRecoilState } from "recoil";
import { isLoadingAtom } from "../../atoms/ui-atoms";
import { useEffect, useRef } from "react";
import { sortingTableColumnsAtom } from "../../../features/exhibitors/atoms/exhibitors-atoms";

function parseDate(dateString: any) {
  const [day, month, year] = dateString.split(" ");
  const months = [
    "janvier",
    "février",
    "mars",
    "avril",
    "mai",
    "juin",
    "juillet",
    "août",
    "septembre",
    "octobre",
    "novembre",
    "décembre",
  ];
  const monthIndex = months.indexOf(month.toLowerCase());
  return new Date(year, monthIndex, day).getTime();
}

export interface TableProps {
  data: any[];
  columns: any[];
  sorting?: SortingState;
  setSorting?: React.Dispatch<React.SetStateAction<SortingState>>;
  fetchData?: (sorting: SortingState) => void;
  enableSorting?: boolean;
  isLoading?: boolean;
}

const Table = ({
  data,
  columns,
  sorting,
  setSorting,
  fetchData,
  enableSorting = true,
  isLoading = true,
}: TableProps) => {
  // const [isLoading, setIsLoading] = useRecoilState(isLoadingAtom);
  const sortingTableColumns = useRef<SortingState>([]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    manualSorting: false,
    sortingFns: {
      date: (a, b) => {
        return parseDate(a) - parseDate(b);
        // return new Date(a).getTime() - new Date(b).getTime();
      },
    },
    onSortingChange: (newSorting) => {
      setSorting && setSorting(newSorting); // Update the internal state
    },
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
    enableSorting: enableSorting,
  });

  useEffect(() => {
    if (sorting && sorting.length > 0) {
      sortingTableColumns.current = sorting;
      console.log("sorting ", sorting[0]);
    }
  }, [sorting]);

  return (
    <div className="relative">
      <table className="w-full border-collapse overflow-hidden rounded-lg bg-[#14B8A6] shadow-md">
        <thead className="">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  className="border-none p-6 text-lg font-bold uppercase text-[#ffffff] 2xl:text-xl"
                  key={header.id}
                >
                  {header.isPlaceholder ? null : (
                    <div
                      {...{
                        className: header.column.getCanSort()
                          // ? "cursor-pointer select-none flex flex-row items-center justify-center gap-x-2"
                          ? "select-none flex flex-row items-center justify-center gap-x-2"
                          : "flex flex-row items-center justify-center gap-x-2",
                        // onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {{
                        asc: <ChevronUpIcon className="w-6 h-6" />,
                        desc: <ChevronDownIcon className="w-6 h-6" />,
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>
                  )}
                  {/* {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )} */}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {isLoading && (
          <tbody className="relative h-[100px]">
            <tr className="absolute flex items-center justify-center w-full h-full bg-white">
              <td>
                <span className="text-xl">Chargement des données...</span>
              </td>
            </tr>
          </tbody>
        )}
        {!isLoading && data.length === 0 && (
          <tbody className="relative h-[100px]">
            <tr className="absolute flex items-center justify-center w-full h-full bg-white">
              <td>
                <span className="text-xl">Aucun résultat</span>
              </td>
            </tr>
          </tbody>
        )}
        {!isLoading && data.length > 0 && (
          <tbody className="bg-white">
            {table.getRowModel().rows.map((row, index) => (
              <tr
                style={{
                  backgroundColor: index % 2 === 0 ? "#fff" : "#f7f5ef",
                }}
                key={row.id}
              >
                {row.getVisibleCells().map((cell) => (
                  <td
                    className="border-none p-6 text-center text-lg text-[#64748B] 2xl:text-xl"
                    key={cell.id}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        )}
      </table>
    </div>
  );
};

export default Table;

// // Path: src/shared/ui/components/Table.tsx
