import { ChevronRightIcon } from "@heroicons/react/20/solid";

interface ImageBoxProps {
  imageSrc: string;
  imageAlt: string;
  title: string;
  subtitle?: string;
  subtitleLink?: string;
  subtitleLinkTitle?: string;
}

const ImageBox = ({
  imageSrc,
  imageAlt,
  title,
  subtitle,
  subtitleLink,
  subtitleLinkTitle,
}: ImageBoxProps) => {
  return (
    <div className="flex items-center gap-x-4">
      <img
        src={imageSrc}
        alt={imageAlt}
        className="h-8 w-8 object-cover 2xl:h-16 2xl:w-16"
      />
      <div className="flex gap-x-2 text-xl text-white 2xl:flex-col">
        <span className="font-bold">{title}</span>
        {subtitleLink ? (
          <a
            className="flex flex-row items-end text-white underline"
            href={subtitleLink}
            title={subtitleLinkTitle}
            target="_blank"
            rel="noreferrer"
          >
            {subtitle}
            <ChevronRightIcon className="w-6" />
          </a>
        ) : (
          <span>{subtitle}</span>
        )}
      </div>
    </div>
  );
};

export default ImageBox;

// Path: src/shared/ui/components/ImageBox.tsx
