import { atom } from "recoil";
import {
  ActivityAddressType,
  CustomerProps,
  InvoiceAddressType,
} from "../../../shared/types/sharedTypes";
import { OrderData } from "../../orders/types/orders";

interface VisitorProps extends CustomerProps {
  adminComments?: string;
  newsletter?: boolean;
  orders?: OrderData[];
}
interface SingleVisitorInfoProps {
  id: number;
  company: string;
  firstName: string;
  lastName: string;
  haveAlreadyParticipated: boolean;
  email: string;
  phone: string;
  website: string;
  activityType: string;
  invoiceAddress: InvoiceAddressType;
  activityAddress: ActivityAddressType;
  adminComments: string;
  newsletter: boolean;
  orders: OrderData[];
  turnover: number;
}
export interface VisitorDataProps {
  id: number;
  company: string;
  companyNormalized: string;
  firstName: string;
  firstNameNormalized: string;
  lastName: string;
  lastNameNormalized: string;
  phone: string;
  email: string;
  site: string;
  singleInfo: SingleVisitorInfoProps;
}

export interface VisitorDataPropsV2 {
  id: number;
  billing_company: string;
  billing_first_name: string;
  billing_last_name: string;
  billing_email: string;
  billing_phone: string;
  user_url: string;
}

export interface SortingTableColumns {
  id: string;
  desc: boolean;
}

export const visitorsAtom = atom<VisitorDataProps[]>({
  key: "visitorsAtom",
  default: [],
});

export const selectedVisitorAtom = atom<VisitorDataProps | null>({
  key: "selectedVisitorAtom",
  default: null,
});

export const isEditingVisitorInvoiceAddressAtom = atom<boolean>({
  key: "isEditingVisitorInvoiceAddressAtom",
  default: false,
});

export const isEditingVisitorActivityAddressAtom = atom<boolean>({
  key: "isEditingVisitorActivityAddressAtom",
  default: false,
});

export const sortingTableColumnsAtom = atom<SortingTableColumns[]>({
  key: "sortingTableColumnsAtom",
  default: [],
});
