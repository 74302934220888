import { useEffect, useState } from "react";
import OverviewBoxesWithTitleLayout from "./OverviewBoxesWithTitleLayout";
import {
  fetchExhibitionIdAndOrdersCount
} from "../../../features/orders/api/fetchOrders";
import { useNavigate } from "react-router-dom";

interface OrdersOverviewBoxesLayoutProps {
  title?: string;
  customGlobalLabel?: string;
}

const init = [
  {
    exhibition_name: "Morges",
    exhibition_year: "-",
    exhibition_primary_color: "#89a8c9",
    exhibition_secondary_color: "#e1e7f4",
    orders: 0,
    loading: true,
  },
  {
    exhibition_name: "Bulle",
    exhibition_year: "-",
    exhibition_primary_color: "#c87575",
    exhibition_secondary_color: "#f1d9d9",
    orders: 0,
    loading: true,
  },
  {
    exhibition_name: "Aigle",
    exhibition_year: "-",
    exhibition_primary_color: "#e3ab50",
    exhibition_secondary_color: "#fee5b8",
    orders: 0,
    loading: true,
  },
  {
    exhibition_name: "Genève",
    exhibition_year: "-",
    exhibition_primary_color: "#87ac96",
    exhibition_secondary_color: "#d6e9be",
    orders: 0,
    loading: true,
  },
];
const OrdersOverviewBoxesLayout = ({
  title = "Commandes en cours",
  customGlobalLabel = '',
}: OrdersOverviewBoxesLayoutProps) => {
  const [exhibitions, setExhibitions] = useState<any[]>(init);

  useEffect(() => {
    const fetchAllExhibitionsData = async () => {
      fetchExhibitionIdAndOrdersCount().then((data) => {

        setExhibitions(data as any);
      });
    };

    fetchAllExhibitionsData();
  }, []);

  let navigate = useNavigate();

  const clickOnSeeExhibition = (exhibitionId: number) => {
    if (exhibitionId !== 0) {
      navigate(`/salons/${exhibitionId}`);
    } else {
      alert("Ce salon n'est pas encore ouvert");
    }
  };

  const children = exhibitions.map((exhibition) => {
    return {
      title: customGlobalLabel || exhibition.exhibition_name,
      label: exhibition.exhibition_year,
      primaryColor: exhibition.exhibition_primary_color,
      secondaryColor: exhibition.exhibition_secondary_color,
      value: exhibition.orders,
      loading: exhibition.loading,
      actionFunction: () => {
        exhibition.id ? clickOnSeeExhibition(exhibition.id) : alert("Une erreur est survenue");
      },
    };
  });

  return <OverviewBoxesWithTitleLayout title={title} children={children} />;
};

export default OrdersOverviewBoxesLayout;
