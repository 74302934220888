import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { useState } from "react";

interface PaginationButtonsProps {
  pageNumber: number;
  isLoading: boolean;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  numberOfPages: number;
  totalOfItems: number;
}
const PaginationButtons = ({
  pageNumber,
  isLoading,
  setPageNumber,
  numberOfPages,
  totalOfItems,
}: PaginationButtonsProps) => {
  return (
    <div className="flex items-center justify-between">
      <div className="flex justify-between flex-1 sm:hidden">
        <button
          className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
          disabled={pageNumber <= 0 || isLoading}
          onClick={() => setPageNumber(pageNumber - 1)}
        >
          Précédent
        </button>
        <button
          className="relative inline-flex items-center px-4 py-2 ml-3 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
          disabled={pageNumber === numberOfPages - 1}
          onClick={() => setPageNumber(pageNumber + 1)}
        >
          Suivant
        </button>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          {isLoading ? (
            <p className="text-sm text-gray-700">Chargement des résultats...</p>
          ) : (
            <p className="text-sm text-gray-700">
              Page <span className="font-medium">{pageNumber + 1}</span> sur{" "}
              <span className="font-medium">{numberOfPages}</span> pour{" "}
              <span className="font-medium">{totalOfItems}</span>{" "}
              {totalOfItems > 1 ? "résultats" : "résultat"}
            </p>
          )}
        </div>
        <div>
          <nav
            className="inline-flex -space-x-px rounded-md shadow-sm isolate"
            aria-label="Pagination"
          >
            <button
              className={`relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 ${
                pageNumber === 0 ? "cursor-default" : "cursor-pointer"
              }`}
              disabled={pageNumber === 0}
              onClick={() => setPageNumber(pageNumber - 1)}
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="w-5 h-5" aria-hidden="true" />
            </button>
            {Array.from({ length: numberOfPages }, (_, index) => (
              <button
                key={index}
                className={`relative inline-flex w-10 items-center justify-center ${
                  pageNumber === index
                    ? "focus:outline-[bg-[#14B8A6] rounded-none bg-[#14B8A6] text-white shadow"
                    : "rounded-none text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0"
                } ${
                  index === 0 || index === numberOfPages - 1 ? "px-4" : "px-2"
                } py-2 text-sm font-semibold ${
                  index === 0 || index === numberOfPages - 1 ? "rounded-md" : ""
                }`}
                onClick={() => setPageNumber(index)}
                disabled={isLoading}
              >
                {index + 1}
              </button>
            ))}
            <button
              className={`relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 ${
                pageNumber === numberOfPages - 1
                  ? "cursor-default"
                  : "cursor-pointer"
              }`}
              // TODO: Handle disabled state when loading
              disabled={pageNumber === numberOfPages - 1 || isLoading}
              onClick={() => setPageNumber(pageNumber + 1)}
            >
              <span className="sr-only">Suivant</span>
              <ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default PaginationButtons;
