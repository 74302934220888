// TODO : Change fake data to fetched data from API
import { atom, selector } from "recoil";
import { sortableOrderStatuses } from "../../../shared/constants/orderStatuses";
import { CustomerProps, Exhibition } from "../../../shared/types/sharedTypes";
import { emptyOrder } from "../constants/emptyOrder";
import { defaultAvailableProducts, defaultData } from "../fakeData";
import { fakeOrderNotes } from "../fakeOrderNotes";
import fakeSingleOrderData from "../fakeSingleOrderData";
import {
  MainOrderInfoType,
  OrderData,
  OrderNoteProps,
  PaymentAndInvoiceInfoType,
  Product,
} from "../types/orders";

export const ordersDataAtom = atom<OrderData[]>({
  key: "ordersDataAtom",
  default: [],
});

export const filteredOrdersDataAtom = atom<OrderData[] | null>({
  key: "filteredOrdersDataAtom",
  default: null,
});

export const initialSelectedOrderStatusAtom = atom<string>({
  key: "initialSelectedOrderStatusAtom",
  default: "",
});

export const isCreatingNewOrderAtom = atom<boolean>({
  key: "isCreatingNewOrderAtom",
  default: false,
});

export const isUpdatingOrderAtom = atom<boolean>({
  key: "isUpdatingOrderAtom",
  default: false,
});

export const selectedOrderCustomerAtom = atom<CustomerProps | null>({
  key: "selectedOrderCustomerAtom",
  default: null,
});

export const newOrderDataAtom = atom<OrderData>({
  key: "newOrderDataAtom",
  default: emptyOrder,
});

export const clickedGenerateInvoiceAtom = atom<boolean>({
  key: "clickedGenerateInvoiceAtom",
  default: false,
});
export const clickedDownloadInvoiceAtom = atom<boolean>({
  key: "clickedDownloadInvoiceAtom",
  default: false,
});

export const clickedSendInvoiceAtom = atom<boolean>({
  key: "clickedSendInvoiceAtom",
  default: false,
});

export const isEditingInvoiceAddressAtom = atom<boolean>({
  key: "isEditingInvoiceAddressAtom",
  default: false,
});

export const isEditingActivityAddressAtom = atom<boolean>({
  key: "isEditingActivityAddressAtom",
  default: false,
});

// Create an atom to store the selected order index in the orders list
export const selectedOrderIndexAtom = atom<number | null>({
  key: "selectedOrderIndexAtom",
  default: null,
  // default: 11,
});

interface returnedDataProps {
  orderId: number;
  products: Product[];
}
export const selectedOrderFormattedDataForUpdateSelector =
  selector<returnedDataProps | null>({
    key: "selectedOrderFormattedDataForUpdateSelector",
    get: ({ get }) => {
      // const ordersData = get(ordersDataAtom);
      const ordersData = get(ordersDataAtom);
      const newProducts = get(singleOrderLineItemsDataCombinedSelector);
      const selectedOrderIndex = get(selectedOrderIndexAtom);
      if (selectedOrderIndex !== null) {
        const selectedOrderData = ordersData[selectedOrderIndex];
        const selectedOrderId = selectedOrderData.orderId;
        const selectedOrderProducts = newProducts;
        return { orderId: selectedOrderId, products: selectedOrderProducts };
      }
      return null;
    },
  });

export const selectedOrderAtom = atom<OrderData | null>({
  key: "selectedOrderAtom",
  default: null,
});

export const selectedOrderMainTaxRateAtom = atom<number>({
  key: "selectedOrderMainTaxRateAtom",
  default: 0,
});

export const selectedOrderAtomUntouched = atom<OrderData | null>({
  key: "selectedOrderAtomUntouched",
  default: null,
});

export const selectedOrderHasBeenUpdatedAtom = atom<boolean>({
  key: "selectedOrderHasBeenUpdatedAtom",
  default: false,
});

export const availableProductsAtoms = atom<Product[]>({
  key: "availableProductsAtoms",
  // default: defaultAvailableProducts,
  default: [],
});

export const selectedOrderAddProductButtonClickedAtom = atom<boolean>({
  key: "selectedOrderAddProductButtonClickedAtom",
  default: false,
});

// Discounts
export const selectedOrderAddDiscountProductButtonClickedAtom = atom<boolean>({
  key: "selectedOrderAddDiscountProductButtonClickedAtom",
  default: false,
});
export const selectedOrderAddRabaisProductButtonClickedAtom = atom<boolean>({
  key: "selectedOrderAddRabaisProductButtonClickedAtom",
  default: false,
});
export interface DiscountLineItem {
  id: number;
  amount: number;
  vat: number;
  name: string;
}
export const selectedOrderDiscountLineItemsAtom = atom<DiscountLineItem[]>({
  key: "selectedOrderDiscountLineItemsAtom",
  default: [],
});
export const selectedOrderSelectedDiscountLineItemAtom =
  atom<DiscountLineItem | null>({
    key: "selectedOrderSelectedDiscountLineItemAtom",
    default: null,
  });
// \Discounts

// Notes
export const selectedOrderNotesAtom = atom<OrderNoteProps[]>({
  key: "selectedOrderNotesAtom",
  default: [],
  // default: fakeOrderNotes,
});

export const selectedOrderAddNoteButtonClickedAtom = atom<boolean>({
  key: "selectedOrderAddNoteButtonClickedAtom",
  default: false,
});

// \Notes

export const selectedEditProductDataAtom = atom<Product[]>({
  key: "selectedEditProductDataAtom",
  default: [],
});

export const selectedEditProductIndexAtom = atom<number | null>({
  key: "selectedEditProductIndexAtom",
  default: null,
});

// export const editProductSelectedProductAtom = atom<Product | null>({
//   key: "editProductSelectedProductAtom",
//   default: null,
// });

export const singleOrderLineItemsDataAtom = selector<Product[]>({
  key: "singleOrderLineItemsDataAtom",
  get: ({ get }) => {
    // const singleOrderData = get(selectedOrderAtom);
    const singleOrderData = get(selectedOrderAtom);
    if (singleOrderData) {
      return singleOrderData.products;
    } else {
      return [];
    }
  },
  set: ({ get, set }, newValue) => {
    const singleOrderData = get(selectedOrderAtom);
    if (singleOrderData) {
      const newSingleOrderData = { ...singleOrderData };
      newSingleOrderData.products = newValue as Product[];
      // // TOTAL HT
      // const newTotalWithoutVat = newSingleOrderData.products.reduce(
      //   (acc, product) => {
      //     return acc + product.price;
      //   },
      //   0
      // );
      // // TOTAL TVA
      // const newVat = newSingleOrderData.products.reduce((acc, product) => {
      //   return acc + product.vat;
      // }, 0);
      // // MONTANT DE LA COMMANDE
      // const newTotal = newSingleOrderData.products.reduce((acc, product) => {
      //   return acc + product.price + product.vat;
      // }, 0);
      // newSingleOrderData.totalWithoutVat = newTotalWithoutVat;
      // newSingleOrderData.vat = newVat;
      // newSingleOrderData.total = newTotal;
      set(selectedOrderAtom, newSingleOrderData);
    }
  },
});
// export const singleOrderLineItemsDataAtom = atom<Product[]>({
//   key: "singleOrderLineItemsDataAtom",
//   default: defaultData,
// });

export const singleOrderLineItemsNewProductsSelectedInPopupAtom = atom<
  Product[]
>({
  key: "singleOrderLineItemsNewProductsSelectedInPopupAtom",
  default: [],
});

export const singleOrderLineItemsNewDataSelectedAndAddedAtom = atom<Product[]>({
  key: "singleOrderLineItemsNewDataSelectedAndAddedAtom",
  default: [],
});

// Selector that has a get that returns a combination of singleOrderLineItemsDataAtom and singleOrderLineItemsNewProductsSelectedInPopupAtom
export const singleOrderLineItemsDataCombinedSelector = selector({
  key: "singleOrderLineItemsDataCombinedSelector",
  get: ({ get }) => {
    const singleOrderLineItemsData = get(singleOrderLineItemsDataAtom);
    const singleOrderLineItemsNewDataSelectedAndAdded = get(
      singleOrderLineItemsNewDataSelectedAndAddedAtom
    );
    return [
      ...singleOrderLineItemsData,
      ...singleOrderLineItemsNewDataSelectedAndAdded,
    ];
  },
  // Set that adds singleOrderLineItemsNewProductsSelectedInPopupAtom to singleOrderLineItemsNewDataSelectedAndAddedAtom
});

export const availableExhibitionsNamesAtom = atom<Exhibition[]>({
  key: "availableExhibitionsNamesAtom",
  default: [],
});

export const availableCustomersAtom = atom<CustomerProps[]>({
  key: "availableCustomersAtom",
  default: [],
});

export const recalculateVatButtonClickedAtom = atom<boolean>({
  key: "recalculateVatButtonClickedAtom",
  default: false,
});

export interface QuickViewOrderItemProps {
  company: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  website: string;
  activityType: string;
  haveAlreadyParticipated: boolean;
  // company: OrderData["company"];
  // firstName: OrderData["customer"]["invoiceAddress"]["firstNameInvoice"];
  // lastName: OrderData["customer"]["invoiceAddress"]["lastNameInvoice"];
  // email: OrderData["customer"]["email"];
  // phone: OrderData["customer"]["phone"];
  // website: OrderData["customer"]["website"];
  // activityType: OrderData["customer"]["activityType"];
  // haveAlreadyParticipated: OrderData["customer"]["haveAlreadyParticipated"];
}
export const quickViewOrderItemAtom = atom<QuickViewOrderItemProps | null>({
  key: "quickViewOrderItemAtom",
  default: null,
});

export const orderHasBeenEditedAtom = atom<boolean>({
  key: "orderHasBeenEditedAtom",
  default: false,
});