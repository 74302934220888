import apiFetch from "@wordpress/api-fetch";
import { returnRootUrlBasedOnAppLocation } from "../../../shared/utils/checkIfIsInlocal";
import { OrderData } from "../types/orders";
import axios from "axios";

interface SendOrderEmailProps {
  order_id: number;
  forAdmin?: "true" | "false";
}

export const sendOrderEmail = async ({
  order_id,
  forAdmin = "false",
}: SendOrderEmailProps) => {
  const rootUrl = returnRootUrlBasedOnAppLocation();
  const url = `${rootUrl}satn/v2/send-order-received-email?order_id=${order_id.toString()}&forAdmin=${forAdmin}`;
  const response = await apiFetch({
    path: url,
    method: "POST",
    headers: {
      //   Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return response;
};

export const sendOrderValidatedEmail = async (order: OrderData) => {
  const paymentUrl =
    order.paymentAndInvoiceInfo?.actions?.paymentLink.link || "";
  const exhibitionName = order.exhibition?.name || "";
  const accentColor = order.exhibition?.primaryColor || "";
  const customerEmail = order.customer?.email || "dev@pycofa.fr";
  const customerId = order.customer?.id || "";
  const orderId = order.orderId || "";
  const year = order.exhibition?.year || "";
  const exhibition =
    order.exhibition.name !== "-" && order.exhibition.name !== ""
      ? order.exhibition.name.split(" ")[0].slice(0, 2).toUpperCase()
      : "CDE";
  const invoiceName = `${exhibition}${year}-${orderId}`;
  const body = {
    paymentUrl: paymentUrl,
    exhibitionName: exhibitionName,
    accentColor: accentColor,
    to: customerEmail,
    customerId: customerId,
    orderId: orderId,
    invoiceName: invoiceName,
  };
  try {
    const res = await axios.post(
      "https://europe-west1-satn-ad75c.cloudfunctions.net/sendOrderValidatedEmail",
      body
    );
    console.log(res.data);
  } catch (error) {
    console.log(error);
  }
};

export const sendOrderPayReminderEmail = async (order: OrderData) => {
  const paymentUrl =
    order.paymentAndInvoiceInfo?.actions?.paymentLink.link || "";
  const exhibitionName = order.exhibition?.name || "";
  const accentColor = order.exhibition?.primaryColor || "";
  const customerEmail = order.customer?.email || "dev@pycofa.fr";
  const customerId = order.customer?.id || "";
  const orderId = order.orderId || "";
  const year = order.exhibition?.year || "";
  const exhibition =
    order.exhibition.name !== "-" && order.exhibition.name !== ""
      ? order.exhibition.name.split(" ")[0].slice(0, 2).toUpperCase()
      : "CDE";
  const invoiceName = `${exhibition}${year}-${orderId}`;
  const body = {
    paymentUrl: paymentUrl,
    exhibitionName: exhibitionName,
    accentColor: accentColor,
    to: customerEmail,
    customerId: customerId,
    orderId: orderId,
    invoiceName: invoiceName,
  };
  try {
    const res = await axios.post(
      "https://europe-west1-satn-ad75c.cloudfunctions.net/sendOrderPayReminder",
      body
    );
    console.log(res.data);
  } catch (error) {
    console.log(error);
  }
};

export const sendOrderPaidEmail = async (order: OrderData) => {
  console.log("SENDING ORDER PAID EMAIL");

  const exhibitionName = order.exhibition?.name || "";
  const accentColor = order.exhibition?.primaryColor || "";
  const customerEmail = order.customer?.email || "dev@pycofa.fr";

  const body = {
    exhibitionName: exhibitionName,
    accentColor: accentColor,
    to: customerEmail,
  };

  try {
    const res = await axios.post(
      "https://europe-west1-satn-ad75c.cloudfunctions.net/sendOrderPayedEmail",
      body
    );
    console.log(res.data);
  } catch (error) {
    console.log(error);
  }
};
