import apiFetch from "@wordpress/api-fetch";
import { returnRootUrlBasedOnAppLocation } from "../../../shared/utils/checkIfIsInlocal";
import { sendOrderPaidEmail } from "./sendOrderEmail";

interface UpdateOrderProps {
  selectedOrderData: any;
  setIsGeneratingAndUpdatingInvoice: any;
  setClickedGenerateInvoice: any;
  setIsUpdatingOrder: any;
  setSelectedOrderHasBeenUpdated: any;
  token: any;
}
export const updateOrder = async ({
  selectedOrderData,
  setIsGeneratingAndUpdatingInvoice,
  setClickedGenerateInvoice,
  setIsUpdatingOrder,
  setSelectedOrderHasBeenUpdated,
  token,
}: UpdateOrderProps) => {
  if (selectedOrderData) {
    setIsGeneratingAndUpdatingInvoice(true);
    apiFetch({
      path: `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v1/update-order-final-final/`,
      method: "POST",
      data: {
        orderId: selectedOrderData.orderId,
        products: selectedOrderData.products,
        fees: selectedOrderData.fees,
        status: selectedOrderData?.status,
        exhibition: selectedOrderData?.exhibition,
        customerId: selectedOrderData?.customer.id,
        shippingAddress: selectedOrderData?.activityAddress,
        invoiceAddress: selectedOrderData?.invoiceAddress,
        standNumber: selectedOrderData?.standNumber,

        email: selectedOrderData?.mainOrderInfo.email,
        customer: selectedOrderData?.customer,
        website: selectedOrderData?.mainOrderInfo.website,
        phone: selectedOrderData?.mainOrderInfo.phone,
        dateTime: selectedOrderData?.mainOrderInfo.dateTime,
        adminComments: selectedOrderData?.adminComments,
        invoiceComments: selectedOrderData?.invoiceComments,
        userBillingEmailAddress: "ok@apple.com",
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => {
        if (selectedOrderData?.originalStatus !== selectedOrderData?.status) {
          selectedOrderData &&
            selectedOrderData.status === "Inscription confirmée" &&
            sendOrderPaidEmail(selectedOrderData).finally(() => {
              setIsUpdatingOrder(false);
              setSelectedOrderHasBeenUpdated(true);
            });
        }
      })
      .catch((error) => {
        //console.log("error", error);
        if (error.code.includes("jwt")) {
          localStorage.removeItem("token-dashboard");
          window.location.replace("/");
        }
      })
      .finally(() => {
        // setIsUpdatingOrder(false);
        setIsGeneratingAndUpdatingInvoice(false);
        setClickedGenerateInvoice(false);
        window.location.reload();
      });
  }
};
