import { ArrowLeftCircleIcon } from "@heroicons/react/24/outline";
import { PencilIcon } from "@heroicons/react/24/solid";
import apiFetch from "@wordpress/api-fetch";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { sortableOrderStatuses } from "../../../shared/constants/orderStatuses";
import ActionPopup from "../../../shared/ui/components/ActionPopup";
import Loader from "../../../shared/ui/components/Loader";
import PageLayout from "../../../shared/ui/layouts/PageLayout";
import { returnRootUrlBasedOnAppLocation } from "../../../shared/utils/checkIfIsInlocal";
import { currentFormattedDate } from "../../../shared/utils/returnCurrentFormattedDateAndTime";
import {
  availableCustomersAtom,
  availableExhibitionsNamesAtom,
  clickedDownloadInvoiceAtom,
  clickedGenerateInvoiceAtom,
  clickedSendInvoiceAtom,
  initialSelectedOrderStatusAtom,
  isCreatingNewOrderAtom,
  isUpdatingOrderAtom,
  newOrderDataAtom,
  orderHasBeenEditedAtom,
  recalculateVatButtonClickedAtom,
  selectedEditProductDataAtom,
  selectedEditProductIndexAtom,
  selectedOrderAddDiscountProductButtonClickedAtom,
  selectedOrderAddNoteButtonClickedAtom,
  selectedOrderAddProductButtonClickedAtom,
  selectedOrderAddRabaisProductButtonClickedAtom,
  selectedOrderAtom,
  selectedOrderAtomUntouched,
  selectedOrderDiscountLineItemsAtom,
  selectedOrderFormattedDataForUpdateSelector,
  selectedOrderHasBeenUpdatedAtom,
  selectedOrderIndexAtom,
  selectedOrderNotesAtom,
  singleOrderLineItemsDataAtom,
  singleOrderLineItemsNewProductsSelectedInPopupAtom,
} from "../atoms/orders-atoms";
import { generateSingleInvoiceWithFirebase } from "../components/SingleOrder/SingleOrderDetails/PaymentAndInvoiceInfo";
import SingleOrderInfoCardsItemData from "../components/SingleOrder/SingleOrderInfoCardsItemData";
import SingleOrderPopupAddDiscountProduct from "../components/SingleOrder/SingleOrderPopupActionsContent/SingleOrderPopupAddDiscount/SingleOrderPopupAddDiscount";
import SingleOrderPopupAddNote from "../components/SingleOrder/SingleOrderPopupActionsContent/SingleOrderPopupAddNote/SingleOrderPopupAddNote";
import SingleOrderPopupAddProduct from "../components/SingleOrder/SingleOrderPopupActionsContent/SingleOrderPopupAddProduct/SingleOrderPopupAddProduct";
import SingleOrderPopupAddRabaisProduct from "../components/SingleOrder/SingleOrderPopupActionsContent/SingleOrderPopupAddRabais/SingleOrderPopupAddRabais";
import SingleOrderPopupEditProduct from "../components/SingleOrder/SingleOrderPopupActionsContent/SingleOrderPopupEditProduct/SingleOrderPopupEditProduct";
import SingleOrderPopupRecalculate from "../components/SingleOrder/SingleOrderPopupActionsContent/SingleOrderPopupRecalculate/SingleOrderPopupRecalculate";
import SingleOrderLineItemsTable from "../layouts/PopupActionsContent/SingleOrderLineItemsTable";
import SingleOrderInfoCardsWithTitle from "../layouts/SingleOrderInfoCardsWithTitle";
import "../styles/singleOrder.css";
import {
  sendOrderPaidEmail,
  sendOrderValidatedEmail,
} from "../api/sendOrderEmail";
import { verifyIfFileExists } from "../api/verifyIfFileExists";
import { deleteOrder } from "../api/deleteOrder";
import { doAll } from "../api/updateInvoiceGenerationDateAndOrder";
import { Tooltip } from "react-tooltip";
import { ConferenceFlagAtom } from "../../../shared/atoms/flag-atoms";
import { selectedPostAtom } from "../../../shared/atoms/single-data-atoms";
// const sgMail = require("@sendgrid/mail");

interface InvoiceNameProps {
  exhibitionName: string;
  orderId: string;
  year: string;
}
export const returnInvoiceName = ({
  exhibitionName = "",
  orderId = "",
  year = "",
}: InvoiceNameProps) => {
  let invoiceName = "";
  const exhibition =
    exhibitionName !== "-" && exhibitionName !== ""
      ? exhibitionName.split(" ")[0].slice(0, 2).toUpperCase()
      : "CDE";
  invoiceName = `${exhibition}${year}-${orderId}`;
  return invoiceName;
};

interface CardWithHeaderProps {
  title: string;
  children: React.ReactNode;
  isEditable?: boolean;
  centerElements?: boolean;
  justifyCenter?: boolean;
  width?: string;
  headerChildren?: React.ReactNode;
}
export const CardWithHeader = ({
  title,
  children,
  isEditable = false,
  centerElements = false,
  justifyCenter = true,
  width = "",
  headerChildren = null,
}: CardWithHeaderProps) => {
  return (
    <div className={`${width} flex grow flex-col rounded-lg bg-white shadow`}>
      <div className="flex w-full flex-row items-center justify-between rounded-lg rounded-b-none bg-[#14B8A6] px-6 py-3 font-bold text-white">
        <div className="flex flex-row items-center justify-between ">
          <span className="text-xl">{title}</span>
          {isEditable && <PencilIcon className="w-5" />}
        </div>
        <>{headerChildren}</>
      </div>
      <div
        className={`flex grow flex-col ${justifyCenter && "justify-center"
          } px-6 py-4 ${centerElements && "mx-auto"}`}
      >
        {children}
      </div>
    </div>
  );
};

interface UpdatedOrderMessageProps {
  isCreatingNewOrder: boolean;
  selectedOrderData: any;
}
export const UpdatedOrderMessage = ({
  isCreatingNewOrder,
  selectedOrderData,
}: UpdatedOrderMessageProps) => {
  return (
    <span className="text-xl">
      {isCreatingNewOrder && "La commande a été créée avec succès."}
      {!isCreatingNewOrder &&
        `La commande ${selectedOrderData ? selectedOrderData.orderId : ""} a été
      mise à jour avec succès.`}
    </span>
  );
};

const SingleOrderScreen = () => {
  const [isExhibitionSelected, setIsExhibitionSelected] = useState(false);
  const [selectedOrderData, setSelectedOrderData] =
    useRecoilState(selectedOrderAtom);

  const [newOrderData, setNewOrderData] = useRecoilState(newOrderDataAtom);

  const [selectedOrderHasBeenUpdated, setSelectedOrderHasBeenUpdated] =
    useRecoilState(selectedOrderHasBeenUpdatedAtom);

  const [
    selectedOrderAddProductButtonClicked,
    setSelectedOrderAddProductButtonClicked,
  ] = useRecoilState(selectedOrderAddProductButtonClickedAtom);

  const [
    selectedOrderAddDiscountProductButtonClicked,
    setSelectedOrderAddDiscountProductButtonClicked,
  ] = useRecoilState(selectedOrderAddDiscountProductButtonClickedAtom);

  const [
    selectedOrderAddRabaisProductButtonClicked,
    setSelectedOrderAddRabaisProductButtonClicked,
  ] = useRecoilState(selectedOrderAddRabaisProductButtonClickedAtom);

  const [
    selectedOrderAddNoteButtonClicked,
    setSelectedOrderAddNoteButtonClicked,
  ] = useRecoilState(selectedOrderAddNoteButtonClickedAtom);

  const [singleOrderLineItemsData, setSingleOrderLineItemsData] =
    useRecoilState(singleOrderLineItemsDataAtom);

  const singleOrderLineItemsNewProductsSelectedInPopup = useRecoilValue(
    singleOrderLineItemsNewProductsSelectedInPopupAtom
  );

  const singleOrderLineItemsNewDataSelected = useSetRecoilState(
    singleOrderLineItemsNewProductsSelectedInPopupAtom
  );

  const [selectedEditProductData, setSelectedEditProductData] = useRecoilState(
    selectedEditProductDataAtom
  );

  const [selectedOrderIndex, setSelectedOrderIndex] = useRecoilState(
    selectedOrderIndexAtom
  );

  const [selectedEditProductIndex, setSelectedEditProductIndex] =
    useRecoilState(selectedEditProductIndexAtom);

  const selectedOrderFormattedDataForUpdate = useRecoilValue(
    selectedOrderFormattedDataForUpdateSelector
  );

  const [isCreatingNewOrder, setIsCreatingNewOrder] = useRecoilState(
    isCreatingNewOrderAtom
  );

  const [selectedOrderDiscountLineItems, setSelectedOrderDiscountLineItems] =
    useRecoilState(selectedOrderDiscountLineItemsAtom);

  const [availableExhibitionsNames, setAvailableExhibitionsNames] =
    useRecoilState(availableExhibitionsNamesAtom);

  const [availableCustomers, setAvailableCustomers] = useRecoilState(
    availableCustomersAtom
  );

  const [recalculateVatButtonClicked, setRecalculateVatButtonClicked] =
    useRecoilState(recalculateVatButtonClickedAtom);

  const [selectedOrderNotes, setSelectedOrderNotes] = useRecoilState(
    selectedOrderNotesAtom
  );

  const [isUpdatingOrder, setIsUpdatingOrder] =
    useRecoilState(isUpdatingOrderAtom);

  const [clickedGenerateInvoice, setClickedGenerateInvoice] = useRecoilState(
    clickedGenerateInvoiceAtom
  );
  const [clickedSendInvoice, setClickedSendInvoice] = useRecoilState(
    clickedSendInvoiceAtom
  );
  const [clickedDownloadInvoice, setClickedDownloadInvoice] = useRecoilState(
    clickedDownloadInvoiceAtom
  );

  const [selectedOrderUntouched, setSelectedOrderUntouched] = useRecoilState(
    selectedOrderAtomUntouched
  );

  const [orderHasBeenEdited, setOrderHasBeenEdited] = useRecoilState(
    orderHasBeenEditedAtom
  );


  const [isLoading, setIsLoading] = useState(false);
  const [isDeletingOrder, setIsDeletingOrder] = useState(false);
  const [isConfirmDeletionPopupVisible, setIsConfirmDeletionPopupVisible] =
    useState(false);

  const selected = useRecoilValue(ConferenceFlagAtom);

  const [selectedPost, setSelectedPost] = useRecoilState(selectedPostAtom);

  useEffect(() => {
    // console.log("selectedOrderData.exhibition", selectedOrderData?.exhibition);


    if (!selectedOrderUntouched) {
      setSelectedOrderUntouched(selectedOrderData);
    }

    // !orderHasBeenEdited &&
    //   compareObjects(selectedOrderUntouched, selectedOrderData) &&
    //   setOrderHasBeenEdited(true);
    // orderHasBeenEdited &&
    //   !compareObjects(selectedOrderUntouched, selectedOrderData) &&
    //   setOrderHasBeenEdited(false);

    // If order has been edited, set orderHasBeenEdited to true
    if (compareObjects(selectedOrderUntouched, selectedOrderData) === false) {
      setOrderHasBeenEdited(false);
    } else {
      setOrderHasBeenEdited(true);
    }
    setIsLoading(false);
  }, [selectedOrderData]);

  // function compareObjects(obj1: any, obj2: any): boolean {
  //   // If types are not equal, objects are different
  //   if (typeof obj1 !== typeof obj2) {
  //     return true;
  //   }

  //   // If objects are null or undefined, they are considered equal
  //   if (obj1 == null || obj2 == null) {
  //     return false;
  //   }

  //   // If objects are primitive types, compare them directly
  //   if (typeof obj1 !== "object") {
  //     return obj1 !== obj2;
  //   }

  //   // Compare objects recursively, property by property
  //   for (const propName in obj1) {
  //     if (obj1.hasOwnProperty(propName)) {
  //       if (Array.isArray(obj1[propName])) {
  //         // Compare arrays by length and by comparing each element recursively
  //         if (obj1[propName].length !== obj2[propName].length) {
  //           return true;
  //         }
  //         for (let i = 0; i < obj1[propName].length; i++) {
  //           if (compareObjects(obj1[propName][i], obj2[propName][i])) {
  //             return true;
  //           }
  //         }
  //       } else if (
  //         typeof obj1[propName] === "object" &&
  //         typeof obj2[propName] === "object"
  //       ) {
  //         // Compare nested objects recursively
  //         if (compareObjects(obj1[propName], obj2[propName])) {
  //           return true;
  //         }
  //       } else if (
  //         propName === "price" ||
  //         propName === "regularPrice" ||
  //         propName === "vat" ||
  //         propName === "total" ||
  //         propName === "totalWithoutVat"
  //       ) {
  //       } else {
  //         // Compare other properties recursively
  //         if (compareObjects(obj1[propName], obj2[propName])) {
  //           return true;
  //         }
  //       }
  //     }
  //   }

  //   // Objects are equal
  //   return false;
  // }

  function compareObjects(obj1: any, obj2: any): boolean {
    // Check if both arguments are objects and not null
    if ((obj1 === null || typeof obj1 !== 'object') || (obj2 === null || typeof obj2 !== 'object')) {
      return obj1 !== obj2;
    }

    // Get keys of both objects
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // If number of keys is different, objects are different
    if (keys1.length !== keys2.length) {
      return true;
    }

    // Check if both objects have the same set of keys
    for (const key of keys1) {
      if (!keys2.includes(key)) {
        return true;
      }
    }

    // Deep comparison for each key
    for (const key of keys1) {
      const val1 = obj1[key];
      const val2 = obj2[key];

      // If values are objects, recursively compare
      if (typeof val1 === 'object' && typeof val2 === 'object') {
        if (compareObjects(val1, val2)) {
          return true;
        }
      } else if (Array.isArray(val1) && Array.isArray(val2)) {
        // Compare arrays
        if (val1.length !== val2.length || val1.some((item, index) => compareObjects(item, val2[index]))) {
          return true;
        }
      } else if (val1 !== val2) {
        // Compare primitive types
        return true;
      }
    }

    // Objects are equal
    return false;
  }

  function isOrderMadeBefore2024(orderDate: number): boolean {
    const cutoffDate = new Date("2024-01-01");
    const actualOrderDate = new Date(orderDate * 1000); // Convert orderDate to milliseconds
    return actualOrderDate < cutoffDate;
  }


  useEffect(() => {
    isCreatingNewOrder && setIsCreatingNewOrder(false);
    let token = "";
    if (localStorage.getItem("token-dashboard")) {
      token = JSON.parse(localStorage.getItem("token-dashboard") as string);
    }

    //  Check if url includes 'nouvelle-commande'
    if (!window.location.href.includes("nouvelle-commande")) {
      //
      const orderId = window.location.href.split("/").pop();
      if (!selectedOrderData && orderId) {
        setIsLoading(true);
      }
      !selectedOrderData &&
        orderId &&
        axios
          .get(
            `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v1/orders?id=${orderId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setSelectedOrderData(res.data[0]);
            setSelectedPost(res.data[0]);
            //console.log(res.data);
            isLoading && setIsLoading(false);
          })
          .catch((err) => {
            //console.log(err);
            if (err.code.includes("jwt")) {
              console.log(err);
              localStorage.removeItem("token-dashboard");
              window.location.replace("/");
            }
          });
    } else {
      setIsCreatingNewOrder(true);
      setSelectedOrderData(newOrderData);
    }
    axios
      .get(
        `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v1/exhibitions/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setAvailableExhibitionsNames(res.data);
      })
      .catch((err) => {
        //console.log(err);
        if (err.code.includes("jwt")) {
          console.log(err);
          localStorage.removeItem("token-dashboard");
          window.location.replace("/");
        }
      });
    axios
      .get(`${returnRootUrlBasedOnAppLocation()}admin-dashboard/v1/users/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // setAvailableExhibitionsNames(res.data);
        setAvailableCustomers(res.data);
      })
      .catch((err) => {
        //console.log(err);
        if (err.code.includes("jwt")) {
          console.log(err);
          localStorage.removeItem("token-dashboard");
          window.location.replace("/");
        }
      });
    selectedOrderData && setSelectedOrderNotes(selectedOrderData.notes);
    //console.log(selectedOrderData);
  }, []);

  let token = "";
  if (localStorage.getItem("token-dashboard")) {
    token = JSON.parse(localStorage.getItem("token-dashboard") as string);
  }

  const [isGeneratingAndUpdatingInvoice, setIsGeneratingAndUpdatingInvoice] =
    useState(false);

  const navigate = useNavigate();

  const popups = [
    <ActionPopup
      actionRunning={isDeletingOrder}
      key={Math.random()}
      title="Supprimer la commande ?"
      children={
        <p>
          Voulez-vous vraimer supprimer la commande {selectedOrderData?.orderId}{" "}
          ?
        </p>
      }
      isVisible={isConfirmDeletionPopupVisible}
      setIsVisible={() => {
        setIsConfirmDeletionPopupVisible(!isConfirmDeletionPopupVisible);
      }}
      action={() => {
        !isDeletingOrder &&
          deleteOrder({
            setIsDeletingOrder,
            selectedOrderData,
            token,
            navigate,
          });
      }}
      cancelAction={() => {
        setIsConfirmDeletionPopupVisible(false);
      }}
      actionButtonDisabled={false}
      actionButtonTitle={
        <div
          className={`flex flex-row items-center ${isDeletingOrder && "cursor-wait"
            }`}
        >
          {isDeletingOrder && (
            <svg
              className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}
          {isDeletingOrder ? "Suppression..." : "Supprimer"}
        </div>
      }
      cancelButtonVisible={true}
      actionButtonBackgroundColor="#f87171"
    />,
    <ActionPopup
      key={Math.random()}
      title={
        isCreatingNewOrder
          ? "Commande créee avec succès"
          : "Commande mise à jour avec succès"
      }
      children={
        <UpdatedOrderMessage
          isCreatingNewOrder
          selectedOrderData={selectedOrderData}
        />
      }
      isVisible={selectedOrderHasBeenUpdated}
      setIsVisible={() => {
        setSelectedOrderHasBeenUpdated(!selectedOrderHasBeenUpdated);
        if (isCreatingNewOrder) {
          window.location.replace(`/commandes/${selectedOrderData?.orderId}`);
        } else {
          window.location.reload();
        }
      }}
      action={() => {
        setSelectedOrderHasBeenUpdated(false);
        if (isCreatingNewOrder) {
          window.location.replace(`/commandes/${selectedOrderData?.orderId}`);
        } else {
          window.location.reload();
        }
      }}
      cancelAction={() => {
        setSelectedOrderHasBeenUpdated(false);
        if (isCreatingNewOrder) {
          window.location.replace(`/commandes/${selectedOrderData?.orderId}`);
        } else {
          window.location.reload();
        }
      }}
      actionButtonDisabled={false}
      actionButtonTitle="Fermer"
      cancelButtonVisible={false}
    />,
    <ActionPopup
      key={Math.random()}
      title="Ajouter un article"
      children={<SingleOrderPopupAddProduct />}
      isVisible={selectedOrderAddProductButtonClicked}
      setIsVisible={() => setSelectedOrderAddProductButtonClicked(false)}
      action={() => {
        // Add selected products to the table
        setSingleOrderLineItemsData([
          ...singleOrderLineItemsData,
          ...singleOrderLineItemsNewProductsSelectedInPopup,
        ]);
        // Empty selected products array
        singleOrderLineItemsNewDataSelected([]);
        setSelectedOrderAddProductButtonClicked(false);
        // Update order total, totalWithoutVat, vat based on what has been added
        selectedOrderData &&
          setSelectedOrderData({
            ...selectedOrderData,
            totalWithoutVat:
              selectedOrderData.totalWithoutVat +
              singleOrderLineItemsNewProductsSelectedInPopup.reduce(
                (acc, product) => acc + product.regularPrice,
                0
              ),
            vat:
              selectedOrderData.vat +
              singleOrderLineItemsNewProductsSelectedInPopup.reduce(
                (acc, product) => acc + product.vat,
                0
              ),
            total:
              selectedOrderData.total +
              singleOrderLineItemsNewProductsSelectedInPopup.reduce(
                (acc, product) => acc + product.price + product.vat,
                0
              ),
            products: [
              ...selectedOrderData.products,
              ...singleOrderLineItemsNewProductsSelectedInPopup,
            ],
          });
      }}
      cancelAction={() => {
        singleOrderLineItemsNewDataSelected([]);
        setSelectedOrderAddProductButtonClicked(false);
      }}
      actionButtonDisabled={false}
      actionButtonTitle="Ajouter"
    />,
    <ActionPopup
      key={Math.random()}
      title="Modifier un article"
      // children={<SingleOrderPopupEditProduct isOrderWithNewVatRate={selectedOrderData?.date ? isOrderMadeBefore2024(parseInt(selectedOrderData?.date)) : false} />}
      children={<SingleOrderPopupEditProduct isOrderWithNewVatRate={true} />}
      isVisible={selectedEditProductData.length > 0 ? true : false}
      setIsVisible={() => setSelectedEditProductData([])}
      action={() => {
        const updatedSingleOrderLineItemsData = singleOrderLineItemsData.map(
          (product, index) => {
            if (index === selectedEditProductIndex) {
              return selectedEditProductData[0];
            } else {
              return product;
            }
          }
        );

        setSingleOrderLineItemsData(updatedSingleOrderLineItemsData);
        setSelectedEditProductData([]);
      }}
      cancelAction={() => setSelectedEditProductData([])}
      actionButtonDisabled={false}
      actionButtonTitle="Valider"
      actionButtonsVisible={false}
    />,
    <ActionPopup
      key={Math.random()}
      title="Appliquer un frais"
      children={<SingleOrderPopupAddDiscountProduct />}
      isVisible={selectedOrderAddDiscountProductButtonClicked}
      setIsVisible={() =>
        setSelectedOrderAddDiscountProductButtonClicked(false)
      }
      action={() => { }}
      cancelAction={() => {
        setSelectedOrderAddDiscountProductButtonClicked(false);
      }}
      actionButtonDisabled={false}
      actionButtonTitle="Ajouter"
      actionButtonsVisible={false}
    />,
    <ActionPopup
      key={Math.random()}
      title="Appliquer un rabais"
      children={<SingleOrderPopupAddRabaisProduct />}
      isVisible={selectedOrderAddRabaisProductButtonClicked}
      setIsVisible={() => setSelectedOrderAddRabaisProductButtonClicked(false)}
      action={() => { }}
      cancelAction={() => {
        setSelectedOrderAddRabaisProductButtonClicked(false);
      }}
      actionButtonDisabled={false}
      actionButtonTitle="Ajouter"
      actionButtonsVisible={false}
    />,
    <ActionPopup
      key={Math.random()}
      title="Nouvelle note de commande"
      children={<SingleOrderPopupAddNote />}
      isVisible={selectedOrderAddNoteButtonClicked}
      setIsVisible={() =>
        setSelectedOrderAddNoteButtonClicked(!selectedOrderAddNoteButtonClicked)
      }
      action={() => { }}
      cancelAction={() => {
        setSelectedOrderAddNoteButtonClicked(false);
      }}
      actionButtonDisabled={false}
      actionButtonTitle="Ajouter"
      actionButtonsVisible={false}
    />,
    <ActionPopup
      key={Math.random()}
      title="Recalculer la TVA"
      children={<SingleOrderPopupRecalculate />}
      isVisible={recalculateVatButtonClicked}
      setIsVisible={() =>
        setRecalculateVatButtonClicked(!recalculateVatButtonClicked)
      }
      action={() => { }}
      cancelAction={() => {
        setRecalculateVatButtonClicked(false);
      }}
      actionButtonDisabled={false}
      actionButtonTitle="Valider"
      actionButtonsVisible={false}
    />,
    <ActionPopup
      key={Math.random()}
      title="Génération de la facture"
      children={
        <>
          Si vous avez apporté des modifications à la commande, elles seront
          enregistrées et appliquées à la facture
        </>
      }
      isVisible={clickedGenerateInvoice}
      setIsVisible={() => setClickedGenerateInvoice(!clickedGenerateInvoice)}
      action={() => {
        doAll({
          selectedOrderData,
          setIsGeneratingAndUpdatingInvoice,
          setClickedGenerateInvoice,
          setIsUpdatingOrder,
          setSelectedOrderHasBeenUpdated,
          token,
        });
      }}
      cancelAction={() => {
        setClickedGenerateInvoice(false);
      }}
      actionButtonDisabled={false}
      actionButtonTitle={
        isGeneratingAndUpdatingInvoice ? "Génération..." : "Générer"
      }
      actionButtonsVisible={true}
      cancelButtonVisible={true}
    />,
    <ActionPopup
      key={Math.random()}
      title="Télécharger la facture"
      children={
        <>
          Si vous avez apporté des modifications à la commande, elles seront
          enregistrées et appliquées à la facture
        </>
      }
      isVisible={clickedDownloadInvoice}
      setIsVisible={() => setClickedGenerateInvoice(!clickedGenerateInvoice)}
      action={() => { }}
      cancelAction={() => {
        setClickedDownloadInvoice(false);
      }}
      actionButtonDisabled={false}
      actionButtonTitle={
        isGeneratingAndUpdatingInvoice
          ? "Téléchargement en cours..."
          : "Télécharger"
      }
      actionButtonsVisible={true}
      cancelButtonVisible={true}
    />,
    <ActionPopup
      key={Math.random()}
      title="Renvoyer la facture"
      children={
        <>
          Si vous avez apporté des modifications à la commande, elles seront
          enregistrées et appliquées à la facture
        </>
      }
      isVisible={clickedSendInvoice}
      setIsVisible={() => setClickedGenerateInvoice(!clickedGenerateInvoice)}
      action={() => {
        alert("A implementer");
      }}
      cancelAction={() => {
        setClickedSendInvoice(false);
      }}
      actionButtonDisabled={false}
      actionButtonTitle={
        isGeneratingAndUpdatingInvoice ? "Envoi en cours..." : "Renvoyer"
      }
      actionButtonsVisible={true}
      cancelButtonVisible={true}
    />,
  ];

  useEffect(() => {
    if (isCreatingNewOrder) {
      setSelectedOrderData({
        ...newOrderData,
        date: currentFormattedDate,
        status: sortableOrderStatuses[1],
        mainOrderInfo: {
          ...newOrderData.mainOrderInfo,
        },
        paymentAndInvoiceInfo: {
          ...newOrderData.paymentAndInvoiceInfo,
          status: sortableOrderStatuses[1],
        },
      });
    }
  }, [isCreatingNewOrder]);

  // useEffect(() => {
  //   selectedOrderData && console.log(selectedOrderData.fees);
  // }, [selectedOrderData]);

  const [initialSelectedOrderStatus, setInitialSelectedOrderStatus] =
    useRecoilState(initialSelectedOrderStatusAtom);

  useEffect(() => {
    if (selectedOrderData && initialSelectedOrderStatus === "") {
      setInitialSelectedOrderStatus(selectedOrderData.status);
    }
    if (selectedOrderData && selectedOrderData.exhibition.id !== 0) {
      setIsExhibitionSelected(true);
    }

    console.log("selectedOrderData", selectedOrderData);
    selectedOrderData && console.log('B4 2024 ', isOrderMadeBefore2024(parseInt(selectedOrderData?.date)));
    console.log('selectedOrderData?.date', selectedOrderData?.date);


  }, [selectedOrderData]);

  useEffect(() => {
    console.log('untouched', selectedOrderUntouched);
  }, [selectedOrderUntouched]);


  const phoneWithPrefix = selectedOrderData?.mainOrderInfo.phone;

  const phoneWithoutPrefix = phoneWithPrefix?.replace(/^\+\d+/, '');

  return (
    <PageLayout>
      <>
        {popups}
        {isLoading ? (
          <Loader />
        ) : (
          <div className="flex flex-col gap-y-16">
            <div className="flex w-full justify-between">
              <Link
                className="flex flex-row items-center gap-x-4 text-2xl text-[#3c7179]"
                to={`/commandes`}
                onClick={() => {
                  setSelectedOrderIndex(null);
                  setSelectedOrderData(null);
                  setIsCreatingNewOrder(false);
                  setSelectedOrderDiscountLineItems([]);
                }}
              >
                <ArrowLeftCircleIcon className="color-[#3C7179] w-10" /> Retour
                à la liste des commandes
              </Link>
              <div className="flex flex-row items-center gap-x-8">
                {!isCreatingNewOrder && (
                  <button
                    disabled={isDeletingOrder || isUpdatingOrder}
                    className={`${(isDeletingOrder || isUpdatingOrder) &&
                      "cursor-not-allowed opacity-40"
                      } flex flex-row items-center rounded-md border-none bg-red-400 px-8 py-4 text-lg font-semibold text-white shadow`}
                    onClick={() => {
                      setIsConfirmDeletionPopupVisible(true);
                    }}
                    type="button"
                  >
                    Supprimer
                  </button>
                )}
                {!isExhibitionSelected && <Tooltip id="my-tooltip" />}
                <button
                  disabled={
                    !orderHasBeenEdited ||
                    isUpdatingOrder ||
                    !isExhibitionSelected ||
                    isDeletingOrder
                  }
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Veuillez renseigner le salon"
                  className={`${isUpdatingOrder ||
                    ((!orderHasBeenEdited ||
                      !isExhibitionSelected ||
                      isDeletingOrder) &&
                      "cursor-not-allowed opacity-40")
                    } flex flex-row items-center rounded-md border-none bg-[#14b8a6] px-8 py-4 text-lg font-semibold text-white shadow`}
                  onClick={() => {
                    let token = "";
                    if (localStorage.getItem("token-dashboard")) {
                      token = JSON.parse(
                        localStorage.getItem("token-dashboard") as string
                      );
                    }

                    if (selectedOrderData) {
                      setIsUpdatingOrder(true);
                      console.log("selectedOrderData", selectedOrderData);
                      apiFetch({
                        path: `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v1/update-order-final-final/`,
                        method: "POST",
                        data: {
                          orderId: selectedOrderData.orderId,
                          products: selectedOrderData.products,
                          fees: selectedOrderData.fees,
                          status: selectedOrderData?.status,
                          exhibition: selectedOrderData?.exhibition,
                          customerId: selectedOrderData?.customer.id,
                          shippingAddress: selectedOrderData?.activityAddress,
                          invoiceAddress: selectedOrderData?.invoiceAddress,
                          customer: selectedOrderData?.customer,
                          standNumber: selectedOrderData?.standNumber,
                          // email: selectedOrderData?.customer.email,
                          // phone: selectedOrderData?.customer.phone,
                          email: selectedOrderData?.mainOrderInfo.email,
                          phone: selectedOrderData?.mainOrderInfo.phone,
                          website: selectedOrderData?.mainOrderInfo.website,
                          dateTime: selectedOrderData?.mainOrderInfo.dateTime,
                          adminComments: selectedOrderData?.adminComments,
                          invoiceComments: selectedOrderData?.invoiceComments,
                          userBillingEmailAddress:
                            selectedOrderData?.mainOrderInfo.emailOrder,
                          topCardsInfo: {
                            company: selectedOrderData?.company,
                            firstName:
                              selectedOrderData?.customer.invoiceAddress
                                .firstNameInvoice,
                            lastName:
                              selectedOrderData?.customer.invoiceAddress
                                .lastNameInvoice,
                            email: selectedOrderData?.customer.email,
                            phone: selectedOrderData?.customer.phone,
                            website: selectedOrderData?.mainOrderInfo.website,
                            activityType:
                              selectedOrderData?.customer.activityType,
                            hasAllreadyBeenToExhibition:
                              selectedOrderData?.customer
                                .haveAlreadyParticipated,
                          },
                          billing_country_code: selected,
                        },
                        headers: {
                          Authorization: `Bearer ${token}`,
                        },
                      })
                        .then((data) => {
                          setSelectedOrderData({
                            ...selectedOrderData,
                            orderId: data as number,
                          });
                          console.log(
                            "order status origianl ",
                            selectedOrderData.originalStatus
                          );
                          console.log(
                            "order status ",
                            selectedOrderData.status
                          );
                          // Send emails
                          if (
                            selectedOrderData?.originalStatus !==
                            selectedOrderData?.status
                          ) {
                            if (
                              selectedOrderData &&
                              selectedOrderData.status === "Attente paiement"
                            ) {
                              const invoicePath = returnInvoiceName({
                                exhibitionName:
                                  selectedOrderData.exhibition.name,
                                orderId: selectedOrderData.orderId.toString(),
                                year: selectedOrderData.exhibition.year,
                              });
                              console.log("invoicePath", invoicePath);

                              const fileName = `invoices/${selectedOrderData.customer.id}/${selectedOrderData.orderId}/${invoicePath}.pdf`;
                              console.log("fileName ", fileName);

                              verifyIfFileExists(fileName).then((data) => {
                                if (data) {
                                  // Pdf exists we can send the email
                                  selectedOrderData &&
                                    sendOrderValidatedEmail(
                                      selectedOrderData
                                    ).finally(() => {
                                      setIsUpdatingOrder(false);
                                      setSelectedOrderHasBeenUpdated(true);
                                    });
                                } else {
                                  // Pdf does not exist we need to generate it
                                  generateSingleInvoiceWithFirebase({
                                    order: selectedOrderData,
                                    openAfterGeneration: false,
                                  }).then(() => {
                                    sendOrderValidatedEmail(
                                      selectedOrderData
                                    ).finally(() => {
                                      setIsUpdatingOrder(false);
                                      setSelectedOrderHasBeenUpdated(true);
                                    });
                                  });
                                }
                              });
                            } else if (
                              selectedOrderData &&
                              selectedOrderData.status === "Inscription payée"
                            ) {
                              sendOrderPaidEmail(selectedOrderData).finally(
                                () => {
                                  setIsUpdatingOrder(false);
                                  setSelectedOrderHasBeenUpdated(true);
                                }
                              );
                            } else {
                              setIsUpdatingOrder(false);
                              setSelectedOrderHasBeenUpdated(true);
                            }
                          } else {
                            setIsUpdatingOrder(false);
                            setSelectedOrderHasBeenUpdated(true);
                          }
                        })
                        .catch((error) => {
                          setIsUpdatingOrder(false);
                          if (error.code.includes("jwt")) {
                            localStorage.removeItem("token-dashboard");
                            window.location.replace("/");
                          }
                        })
                        .finally(() => { });
                    }
                  }}
                  type="button"
                >
                  {isUpdatingOrder && (
                    <svg
                      className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                  {isCreatingNewOrder
                    ? "Créer la commande"
                    : isUpdatingOrder
                      ? "Mise à jour..."
                      : "Mettre à jour"}
                </button>
              </div>
            </div>

            {selectedOrderData ? (
              <>
                <SingleOrderInfoCardsWithTitle
                  isNewOrder={isCreatingNewOrder}
                  mainOrderInfo={selectedOrderData.mainOrderInfo}
                  paymentAndInvoiceInfo={
                    selectedOrderData.paymentAndInvoiceInfo
                  }
                  invoiceAddress={selectedOrderData.invoiceAddress}
                  activityAddress={selectedOrderData.activityAddress}
                />
                <SingleOrderLineItemsTable />

                {/* <SingleOrderNotes /> */}
                <CardWithHeader title="Commentaire admin">
                  <SingleOrderInfoCardsItemData
                    isEditable={true}
                    setValue={(value) => {
                      selectedOrderData &&
                        setSelectedOrderData({
                          ...selectedOrderData,
                          adminComments: value,
                        });
                    }}
                    title="Commentaire"
                    value={selectedOrderData?.adminComments || "-"}
                  />
                </CardWithHeader>
                {selectedOrderData?.comments?.stand.map((comment, index) => {
                  return (
                    <CardWithHeader
                      key={index}
                      title={`Commentaire stand${(selectedOrderData?.comments?.stand as string[])
                        .length > 1
                        ? ` ${index + 1}`
                        : ""
                        }`}
                    >
                      <SingleOrderInfoCardsItemData
                        isEditable={false}
                        value={comment || "-"}
                      />
                    </CardWithHeader>
                  );
                })}
                {selectedOrderData?.comments?.conference.map(
                  (comment, index) => {
                    return (
                      <CardWithHeader
                        key={index}
                        title={`Commentaire conference${(selectedOrderData?.comments?.conference as string[])
                          .length > 1
                          ? ` ${index + 1}`
                          : ""
                          }`}
                      >
                        <SingleOrderInfoCardsItemData
                          isEditable={false}
                          value={comment || "-"}
                        />
                      </CardWithHeader>
                    );
                  }
                )}
                {selectedOrderData?.comments?.workshop.map((comment, index) => {
                  return (
                    <CardWithHeader
                      key={index}
                      title={`Commentaire atelier${(selectedOrderData?.comments?.workshop as string[])
                        .length > 1
                        ? ` ${index + 1}`
                        : ""
                        }`}
                    >
                      <SingleOrderInfoCardsItemData
                        isEditable={false}
                        value={comment || "-"}
                      />
                    </CardWithHeader>
                  );
                })}
                {selectedOrderData?.comments?.careCenter.map(
                  (comment, index) => {
                    return (
                      <CardWithHeader
                        key={index}
                        title={`Commentaire espace soins${(selectedOrderData?.comments?.careCenter as string[])
                          .length > 1
                          ? ` ${index + 1}`
                          : ""
                          }`}
                      >
                        <SingleOrderInfoCardsItemData
                          isEditable={false}
                          value={comment || "-"}
                        />
                      </CardWithHeader>
                    );
                  }
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        )}
      </>
    </PageLayout>
  );
};

export default SingleOrderScreen;
