import { atom } from "recoil";
import {
  ActivityAddressType,
  CustomerProps,
  InvoiceAddressType,
} from "../../../shared/types/sharedTypes";
import { OrderData } from "../../orders/types/orders";

interface ExhibitorProps extends CustomerProps {
  adminComments?: string;
  newsletter?: boolean;
  orders?: OrderData[];
}
interface SingleExhibitorInfoProps {
  id: number;
  company: string;
  firstName: string;
  lastName: string;
  haveAlreadyParticipated: boolean;
  email: string;
  phone: string;
  website: string;
  activityType: string;
  invoiceAddress: InvoiceAddressType;
  activityAddress: ActivityAddressType;
  adminComments: string;
  newsletter: boolean;
  orders: OrderData[];
  turnover: number;
}
export interface ExhibitorDataProps {
  id: number;
  company: string;
  companyNormalized: string;
  firstName: string;
  firstNameNormalized: string;
  lastName: string;
  lastNameNormalized: string;
  phone: string;
  email: string;
  site: string;
  singleInfo: SingleExhibitorInfoProps;
}

export interface ExhibitorDataPropsV2 {
  id: number;
  billing_company: string;
  billing_first_name: string;
  billing_last_name: string;
  billing_email: string;
  billing_phone: string;
  user_url: string;
}

export interface SortingTableColumns {
  id: string;
  desc: boolean;
}

export const exhibitorsAtom = atom<ExhibitorDataProps[]>({
  key: "exhibitorsAtom",
  default: [],
});

export const selectedExhibitorAtom = atom<ExhibitorDataProps | null>({
  key: "selectedExhibitorAtom",
  default: null,
});

export const isEditingExhibitorInvoiceAddressAtom = atom<boolean>({
  key: "isEditingExhibitorInvoiceAddressAtom",
  default: false,
});

export const isEditingExhibitorActivityAddressAtom = atom<boolean>({
  key: "isEditingExhibitorActivityAddressAtom",
  default: false,
});

export const sortingTableColumnsAtom = atom<SortingTableColumns[]>({
  key: "sortingTableColumnsAtom",
  default: [],
});
