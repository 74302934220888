import { UseFormRegister } from "react-hook-form";

interface AddressInputProps {
  title: string;
  label: string;
  value: string;
  isEditable?: boolean;
  register: UseFormRegister<any>;
}

const AddressInput = ({
  label,
  title,
  value,
  isEditable = true,
  register,
}: AddressInputProps) => {
  return (
    <div className="flex flex-col gap-y-1">
      <label htmlFor={title}>{label}</label>
      <input
        className="w-full grow rounded border-2 bg-gray-50 p-1 focus:outline-none"
        type="text"
        id={title}
        defaultValue={value}
        disabled={!isEditable}
        {...register(title)}
      />
    </div>
  );
};

export default AddressInput;

// Path: src/features/orders/components/SingleOrder/SingleOrderDetails/AddressInput.tsx
