import apiFetch from "@wordpress/api-fetch";
import { addQueryArgs } from "@wordpress/url";
import { returnRootUrlBasedOnAppLocation } from "../../../shared/utils/checkIfIsInlocal";
import { VisitorDataProps } from "../atoms/visitors-atoms";

// Create an api call with @wordpress/api-fetch for the endpoint admin-dashboard/v1/orders/
interface VisitorQueryParams {
  search?: string;
  page?: number;
  per_page?: number;
  sort_by?: string;
  sort_order?: string;
  sorting_params?: string;
}
interface VisitorResponse {
  exhibitors: VisitorDataProps[];
  total_pages: number;
  total_elements: number;
}
export const fetchAllVisitors = async ({
  search,
  page,
  per_page,
  sort_by,
  sort_order,
  sorting_params,
}: VisitorQueryParams): Promise<VisitorResponse> => {
  const queryParams = {
    search: search,
    page: page,
    per_page: per_page,
    sort_by: sort_by,
    sort_order: sort_order,
    sorting_params: sorting_params,
  };
  let token = "";
  if (localStorage.getItem("token-dashboard")) {
    token = JSON.parse(localStorage.getItem("token-dashboard") as string);
  }
  const response = await apiFetch({
    path: addQueryArgs(
      `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v2/exhibitors-no-pro`,
      queryParams
    ),
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response as VisitorResponse;
};
