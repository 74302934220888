export const sortableOrderStatuses = [
  "Tous les statuts de commande",
  "Attente acceptation",
  "Attente paiement",
  "Inscription payée",
  "Salon terminé",
];

export const orderStatuses = [
  "Attente acceptation",
  "Attente paiement",
  "Inscription payée",
  "Salon terminé",
];
