import { ArrowLeftCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon, EyeIcon } from "@heroicons/react/24/solid";
import { createColumnHelper } from "@tanstack/react-table";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import ActionPopup from "../../../shared/ui/components/ActionPopup";
import OverviewBoxComponent, {
  OverviewBoxComponentProps,
} from "../../../shared/ui/components/OverviewBoxComponent";
import SectionTitle from "../../../shared/ui/components/SectionTitle";
import PageLayout from "../../../shared/ui/layouts/PageLayout";
import { returnRootUrlBasedOnAppLocation } from "../../../shared/utils/checkIfIsInlocal";
import {
  QuickViewOrderItemProps,
  ordersDataAtom,
  quickViewOrderItemAtom,
} from "../../orders/atoms/orders-atoms";
import OrdersQuickViewPopup from "../../orders/components/Orders/OrdersPopupActionsContent/OrdersQuickViewPopup";
import TableWithTitle from "../../orders/layouts/TableWithTitle";
import {
  selectedExhibitionAtom
} from "../atoms/exhibitions-atoms";
import { ExhibitionExhibitor } from "./ExhibitionsScreen";
const columnHelper = createColumnHelper<ExhibitionExhibitor>();
const SingleExhibitionScreen = () => {
  const exhibitionId = window.location.href.split("/").pop();
  const [exhibition, setExhibition] = useRecoilState(selectedExhibitionAtom);
  const [quickViewOrderItem, setQuickViewOrderItem] = useRecoilState(
    quickViewOrderItemAtom
  );
  const [orders, setOrders] = useRecoilState(ordersDataAtom);
  let token = "";
  if (localStorage.getItem("token-dashboard")) {
    token = JSON.parse(localStorage.getItem("token-dashboard") as string);
  }
  const [isLoading, setIsLoading] = useState(true);
  const overViewBoxes: OverviewBoxComponentProps[] = [
    {
      title: "Inscriptions",
      label: "",
      value: exhibition ? exhibition.standsCount.toString() : "0",
      //   actionFunction: () => {},
      primaryColor: exhibition ? exhibition.exhibition.primaryColor : "#EEDAD9",
      secondaryColor: exhibition
        ? exhibition.exhibition.secondaryColor
        : "#FEE5B8",
      // thirdColor: "#593937",
      actionTitle: "Stands",
      alignCenter: true,
      disableLink: true,
    },
    {
      title: "Inscriptions",
      label: "",
      value: exhibition ? exhibition.conferencesCount.toString() : "0",
      //   actionFunction: () => {},
      primaryColor: exhibition ? exhibition.exhibition.primaryColor : "#EEDAD9",
      secondaryColor: exhibition
        ? exhibition.exhibition.secondaryColor
        : "#FEE5B8",
      // thirdColor: "#445463",
      actionTitle: "Conférences",
      alignCenter: true,
      disableLink: true,
    },
    {
      title: "Inscriptions",
      label: "",
      value: exhibition ? exhibition.workshopsCount.toString() : "0",
      //   actionFunction: () => {},
      primaryColor: exhibition ? exhibition.exhibition.primaryColor : "#EEDAD9",
      secondaryColor: exhibition
        ? exhibition.exhibition.secondaryColor
        : "#FEE5B8",
      // thirdColor: "#7D5E2C",
      actionTitle: "Ateliers",
      alignCenter: true,
      // actionFunction: () => null,
      disableLink: true,
    },
    {
      title: "Inscriptions",
      label: "",
      value: exhibition ? exhibition.careCentersCount.toString() : "0",
      //   actionFunction: () => {},
      primaryColor: exhibition ? exhibition.exhibition.primaryColor : "#EEDAD9",
      secondaryColor: exhibition
        ? exhibition.exhibition.secondaryColor
        : "#FEE5B8",
      // thirdColor: "#36453C",
      actionTitle: "Espaces Soins",
      alignCenter: true,
      disableLink: true,
    },
  ];
  const colums = [
    columnHelper.accessor("companyName", {
      header: "Client",
      // cell: ({ getValue, row }) => <span>{getValue()}</span>,
      cell: ({ getValue, row }) => (
        <div className="flex items-center justify-center gap-x-2">
          <span>{getValue()}</span>
          <div
            className="hover:cursor-pointer"
            onClick={() => {
              const selectedOrder =
                orders.find(
                  (order, index) =>
                    order.orderId.toString() === row.original.orderId.toString()
                ) || null;

              if (row.original.quickView) {
                const quickViewOrderItem: QuickViewOrderItemProps = {
                  company: row.original.quickView.company,
                  firstName: row.original.quickView.firstName,
                  lastName: row.original.quickView.lastName,
                  email: row.original.quickView.email,
                  phone: row.original.quickView.phone,
                  website: row.original.quickView.website,
                  activityType: row.original.quickView.activityType,
                  haveAlreadyParticipated:
                    row.original.quickView.hasAllreadyBeenToExhibition,
                };
                setQuickViewOrderItem(quickViewOrderItem);
              } else {
                setQuickViewOrderItem(null);
              }
            }}
          >
            <EyeIcon className="w-4 h-4" />
          </div>
        </div>
      ),
    }),
    columnHelper.accessor("orderId", {
      header: "Commande",
      cell: ({ getValue, row }) =>
        getValue() ? (
          <Link
            className="underline"
            to={`/commandes/${getValue()}`}
            onClick={() => localStorage.setItem("selectedMenuIndex", "0")}
          >
            <span>{getValue() || "-"}</span>
          </Link>
        ) : (
          <span>{getValue() || "-"}</span>
        ),
    }),
    columnHelper.accessor("stands", {
      header: "Stand",
      cell: ({ getValue, row }) => (
        <span>
          {getValue() ? (
            <CheckCircleIcon className="mx-auto w-7" />
          ) : (
            <XCircleIcon className="mx-auto w-7" />
          )}
        </span>
      ),
    }),
    columnHelper.accessor("conferences", {
      header: "Conf",
      cell: ({ getValue, row }) => (
        <span>
          {getValue() ? (
            <CheckCircleIcon className="mx-auto w-7" />
          ) : (
            <XCircleIcon className="mx-auto w-7" />
          )}
        </span>
      ),
    }),
    columnHelper.accessor("workshops", {
      header: "Ateliers",
      cell: ({ getValue, row }) => (
        <span>
          {getValue() ? (
            <CheckCircleIcon className="mx-auto w-7" />
          ) : (
            <XCircleIcon className="mx-auto w-7" />
          )}
        </span>
      ),
    }),
    columnHelper.accessor("careCenters", {
      header: "Soins",
      cell: ({ getValue, row }) => (
        <span>
          {getValue() ? (
            <CheckCircleIcon className="mx-auto w-7" />
          ) : (
            <XCircleIcon className="mx-auto w-7" />
          )}
        </span>
      ),
    }),
    columnHelper.accessor("betterVisibility", {
      header: "MV",
      cell: ({ getValue, row }) => (
        <span>
          {getValue() ? (
            <CheckCircleIcon className="mx-auto w-7" />
          ) : (
            <XCircleIcon className="mx-auto w-7" />
          )}
        </span>
      ),
    }),
    columnHelper.accessor("ads", {
      header: "Pub",
      cell: ({ getValue, row }) => (
        <span>
          {getValue() ? (
            <CheckCircleIcon className="mx-auto w-7" />
          ) : (
            <XCircleIcon className="mx-auto w-7" />
          )}
        </span>
      ),
    }),
    columnHelper.accessor("parking", {
      header: "Parking",
      cell: ({ getValue, row }) => (
        <span>
          {getValue() ? (
            <CheckCircleIcon className="mx-auto w-7" />
          ) : (
            <XCircleIcon className="mx-auto w-7" />
          )}
        </span>
      ),
    }),
  ];

  const popus: JSX.Element[] = [
    <ActionPopup
      forQuickView={true}
      key={Math.random()}
      title={`${quickViewOrderItem ? quickViewOrderItem.company : "Aperçu fiche client"
        }`}
      children={
        <OrdersQuickViewPopup
          activityType={quickViewOrderItem?.activityType ?? ""}
          company={quickViewOrderItem?.company ?? ""}
          email={quickViewOrderItem?.email ?? ""}
          firstName={quickViewOrderItem?.firstName ?? ""}
          haveAlreadyParticipated={
            quickViewOrderItem?.haveAlreadyParticipated ?? false
          }
          lastName={quickViewOrderItem?.lastName ?? ""}
          phone={quickViewOrderItem?.phone ?? ""}
          website={quickViewOrderItem?.website ?? ""}
        />
      }
      isVisible={quickViewOrderItem !== null}
      // isVisible={true}
      setIsVisible={() => setQuickViewOrderItem(null)}
      action={() => { }}
      cancelAction={() => {
        setQuickViewOrderItem(null);
      }}
      actionButtonDisabled={false}
      actionButtonTitle="Ajouter"
      actionButtonsVisible={false}
    />,
  ];

  useEffect(() => {
    setIsLoading(true);
    if (!exhibition) {
      axios
        .get(
          `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v1/exhibitions-listing?id=${exhibitionId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setExhibition(res.data[0]);
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
          if (err.code.includes("jwt")) {
            console.log(err);
            localStorage.removeItem("token-dashboard");
            window.location.replace("/");
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []);

  return (
    <PageLayout>
      {popus}
      <>
        <div className="flex flex-col gap-y-16">
          <div className="flex justify-between w-full">
            <Link
              className="flex flex-row items-center gap-x-4 text-2xl text-[#3c7179]"
              to={`/salons`}
              onClick={() => {
                // setSelectedOrderIndex(null);
                // setSelectedOrderData(null);
                // setIsCreatingNewOrder(false);
                // setSelectedOrderDiscountLineItems([]);
              }}
            >
              <ArrowLeftCircleIcon className="color-[#3C7179] w-10" /> Retour à
              la liste des salons
            </Link>
          </div>
          {/* <OverviewBoxesWithTitleLayout children={overViewBoxes} /> */}
          <div className="flex flex-col gap-y-6">
            <SectionTitle title="Vue d'ensemble" />
            <div className="grid grid-cols-4 gap-x-8">
              {overViewBoxes.map((child, index) => {
                return (
                  <OverviewBoxComponent
                    key={index}
                    loading={isLoading}
                    {...child}
                  />
                );
              })}
            </div>
          </div>
          <TableWithTitle
            isLoading={isLoading}
            title="Liste des inscrits"
            tableData={exhibition ? exhibition?.exhibitors : []}
            tableColumns={colums}
          />
        </div>
      </>
    </PageLayout>
  );
};

export default SingleExhibitionScreen;
