import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { returnPriceFormattedCHF } from "../../../../../../shared/utils/numbersManipulations";
import {
  orderHasBeenEditedAtom,
  selectedEditProductDataAtom,
  selectedEditProductIndexAtom,
  singleOrderLineItemsDataAtom,
} from "../../../../atoms/orders-atoms";
// import { editProductSelectedProductAtom } from "../../../../atoms/orders-atoms";
import { Product } from "../../../../types/orders";

let vatRate = 0.077;

interface StyledInputForAmountProps {
  value: number;
  onChange: (value: number) => void;
  prefix?: boolean;
  currency?: string;
  inputName: string;
  isQuantity?: boolean;
  originalValue?: number;
  quantity?: number;
  isOrderWithNewVatRate?: boolean;
}
const StyledInputForAmount = ({
  value,
  onChange,
  prefix = true,
  currency = "CHF",
  inputName,
  isQuantity = false,
  originalValue,
  quantity = 1,
  isOrderWithNewVatRate = false,
}: StyledInputForAmountProps) => {
  // const formattedValue = prefix
  //   ? value.toFixed(2).replace(",", ".")
  //   : value.toString();
  // const formattedValue = returnPriceFormattedCHF(value, false);
  let formattedValue = returnPriceFormattedCHF(value, false).replace("CHF", "");
  // Keep only 2 decimals

  if (isOrderWithNewVatRate) {
    vatRate = 0.081;
  }

  if (isQuantity) {
    formattedValue = value.toString();
  }

  return (
    <div className="flex flex-col gap-y-2">
      <div className="flex items-center px-4 border-2 rounded gap-x-2">
        {prefix && <span>{currency}</span>}
        <div>
          <label className="sr-only" htmlFor={inputName}>
            {inputName}
          </label>
          <input
            pattern="[^-]*"
            name={inputName}
            className="w-full py-2 border-none bg-none focus-visible:border-none focus-visible:outline-none"
            // type="number"
            type={isQuantity ? "number" : "text"}
            min={1}
            // defaultValue={value ? formattedValue : undefined}
            value={value}
            // value={undefined}
            // value={undefined}
            // defaultValue={value.toString()}
            placeholder={formattedValue}
            onChange={(e) => {
              onChange(Number(e.currentTarget.value));
            }}
          // onKeyDown={(e) => (e.key === "-" ? e.preventDefault() : null)}
          />
          {/* <input
          name={inputName}
          className="w-full py-2 border-none bg-none focus-visible:border-none focus-visible:outline-none"
          type="number"
          // defaultValue={formattedValue}
          value={formattedValue}
          placeholder={formattedValue}
          onInput={(e) => {
            onChange(Number(e.currentTarget.value));
          }}
        /> */}
        </div>
      </div>
      {!isQuantity && (
        <div className="px-1 text-xs text-gray-500">
          <span>
            Avant rabais. :{" "}
            {originalValue && returnPriceFormattedCHF(originalValue)}
          </span>
        </div>
      )}
    </div>
  );
};

// interface SingleOrderPopupEditProductProps {
//   product?: Product | null;
// }
export interface SingleOrderPopupEditProductProps {
  isOrderWithNewVatRate: boolean;
}
const SingleOrderPopupEditProduct = ({
  isOrderWithNewVatRate = false,
}: SingleOrderPopupEditProductProps) => {
  const [editProductSelectedProduct, setEditProductSelectedProduct] =
    useRecoilState(selectedEditProductDataAtom);
  const regul = { ...editProductSelectedProduct[0] }.regularPrice;
  let originalVat = { ...editProductSelectedProduct[0] }.vat;
  //
  const taxRate = { ...editProductSelectedProduct[0] }.taxRate;
  const calculatedVatBasedOnTaxRate = taxRate
    ? regul * (taxRate / 100)
    : regul * (vatRate / 100);
  originalVat = calculatedVatBasedOnTaxRate;
  //
  const [regularPrice, setRegularPrice] = useState<number>();
  const [regularVat, setRegularVat] = useState<number>();
  const [amount, setAmount] = useState<number>(
    editProductSelectedProduct[0]
      ? // ? editProductSelectedProduct[0].regularPrice
      editProductSelectedProduct[0].price
      : 0
  );
  const [vat, setVat] = useState<number>(
    // editProductSelectedProduct[0] ? editProductSelectedProduct[0].vat : 0
    // originalVat
    editProductSelectedProduct[0]
      ? // ? editProductSelectedProduct[0].regularPrice
      editProductSelectedProduct[0].vat
      : originalVat
  );
  const [quantity, setQuantity] = useState<number>(
    editProductSelectedProduct[0] ? editProductSelectedProduct[0].quantity : 1
  );
  const [singleOrderLineItemsData, setSingleOrderLineItemsData] =
    useRecoilState(singleOrderLineItemsDataAtom);

  const [selectedEditProductIndex, setSelectedEditProductIndex] =
    useRecoilState(selectedEditProductIndexAtom);

  const [selectedEditProductData, setSelectedEditProductData] = useRecoilState(
    selectedEditProductDataAtom
  );

  useEffect(() => {
    if (editProductSelectedProduct[0]) {
      setRegularPrice(regul);
      setRegularVat(originalVat);
    }
  }, []);
  const [orderHasBeenEdited, setOrderHasBeenEdited] = useRecoilState(
    orderHasBeenEditedAtom
  );

  // useEffect(() => {
  //   console.log("QUANTITY CHANGED");
  //   // Check if quantity is different from the prev state
  //     editProductSelectedProduct &&
  //       setVat(editProductSelectedProduct[0]?.vat * quantity);
  //     setAmount(editProductSelectedProduct[0]?.regularPrice * quantity);
  // }, [quantity]);
  useEffect(() => {
    console.log('EDIT', editProductSelectedProduct[0]);
  }, []);

  return editProductSelectedProduct.length > 0 ? (
    <div className="flex flex-col gap-y-6">
      <div className="flex flex-row items-start justify-between gap-x-4">
        <div className="flex flex-col grow gap-y-2">
          <span className="text-sm text-gray-500">Article</span>
          <span className="py-2 font-bold">
            {editProductSelectedProduct[0].name}
          </span>
        </div>
        <div className="flex w-[10%] flex-col gap-y-2">
          <span className="text-sm text-gray-500">Qté</span>
          <StyledInputForAmount
            inputName="quantity"
            value={quantity}
            prefix={false}
            isQuantity={true}
            onChange={(e) => {
              setQuantity(e);
              setAmount(editProductSelectedProduct[0].regularPrice * e);
              setVat(vatRate * (editProductSelectedProduct[0].regularPrice * e));
            }}
            isOrderWithNewVatRate={isOrderWithNewVatRate}
          />
        </div>
        {editProductSelectedProduct[0]?.name.toLowerCase().includes('sur-mesure') ? (<>
          <div className="flex w-[25%] flex-col gap-y-2">
            <span className="text-sm text-gray-500">Montant HT</span>
            <StyledInputForAmount
              quantity={quantity}
              originalValue={regul ? regul * quantity : regul}
              inputName="montant-ht"
              value={amount}
              onChange={(e) => {// )
                setRegularPrice(e / quantity);
                setAmount(e);
                setVat(
                  e * vatRate
                );
              }}
            />
          </div>
          <div className="flex w-[20%] flex-col gap-y-2">
            <span className="text-sm text-gray-500">Montant TVA</span>
            <StyledInputForAmount
              quantity={quantity}
              originalValue={
                originalVat ? originalVat * quantity : regul * quantity * vatRate
                // 1000
              }
              inputName="tva"
              value={vat}
              onChange={(e) => {
                setVat(e);
              }}
            />
          </div>
        </>) : <></>}
      </div>
      <div className="flex flex-row justify-end mt-4 gap-x-6">
        <button
          type="button"
          className="border-none bg-none"
          onClick={() => {
            // setSelectedOrderAddDiscountProductButtonClicked(false);
            // setSelectedOrderSelectedDiscountLineItem(null);
            setEditProductSelectedProduct([]);
          }}
        >
          <span className="text-lg font-semibold text-[#3C7179]">Annuler</span>
        </button>
        <button
          type="button"
          className="rounded-md border-none bg-[#3C7179] px-8 py-4 text-lg font-semibold text-white"
          onClick={() => {
            const updatedSingleOrderLineItemsData =
              singleOrderLineItemsData.map((product, index) => {
                if (index === selectedEditProductIndex) {
                  console.log("same");
                  return {
                    ...product,
                    vat: vat !== product.vat ? vat : amount * vatRate,
                    quantity: quantity,
                    price: amount,
                    regularPrice: regularPrice ? regularPrice : amount,
                  };
                } else {
                  console.log("not same");
                  return product;
                }
              });

            setSingleOrderLineItemsData(updatedSingleOrderLineItemsData);
            setSelectedEditProductData([]);
            setOrderHasBeenEdited(true);
          }}
          disabled={false}
        >
          {/* Ajouter */}
          Mettre à jour
        </button>
      </div>
    </div>
  ) : (
    <div>Produit non trouvé</div>
  );
};

export default SingleOrderPopupEditProduct;
