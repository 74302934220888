import { CheckIcon, PencilIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { useEffect, useRef, useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import { useRecoilState } from "recoil";
import { ConferenceFlagAtom } from "../../atoms/flag-atoms";
import { selectedPostAtom } from "../../atoms/single-data-atoms";
import { allCountryCodes, countries } from "../../types/countries";

interface LinkType {
  type: "email" | "phone" | "website";
  value: string;
}
const returnLinkBasedOnLinkType = ({ type, value }: LinkType) => {
  let formattedWebsite = value;
  // Check if value is website and if does not have http:// or https:// then add it
  if (type === "website") {
    if (value.includes("http://") || value.includes("https://")) {
      formattedWebsite = value;
    } else {
      formattedWebsite = `http://${value}`;
    }
  }
  switch (type) {
    case "email":
      return `mailto:${value}`;
    case "phone":
      return `tel:${value}`;
    case "website":
      return formattedWebsite;
    default:
      return value;
  }
};

interface SinglePostInfoCardsItemDataProps {
  title?: string;
  value?: any;
  setValue?: (arg0: string) => void;
  link?: string;
  isEditable?: boolean;
  itemsCenter?: boolean;
  isSelect?: boolean;
  email?: string | null;
  phone?: string | null;
  website?: string | null;
  date?: boolean | null;
}
const SinglePostInfoCardsItemData = ({
  title,
  value,
  setValue,
  link,
  isEditable = true,
  itemsCenter = false,
  isSelect = false,
  email,
  phone,
  website,
  date,
}: SinglePostInfoCardsItemDataProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [tempValue, setTempValue] = useState(value);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const phoneRef = useRef<HTMLInputElement>(null);
  const [selected, setSelected] = useRecoilState(ConferenceFlagAtom);
  const [selectedFlag, setSelectedFlag] = useState(selected);
  const [selectedPost, setSelectedPost] = useRecoilState(selectedPostAtom);

  // Initialisez `selected` avec la valeur de `selectedConferenceData` lors du montage du composant
  // useEffect(() => {
  //   if (selectedConferenceData?.exhibitorInfo?.phone_country_code) {
  //     setSelected(selectedConferenceData.exhibitorInfo.phone_country_code);
  //   }
  // }, [selectedConferenceData, setSelected]);

  const prefixRegex = /^(\+\d+)/;
  const match = phone?.match(prefixRegex);

  // `match` est un tableau où le premier élément est la chaîne complète qui correspond à la regex,
  // et les éléments suivants représentent les groupes capturés.
  // Dans ce cas, match[0] contiendra le préfixe s'il est trouvé.
  const prefix = match ? match[0] : "";

  useEffect(() => {
    setSelected(selectedFlag);
  }, [selectedFlag]);

  useEffect(() => {
    console.log(selected);
  }, [selected]);

  useEffect(() => {
    console.log(phoneRef);
  }, []);

  useEffect(() => {
    selectedPost &&
      setSelectedFlag(selectedPost?.exhibitorInfo.phone_country_code);
    selectedPost && setSelected(selectedPost?.exhibitorInfo.phone_country_code);
  }, []);

  const [tempSelectedFlag, setTempSelectedFlag] = useState(
    selectedPost?.exhibitorInfo.phone_country_code
  );

  useEffect(() => {
    console.log("cjkdsncjkdsn---cdnksckdslc", prefix);
  }, [prefix]);

  return link ? (
    <div className="flex flex-col items-start">
      <button
        type="button"
        className="border-none bg-none"
        onClick={() => console.log(link)}
      >
        <span className="underline">{title}</span>
      </button>
    </div>
  ) : (
    <div className={`flex flex-col ${itemsCenter && "items-center"}`}>
      {title && value && (
        <div className="flex flex-row items-center gap-x-4">
          <span className="font-bold text-[#3C7179]">{title}</span>
          {isEditable && (
            <div
              className={`flex cursor-pointer flex-row gap-x-2 ${
                isEditing && "cursor-auto opacity-0"
              }`}
            >
              <div
                className="p-[6px] text-gray-400 transition hover:text-gray-600"
                onClick={() => {
                  setIsEditing(true);
                  inputRef.current?.focus();
                }}
              >
                <PencilIcon className="w-5" />
              </div>
            </div>
          )}
        </div>
      )}
      <div className="flex flex-row items-center gap-x-4">
        {value && (
          <div className={`${isEditing ? "bg-gray-50" : ""} grow rounded`}>
            {!isEditing && !email && !phone && !website && <span>{value}</span>}
            {!isEditing && email && (
              <a
                className="underline"
                href={returnLinkBasedOnLinkType({
                  type: "email",
                  value: email,
                })}
              >
                {value}
              </a>
            )}
            {!isEditing && phone && (
              <div className="lg:w-full">
                <div className="flex flex-row items-center justify-start gap-3">
                  <div className="flex flex-row items-center justify-start gap-1">
                    <ReactFlagsSelect
                      selected={selectedFlag}
                      onSelect={(code) => setSelected(code)}
                      disabled={true}
                      showSelectedLabel={false}
                      className={
                        isEditing ? "flag-select" : "flag-select-viewOnly"
                      }
                    />
                    <span>{prefix}</span>
                  </div>
                  <a
                    className="underline"
                    href={returnLinkBasedOnLinkType({
                      type: "phone",
                      value: phone,
                    })}
                  >
                    {value}
                  </a>
                </div>
              </div>
            )}
            {!isEditing && website && (
              <a
                className={
                  website !== "-" ? "underline" : "pointer-events-none"
                }
                rel="noreferrer"
                target="_blank"
                href={returnLinkBasedOnLinkType({
                  type: "website",
                  value: website,
                })}
              >
                {value}
              </a>
            )}
            {!isSelect && isEditing && phone && (
              <div className="lg:w-full">
                <div className="flex flex-row items-center justify-start gap-1">
                  <ReactFlagsSelect
                    selected={selectedFlag}
                    onSelect={(code) => setSelectedFlag(code)}
                    searchable={true}
                    countries={allCountryCodes}
                    customLabels={countries}
                    placeholder="Pays"
                    disabled={false}
                    showSelectedLabel={false}
                    className="flag-select"
                    searchPlaceholder="Rechercher un pays"
                  />
                  <input
                    type="text"
                    defaultValue={
                      value !== "-" ? value.replace(/\s+/g, "") : ""
                    }
                    placeholder={value === "Non renseigné" ? value : ""}
                    autoFocus={isEditing}
                    className={`${
                      isEditing && "w-full"
                    } w-full rounded border-none bg-transparent p-1 focus:outline-none`}
                    ref={phoneRef}
                    readOnly={!isEditing}
                    pattern="[0-9]*"
                    onInput={(e) => {
                      const input = e.target as HTMLInputElement; // Assurer que e.target est de type HTMLInputElement
                      const filteredValue = input.value.replace(/[^0-9]/g, "");
                      setTempValue(filteredValue);
                      input.value = filteredValue; // Mettre à jour la valeur affichée dans l'input
                    }}
                    onPaste={(e) => {
                      // Empêcher le collage de texte qui pourrait contenir des espaces ou des caractères non désirés
                      e.preventDefault();
                      // Extraire le texte du presse-papiers et filtrer pour ne garder que les chiffres
                      const pasteText = (e.clipboardData || window.Clipboard)
                        .getData("text")
                        .replace(/[^0-9]/g, "");
                      // Insérer le texte filtré dans l'input
                      document.execCommand("insertText", false, pasteText);
                    }}
                  />
                </div>
              </div>
            )}
            {isSelect && isEditing && !phone && (
              <select
                className={`${
                  isEditing && "w-full"
                } w-full rounded border-none bg-transparent p-1 focus:outline-none`}
                defaultValue={value === "Oui" ? "true" : "false"}
                autoFocus={isEditing}
                onChange={(e) => {
                  setTempValue(e.target.value); // Utilisez setTempValue ici
                }}
              >
                <option value="false">Non</option>
                <option value="true">Oui</option>
              </select>
            )}
            {isEditing && !isSelect && !phone && (
              <textarea
                // disabled={!isEditing}
                readOnly={!isEditing}
                rows={6}
                ref={inputRef}
                className={`${
                  isEditing && "w-full"
                } w-full rounded border-none bg-transparent p-1 focus:outline-none`}
                defaultValue={value !== "-" ? value : ""}
                placeholder={value === "Non renseigné" ? value : ""}
                autoFocus={isEditing}
                onChange={(e) => {
                  setTempValue(e.target.value); // Utilisez setTempValue ici
                }}
              />
            )}
          </div>
        )}
        {title && !value && (
          <span className="font-bold text-[#3C7179]">{title}</span>
        )}
        {/* Edit icons */}
        {isEditable && (
          <div>
            {isEditing && (
              <div className="flex flex-row items-center gap-x-2">
                <div
                  onClick={() => {
                    setIsEditing(false);
                    setValue && setValue(tempValue);
                  }}
                  className="cursor-pointer rounded-full p-1 text-green-400 transition hover:bg-green-100 hover:shadow"
                >
                  <CheckIcon className="w-6" />
                </div>
                <div
                  onClick={() => {
                    setIsEditing(false);
                    setTempValue(value);
                    tempSelectedFlag && setSelectedFlag(tempSelectedFlag);
                    selectedPost &&
                      setSelectedPost({
                        ...selectedPost,
                        exhibitorInfo: {
                          ...selectedPost.exhibitorInfo,
                          phone_country_code: tempSelectedFlag || "CH",
                        },
                      });
                  }}
                  className="cursor-pointer rounded-full p-1 text-red-400 transition hover:bg-red-100 hover:shadow"
                >
                  <XMarkIcon className="w-6" />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SinglePostInfoCardsItemData;
