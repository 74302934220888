import { XCircleIcon } from "@heroicons/react/24/outline";
import { XCircleIcon as XCircleIconSolid } from "@heroicons/react/24/solid";
import { useState } from "react";
import clearLocalStorage from "../../../shared/utils/clearLocalStorage";

interface ClearTableFiltersProps {
  resetFilters: () => void;
  localStorageFiltersKeys: string[];
  isLoading?: boolean;
}
const ClearTableFilters = ({
  resetFilters,
  localStorageFiltersKeys,
  isLoading,
}: ClearTableFiltersProps) => {
  const [isHoveringRemoveFilters, setIsHoveringRemoveFilters] = useState(false);
  return (
    <div
      onClick={() => {
        if (isLoading) return;
        clearLocalStorage({ keys: localStorageFiltersKeys });
        resetFilters();
      }}
      onMouseEnter={() => setIsHoveringRemoveFilters(true)}
      onMouseLeave={() => setIsHoveringRemoveFilters(false)}
      className={`${
        isLoading && "cursor-wait"
      } mt-1 flex cursor-pointer items-center justify-center`}
    >
      {(!isHoveringRemoveFilters || isLoading) && (
        <XCircleIcon className="w-6 h-6 text-red-400" />
      )}
      {isHoveringRemoveFilters && !isLoading && (
        <XCircleIconSolid className="w-6 h-6 text-red-400" />
      )}
    </div>
  );
};

export default ClearTableFilters;
