import { atom } from "recoil";
import { CustomerProps, Exhibition } from "../../../shared/types/sharedTypes";
import { OrderData } from "../../orders/types/orders";
import { QuickView, WorkshopListingProps } from "../api/fetchWorkshops";

interface WorkshopProps extends CustomerProps {
  adminComments?: string;
  newsletter?: boolean;
  orders?: OrderData[];
}

type WorkshopGeneralInfo = {
  workshopName: string;
  workshopDescription: string;
  company: string;
  firstName: string;
  lastName: string;
  fullName: string;
  hasAllreadyBeenToExhibition: boolean;
  exhibition: Exhibition;
  workshopDateHeure: string;
  photoDeLatelier: any;
  photoDeLorganisateurDeLatelier: any;
  informationsEtInscriptions: string;
  salle: string;
  userId: number;
  // workshopDateEtHeure: string;
};
type ExhibitorInfo = {
  email: string;
  phone: string;
  website: string;
  activityType: string;
};
type OrderAndInfo = {
  orderNumber: string;
  orderStatus: string;
  workshopStatus: string;
  standNumber: string;
  orderHasStand: boolean;
};
export interface WorkshopDataProps {
  user_meta: any;
  id: string;
  workshopStatus: string;
  workshopStatusNormalized: string;
  titleNormalized: string;
  statusNormalized: string;
  exhibitionNormalized: string;
  speakerNormalized: string;
  speakerJob: string;
  speakerLastName: string;
  speakerFirstName: string;
  title: string;
  info: string;
  timeSlot1: string;
  timeSlot2: string;
  timeSlot3: string;
  timeSlot4: string;
  timeSlot5: string;
  workshopGeneralInfo: WorkshopGeneralInfo;
  exhibitorInfo: ExhibitorInfo;
  orderAndWorkshopInfo: OrderAndInfo;
  exhibitorComments: string;
  adminComments: string;
  quickView: QuickView;
  speaker: string;
  creneauxEtSalles: string;
}

export const workshopsAtom = atom<WorkshopDataProps[]>({
  key: "workshopsAtom",
  default: [],
});

export const filteredWorkshopsAtom = atom<WorkshopDataProps[] | null>({
  key: "filteredWorkshopsAtom",
  default: null,
});

export const selectedWorkshopAtom = atom<WorkshopDataProps | null>({
  key: "selectedWorkshopAtom",
  default: null,
});

export const isEditingWorkshopInvoiceAddressAtom = atom<boolean>({
  key: "isEditingWorkshopInvoiceAddressAtom",
  default: false,
});

export const isEditingWorkshopActivityAddressAtom = atom<boolean>({
  key: "isEditingWorkshopActivityAddressAtom",
  default: false,
});
