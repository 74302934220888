// TODO: Add the chevron icon to the button
import Pill from "./Pill";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
export interface OverviewBoxComponentProps {
  title: string;
  label: string;
  value: string;
  subtitle?: string;
  actionTitle?: string;
  actionFunction?: () => void;
  primaryColor?: string;
  secondaryColor?: string;
  thirdColor?: string;
  alignCenter?: boolean;
  disableLink?: boolean;
  loading?: boolean;
}
const OverviewBoxComponent = ({
  title,
  label,
  value,
  subtitle,
  actionTitle = "Voir le salon",
  actionFunction = () => alert("Not implemented yet"),
  primaryColor,
  secondaryColor,
  thirdColor,
  alignCenter = false,
  disableLink = false,
  loading = true,
}: OverviewBoxComponentProps) => {
  return (
    <div className="w-full rounded-lg bg-white shadow-md">
      {/* First half of the card */}
      <div className="flex flex-col gap-y-4 p-4">
        {/* Title and Pill */}
        <div className="flex flex-col items-center justify-between gap-x-4 gap-y-2 2xl:flex-row">
          <span
            className={`${
              alignCenter && "text-center"
            } grow text-2xl font-bold`}
          >
            {title}
          </span>
          {label !== "" && (
            <Pill label={label} bgColor={primaryColor} textColor={thirdColor} />
          )}
        </div>
        {/* Value and subtitle */}
        {!loading && (
          <div
            className={`flex flex-col items-center ${
              !alignCenter && "2xl:items-start"
            }`}
          >
            <div
              className="text-7xl font-normal"
              style={{ color: primaryColor }}
            >
              {value}
            </div>
            {/* <span className="text-sm" style={{ color: thirdColor }}> */}
            <span className="text-md text-[#94a3b8]">{subtitle}</span>
          </div>
        )}
        {/* Loading */}
        {loading && (
          <div
            className={`w-full ${
              // alignCenter && "text-center"
              "text-center"
            } cursor-default select-none`}
          >
            <span className="animate-pulse rounded bg-slate-200 text-7xl font-normal text-transparent">
              000
            </span>
          </div>
        )}
      </div>
      {/* Second half of the card */}
      {actionTitle && actionFunction ? (
        <div
          className="flex flex-row items-center justify-center rounded-b-lg p-4"
          style={{ backgroundColor: secondaryColor }}
        >
          <button
            disabled={disableLink || loading}
            className={`${disableLink === true && "cursor-text"} ${
              loading && "cursor-wait"
            } w-full rounded-full border-none bg-none text-xl font-light hover:bg-none`}
            style={{ color: thirdColor }}
            onClick={actionFunction}
          >
            <div className="flex items-center justify-center gap-x-2">
              {actionTitle}
              {disableLink === false && <ChevronRightIcon className="w-6" />}
            </div>
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default OverviewBoxComponent;
