import { atom } from "recoil";
// TODO: Remove this default value

export const ConferenceFlagAtom = atom<string>({
  key: "ConferenceFlagAtom",
  //   default: "",
  default: "CH",
});

export const WorkshopFlagAtom = atom<string>({
  key: "WorkshopFlagAtom",
  //   default: "",
  default: "CH",
});
