import {
  ArrowDownTrayIcon,
  BanknotesIcon,
  BriefcaseIcon,
  BuildingStorefrontIcon,
  CalendarDaysIcon,
  PaintBrushIcon,
  ShoppingBagIcon,
  SpeakerWaveIcon,
  TicketIcon,
  UserGroupIcon,
  UsersIcon,
} from "@heroicons/react/20/solid";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { selectedMenuIndexAtom } from "../../atoms/navigation-atoms";
import { searchTermAtom } from "../../atoms/search-atoms";
import { faviconUrl } from "../../constants/imagesUrl";
import ImageBox from "../components/ImageBox";
import SearchComponent from "../components/SearchComponent";
import SidebarMenu, { MenuItem } from "../components/SidebarMenu";

export type AdminName = {
  firstName: string;
  lastName: string;
};

interface SidebarProps {
  adminName: AdminName;
}

export const Sidebar = ({ adminName }: SidebarProps) => {
  const [selectedMenuIndex, setSelectedMenuIndex] = useRecoilState(
    selectedMenuIndexAtom
  );
  let navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useRecoilState<string | null>(
    searchTermAtom
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<any>([]);

  const menu: MenuItem[] = [
    {
      label: "Commandes",
      icon: <ShoppingBagIcon className="h-6 w-6" />,
      onClick: () => {
        setSearchTerm(null);
        // window.location.replace("/admin-dashboard");
        navigate("/commandes");
        // setSelectedMenuIndex(0);
        // Set the index in the local storage
        localStorage.setItem("selectedMenuIndex", "0");
      },
    },
    {
      label: "Salons",
      icon: <CalendarDaysIcon className="h-6 w-6" />,
      onClick: () => {
        setSearchTerm(null);
        navigate("/salons");
        // setSelectedMenuIndex(1);
        localStorage.setItem("selectedMenuIndex", "1");
      },
    },
    {
      label: "Stands",
      icon: <BuildingStorefrontIcon className="h-6 w-6" />,
      onClick: () => {
        setSearchTerm(null);
        // window.location.replace("/stands");
        navigate("/stands");
        // setSelectedMenuIndex(2);
        localStorage.setItem("selectedMenuIndex", "2");
      },
    },
    {
      label: "Conférences",
      icon: <SpeakerWaveIcon className="h-6 w-6" />,
      onClick: () => {
        setSearchTerm(null);
        navigate("/conferences");
        // setSelectedMenuIndex(3);
        localStorage.setItem("selectedMenuIndex", "3");
      },
    },
    {
      label: "Ateliers",
      icon: <PaintBrushIcon className="h-6 w-6" />,
      onClick: () => {
        setSearchTerm(null);
        navigate("/ateliers");
        // setSelectedMenuIndex(4);
        localStorage.setItem("selectedMenuIndex", "4");
      },
    },
    {
      label: "Espace Soins",
      icon: <UsersIcon className="h-6 w-6" />,
      onClick: () => {
        setSearchTerm(null);
        navigate("/espaces-soins");
        // setSelectedMenuIndex(5);
        localStorage.setItem("selectedMenuIndex", "5");
      },
    },
    {
      label: "Clients",
      icon: <BriefcaseIcon className="h-6 w-6" />,
      onClick: () => {
        setSearchTerm(null);
        navigate("/clients");
        // setSelectedMenuIndex(6);
        localStorage.setItem("selectedMenuIndex", "6");
      },
    },
    {
      label: "Visiteurs",
      icon: <UserGroupIcon className="h-6 w-6" />,
      onClick: () => {
        setSearchTerm(null);
        navigate("/visiteurs");
        setSelectedMenuIndex(7);
        localStorage.setItem("selectedMenuIndex", "7");
      },
    },
    {
      label: "Billets",
      icon: <TicketIcon className="h-6 w-6" />,
      onClick: () => {
        setSearchTerm(null);
        // alert("Visiteurs");
        navigate("/billets");
        setSelectedMenuIndex(7);
        localStorage.setItem("selectedMenuIndex", "8");
      },
    },
    {
      label: "Coupons",
      icon: <BanknotesIcon className="h-6 w-6" />,
      onClick: () => {
        setSearchTerm(null);
        // setSelectedMenuIndex(9);
        navigate("/coupons");
        localStorage.setItem("selectedMenuIndex", "9");
      },
    },
    {
      label: "Export",
      icon: <ArrowDownTrayIcon className="h-6 w-6" />,
      onClick: () => {
        setSearchTerm(null);
        // setSelectedMenuIndex(8);
        navigate("/export");
        localStorage.setItem("selectedMenuIndex", "10");
      },
    },
  ];

  return (
    // <div className="flex min-h-screen w-full max-w-[90px] flex-col bg-[#0F766E] pb-6 2xl:max-w-[300px]">
    <div className="flex min-h-screen flex-col bg-[#0F766E] pb-6">
      {/* Welcome */}
      <div className="absolute z-[100] flex w-full items-center justify-between gap-4 bg-[#14B8A6] px-4 py-4 2xl:px-8">
        <ImageBox imageSrc={faviconUrl} imageAlt="logo" title="Bonjour" />
        <SearchComponent />
        <div>
          <button
            className="text-light rounded border-none bg-[#0F766E] px-4 py-2 text-base text-white shadow hover:bg-transparent hover:text-white"
            onClick={() => {
              // Remove the token from the local storage
              localStorage.removeItem("token-dashboard");
              // Redirect to the login page
              // navigate("/");
              window.location.replace("/");
            }}
            type="button"
          >
            Se déconnecter
          </button>
        </div>
      </div>
      {/* Menu and website link */}
      <div className="mt-20 flex h-full w-[90px] grow flex-col justify-between 2xl:mt-28 2xl:w-[250px]">
        <SidebarMenu menu={menu} />
        <div className="px-4">
          <div className="hidden 2xl:block">
            <ImageBox
              imageSrc={faviconUrl}
              imageAlt="logo"
              title="SATN"
              subtitle="Voir le site"
              subtitleLink="https://salontherapiesnaturelles.ch"
              subtitleLinkTitle="Voir le site"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

// Path: src/shared/ui/layouts/Sidebar.tsx
