// TODO : Check if order notes id have to be a suite of numbers or if they can be random
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { useRecoilState, useRecoilValue } from "recoil";
import { adminAuthNameAtom } from "../../../../../../shared/atoms/admin-atoms";
import {
  selectedOrderAddNoteButtonClickedAtom,
  selectedOrderAtom,
  selectedOrderNotesAtom,
} from "../../../../atoms/orders-atoms";
import { OrderNoteProps } from "../../../../types/orders";
import axios from "axios";
import { returnRootUrlBasedOnAppLocation } from "../../../../../../shared/utils/checkIfIsInlocal";

interface NoteType {
  id: number;
  name: string;
  private: boolean;
}
const noteTypes: NoteType[] = [
  { id: 1, name: "Note privée", private: true },
  { id: 2, name: "Note au client", private: false },
];

const returnCurrentFormattedDateForNote = (): string => {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1; // month is zero-based
  const day = now.getDate();
  const hour = now.getHours();
  const min = now.getMinutes();
  const sec = now.getSeconds();
  const formattedDate =
    year +
    "-" +
    month.toString().padStart(2, "0") +
    "-" +
    day.toString().padStart(2, "0") +
    " " +
    hour.toString().padStart(2, "0") +
    ":" +
    min.toString().padStart(2, "0") +
    ":" +
    sec.toString().padStart(2, "0");
  return formattedDate;
};

const addNoteToSelectedOrderNotes = (
  selectedOrderNotes: OrderNoteProps[],
  setSelectedOrderNotes: (value: OrderNoteProps[]) => void,
  newNote: OrderNoteProps
): void => {
  const newNotes = [newNote, ...selectedOrderNotes];
  setSelectedOrderNotes(newNotes);
};

const SingleOrderPopupAddNote = () => {
  const [
    selectedOrderAddNoteButtonClicked,
    setSelectedOrderAddNoteButtonClicked,
  ] = useRecoilState(selectedOrderAddNoteButtonClickedAtom);
  const [selectedNoteType, setSelectedNoteType] = useState(noteTypes[0]);
  const [selectedOrderNotes, setSelectedOrderNotes] = useRecoilState(
    selectedOrderNotesAtom
  );
  const adminAuthorName = useRecoilValue(adminAuthNameAtom);
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm<OrderNoteProps>();

  const [selectedOrderData, setSelectedOrderData] =
    useRecoilState(selectedOrderAtom);

  const [isLoading, setIsLoading] = useState(false);
  let token = "";
  if (localStorage.getItem("token-dashboard")) {
    token = JSON.parse(localStorage.getItem("token-dashboard") as string);
  }

  const NoteTypeSelect = () => {
    return (
      <div className="max-w-[200px] grow">
        <Listbox value={selectedNoteType} onChange={setSelectedNoteType}>
          <div className="relative">
            <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
              <span className="block truncate">{selectedNoteType.name}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-[1] mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {noteTypes.map((note: NoteType) => (
                  <Listbox.Option
                    key={note.id}
                    value={note}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? "bg-amber-100 text-amber-900" : "text-gray-900"
                      }`
                    }
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {note.name}
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      </div>
    );
  };
  return (
    <div className="flex flex-col gap-y-6">
      <div className="flex flex-col gap-y-4">
        <div className="flex flex-row items-center gap-x-4">
          <label
            htmlFor="note"
            className="text-lg font-semibold text-[#3C7179]"
          >
            Note
          </label>
          {/* <NoteTypeSelect /> */}
        </div>
        <textarea
          id="note"
          className="w-full rounded border-2 bg-none p-2 py-2 focus-visible:outline-none"
          placeholder="Ajouter une note"
          {...register("content", { required: true })}
        />
      </div>
      <div className="mt-4 flex flex-row justify-end gap-x-6">
        <button
          type="button"
          className="border-none bg-none"
          onClick={() => {
            setSelectedOrderAddNoteButtonClicked(
              !selectedOrderAddNoteButtonClicked
            );
          }}
        >
          <span className="text-lg font-semibold text-[#3C7179]">Annuler</span>
        </button>
        <button
          type="button"
          className="flex flex-row-reverse items-center gap-x-2 rounded-md border-none bg-[#3C7179] px-8 py-4 text-lg font-semibold text-white disabled:opacity-50"
          onClick={() => {
            // Get the id of the last note of the selected order notes
            let lastNoteId = "-1";
            selectedOrderNotes.length > 0 &&
              (lastNoteId =
                selectedOrderNotes[selectedOrderNotes.length - 1].id);
            // Convert the id to a number and add 1 to it then convert it back to a string
            const newNoteId =
              lastNoteId !== "-1"
                ? (parseInt(lastNoteId) + 1).toString()
                : Math.floor(Math.random() * 1000000000).toString();
            const newNote: OrderNoteProps = {
              id: newNoteId,
              content: watch("content"),
              date: returnCurrentFormattedDateForNote(),
              author: adminAuthorName,
              is_customer_note: selectedNoteType.private ? false : true,
              is_system_note: false,
            };

            setIsLoading(true);

            //         $author = $request['note_author'] ?? '';
            selectedOrderData &&
              axios
                .post(
                  `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v1/add-order-note/`,
                  {
                    note_content: newNote.content,
                    note_author: newNote.author,
                    order_id: selectedOrderData.orderId,
                    note_for_customer: newNote.is_customer_note,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((res) => {
                  // set a react timeout to wait for the request to be completed
                  // setTimeout(() => {
                  console.log("RES", res.data);
                  if (res.data !== "false") {
                    addNoteToSelectedOrderNotes(
                      selectedOrderNotes,
                      setSelectedOrderNotes,
                      { ...newNote, id: res.data }
                    );
                    setSelectedOrderAddNoteButtonClicked(
                      !selectedOrderAddNoteButtonClicked
                    );
                  }
                  // }, 5000);
                  // If the request is successful, delete the note from the selectedOrderNotes state
                  // deleteSelectedNoteFromOrderNotes(note);
                })
                .catch((err) => {
                  console.log("ERR", err);
                  // alert("Une erreur est survenue");
                  if (err.code.includes("jwt")) {
                    console.log(err);
                    localStorage.removeItem("token-dashboard");
                    window.location.replace("/");
                  }
                })
                .finally(() => {
                  // setLoading(false);
                  setIsLoading(false);
                  setSelectedOrderAddNoteButtonClicked(
                    !selectedOrderAddNoteButtonClicked
                  );
                });

            // addNoteToSelectedOrderNotes(
            //   selectedOrderNotes,
            //   setSelectedOrderNotes,
            //   newNote
            // );
          }}
          disabled={
            watch("content") === undefined ||
            watch("content") === "" ||
            isLoading
          }
        >
          {/* Ajouter */}
          {isLoading ? <span>Ajout en cours...</span> : <span>Ajouter</span>}
          {isLoading && (
            <svg
              // className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
              className="h-5 w-5 animate-spin text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}
        </button>
      </div>
    </div>
  );
};

export default SingleOrderPopupAddNote;
