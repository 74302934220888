import axios from "axios";
import { saveAs } from "file-saver";
import { useEffect, useRef, useState } from "react";
import * as XLSX from "xlsx";

interface HeaderType {
  label: string;
  key: string;
}
interface CsvExportProps {
  url: string;
  children?: Node;
  filename?: string;
  disable?: boolean;
  headers?: HeaderType[];
  label?: string;
  classes?: any;
  transform?: (data: any[]) => any[];
  chosen_headers?: string[];
}

type Props = CsvExportProps;
export const CsvExport = ({
  url,
  children,
  disable,
  label,
  filename,
  headers,
  transform,
  classes,
  chosen_headers,
}: Props) => {
  const [csvData, setCsvData]: any[] = useState([]);
  const [loading, setLoading] = useState(false);
  const csvInstance = useRef<any | null>(null);
  let token = "";
  if (localStorage.getItem("token-dashboard")) {
    token = JSON.parse(localStorage.getItem("token-dashboard") as string);
  }
  const asyncExportMethod = () => {
    setLoading(true);
    axios(url, {
      method: "GET",
      params: {
        chosen_headers: chosen_headers,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        let data = response.data;
        if (transform) {
          data = transform(response.data);
        }
        setCsvData(data);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data.code === "no_data") {
          alert("Aucune données à exporter");
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    // if (
    //   csvData &&
    //   csvInstance &&
    //   csvInstance.current &&
    //   csvInstance.current.link
    // ) {
    //   setTimeout(() => {
    //     csvInstance.current.link.click();
    //     setCsvData([]);
    //   });
    // }
    if (csvData.length > 0) {
      exportToExcel(csvData);
    }
  }, [csvData]);

  function exportToExcel(data: any) {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const fileData = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(fileData, filename || "export.xlsx");
  }

  return (
    <>
      <div
        onClick={() => {
          if (disable) return;
          asyncExportMethod();
        }}
        className="cursor-pointer"
      >
        <button
          disabled={loading || chosen_headers?.length === 0}
          className={`${loading && "cursor-progress opacity-30"} ${
            chosen_headers?.length === 0 && "cursor-not-allowed opacity-30"
            // } w-full justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-center text-lg font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2`}
          } w-full rounded-lg bg-[#14B8A6] px-10 py-4 text-[16px] text-base font-bold uppercase text-white shadow transition duration-300 ease-in-out hover:bg-[#16D1BF] hover:shadow-none`}
        >
          <span>{loading ? "Export en cours..." : "Exporter"}</span>
        </button>
        {/* {label ? <button>{label}</button> : "Télécharger"} */}
      </div>
      {/* {csvData.length > 0 ? (
        <CSVLink
          data={csvData}
          headers={headers || Object.keys(csvData[0])}
          filename={filename || "export.csv"}
          ref={csvInstance}
        />
      ) : undefined} */}
    </>
  );
};
