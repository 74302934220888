import OverviewBoxComponent, {
  OverviewBoxComponentProps,
} from "../components/OverviewBoxComponent";
import SectionTitle from "../components/SectionTitle";

interface OverviewBoxesWithTitleLayoutProps {
  title?: string;
  // children: OverviewBoxComponentProps[];
  children: any[];
}
const OverviewBoxesWithTitleLayout = ({
  title = "Vue d'ensemble",
  children,
}: OverviewBoxesWithTitleLayoutProps) => {
  return (
    <div className="flex flex-col gap-y-6">
      {title && <SectionTitle title={title} />}
      {children && (
        // <div className="flex flex-row items-start justify-evenly gap-x-2 2xl:gap-x-8">
        <div className="grid grid-cols-4 gap-x-8">
          {children.map((child, index) => {
            return <OverviewBoxComponent key={index} {...child} />;
          })}
        </div>
      )}
    </div>
  );
};

export default OverviewBoxesWithTitleLayout;
