import axios from "axios";

const URL =
  "https://europe-west1-satn-ad75c.cloudfunctions.net/checkIfFileExists";

export const verifyIfFileExists = async (filePath: string) => {
  const body = {
    filePath: filePath,
  };
  console.log(filePath);
  try {
    const res = await axios.post(URL, body);
    console.log(res.data);
    if (res.data == true) {
      return true;
    }
  } catch (error) {
    console.log(error);
  } finally {
    return false;
  }
};
