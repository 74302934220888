import axios from "axios";
import { updateOrder } from "./updateOrder";
import { returnRootUrlBasedOnAppLocation } from "../../../shared/utils/checkIfIsInlocal";

interface doAllProps {
  selectedOrderData: any;
  setIsGeneratingAndUpdatingInvoice: any;
  setClickedGenerateInvoice: any;
  setIsUpdatingOrder: any;
  setSelectedOrderHasBeenUpdated: any;
  token: any;
}

export const doAll = async ({
  selectedOrderData,
  setIsGeneratingAndUpdatingInvoice,
  setClickedGenerateInvoice,
  setIsUpdatingOrder,
  setSelectedOrderHasBeenUpdated,
  token,
}: doAllProps) => {
  await updateInvoiceGenerationDate({
    selectedOrderData,
    token,
  });
  await updateOrder({
    selectedOrderData,
    token,
    setIsGeneratingAndUpdatingInvoice,
    setClickedGenerateInvoice,
    setSelectedOrderHasBeenUpdated,
    setIsUpdatingOrder,
  });
};

interface updateInvoiceGenerationDateProps {
  selectedOrderData: any;
  token: any;
}
const updateInvoiceGenerationDate = async ({
  selectedOrderData,
  token,
}: updateInvoiceGenerationDateProps) => {
  axios
    .post(
      `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v1/update-invoice-generation-date`,
      {
        order_id: selectedOrderData?.mainOrderInfo.orderId || 0,
        date: new Date().toLocaleString(),
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => {})
    .catch((err) => {
      alert("Une erreur est survenue, veuillez réessayer");
    })
    .finally(() => {});
};
