import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import CustomHeaderComponent from "../../../shared/ui/components/CustomHeaderComponent";
import LoadingTable from "../../../shared/ui/layouts/LoadingTable";
import Dropdown, {
    Option,
} from "../../../shared/ui/layouts/NumberOfElementsDropdown";
import PageLayout from "../../../shared/ui/layouts/PageLayout";
import PaginationButtons from "../../../shared/ui/layouts/PaginationButtons";
import TableWithTitle from "../../orders/layouts/TableWithTitle";
import { fetchAllExhibitors } from "../api/fetchExhibitors";
import {
    ExhibitorDataPropsV2,
    exhibitorsAtom,
    sortingTableColumnsAtom,
} from "../atoms/exhibitors-atoms";
import "../css/exhibitorsListing.css";
import { AddAccountPopup } from "./NewAccount";
interface storePerPageNumberProps {
  perPageNumber: number;
  // setter setPerPageNumber
  setPerPageNumber: (value: number) => void;
}

const storePerPageNumber = ({
  perPageNumber,
  setPerPageNumber,
}: storePerPageNumberProps) => {
  localStorage.setItem("perPageNumberExhibitors", perPageNumber.toString());
  setPerPageNumber(perPageNumber);
};
const getPerPageNumber = () => {
  const perPageNumber = localStorage.getItem("perPageNumberExhibitors");
  if (perPageNumber) {
    return parseInt(perPageNumber);
  }
  return 50;
};

const ExhibitorsScreen = () => {
  const [exhibitors, setExhibitors] = useRecoilState(exhibitorsAtom);
  let [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [perPageNumber, setPerPageNumber] = useState(getPerPageNumber());
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [totalOfExhibitors, setTotalOfExhibitors] = useState(0);
  // const sortingTableColumns = useRef<SortingState>([]);

  let token = "";
  if (localStorage.getItem("token-dashboard")) {
    token = JSON.parse(localStorage.getItem("token-dashboard") as string);
  }

  const setSortingTableColumns = useSetRecoilState(sortingTableColumnsAtom);
  const sortingTableColumns = useRecoilValue(sortingTableColumnsAtom);
  const [sortingChanged, setSortingChanged] = useState(false);
  const [selectedSortingColumn, setSelectedSortingColumn] =
    useState<string>("");

  useEffect(() => {
    if (sortingTableColumns && sortingTableColumns.length > 0) {
      console.log("sorting ", sortingTableColumns[0]);
      setSortingChanged((prev) => !prev); // Toggle sortingChanged state to trigger useEffect below
    }
  }, [sortingTableColumns]);

  const updateFiltersBasedOnLocalStorage = () => {
    // Sorting column
    const sortingColumn = localStorage.getItem("sorting-exhibitors-column");
    if (sortingColumn) {
      setSelectedSortingColumn(sortingColumn);
    }
  };

  useEffect(() => {
    const queryParams = {
      search: selectedSortingColumn,
      page: pageNumber + 1,
      per_page: perPageNumber,
      sort_by: "billing_company",
      sort_order: "ASC",
      sorting_params: selectedSortingColumn,
    };
    if (sortingTableColumns.length > 0) {
      queryParams.sort_by = sortingTableColumns[0].id;
      queryParams.sort_order =
        sortingTableColumns[0].desc === false ? "ASC" : "DESC";
      console.log("queryParams.sort_order", sortingTableColumns[0].desc);
    }
    console.log("!!!! queryParams!!!! ", queryParams);
    updateFiltersBasedOnLocalStorage();
    setIsLoading(true);
    fetchAllExhibitors(queryParams)
      .then((data) => {
        setExhibitors(data.exhibitors);
        setNumberOfPages(data.total_pages);
        setTotalOfExhibitors(data.total_elements);
      })
      .catch((err) => {
        console.log(err);
        if (err.code.includes("jwt")) {
          console.log(err);
          localStorage.removeItem("token-dashboard");
          window.location.replace("/");
        }
      })
      .finally(() => {
        console.log("done loading exhibitors");
        setIsLoading(false);
      });
  }, [pageNumber, perPageNumber, sortingChanged, selectedSortingColumn]);
  const Pagination = () => {
    return (
      <PaginationButtons
        pageNumber={pageNumber}
        isLoading={isLoading}
        setPageNumber={setPageNumber}
        numberOfPages={numberOfPages}
        totalOfItems={totalOfExhibitors}
      />
    );
  };

  const options: Option[] = [
    { label: "20", value: 20 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
    { label: "200", value: 200 },
    { label: "500", value: 500 },
  ];

  const defaultOption: Option =
    options.find((option) => option.value === perPageNumber) || options[0];

  const handleDropdownChange = (selectedOption: Option) => {
    setPerPageNumber(selectedOption.value);
    storePerPageNumber({
      setPerPageNumber,
      perPageNumber: selectedOption.value,
    });
    setPageNumber(0);
    setNumberOfPages(0);
  };

  const columnHelper = createColumnHelper<ExhibitorDataPropsV2>();

  const columns = [
    columnHelper.accessor("billing_company", {
      header: () => (
        <CustomHeaderComponent
          title="Entreprise"
          sortName="billingCompany"
          post="exhibitors"
          selectedSortingColumn={selectedSortingColumn}
          setSelectedSortingColumn={setSelectedSortingColumn}
        />
      ),
      cell: ({ getValue, row }) => (
        <Link
          className="underline"
          to={`/clients/${row.original.id}`}
          onClick={() => {}}
        >
          {row.original.billing_company || "-"}
        </Link>
      ),
    }),
    columnHelper.accessor("billing_first_name", {
      header: () => (
        <CustomHeaderComponent
          title="Prénom"
          sortName="billingFirstName"
          post="exhibitors"
          selectedSortingColumn={selectedSortingColumn}
          setSelectedSortingColumn={setSelectedSortingColumn}
        />
      ),
      cell: ({ getValue, row }) => (
        <span>{row.original.billing_first_name}</span>
      ),
    }),
    columnHelper.accessor("billing_last_name", {
      header: () => (
        <CustomHeaderComponent
          title="Nom"
          sortName="billingLastName"
          post="exhibitors"
          selectedSortingColumn={selectedSortingColumn}
          setSelectedSortingColumn={setSelectedSortingColumn}
        />
      ),
      cell: ({ getValue, row }) => (
        <span>{row.original.billing_last_name}</span>
      ),
    }),
    columnHelper.accessor("billing_phone", {
      header: "Téléphone",
      cell: ({ getValue }) => <span>{getValue()}</span>,
    }),
    columnHelper.accessor("billing_email", {
      header: "Email",
      cell: ({ getValue }) => {
        return (
          <span>
            {getValue() !== "-" ? (
              <a className="underline" href={`mailto:${getValue()}`}>
                {getValue()}
              </a>
            ) : (
              getValue()
            )}
          </span>
        );
      },
    }),
    columnHelper.accessor("user_url", {
      header: "Site",
      cell: ({ getValue }) => (
        <span>
          {getValue() !== "-" ? (
            <a
              className="underline"
              href={getValue()}
              target="_blank"
              rel="noreferrer"
            >
              {getValue()}
            </a>
          ) : (
            getValue()
          )}
        </span>
      ),
    }),
  ];

  return (
    <PageLayout>
      <AddAccountPopup isOpen={isOpen} setIsOpen={setIsOpen} />
      {/* <AccountAllreadyExists /> */}
      <div id="exhibitors">
        <div className="flex items-center justify-end">
          <button
            className="flex flex-row items-center gap-x-4 rounded border-none bg-[#14b8a6] px-4 py-2 text-lg text-gray-50 shadow"
            onClick={() => setIsOpen(true)}
          >
            Ajouter un client
          </button>
        </div>
        {isLoading && (
          <LoadingTable
            title="Liste des clients"
            columns={columns}
            data={[{}, {}, {}, {}, {}, {}]}
            numberOfItems={perPageNumber}
            paginationButtons={<Pagination />}
            dropdown={
              <Dropdown
                options={options}
                onChange={handleDropdownChange}
                defaultValue={defaultOption}
                disabled={true}
              />
            }
          />
        )}
        {!isLoading && (
          <>
            <TableWithTitle
              isLoading={isLoading}
              title="Liste des clients"
              tableData={exhibitors}
              tableColumns={columns}
              paginationButtons={<Pagination />}
              enableSorting={true}
              dropdown={
                <Dropdown
                  options={options}
                  onChange={handleDropdownChange}
                  defaultValue={defaultOption}
                />
              }
              onSortingChange={setSortingTableColumns} // Pass the setSortingTableColumns function to TableWithTitle
            />
          </>
        )}
        {/* Pagination buttons with numbers */}
        <div className="mt-10">
          <Pagination />
        </div>
      </div>
      <p className="text-right text-[#cbd5e1]">v. 2.0.18</p>
    </PageLayout>
  );
};

export default ExhibitorsScreen;
