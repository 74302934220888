import { ArrowLeftCircleIcon } from "@heroicons/react/24/outline";
import DatePicker, { CalendarContainer } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Fragment, useRef, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import fr from "date-fns/locale/fr";
import { Dialog } from "@headlessui/react";
import {
  EyeIcon,
  PencilIcon,
  CheckCircleIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import CardWithData from "../../../shared/ui/components/CardWithData";
import Pill from "../../../shared/ui/components/Pill";
import PageLayout from "../../../shared/ui/layouts/PageLayout";
import axios from "axios";
import { returnRootUrlBasedOnAppLocation } from "../../../shared/utils/checkIfIsInlocal";
import ActionPopup from "../../../shared/ui/components/ActionPopup";
import { selectedConferenceAtom } from "../atoms/conferences-atoms";
// import SinglePostInfoCardsItemData from "../components/SinglePostInfoCardsItemData";
import CustomDropzone from "../../../shared/ui/components/ImageDropzone";
import Loader from "../../../shared/ui/components/Loader";
import SinglePostInfoCardsItemData from "../../../shared/ui/components/SinglePostInfoCardsItemData";
import { ConferenceFlagAtom } from "../../../shared/atoms/flag-atoms";
import { selectedPostAtom } from "../../../shared/atoms/single-data-atoms";

interface CardWithHeaderProps {
  title: string;
  children: React.ReactNode;
  isEditable?: boolean;
  centerElements?: boolean;
  justifyCenter?: boolean;
  width?: string;
  headerChildren?: React.ReactNode;
}

interface EditableDataActionButtonsProps {
  handleEdit: () => void;
  handleValidate: () => void;
  handleCancel: () => void;
  isEditing: boolean;
}

const EditableDataActionButtons = ({
  handleEdit,
  handleValidate,
  handleCancel,
  isEditing,
}: EditableDataActionButtonsProps) => {
  return (
    <div>
      {/* Default state */}
      {!isEditing && (
        <div className="flex flex-row gap-x-2">
          <div
            className="cursor-pointer p-[6px] text-gray-200 transition hover:text-gray-600"
            onClick={() => {
              handleEdit();
            }}
          >
            <PencilIcon className="w-5" />
          </div>
        </div>
      )}
      {/* Editing state  */}
      {isEditing && (
        <div className="flex flex-row items-center gap-x-2">
          <div
            onClick={() => {
              handleValidate();
            }}
            className="p-1 text-green-400 transition rounded-full cursor-pointer hover:bg-green-100 hover:shadow"
          >
            <CheckIcon className="w-6" />
          </div>
          <div className="p-1 text-red-400 transition rounded-full cursor-pointer hover:bg-red-100 hover:shadow">
            <XMarkIcon className="w-6" onClick={() => handleCancel()} />
          </div>
        </div>
      )}
    </div>
  );
};

const CardWithHeader = ({
  title,
  children,
  isEditable = false,
  centerElements = false,
  justifyCenter = true,
  width = "",
  headerChildren = null,
}: CardWithHeaderProps) => {
  return (
    <div className={`${width} flex grow flex-col rounded-lg bg-white shadow`}>
      <div className="flex w-full flex-row items-center justify-between rounded-lg rounded-b-none bg-[#14B8A6] px-6 py-3 font-bold text-white">
        <div className="flex flex-row items-center justify-between ">
          <span className="text-xl">{title}</span>
          {isEditable && <PencilIcon className="w-5" />}
        </div>
        <>{headerChildren}</>
      </div>
      <div
        className={`flex grow flex-col ${justifyCenter && "justify-center"
          } px-6 py-4 ${centerElements && "mx-auto"}`}
      >
        {children}
      </div>
    </div>
  );
};
interface ConferenceStatus {
  id: number;
  name: string;
}
const SingleConferenceScreen = () => {
  let token = "";
  if (localStorage.getItem("token-dashboard")) {
    token = JSON.parse(localStorage.getItem("token-dashboard") as string);
  }
  const conferenceId = window.location.href.split("/").pop();
  const [selectedConferenceData, setSelectedConferenceData] = useRecoilState(
    selectedConferenceAtom
  );
  const [conferenceStatuses, setConferenceStatuses] = useState<
    ConferenceStatus[]
  >([
    {
      id: 0,
      name: "-",
    },
  ]);
  const [selectedPost, setSelectedPost] = useRecoilState(selectedPostAtom);
  interface ConferenceFeatured {
    id: number;
    name: string;
  }
  const [conferenceFeatured, setConferenceFeatured] = useState<
    ConferenceFeatured[]
  >([{ id: 0, name: "-" }]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedConferenceStatus, setSelectedConferenceStatus] =
    useState<ConferenceStatus>(conferenceStatuses[0]);
  const [selectedConferenceFeatured, setSelectedConferenceFeatured] =
    useState<ConferenceFeatured>(conferenceFeatured[0]);
  const [isUpdatingConference, setIsUpdatingConference] = useState(false);
  const [isDeletingConference, setIsDeletingConference] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenAdmin, setIsOpenAdmin] = useState(false);

  const [isEditingConferenceNumber, setIsEditingConferenceNumber] =
    useState(false);
  const [isEditingStandNumber, setIsEditingStandNumber] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isConfirmDeletionPopupVisible, setIsConfirmDeletionPopupVisible] =
    useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const inputRefStandNumber = useRef<HTMLInputElement>(null);

  const [filesSpeaker, setFilesSpeaker] = useState<Object[]>();
  const [filesAdmin, setFilesAdmin] = useState<Object[]>();

  const handleEdit = () => {
    setIsEditingConferenceNumber(true);
    inputRef.current?.focus();
  };

  const handleValidate = () => {
    setIsEditingConferenceNumber(false);

    if (selectedConferenceData && inputRef.current?.value) {
      setSelectedConferenceData({
        ...selectedConferenceData,
        salle: inputRef.current?.value,
      });
    }
  };
  const handleCancel = () => {
    // if (selectedConferenceData) {
    //   setSelectedConferenceData({
    //     ...selectedConferenceData,
    //     salle: "",
    //   });
    // }
    setIsEditingConferenceNumber(false);
  };

  const handleEditStandNumber = () => {
    setIsEditingStandNumber(true);
    inputRefStandNumber.current?.focus();
  };

  const handleValidateStandNumber = () => {
    setIsEditingStandNumber(false);

    if (selectedConferenceData && inputRefStandNumber.current?.value) {
      setSelectedConferenceData({
        ...selectedConferenceData,
        orderAndConferenceInfo: {
          ...selectedConferenceData.orderAndConferenceInfo,
          standNumber: inputRefStandNumber.current?.value,
        },
      });
    }
  };

  const handleCancelStandNumber = () => {
    // if (selectedConferenceData) {
    //   setSelectedConferenceData({
    //     ...selectedConferenceData,
    //     orderAndConferenceInfo: {
    //       ...selectedConferenceData.orderAndConferenceInfo,
    //       standNumber:
    //         selectedConferenceData.orderAndConferenceInfo.standNumber,
    //     },
    //   });
    // }
    setIsEditingStandNumber(false);
  };

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  function closeModalAdmin() {
    setIsOpenAdmin(false);
  }

  function openModalAdmin() {
    setIsOpenAdmin(true);
  }

  const [isDatepickerForTimeOpen, setIsDatepickerForTimeOpen] = useState(false);

  const [startDate, setStartDate] = useState<Date | null>();
  // new Date()
  // new Date(
  //   (selectedConferenceData?.conferenceGeneralInfo
  //     .conferenceDateEtHeure as any) * 1000
  // )
  // selectedConferenceData?.conferenceGeneralInfo?.conferenceDateEtHeure

  useEffect(() => {
    if (selectedConferenceData && startDate) {
      const date = startDate;
      const formattedDate = `${date.getDate().toString().padStart(2, "0")}-${(
        date.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${date.getFullYear()} ${date
          .getHours()
          .toString()
          .padStart(2, "0")}:${date
            .getMinutes()
            .toString()
            .padStart(2, "0")}:${date.getSeconds().toString().padStart(2, "0")}`;

      setSelectedConferenceData({
        ...selectedConferenceData,
        conferenceGeneralInfo: {
          ...selectedConferenceData.conferenceGeneralInfo,
          conferenceDateEtHeure: formattedDate,
        },
      });
    }
  }, [startDate]);

  const CustomInputForTime = (props: any) => {
    return (
      <div
        className="flex flex-row items-center cursor-pointer select-none gap-x-2"
        onClick={() => {
          props.onClick();
          setIsDatepickerForTimeOpen(!isDatepickerForTimeOpen);
        }}
      >
        {props.date}
        <PencilIcon
          // type="text"
          // value={props.value}
          className="w-5"
        />
      </div>
    );
  };

  // let dateString = startDate?.toLocaleDateString("fr-FR", {
  //   day: "numeric",
  //   month: "long",
  //   year: "numeric",
  //   hour: "2-digit",
  //   minute: "2-digit",
  // });

  let dateString = "";
  if (startDate) {
    dateString = startDate?.toLocaleDateString("fr-FR", {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  } else {
    if (selectedConferenceData?.conferenceGeneralInfo?.conferenceDateEtHeure) {
      // dateString = new Date(
      //   selectedConferenceData?.conferenceGeneralInfo?.conferenceDateEtHeure
      // ).toLocaleDateString("fr-FR", {
      //   day: "numeric",
      //   month: "long",
      //   year: "numeric",
      //   hour: "2-digit",
      //   minute: "2-digit",
      // });
      dateString =
        selectedConferenceData?.conferenceGeneralInfo?.conferenceDateEtHeure;
    } else {
      dateString = "Aucune date";
    }
  }

  let navigate = useNavigate();

  const selected = useRecoilValue(ConferenceFlagAtom);

  const fetchConferencesAndStatusesData = async () => {
    setIsLoading(true);
    await axios
      .get(
        `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v1/conferences?id=${conferenceId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setSelectedConferenceData(res.data[0]);
        setSelectedPost(res.data[0]);
      })
      .catch((err) => {
        console.log(err);
        if (err.code.includes("jwt")) {
          console.log(err);
          localStorage.removeItem("token-dashboard");
          window.location.replace("/");
        }
      });

    // Fetch admin-dashboard/v1/conferences-statuses
    await axios
      .get(
        `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v1/conferences-statuses`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setConferenceStatuses(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.code.includes("jwt")) {
          console.log(err);
          localStorage.removeItem("token-dashboard");
          window.location.replace("/");
        }
      })
      .finally(() => {
        // setIsLoading(false);
      });
    await axios
      .get(
        `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v1/conferences-featured`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setConferenceFeatured(res.data);
        console.log("résulat", res.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.code.includes("jwt")) {
          console.log(err);
          localStorage.removeItem("token-dashboard");
          window.location.replace("/");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (selectedConferenceData && selectedPost) {
      setIsLoading(false);
    }
    fetchConferencesAndStatusesData();
  }, []);

  useEffect(() => {
    console.log(selectedConferenceData);
  }, [selectedConferenceData]);

  useEffect(() => {
    if (conferenceStatuses)
      selectedConferenceData &&
        setSelectedConferenceStatus(
          conferenceStatuses.find(
            (conferenceStatus) =>
              conferenceStatus.name ===
              selectedConferenceData.orderAndConferenceInfo.conferenceStatus
          ) as ConferenceStatus
        );
  }, [conferenceStatuses]);

  useEffect(() => {
    if (conferenceFeatured) {
      selectedConferenceData &&
        setSelectedConferenceFeatured(
          conferenceFeatured.find(
            (conferenceFeatured) =>
              conferenceFeatured.name ===
              selectedConferenceData.orderAndConferenceInfo.conferenceFeatured
          ) as ConferenceFeatured
        );
    }
  }, [conferenceFeatured]);
  const popups = [
    <ActionPopup
      actionRunning={isDeletingConference}
      key={Math.random()}
      title="Supprimer la conférence ?"
      children={
        <p>
          Voulez-vous vraimer supprimer la conférence{" "}
          {selectedConferenceData?.id} ?
        </p>
      }
      isVisible={isConfirmDeletionPopupVisible}
      setIsVisible={() => {
        setIsConfirmDeletionPopupVisible(!isConfirmDeletionPopupVisible);
      }}
      action={() => {
        !isDeletingConference && deleteConference();
      }}
      cancelAction={() => {
        setIsConfirmDeletionPopupVisible(false);
      }}
      actionButtonDisabled={false}
      actionButtonTitle={
        <div
          className={`flex flex-row items-center ${isDeletingConference && "cursor-wait"
            }`}
        >
          {isDeletingConference && (
            <svg
              className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}
          {isDeletingConference ? "Suppression..." : "Supprimer"}
        </div>
      }
      cancelButtonVisible={true}
      actionButtonBackgroundColor="#f87171"
    />,
    <ActionPopup
      key={Math.random()}
      title="Conférence mise à jour"
      children={
        <p>
          La conférence {selectedConferenceData?.id} a été mise à jour avec
          succès
        </p>
      }
      isVisible={isPopupVisible}
      setIsVisible={() => {
        setIsPopupVisible(!isPopupVisible);
        window.location.reload();
      }}
      action={() => {
        setIsPopupVisible(false);
        window.location.reload();
      }}
      cancelAction={() => {
        setIsPopupVisible(false);
        window.location.reload();
      }}
      actionButtonDisabled={false}
      actionButtonTitle="Fermer"
      cancelButtonVisible={false}
    />,
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-[1200px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="flex justify-end mb-4">
                  <button
                    type="button"
                    // className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={closeModal}
                  >
                    <XCircleIcon className="w-8 text-[#0F766E]" />
                  </button>
                </div>
                <div className="flex items-center justify-center">
                  <img
                    src={
                      selectedConferenceData?.conferenceGeneralInfo
                        ?.photoDuConferencier?.url
                    }
                    alt=""
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>,
    <Transition appear show={isOpenAdmin} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModalAdmin}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-[1200px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="flex justify-end mb-4">
                  <button
                    type="button"
                    // className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={closeModalAdmin}
                  >
                    <XCircleIcon className="w-8 text-[#0F766E]" />
                  </button>
                </div>
                <div className="flex items-center justify-center">
                  <img
                    src={
                      selectedConferenceData?.conferenceGeneralInfo
                        ?.photoAjouteeParLAdministrateur?.url
                    }
                    alt=""
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>,
  ];
  const deleteConference = () => {
    if (selectedConferenceData) {
      setIsDeletingConference(true);
      axios
        .post(
          `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v1/delete-conference/`,
          {
            id: selectedConferenceData?.id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((data) => {
          console.log("data", data);
          navigate(`/conferences`);
          window.location.reload();
        })
        .catch((error) => {
          console.log("error", error);
          if (error.code.includes("jwt")) {
            localStorage.removeItem("token-dashboard");
            window.location.replace("/");
          }
        })
        .finally(() => {
          setIsDeletingConference(false);
          // setIsPopupVisible(true);
        });
    }
  };

  const phoneWithPrefix = selectedConferenceData?.exhibitorInfo?.phone;

  const phoneWithoutPrefix = phoneWithPrefix?.replace(/^\+\d+/, '');
  return (
    <PageLayout>
      <>
        {popups}
        {isLoading ? (
          <Loader />
        ) : (
          <div className="flex flex-col gap-y-16">
            <div className="flex justify-between w-full">
              <Link
                className="flex flex-row items-center gap-x-4 text-2xl text-[#3c7179]"
                to={`/conferences`}
                onClick={() => { }}
              >
                <ArrowLeftCircleIcon className="color-[#3C7179] w-10" /> Retour
                à la liste des conférences
              </Link>
              <div className="flex flex-row items-center gap-x-8">
                <button
                  disabled={isDeletingConference}
                  // className="flex flex-row items-center gap-x-4 rounded border-none bg-[#14b8a6] px-4 py-2 text-lg text-gray-50 shadow"
                  className={`${isDeletingConference && "opacity-40"
                    } flex flex-row items-center rounded-md border-none bg-red-400 px-8 py-4 text-lg font-semibold text-white shadow`}
                  onClick={() => {
                    setIsConfirmDeletionPopupVisible(true);
                  }}
                  type="button"
                >
                  Supprimer
                </button>
                <button
                  disabled={isUpdatingConference}
                  // className="flex flex-row items-center gap-x-4 rounded border-none bg-[#14b8a6] px-4 py-2 text-lg text-gray-50 shadow"
                  className={`${isUpdatingConference && "opacity-40"
                    } flex flex-row items-center rounded-md border-none bg-[#14b8a6] px-8 py-4 text-lg font-semibold text-white shadow`}
                  onClick={() => {
                    if (selectedConferenceData) {
                      setIsUpdatingConference(true);
                      console.log("POSTED CONFERENCE ", selectedConferenceData);
                      axios
                        .post(
                          `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v1/conferences/`,
                          {
                            conferenceId: selectedConferenceData.id,
                            conferenceStatus: selectedConferenceStatus.name,
                            conferenceFeatured: selectedConferenceFeatured.name,
                            standNumber:
                              selectedConferenceData.orderAndConferenceInfo
                                .standNumber,
                            salle: selectedConferenceData.salle,
                            adminComments: selectedConferenceData.adminComments,
                            orderId:
                              selectedConferenceData.orderAndConferenceInfo
                                .orderNumber,
                            userId:
                              selectedConferenceData.conferenceGeneralInfo
                                .userId,
                            conferenceTitle:
                              selectedConferenceData.conferenceGeneralInfo
                                .conferenceName,
                            conferenceDescription:
                              selectedConferenceData.conferenceGeneralInfo
                                .conferenceDescription,
                            conferenceSpeakerImage:
                              selectedConferenceData.conferenceGeneralInfo
                                .photoDuConferencier?.url || "",
                            conferenceAdminImage:
                              selectedConferenceData.conferenceGeneralInfo
                                .photoAjouteeParLAdministrateur?.url || "",
                            conferenceDateEtHeure:
                              selectedConferenceData.conferenceGeneralInfo
                                .conferenceDateEtHeure,
                            speakerLastName:
                              selectedConferenceData.speakerLastName,
                            speakerFirstName:
                              selectedConferenceData.speakerFirstName,
                            speakerJob: selectedConferenceData.speakerJob,
                            speakerFullName:
                              selectedConferenceData.speakerFullName,
                            topCardsInfo: {
                              company:
                                selectedConferenceData.conferenceGeneralInfo
                                  .company,
                              firstName:
                                selectedConferenceData.conferenceGeneralInfo
                                  .firstName,
                              lastName:
                                selectedConferenceData.conferenceGeneralInfo
                                  .lastName,
                              email: selectedConferenceData.exhibitorInfo.email,
                              phone: selectedConferenceData.exhibitorInfo.phone,
                              website:
                                selectedConferenceData.exhibitorInfo.website,
                              activityType:
                                selectedConferenceData.exhibitorInfo
                                  .activityType,
                              hasAllreadyBeenToExhibition:
                                selectedConferenceData.conferenceGeneralInfo
                                  .hasAllreadyBeenToExhibition,
                            },
                            billing_country_code: selected,
                          },
                          {
                            headers: {
                              Authorization: `Bearer ${token}`,
                            },
                          }
                        )
                        .then((data) => {
                          console.log("data", data);
                        })
                        .catch((error) => {
                          console.log("error", error);
                          if (error.code.includes("jwt")) {
                            localStorage.removeItem("token-dashboard");
                            window.location.replace("/");
                          }
                        })
                        .finally(() => {
                          setIsUpdatingConference(false);
                          setIsPopupVisible(true);
                        });
                    }
                  }}
                  type="button"
                >
                  {isUpdatingConference && (
                    <svg
                      className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                  {isUpdatingConference ? "Mise à jour..." : "Mettre à jour"}
                </button>
              </div>
            </div>
            <>
              {/* Content */}
              <div className="flex flex-col gap-y-11">
                <div className="grid grid-cols-3 gap-x-16">
                  {/* Left */}
                  <div className="flex flex-col col-span-2 gap-y-11">
                    {/* Top */}
                    <div className="flex flex-row gap-x-6">
                      <CardWithData width="33%">
                        <SinglePostInfoCardsItemData
                          isEditable={true}
                          title="Raison sociale"
                          value={
                            selectedConferenceData?.conferenceGeneralInfo
                              ?.company || "-"
                          }
                          setValue={(value) => {
                            setSelectedConferenceData(
                              (prevSelectedConferenceData) => {
                                if (!prevSelectedConferenceData) {
                                  return null;
                                }
                                return {
                                  ...prevSelectedConferenceData,
                                  id: prevSelectedConferenceData.id || "",
                                  conferenceGeneralInfo: {
                                    ...prevSelectedConferenceData.conferenceGeneralInfo,
                                    company: value,
                                  },
                                };
                              }
                            );
                          }}
                        />
                        <SinglePostInfoCardsItemData
                          isEditable={true}
                          title="Prénom"
                          value={
                            selectedConferenceData?.conferenceGeneralInfo
                              ?.firstName || "-"
                          }
                          setValue={(value) => {
                            setSelectedConferenceData(
                              (prevSelectedConferenceData) => {
                                if (!prevSelectedConferenceData) {
                                  return null;
                                }
                                return {
                                  ...prevSelectedConferenceData,
                                  id: prevSelectedConferenceData.id || "",
                                  conferenceGeneralInfo: {
                                    ...prevSelectedConferenceData.conferenceGeneralInfo,
                                    firstName: value,
                                  },
                                };
                              }
                            );
                          }}
                        />
                        <SinglePostInfoCardsItemData
                          isEditable={true}
                          title="Nom"
                          value={
                            selectedConferenceData?.conferenceGeneralInfo
                              ?.lastName || "-"
                          }
                          setValue={(value) => {
                            setSelectedConferenceData(
                              (prevSelectedConferenceData) => {
                                if (!prevSelectedConferenceData) {
                                  return null;
                                }
                                return {
                                  ...prevSelectedConferenceData,
                                  id: prevSelectedConferenceData.id || "",
                                  conferenceGeneralInfo: {
                                    ...prevSelectedConferenceData.conferenceGeneralInfo,
                                    lastName: value,
                                  },
                                };
                              }
                            );
                          }}
                        />
                        <div className="mt-5"></div>
                        <SinglePostInfoCardsItemData
                          isEditable={true}
                          isSelect={true}
                          title="Déjà venu au salon"
                          value={
                            selectedConferenceData?.conferenceGeneralInfo
                              ?.hasAllreadyBeenToExhibition
                              ? "Oui"
                              : "Non"
                          }
                          setValue={(value) => {
                            setSelectedConferenceData(
                              (prevSelectedConferenceData) => {
                                if (!prevSelectedConferenceData) {
                                  return null;
                                }
                                return {
                                  ...prevSelectedConferenceData,
                                  conferenceGeneralInfo: {
                                    ...prevSelectedConferenceData.conferenceGeneralInfo,
                                    hasAllreadyBeenToExhibition:
                                      value === "true" ? true : false,
                                  },
                                };
                              }
                            );
                          }}
                        />
                      </CardWithData>
                      <CardWithData width="33%">
                        <SinglePostInfoCardsItemData
                          email={selectedConferenceData?.exhibitorInfo?.email}
                          isEditable={true}
                          title="Email"
                          value={
                            selectedConferenceData?.exhibitorInfo?.email || "-"
                          }
                          setValue={(value) => {
                            setSelectedConferenceData(
                              (prevSelectedConferenceData) => {
                                if (!prevSelectedConferenceData) {
                                  return null;
                                }
                                return {
                                  ...prevSelectedConferenceData,
                                  id: prevSelectedConferenceData.id || "",
                                  exhibitorInfo: {
                                    ...prevSelectedConferenceData.exhibitorInfo,
                                    email: value,
                                  },
                                };
                              }
                            );
                          }}
                        // value={
                        //   selectedConferenceData?.exhibitorInfo?.email ? (
                        //     <a
                        //       className="underline"
                        //       href={`mailto:${selectedConferenceData?.exhibitorInfo?.email}`}
                        //     >
                        //       {selectedConferenceData?.exhibitorInfo?.email}
                        //     </a>
                        //   ) : (
                        //     "-"
                        //   )
                        // }
                        />
                        <SinglePostInfoCardsItemData
                          phone={phoneWithPrefix}
                          isEditable={true}
                          title="Téléphone"
                          value={phoneWithoutPrefix}
                          setValue={(value) => {
                            setSelectedConferenceData(
                              (prevSelectedConferenceData) => {
                                if (!prevSelectedConferenceData) {
                                  return null;
                                }
                                return {
                                  ...prevSelectedConferenceData,
                                  id: prevSelectedConferenceData.id || "",
                                  exhibitorInfo: {
                                    ...prevSelectedConferenceData.exhibitorInfo,
                                    phone: value,
                                  },
                                };
                              }
                            );
                          }}
                        />
                        <SinglePostInfoCardsItemData
                          website={
                            selectedConferenceData?.exhibitorInfo?.website
                          }
                          isEditable={true}
                          title="Site internet"
                          value={
                            selectedConferenceData?.exhibitorInfo?.website ||
                            "-"
                          }
                          setValue={(value) => {
                            setSelectedConferenceData(
                              (prevSelectedConferenceData) => {
                                if (!prevSelectedConferenceData) {
                                  return null;
                                }
                                return {
                                  ...prevSelectedConferenceData,
                                  id: prevSelectedConferenceData.id || "",
                                  exhibitorInfo: {
                                    ...prevSelectedConferenceData.exhibitorInfo,
                                    website: value,
                                  },
                                };
                              }
                            );
                          }}
                        // value={
                        //   selectedConferenceData?.exhibitorInfo?.website &&
                        //   selectedConferenceData?.exhibitorInfo?.website !==
                        //     "-" ? (
                        //     <a
                        //       target="_blank"
                        //       href={
                        //         selectedConferenceData?.exhibitorInfo?.website
                        //       }
                        //       title="Site internet"
                        //       className="underline"
                        //     >
                        //       {selectedConferenceData?.exhibitorInfo?.website}
                        //     </a>
                        //   ) : (
                        //     "-"
                        //   )
                        // }
                        />
                        <div className="mt-5"></div>
                        <SinglePostInfoCardsItemData
                          isEditable={true}
                          setValue={(value) => {
                            setSelectedConferenceData(
                              (prevSelectedConferenceData) => {
                                if (!prevSelectedConferenceData) {
                                  return null;
                                }
                                return {
                                  ...prevSelectedConferenceData,
                                  id: prevSelectedConferenceData.id || "",
                                  exhibitorInfo: {
                                    ...prevSelectedConferenceData.exhibitorInfo,
                                    activityType: value,
                                  },
                                };
                              }
                            );
                          }}
                          title="Type d'activité"
                          value={
                            selectedConferenceData?.exhibitorInfo
                              ?.activityType || "-"
                          }
                        />
                      </CardWithData>
                      <CardWithData width="33%">
                        <SinglePostInfoCardsItemData
                          isEditable={false}
                          title="Numéro de commande"
                          value={
                            // selectedConferenceData?.orderAndConferenceInfo?.orderNumber || "-"
                            selectedConferenceData?.orderAndConferenceInfo
                              ?.orderNumber &&
                              selectedConferenceData.orderAndConferenceInfo
                                ?.orderNumber !== "-" ? (
                              <Link
                                to={`/commandes/${selectedConferenceData?.orderAndConferenceInfo?.orderNumber}`}
                                onClick={() =>
                                  localStorage.setItem("selectedMenuIndex", "0")
                                }
                              >
                                <span className="underline">
                                  {selectedConferenceData
                                    ?.orderAndConferenceInfo?.orderNumber ||
                                    "-"}
                                </span>
                              </Link>
                            ) : (
                              "-"
                            )
                          }
                        />
                        <SinglePostInfoCardsItemData
                          isEditable={false}
                          title="Statut de commande"
                          value={
                            selectedConferenceData?.orderAndConferenceInfo
                              ?.orderStatus || "-"
                          }
                        />
                        <SinglePostInfoCardsItemData
                          isEditable={false}
                          title="Statut de la conférence"
                          // value={selectedConferenceData?.orderAndConferenceInfo?.conferenceStatus}
                          value={
                            <Listbox
                              value={selectedConferenceStatus}
                              onChange={setSelectedConferenceStatus}
                            >
                              <div className="relative mt-1">
                                <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border rounded-lg cursor-pointer focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                  <span className="block truncate">
                                    {selectedConferenceStatus &&
                                      selectedConferenceStatus.name}
                                  </span>
                                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                    <ChevronUpDownIcon
                                      className="w-5 h-5 text-gray-400"
                                      aria-hidden="true"
                                    />
                                  </span>
                                </Listbox.Button>
                                <Transition
                                  as={Fragment}
                                  leave="transition ease-in duration-100"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                >
                                  <Listbox.Options className="absolute z-[1] mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {conferenceStatuses.map(
                                      (person, personIdx) => (
                                        <Listbox.Option
                                          key={personIdx}
                                          className={({ active }) =>
                                            `relative cursor-pointer select-none py-2 pl-10 pr-4 ${active
                                              ? "bg-amber-100 text-amber-900"
                                              : "text-gray-900"
                                            }`
                                          }
                                          value={person}
                                        >
                                          {({ selected }) => (
                                            <>
                                              <span
                                                className={`block truncate ${selected
                                                    ? "font-medium"
                                                    : "font-normal"
                                                  }`}
                                              >
                                                {person.name}
                                              </span>
                                              {selected ? (
                                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                  <CheckIcon
                                                    className="w-5 h-5"
                                                    aria-hidden="true"
                                                  />
                                                </span>
                                              ) : null}
                                            </>
                                          )}
                                        </Listbox.Option>
                                      )
                                    )}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            </Listbox>
                          }
                        />
                        {/* <div className="mt-5"></div> */}
                        <SinglePostInfoCardsItemData
                          isEditable={false}
                          title="Mise en avant"
                          // value={selectedConferenceData?.orderAndConferenceInfo?.conferenceStatus}
                          value={
                            <Listbox
                              value={selectedConferenceFeatured}
                              onChange={setSelectedConferenceFeatured}
                            >
                              <div className="relative mt-1">
                                <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border rounded-lg cursor-pointer focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                  <span className="block truncate">
                                    {selectedConferenceFeatured &&
                                      selectedConferenceFeatured.name}
                                  </span>
                                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                    <ChevronUpDownIcon
                                      className="w-5 h-5 text-gray-400"
                                      aria-hidden="true"
                                    />
                                  </span>
                                </Listbox.Button>
                                <Transition
                                  as={Fragment}
                                  leave="transition ease-in duration-100"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                >
                                  <Listbox.Options className="absolute z-[1] mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {conferenceFeatured.map(
                                      (person, personIdx) => (
                                        <Listbox.Option
                                          key={personIdx}
                                          className={({ active }) =>
                                            `relative cursor-pointer select-none py-2 pl-10 pr-4 ${active
                                              ? "bg-amber-100 text-amber-900"
                                              : "text-gray-900"
                                            }`
                                          }
                                          value={person}
                                        >
                                          {({ selected }) => (
                                            <>
                                              <span
                                                className={`block truncate ${selected
                                                    ? "font-medium"
                                                    : "font-normal"
                                                  }`}
                                              >
                                                {person.name}
                                              </span>
                                              {selected ? (
                                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                  <CheckIcon
                                                    className="w-5 h-5"
                                                    aria-hidden="true"
                                                  />
                                                </span>
                                              ) : null}
                                            </>
                                          )}
                                        </Listbox.Option>
                                      )
                                    )}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            </Listbox>
                          }
                        />
                        {/* <div>
                        {selectedConferenceData?.orderAndConferenceInfo
                          ?.badgeExhibitorUrl ? (
                          <SinglePostInfoCardsItemData
                            isEditable={false}
                            title="Télécharger le badge exposant"
                            link={
                              selectedConferenceData?.orderAndConferenceInfo
                                ?.badgeExhibitorUrl || "#"
                            }
                          />
                        ) : null}
                        {selectedConferenceData?.orderAndConferenceInfo
                          ?.badgeParkingUrl ? (
                          <SinglePostInfoCardsItemData
                            isEditable={false}
                            title="Télécharger le badge parking"
                            link={
                              selectedConferenceData?.orderAndConferenceInfo
                                ?.badgeParkingUrl || "#"
                            }
                          />
                        ) : null}
                      </div> */}
                      </CardWithData>
                    </div>
                    {/* Bottom */}
                    <CardWithHeader
                      justifyCenter={false}
                      title={
                        selectedConferenceData?.conferenceGeneralInfo.company ||
                        selectedConferenceData?.conferenceGeneralInfo
                          .fullName ||
                        ""
                      }
                      headerChildren={
                        <div className="flex flex-row items-center gap-x-8">
                          {selectedConferenceData?.conferenceGeneralInfo
                            ?.exhibition.name ? (
                            <Pill
                              label={
                                selectedConferenceData?.conferenceGeneralInfo
                                  ?.exhibition.name
                              }
                              bgColor={
                                selectedConferenceData?.conferenceGeneralInfo
                                  .exhibition.primaryColor
                              }
                              textColor={
                                selectedConferenceData?.conferenceGeneralInfo
                                  .exhibition.secondaryColor
                              }
                            />
                          ) : (
                            "-"
                          )}
                          {/* <div className="mx-auto rounded-full bg-[#f7f5ef] px-4 py-1 text-center">
                          <div className="flex flex-row items-center text-base font-bold gap-x-2">
                            <div className="text-[#0F766E]">Conference n°</div>
                            <div className="text-[#0F766E]">
                              {selectedConferenceData?.orderAndConferenceInfo
                                .conferenceNumber || "-"}
                            </div>
                          </div>
                        </div> */}
                          <div className="flex flex-row items-center gap-x-2">
                            {selectedConferenceData?.orderAndConferenceInfo
                              .orderHasStand && (
                                <>
                                  {!isEditingStandNumber && (
                                    <div className="mx-auto rounded-full bg-[#f7f5ef] px-4 py-1 text-center">
                                      <div className="flex flex-row items-center text-base font-bold gap-x-2">
                                        <span className="text-[#0F766E]">
                                          Stand n°
                                          {
                                            selectedConferenceData
                                              ?.orderAndConferenceInfo.standNumber
                                          }
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                  {isEditingStandNumber && (
                                    <input
                                      type="text"
                                      className="max-h-[32px] w-full max-w-[100px] grow rounded-full border-2 bg-gray-50 p-1 text-center text-[#0F766E] focus:outline-none"
                                      placeholder={
                                        selectedConferenceData
                                          ?.orderAndConferenceInfo.standNumber !==
                                          "-"
                                          ? selectedConferenceData
                                            ?.orderAndConferenceInfo.standNumber
                                          : ""
                                      }
                                      ref={inputRefStandNumber}
                                      onChange={(e) => {
                                        // selectedConferenceData &&
                                        //   setSelectedConferenceData({
                                        //     ...selectedConferenceData,
                                        //     orderAndConferenceInfo: {
                                        //       ...selectedConferenceData?.orderAndConferenceInfo,
                                        //       standNumber: e.target.value,
                                        //     },
                                        //   });
                                      }}
                                    />
                                  )}
                                  <EditableDataActionButtons
                                    isEditing={isEditingStandNumber}
                                    handleEdit={handleEditStandNumber}
                                    handleValidate={handleValidateStandNumber}
                                    handleCancel={handleCancelStandNumber}
                                  />
                                </>
                              )}
                            <>
                              {!isEditingConferenceNumber && (
                                <div className="mx-auto rounded-full bg-[#f7f5ef] px-4 py-1 text-center">
                                  <div className="flex flex-row items-center text-base font-bold gap-x-2">
                                    <span className="text-[#0F766E]">
                                      Salle{" "}
                                      {selectedConferenceData?.salle || "-"}
                                    </span>
                                  </div>
                                </div>
                              )}
                              {isEditingConferenceNumber && (
                                <input
                                  type="text"
                                  className="max-h-[32px] w-full max-w-[100px] grow rounded-full border-2 bg-gray-50 p-1 text-center text-[#0F766E] focus:outline-none"
                                  placeholder={
                                    selectedConferenceData?.salle !== "-"
                                      ? selectedConferenceData?.salle
                                      : ""
                                  }
                                  ref={inputRef}
                                  onChange={(e) => {
                                    // selectedConferenceData &&
                                    //   setSelectedConferenceData({
                                    //     ...selectedConferenceData,
                                    //     salle: e.target.value,
                                    //   });
                                  }}
                                />
                              )}
                              <EditableDataActionButtons
                                isEditing={isEditingConferenceNumber}
                                handleEdit={handleEdit}
                                handleValidate={handleValidate}
                                handleCancel={handleCancel}
                              />
                            </>
                            {/* <div
              className={`flex cursor-pointer flex-row gap-x-2 ${
                isEditingConferenceNumber && ""
              }`}
            >
              <div
                className="p-[6px] text-gray-400 transition hover:text-gray-600"
                onClick={() => {
                  setIdEditingConferenceNumber(!isEditingConferenceNumber);
                  // inputRef.current?.focus();
                }}
              >
                <PencilIcon className="w-5" />
              </div>
            </div> */}
                          </div>
                        </div>
                      }
                    >
                      <div className="flex flex-col gap-y-8">
                        {/* Conference */}
                        {/* <div className="flex flex-row items-center justify-between">
                        <SinglePostInfoCardsItemData
                          isEditable={false}
                          title="Type de conference"
                          value={
                            selectedConferenceData?.conferenceGeneralInfo
                              .conferenceType ? (
                              <span>
                                {
                                  selectedConferenceData?.conferenceGeneralInfo
                                    .conferenceType
                                }
                              </span>
                            ) : (
                              "-"
                            )
                          }
                        />
                        <div className="flex flex-row items-center gap-x-6">
                          <SinglePostInfoCardsItemData
                            itemsCenter={true}
                            isEditable={false}
                            title="Parking"
                            value={
                              selectedConferenceData?.parkingProduct ? (
                                <CheckCircleIcon className="w-8 text-green-500" />
                              ) : (
                                <XCircleIcon className="w-8 text-red-500" />
                              )
                            }
                          />
                          <SinglePostInfoCardsItemData
                            itemsCenter={true}
                            isEditable={false}
                            title="Meilleure visibilité"
                            value={
                              selectedConferenceData?.betterVisibilityProduct ? (
                                <CheckCircleIcon className="w-8 text-green-500" />
                              ) : (
                                <XCircleIcon className="w-8 text-gray-400" />
                              )
                            }
                          />
                        </div>
                      </div> */}
                        {/* Comment exhibitor */}
                        <SinglePostInfoCardsItemData
                          isEditable={true}
                          title="Titre conférence"
                          value={selectedConferenceData?.title || "-"}
                          setValue={(value) => {
                            selectedConferenceData &&
                              setSelectedConferenceData({
                                ...selectedConferenceData,
                                title: value,
                                conferenceGeneralInfo: {
                                  ...selectedConferenceData.conferenceGeneralInfo,
                                  conferenceName: value,
                                },
                              });
                          }}
                        />
                        {/* <SinglePostInfoCardsItemData
                          isEditable={true}
                          title="Nom du conférencier"
                          value={selectedConferenceData?.speakerLastName || "-"}
                          setValue={(value) => {
                            selectedConferenceData &&
                              setSelectedConferenceData({
                                ...selectedConferenceData,
                                speakerLastName: value,
                              });
                          }}
                        />
                        <
                        SinglePostInfoCardsItemData
                          isEditable={true}
                          title="Prénom du conférencier"
                          value={
                            selectedConferenceData?.speakerFirstName || "-"
                          }
                          setValue={(value) => {
                            selectedConferenceData &&
                              setSelectedConferenceData({
                                ...selectedConferenceData,
                                speakerFirstName: value,
                              });
                          }}
                        /> */}
                        <SinglePostInfoCardsItemData
                          isEditable={true}
                          title="Nom complet du conférencier"
                          value={selectedConferenceData?.speakerFullName || "-"}
                          setValue={(value) => {
                            selectedConferenceData &&
                              setSelectedConferenceData({
                                ...selectedConferenceData,
                                speakerFullName: value,
                              });
                          }}
                        />
                        <SinglePostInfoCardsItemData
                          isEditable={true}
                          title="Métier du conférencier"
                          value={selectedConferenceData?.speakerJob || "-"}
                          setValue={(value) => {
                            selectedConferenceData &&
                              setSelectedConferenceData({
                                ...selectedConferenceData,
                                speakerJob: value,
                              });
                          }}
                        />
                        <SinglePostInfoCardsItemData
                          isEditable={true}
                          title="Description conférence"
                          value={
                            selectedConferenceData?.conferenceGeneralInfo
                              .conferenceDescription || "-"
                          }
                          setValue={(value) => {
                            selectedConferenceData &&
                              setSelectedConferenceData({
                                ...selectedConferenceData,
                                conferenceGeneralInfo: {
                                  ...selectedConferenceData.conferenceGeneralInfo,
                                  conferenceDescription: value,
                                },
                              });
                          }}
                        />
                        <SinglePostInfoCardsItemData
                          isEditable={false}
                          title="Créneau souhaité"
                          value={
                            selectedConferenceData?.conferenceGeneralInfo
                              .conferenceCreneauSouhaite || "-"
                          }
                        />
                        <div className="flex flex-col gap-y-2">
                          <div className="flex flex-row items-center gap-x-4">
                            <span className="font-bold text-[#3C7179]">
                              Horaire validé
                            </span>
                            {dateString && (
                              <span
                                className="cursor-pointer"
                                onClick={() => {
                                  setStartDate(null);
                                  selectedConferenceData &&
                                    setSelectedConferenceData({
                                      ...selectedConferenceData,
                                      conferenceGeneralInfo: {
                                        ...selectedConferenceData.conferenceGeneralInfo,
                                        conferenceDateEtHeure: "",
                                      },
                                    });
                                }}
                              >
                                <XCircleIcon className="w-5 text-red-500" />
                              </span>
                            )}
                            <span
                              className="cursor-pointer"
                              onClick={() =>
                                setIsDatepickerForTimeOpen(
                                  !isDatepickerForTimeOpen
                                )
                              }
                            >
                              <PencilIcon className="w-5 text-gray-400" />
                            </span>
                          </div>
                          <DatePicker
                            open={isDatepickerForTimeOpen}
                            className="p-1 border-2 border-gray-300 rounded-md"
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            onClickOutside={() =>
                              setIsDatepickerForTimeOpen(false)
                            }
                            customInput={
                              <input
                                className="max-h-[32px] w-full max-w-[300px] grow cursor-pointer select-none rounded-full border-2 bg-gray-50 p-1 text-center text-[#0F766E] focus:outline-none"
                                placeholder={
                                  selectedConferenceData?.conferenceGeneralInfo
                                    .conferenceDateEtHeure !== "-"
                                    ? selectedConferenceData
                                      ?.conferenceGeneralInfo
                                      .conferenceDateEtHeure
                                    : ""
                                }
                              />
                            }
                            onInputClick={() =>
                              setIsDatepickerForTimeOpen(
                                !isDatepickerForTimeOpen
                              )
                            }
                            // value={startDate ? dateString : "Aucune date"}
                            value={dateString}
                            locale={fr}
                            timeFormat="HH:mm"
                            dateFormat="dd/MM/yyyy HH:mm"
                            showTimeInput
                            calendarContainer={CalendarContainer}
                          />
                        </div>
                        {/* <SinglePostInfoCardsItemData
                        isEditable={false}
                        title="Horaire validé"
                        value={selectedConferenceData?.info || "-"}
                      /> */}
                        <hr />
                        <SinglePostInfoCardsItemData
                          isEditable={false}
                          title="Commentaire exposant"
                          value={
                            selectedConferenceData?.exhibitorComments || "-"
                          }
                        />
                        {/* Comment admin */}
                        <SinglePostInfoCardsItemData
                          isEditable={true}
                          title="Commentaire admin"
                          value={selectedConferenceData?.adminComments || "-"}
                          setValue={(value) => {
                            selectedConferenceData &&
                              setSelectedConferenceData({
                                ...selectedConferenceData,
                                adminComments: value,
                              });
                          }}
                        />
                      </div>
                    </CardWithHeader>
                  </div>
                  {/* Right */}
                  <div className="flex flex-col col-span-1">
                    <CardWithHeader title="Photos" justifyCenter={false}>
                      {/* {selectedConferenceData?.adsProduct ? ( */}
                      <div className="flex flex-col gap-y-10">
                        <div className="flex flex-col gap-y-4">
                          {/* Photo conferencier */}
                          <div className="flex flex-col gap-y-4">
                            <div className="flex flex-row items-center gap-x-2 gap-y-0">
                              <span className="font-bold text-[#3C7179]">
                                Photo du conférencier
                              </span>
                              {selectedConferenceData?.conferenceGeneralInfo
                                .photoDuConferencier?.url && (
                                  <div className="flex flex-col items-start">
                                    <button
                                      className="flex items-center justify-center rounded-full bg-[#14B8A6] p-2 text-white shadow"
                                      onClick={() => {
                                        openModal();
                                      }}
                                    >
                                      <EyeIcon className="w-5" />
                                    </button>
                                  </div>
                                )}
                            </div>
                            <div className="flex flex-col justify-between w-full">
                              <div className="flex flex-row gap-x-2">
                                <CustomDropzone
                                  files={
                                    selectedConferenceData
                                      ?.conferenceGeneralInfo
                                      .photoDuConferencier?.url
                                      ? [
                                        {
                                          name: selectedConferenceData
                                            ?.conferenceGeneralInfo
                                            .photoDuConferencier?.url,
                                          preview:
                                            selectedConferenceData
                                              ?.conferenceGeneralInfo
                                              .photoDuConferencier?.url,
                                        },
                                      ]
                                      : []
                                  }
                                  setFiles={setFilesSpeaker}
                                  setValues={(value) =>
                                    selectedConferenceData &&
                                    setSelectedConferenceData({
                                      ...selectedConferenceData,
                                      conferenceGeneralInfo: {
                                        ...selectedConferenceData.conferenceGeneralInfo,
                                        photoDuConferencier: { url: value },
                                      },
                                    })
                                  }
                                />
                              </div>
                            </div>
                            {/* <div className="flex flex-row items-stretch gap-x-2">
                            <div className="">
                              <img
                                src={
                                  selectedConferenceData?.conferenceGeneralInfo
                                    ?.photoDuConferencier?.url
                                }
                                alt=""
                              />
                            </div>
                            <div className="flex flex-col justify-between">
                              <div className="flex flex-row gap-x-2">
                                <button className="flex items-center justify-center rounded-full bg-[#14B8A6] p-2 text-white shadow">
                                  <PencilIcon className="w-5" />
                                </button>
                                <button className="flex items-center justify-center rounded-full bg-[#14B8A6] p-2 text-white shadow">
                                  <TrashIcon className="w-5" />
                                </button>
                              </div>
                              <div className="flex flex-col items-start">
                                <button
                                  className="flex items-center justify-center rounded-full bg-[#14B8A6] p-2 text-white shadow"
                                  onClick={() => {
                                    openModal();
                                  }}
                                >
                                  <EyeIcon className="w-5" />
                                </button>
                              </div>
                            </div>
                          </div> */}
                          </div>
                        </div>
                        <div className="flex flex-col gap-y-4">
                          {/* Photo Admin */}
                          <div className="flex flex-col gap-y-4">
                            <div className="flex flex-row items-center gap-x-2 gap-y-0">
                              <span className="font-bold text-[#3C7179]">
                                Photo ajoutée par l'administrateur
                              </span>
                              {selectedConferenceData?.conferenceGeneralInfo
                                .photoAjouteeParLAdministrateur?.url && (
                                  <div className="flex flex-col items-start">
                                    <button
                                      className="flex items-center justify-center rounded-full bg-[#14B8A6] p-2 text-white shadow"
                                      onClick={() => {
                                        openModalAdmin();
                                      }}
                                    >
                                      <EyeIcon className="w-5" />
                                    </button>
                                  </div>
                                )}
                            </div>
                            <div className="flex flex-col justify-between w-full">
                              <div className="flex flex-row gap-x-2">
                                <CustomDropzone
                                  files={
                                    selectedConferenceData
                                      ?.conferenceGeneralInfo
                                      .photoAjouteeParLAdministrateur?.url
                                      ? [
                                        {
                                          name: selectedConferenceData
                                            ?.conferenceGeneralInfo
                                            .photoAjouteeParLAdministrateur
                                            ?.url,
                                          preview:
                                            selectedConferenceData
                                              ?.conferenceGeneralInfo
                                              .photoAjouteeParLAdministrateur
                                              ?.url,
                                        },
                                      ]
                                      : []
                                  }
                                  setFiles={setFilesAdmin}
                                  setValues={(value) =>
                                    selectedConferenceData &&
                                    setSelectedConferenceData({
                                      ...selectedConferenceData,
                                      conferenceGeneralInfo: {
                                        ...selectedConferenceData.conferenceGeneralInfo,
                                        photoAjouteeParLAdministrateur: {
                                          url: value,
                                        },
                                      },
                                    })
                                  }
                                />
                              </div>
                            </div>
                            {/* <div className="flex flex-row items-stretch gap-x-2">
                            <div className="">
                              <img
                                src={
                                  selectedConferenceData?.conferenceGeneralInfo
                                    ?.photoAjouteeParLAdministrateur?.url
                                }
                                alt=""
                              />
                            </div>
                            <div className="flex flex-col justify-between">
                              <div className="flex flex-row gap-x-2">
                                <button className="flex items-center justify-center rounded-full bg-[#14B8A6] p-2 text-white shadow">
                                  <PencilIcon className="w-5" />
                                </button>
                                <button className="flex items-center justify-center rounded-full bg-[#14B8A6] p-2 text-white shadow">
                                  <TrashIcon className="w-5" />
                                </button>
                              </div>
                              <div className="flex flex-col items-start">
                                <button
                                  className="flex items-center justify-center rounded-full bg-[#14B8A6] p-2 text-white shadow"
                                  onClick={() => {
                                    openModalAdmin();
                                  }}
                                >
                                  <EyeIcon className="w-5" />
                                </button>
                              </div>
                            </div>
                          </div> */}
                          </div>
                        </div>
                      </div>
                      {/* ) : null} */}
                    </CardWithHeader>
                  </div>
                </div>
              </div>
            </>
            {/* )} */}
          </div>
        )}
      </>
    </PageLayout>
  );
};

export default SingleConferenceScreen;
