// Default date and time
const dateOptions = { year: "numeric", month: "long", day: "numeric" };
const timeOptions = { hour: "2-digit", minute: "2-digit", hour12: false };
const date = new Date();
const time = date.toLocaleTimeString("fr-FR", timeOptions as any);
const currentFormattedDate = date.toLocaleDateString(
  "fr-FR",
  dateOptions as any
);
const currentFormattedTime = time.replace(":", "h");

export { currentFormattedDate, currentFormattedTime };

// Path: src/features/orders/screens/SingleOrderScreen.tsx
