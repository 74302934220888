import apiFetch from "@wordpress/api-fetch";
import { Exhibition } from "../../../shared/types/sharedTypes";
import { returnRootUrlBasedOnAppLocation } from "../../../shared/utils/checkIfIsInlocal";
import { WorkshopDataProps } from "../atoms/workshops-atoms";
import { addQueryArgs } from "@wordpress/url";

export type QuickView = {
  company: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  website: string;
  activityType: string;
  hasAllreadyBeenToExhibition: boolean;
  title: string;
};

export interface WorkshopListingProps {
  id: number;
  speaker: string;
  status: string;
  title: string;
  exhibition: Exhibition;
  info: string;
  orderId: number;
  quickView: QuickView;
}
// Create an api call with @wordpress/api-fetch for the endpoint admin-dashboard/v1/orders/
export const fetchAllWorkshops = async ({
  post_name,
  per_page,
  annee,
  post_status,
  page,
  sorting_params,
}: any): Promise<WorkshopDataProps[]> => {
  const queryParams = {
    post_name,
    per_page,
    annee,
    post_status,
    page,
    sorting_params,
  };
  let token = "";
  if (localStorage.getItem("token-dashboard")) {
    token = JSON.parse(localStorage.getItem("token-dashboard") as string);
  }
  const response = await apiFetch({
    path: addQueryArgs(
      `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v3/workshops`,
      queryParams
    ), // Pass the token in the header
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response as WorkshopDataProps[];
};
