import apiFetch from "@wordpress/api-fetch";
import { returnRootUrlBasedOnAppLocation } from "../../../shared/utils/checkIfIsInlocal";
import { Discount, Product } from "../types/discount";

// Create an api call with @wordpress/api-fetch for the endpoint admin-dashboard/v1/tickets/
export const fetchAllDiscounts = async (): Promise<Discount[]> => {
  let token = "";
  if (localStorage.getItem("token-dashboard")) {
    token = JSON.parse(localStorage.getItem("token-dashboard") as string);
  }
  const response = await apiFetch({
    path: `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v2/discounts`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response as Discount[];
};

export const fetchDiscount = async (coupon_id: string): Promise<Discount> => {
  let token = "";
  if (localStorage.getItem("token-dashboard")) {
    token = JSON.parse(localStorage.getItem("token-dashboard") as string);
  }
  const response = await apiFetch({
    path: `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v2/discount?coupon_id=${coupon_id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response as Discount;
};

export const fetchAllProducts = async (): Promise<Product[]> => {
  let token = "";
  if (localStorage.getItem("token-dashboard")) {
    token = JSON.parse(localStorage.getItem("token-dashboard") as string);
  }
  const response = await apiFetch({
    path: `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v2/products`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response as Product[];
};

export const fetchAllAccountEmails = async (): Promise<string[]> => {
  let token = "";
  if (localStorage.getItem("token-dashboard")) {
    token = JSON.parse(localStorage.getItem("token-dashboard") as string);
  }
  const response = await apiFetch({
    path: `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v2/emails`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response as string[];
};
