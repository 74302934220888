import apiFetch from "@wordpress/api-fetch";
import { CustomerProps, Exhibition } from "../../../shared/types/sharedTypes";
import { returnRootUrlBasedOnAppLocation } from "../../../shared/utils/checkIfIsInlocal";
import { CareInfoProps } from "../atoms/care-atoms";
import { addQueryArgs } from "@wordpress/url";

export interface CaresListingProps {
  id: number;
  therapist: string;
  status: string;
  title: string;
  exhibition: Exhibition;
  info: string;
}
// Create an api call with @wordpress/api-fetch for the endpoint admin-dashboard/v1/orders/
export const fetchAllCares = async ({
  post_name,
  per_page,
  annee,
  post_status,
  page,
  sorting_params,
}: any): Promise<CareInfoProps[]> => {
  const queryParams = {
    post_name,
    per_page,
    annee,
    post_status,
    page,
    sorting_params,
  };
  let token = "";
  if (localStorage.getItem("token-dashboard")) {
    token = JSON.parse(localStorage.getItem("token-dashboard") as string);
  }
  const response = await apiFetch({
    path: addQueryArgs(
      `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v3/careAreas`,
      queryParams
    ),
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response as CareInfoProps[];
};
