import apiFetch from "@wordpress/api-fetch";
import { returnRootUrlBasedOnAppLocation } from "../../../shared/utils/checkIfIsInlocal";
import { StandProps } from "../types/stands-types";
import { addQueryArgs } from "@wordpress/url";

// Create an api call with @wordpress/api-fetch for the endpoint admin-dashboard/v1/orders/
export const fetchAllStands = async ({
  post_name,
  per_page,
  annee,
  post_status,
  standType,
  page,
  sorting_params,
}: any): Promise<StandProps[]> => {
  const queryParams = {
    post_name,
    per_page,
    annee,
    post_status,
    standType,
    page,
    sorting_params,
  };
  let token = "";
  if (localStorage.getItem("token-dashboard")) {
    token = JSON.parse(localStorage.getItem("token-dashboard") as string);
  }
  const response = await apiFetch({
    path: addQueryArgs(
      `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v3/stands`,
      queryParams
    ), // Pass the token in the header
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response as StandProps[];
};

// export const fecthAllStandsV2 = async ({
//   company,
//   per_page,
//   annee,
//   stand_status,
//   page,
// }: StandsQueryParams): Promise<StandResponse> => {
//   const queryParams = {
//     company,
//     per_page,
//     annee,
//     stand_status,
//     page,
//   };
// };

export interface StandProductsNamesProps {
  productId: number;
  productsName: string;
}
export const fetchStandsProductsNames = async (): Promise<string[]> => {
  let token = "";
  if (localStorage.getItem("token-dashboard")) {
    token = JSON.parse(localStorage.getItem("token-dashboard") as string);
  }
  const response = await apiFetch({
    path: `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v1/stands-products`,
    // Pass the token in the header
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return ["Tous les stands", ...(response as string[])];
  // return [
  //   { productId: 0, productName: "Tous les stands" },
  //   ...(response as StandProductsNamesProps[]),
  // ] as StandProductsNamesProps[];
};
