import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import PageLayout from "../../../shared/ui/layouts/PageLayout";
import TableWithTitle from "../../orders/layouts/TableWithTitle";
import { fetchAllDiscounts } from "../api/fetch-discounts";
import {
  discountsDataAtom,
  filteredDiscountsDataAtom,
  selectedDiscountAtom,
} from "../atoms/discount-atoms";
import { Discount } from "../types/discount";
import { AddCoupon } from "./NewCoupon";

const columnHelper = createColumnHelper<Discount>();

const CouponsScren = () => {
  let token = "";
  if (localStorage.getItem("token-dashboard")) {
    token = JSON.parse(localStorage.getItem("token-dashboard") as string);
  }

  const [discounts, setDiscounts] = useRecoilState(discountsDataAtom);
  const [filteredDiscounts, setFilteredDiscounts] = useRecoilState(
    filteredDiscountsDataAtom
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [numberOfDiscounts, setNumberOfDiscounts] = useState("");
  const [selectedDiscount, setSelectedDiscount] =
    useRecoilState(selectedDiscountAtom);

  useEffect(() => {
    console.log(localStorage.getItem("token-dashboard"));
    if (!token) {
      window.location.replace("/");
    }

    if (discounts) {
      if (discounts.length > 0) return;
    }
    setIsLoading(true);
    fetchAllDiscounts()
      .then((discounts) => {
        setDiscounts(discounts);
        setNumberOfDiscounts(discounts.length.toString());
        console.log(discounts);
      })
      .catch((err) => {
        if (err.code.includes("jwt")) {
          console.log(err);
          localStorage.removeItem("token-dashboard");
          window.location.replace("/");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
    if (!discounts) {
      setDiscounts([]);
    }
    // setData(defaultData);
  }, []);

  useEffect(() => {
    console.log("discounts", discounts);
  }, [discounts]);

  const setDateFormat = (date: string) => {
    const dateArray = date.split("-");
    return dateArray[2] + "/" + dateArray[1] + "/" + dateArray[0];
  };

  const columns = [
    columnHelper.accessor("coupon_title", {
      header: "Titre du coupon",
      cell: ({ getValue, row }) => {
        return (
          <Link
            to={`/coupons/${row.original.coupon_id}`}
            className="underline"
            onClick={() => {
              const selectedDiscount =
                (discounts &&
                  discounts.find(
                    (discount, index) =>
                      discount.coupon_id === row.original.coupon_id
                  )) ||
                null;

              selectedDiscount && setSelectedDiscount(selectedDiscount);

              window.scrollTo(0, 0);
            }}
          >
            <span>{row.original.coupon_title}</span>
          </Link>
        );
      },
    }),
    columnHelper.accessor("discount_type", {
      header: "Type de coupon",
      cell: ({ getValue }) => {
        return <p>{getValue()}</p>;
      },
    }),
    columnHelper.accessor("amount", {
      header: "Montant",
      cell: ({ getValue, row }) => {
        return (
          <p>
            <span>{row.original.amount}</span>
            <span>
              {row.original.discount_type === "Remise en pourcentage"
                ? "%"
                : "€"}
            </span>
          </p>
        );
      },
    }),
    columnHelper.accessor("description", {
      header: "Description",
      cell: ({ getValue }) => {
        return <span>{getValue() !== null || "" ? getValue() : "-"}</span>;
      },
    }),
    columnHelper.accessor("expiry_date", {
      header: "Date d'expiration",
      // cell: ({ getValue }) => <span>{}</span>,
      cell: ({ getValue }) => (
        <span>
          {getValue() !== null || "" || undefined
            ? setDateFormat(getValue())
            : "∞"}
        </span>
      ),
    }),
    columnHelper.accessor("usage_count", {
      header: "Nombre d'utilisations",
      cell: ({ getValue }) => {
        return <span>{getValue() !== null ? getValue() : "-"}</span>;
      },
    }),
    columnHelper.accessor("usage_limit", {
      header: "Limite d'utilisation",
      cell: ({ getValue }) => {
        return <span>{getValue() !== null ? getValue() : "-"}</span>;
      },
    }),
  ];

  useEffect(() => {
    //Display the number of data displayed for each filter
    if (filteredDiscounts) {
      setNumberOfDiscounts(filteredDiscounts.length.toString());
    } else {
      if (discounts) setNumberOfDiscounts(discounts.length.toString());
    }
  }, [filteredDiscounts]);

  useEffect(() => {
    //Display the number of data displayed for each filter
    if (filteredDiscounts) {
      setNumberOfDiscounts(filteredDiscounts.length.toString());
    } else {
      if (discounts) setNumberOfDiscounts(discounts.length.toString());
    }
  }, [filteredDiscounts]);

  return (
    <PageLayout>
      <AddCoupon isOpen={isOpen} setIsOpen={setIsOpen} />
      <div className="flex items-center justify-end">
        <button
          className="flex flex-row items-center gap-x-4 rounded border-none bg-[#14b8a6] px-4 py-2 text-lg text-gray-50 shadow"
          onClick={() => setIsOpen(true)}
        >
          Ajouter un coupon
        </button>
      </div>
      <TableWithTitle
        title={`Liste des coupons`}
        subtittle={
          numberOfDiscounts
            ? `Nombre total de coupons : ${numberOfDiscounts}`
            : ""
        }
        tableData={discounts ? discounts : []}
        tableColumns={columns}
        // filtersComponents={<FiltersSelects />}
        isLoading={isLoading}
      />
    </PageLayout>
  );
};

export default CouponsScren;
