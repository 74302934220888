import { Switch } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { RecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { ExportItemProps, ExportOptionType } from "../types/export";
import { ExportItemPopup, ExportItemPopupOption } from "./ExportItemPopup";

const returnOptionsJSXBasedOnOptionsTitlesAndSlugs = (
  options: ExportOptionType[],
  atom: RecoilState<string[]>
) => {
  return options.map((option) => (
    <ExportItemPopupOption
      key={Math.random()}
      title={option.title}
      slug={option.slug}
      atom={atom}
    />
  ));
};

const ExportItemTitle = (title: string) => {
  return <h2 className="text-xl font-semibold">{title}</h2>;
};

interface ExportItemDropdownProps {
  allFieldsChecked: boolean;
  options: ExportOptionType[];
  atom: RecoilState<string[]>;
}
const ExportItemDropdownContent = ({
  allFieldsChecked,
  options,
  atom,
}: ExportItemDropdownProps) => {
  // If allFieldsChecked is true, set all options to true
  // If allFieldsChecked is false, set all options to false
  // if (allFieldsChecked) {
  //   setExportOptions(options.map((option) => option.slug));
  // } else {
  //   setExportOptions([]);
  // }s
  // const [isAllSelected, setIsAllSelected] = useState(false);
  // const setExportOptions = useSetRecoilState(atom);
  // const exportOptions = useRecoilValue(atom);

  // const selectAll = () => {
  //   if (isAllSelected) {
  //     setExportOptions([]);
  //   } else {
  //     setExportOptions(options.map((option) => option.slug));
  //   }
  //   setIsAllSelected(!isAllSelected);
  // };

  // useEffect(() => {
  //   // If all options are selected, set isAllSelected to true
  //   // If not, set it to false
  //   if (options.length === 0) {
  //     setIsAllSelected(false);
  //   } else {
  //     setIsAllSelected(
  //       options.every((option) => exportOptions.includes(option.slug)) // use exportOptions instead of setExportOptions
  //     );
  //   }
  // }, [options, exportOptions]);

  return (
    <div className="flex flex-col gap-4 mt-4">
      {/* <div className="flex items-center justify-between">
        <span className="text-lg font-medium">
          {isAllSelected ? "Tout déselectionner" : "Tout sélectionner"}
        </span>
        <Switch
          checked={isAllSelected}
          onChange={() => {
            selectAll();
          }}
          className={`${
            isAllSelected ? "bg-[#16D1BF]" : "bg-gray-200"
          } relative inline-flex h-[28px] w-[56px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
        >
          <span
            aria-hidden="true"
            className={`${
              isAllSelected ? "translate-x-7" : "translate-x-0"
            } pointer-events-none inline-block h-[24px] w-[24px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
          />
        </Switch>
      </div> */}
      {returnOptionsJSXBasedOnOptionsTitlesAndSlugs(options, atom)}
    </div>
  );
};

interface ExportItemDropdownProps {
  options: ExportOptionType[];
  atom: RecoilState<string[]>;
  title: String;
}
const ExportItemDropdown = ({
  options,
  atom,
  title = "Informations Client",
}: ExportItemDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [allFieldsChecked, setAllFieldsChecked] = useState(false);

  return (
    <div key={Math.random()}>
      <div className="flex flex-row items-center justify-between">
        <span className="text-lg font-medium">{title}</span>
        <div className="flex flex-row items-center gap-4">
          <div className="cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? (
              <ChevronUpIcon className="w-6" />
            ) : (
              <ChevronDownIcon className="w-6" />
            )}
          </div>
          {/* <Switch
            checked={allFieldsChecked}
            onChange={() => {
              // updateExportOptions(slug);
              setAllFieldsChecked(!allFieldsChecked);
            }}
            className={`${allFieldsChecked ? "bg-[#16D1BF]" : "bg-gray-200"}
          relative inline-flex h-[28px] w-[56px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
          >
            <span
              aria-hidden="true"
              className={`${
                allFieldsChecked ? "translate-x-7" : "translate-x-0"
              }
            pointer-events-none inline-block h-[24px] w-[24px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
            />
          </Switch> */}
        </div>
      </div>
      {isOpen && (
        <ExportItemDropdownContent
          title={title}
          allFieldsChecked={allFieldsChecked}
          options={options}
          atom={atom}
        />
      )}
    </div>
  );
};

const ExportItemButton = (action: () => void) => {
  return (
    <button
      className="rounded-lg bg-[#14B8A6] px-10 py-4 text-[16px] text-base font-bold uppercase text-white shadow transition duration-300 ease-in-out hover:bg-[#16D1BF] hover:shadow-none"
      onClick={() => action()}
    >
      Exporter les données
    </button>
  );
};

const ExportItem = ({
  title,
  slug,
  options,
  usersOptions,
  ExportButtonComponent,
  atom,
}: ExportItemProps) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const setExportOptions = useSetRecoilState(atom);
  const exportOptions = useRecoilValue(atom);

  const selectAll = () => {
    if (isAllSelected) {
      setExportOptions([]);
    } else {
      // Include all options from options and usersOptions
      const allOptions = [...options, ...(usersOptions || [])];
      setExportOptions(allOptions.map((option) => option.slug));
    }
    setIsAllSelected(!isAllSelected);
  };

  useEffect(() => {
    // If all options are selected, set isAllSelected to true
    // If not, set it to false
    const allOptions = [...options, ...(usersOptions || [])];
    if (allOptions.length === 0) {
      setIsAllSelected(false);
    } else {
      setIsAllSelected(
        allOptions.every((option) => exportOptions.includes(option.slug))
      );
    }
  }, [options, exportOptions, usersOptions]);

  return (
    <>
      <ExportItemPopup
        isOpen={isPopupOpen}
        closeModal={() => setIsPopupOpen(false)}
        title={title}
        content={
          <>
            <div className="flex items-center justify-between">
              <span className="text-lg font-medium">
                {isAllSelected ? "Tout déselectionner" : "Tout sélectionner"}
              </span>
              <Switch
                checked={isAllSelected}
                onChange={() => {
                  selectAll();
                }}
                className={`${
                  isAllSelected ? "bg-[#16D1BF]" : "bg-gray-200"
                } relative inline-flex h-[28px] w-[56px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
              >
                <span
                  aria-hidden="true"
                  className={`${
                    isAllSelected ? "translate-x-7" : "translate-x-0"
                  } pointer-events-none inline-block h-[24px] w-[24px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                />
              </Switch>
            </div>
            {returnOptionsJSXBasedOnOptionsTitlesAndSlugs(options, atom)}
            {usersOptions && (
              <ExportItemDropdown
                title={"Informations Client"}
                allFieldsChecked={false}
                options={usersOptions}
                atom={atom}
              />
            )}
          </>
        }
        ExportButtonComponent={ExportButtonComponent}
      />
      <div className="flex flex-col items-center justify-between gap-y-4 md:flex-row">
        {ExportItemTitle(title)}
        {ExportItemButton(() => setIsPopupOpen(!isPopupOpen))}
      </div>
    </>
  );
};

export default ExportItem;
