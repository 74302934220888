import { Dialog, Switch, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect } from "react";
import { useRecoilState } from "recoil";
import {
  ExportItemPopupOptionProps,
  ExportItemPopupProps,
} from "../types/export";

export const ExportItemPopup = ({
  isOpen,
  closeModal,
  title,
  content,
  ExportButtonComponent,
}: ExportItemPopupProps) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="flex justify-end">
                  <div
                    className="transition-all rounded-full cursor-pointer hover:scale-110"
                    onClick={() => closeModal()}
                  >
                    <XMarkIcon className="w-8" />
                  </div>
                </div>
                <Dialog.Title
                  as="h3"
                  className="text-xl font-medium leading-6 text-gray-900"
                >
                  Sélectionnez les données à exporter pour : {title}
                </Dialog.Title>
                <div className="grid grid-cols-1 gap-8 mt-8">{content}</div>
                <div className="mt-8">{ExportButtonComponent}</div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export const ExportItemPopupOption = ({
  title,
  slug,
  atom,
}: ExportItemPopupOptionProps) => {
  const [exportOptions, setExportOptions] = useRecoilState(atom);
  const enabled = exportOptions.includes(slug);

  const updateExportOptions = (slug: string) => {
    if (exportOptions.includes(slug)) {
      setExportOptions(exportOptions.filter((option) => option !== slug));
    } else {
      setExportOptions([...exportOptions, slug]);
    }
  };

  useEffect(() => {
    console.log("exportOptions", exportOptions);
  }, [exportOptions]);

  return (
    <div
      key={Math.random()}
      className="flex flex-row items-center justify-between"
    >
      <span className="text-lg font-medium">{title}</span>
      <Switch
        checked={enabled}
        onChange={() => {
          updateExportOptions(slug);
        }}
        className={`${enabled ? "bg-[#16D1BF]" : "bg-gray-200"}
              relative inline-flex h-[28px] w-[56px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
      >
        <span
          aria-hidden="true"
          className={`${enabled ? "translate-x-7" : "translate-x-0"}
                pointer-events-none inline-block h-[24px] w-[24px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
        />
      </Switch>
    </div>
  );
};
