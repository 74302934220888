import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";

interface CustomHeaderProps {
  title: string;
  onClick?: () => void;
  post: string;
  selectedSortingColumn: string;
  setSelectedSortingColumn: (value: string) => void;
  sortName: string;
}

const renderChevron = (selectedSortingColumn: string, sortName: string) => {
  // Vérifier si le titre actuel est différent du titre précédemment trié
  const baseTitle = selectedSortingColumn.split("_")[0]; // Extrait la base du titre (sans direction de tri)
  console.log("baseTitle", baseTitle);

  if (sortName === baseTitle) {
    // Si le titre n'a pas changé, alors ajuster la direction du tri en fonction de l'état actuel
    console.log("sortName", sortName);
    console.log("baseTitle", baseTitle);
    console.log("selectedSortingColumn", selectedSortingColumn);
    if (selectedSortingColumn.endsWith("_asc")) {
      return <ChevronUpIcon className="ml-1 h-4 w-4" />;
    } else if (selectedSortingColumn.endsWith("_desc")) {
      return <ChevronDownIcon className="ml-1 h-4 w-4" />;
    }
  } // Sinon, si le titre a changé, newSortingDirection reste "asc"

  // return <ChevronUpIcon className="ml-1 h-4 w-4" />;
  return null;
};

const CustomHeaderComponent: React.FC<CustomHeaderProps> = ({
  title,
  post,
  selectedSortingColumn,
  setSelectedSortingColumn,
  sortName,
}) => (
  <div
    className="cursor-pointer"
    onClick={() => {
      // Déterminer la nouvelle direction du tri
      let newSortingDirection = "asc"; // Par défaut à "asc"

      // Vérifier si le titre actuel est différent du titre précédemment trié
      const baseTitle = selectedSortingColumn.split("_")[0]; // Extrait la base du titre (sans direction de tri)
      console.log("baseTitle", baseTitle);

      if (sortName === baseTitle) {
        // Si le titre n'a pas changé, alors ajuster la direction du tri en fonction de l'état actuel
        console.log("sortName", sortName);
        console.log("baseTitle", baseTitle);
        console.log("selectedSortingColumn", selectedSortingColumn);
        if (selectedSortingColumn.endsWith("_asc")) {
          newSortingDirection = "desc"; // Si actuellement "asc", alors devenir "desc"
          console.log("newSortingDirection", newSortingDirection);
        } else if (selectedSortingColumn.endsWith("_desc")) {
          newSortingDirection = "default"; // Si actuellement "desc", alors revenir à "default"
          console.log("newSortingDirection", newSortingDirection);
        }
      } // Sinon, si le titre a changé, newSortingDirection reste "asc"

      // Construire la nouvelle valeur de tri
      const newSortingValue =
        newSortingDirection === "default"
          ? "" // Si "default", alors supprimez la direction du tri
          : `${sortName}_${newSortingDirection}`; // Sinon, ajoutez la direction du tri

      // Mettre à jour l'état et le stockage local
      console.log("newSortingValue", newSortingValue);
      setSelectedSortingColumn(newSortingValue);
      localStorage.setItem(`sorting-${post}-column`, newSortingValue);
    }}
  >
    <div className="flex items-center gap-x-2">
      {title} {renderChevron(selectedSortingColumn, sortName)}
    </div>
  </div>
);

export default CustomHeaderComponent;
