interface ClearLocalStorageProps {
  keys: string[];
}
const clearLocalStorage = ({ keys }: ClearLocalStorageProps) => {
  keys.forEach((key) => {
    localStorage.removeItem(key);
  });
};

export default clearLocalStorage;
