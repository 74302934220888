export const returnRootUrlBasedOnAppLocation = () => {
  const isInDevMode = window.location.href.includes("localhost");
  const isOnDevWebsite = window.location.href.includes("dev");
  const isOnStagingWebsite = window.location.href.includes("staging");
  // let rootUrl = "https://satn.local/wp-json/";
  // let rootUrl = "http://localstaging.local/wp-json/";
  let rootUrl = "https://salontherapiesnaturelles.ch/wp-json/";
  // if (isInDevMode) {
  //   rootUrl = "http://localstaging.local/wp-json/";
  // } else if (isOnDevWebsite) {
  //   rootUrl = "https://dev.salontherapiesnaturelles.ch/wp-json/";
  // } else if (isOnStagingWebsite) {
  //   rootUrl = "https://staging.salontherapiesnaturelles.ch/wp-json/";
  // } else {
  //   rootUrl = "https://salontherapiesnaturelles.ch/wp-json/";
  // }
  return rootUrl;
};
