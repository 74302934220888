import { useEffect } from "react";
import { useRecoilState } from "recoil";
import {
  isCreatingNewOrderAtom,
  selectedOrderAtom,
  selectedOrderDiscountLineItemsAtom,
  selectedOrderIndexAtom,
} from "../../../features/orders/atoms/orders-atoms";
import { selectedMenuIndexAtom } from "../../atoms/navigation-atoms";

export type MenuItem = {
  label: string;
  icon: React.ReactNode;
  onClick: () => void;
};
interface SidebarMenuProps {
  menu: MenuItem[];
}

function extractSegment(urlString: string): string | null {
  try {
    const url = new URL(urlString);
    const pathSegments = url.pathname
      .split("/")
      .filter((segment) => segment.trim() !== "");

    // If the URL has more than one segment, return the first segment
    if (pathSegments.length > 1) {
      return pathSegments[0];
    }
    // If the URL has only one segment, return the first segment
    else if (pathSegments.length === 1) {
      return pathSegments[0];
    }
  } catch (e) {
    console.error("Invalid URL provided:", e);
  }

  return null;
}

const returnIndexBasedOnUrl = () => {
  // Check if the segment contains "salons", "stands", "conferences", "ateliers", "espaces-soins", "clients", "billets", "export" or does not exist; using extractSegment and return the index accordingly
  const segment = extractSegment(window.location.href);
  // Use a switch statement to return the index based on the segment
  switch (segment) {
    case "commandes":
      return 0;
    case "salons":
      return 1;
    case "stands":
      return 2;
    case "conferences":
      return 3;
    case "ateliers":
      return 4;
    case "espaces-soins":
      return 5;
    case "clients":
      return 6;
    case "visiteurs":
      return 7;
    case "billets":
      return 8;
    case "coupons":
      return 9;
    case "export":
      return 10;
    default:
      return 0;
  }
};

const SidebarMenu = ({ menu }: SidebarMenuProps) => {
  const [selectedMenuIndex, setSelectedMenuIndex] = useRecoilState(
    selectedMenuIndexAtom
  );
  const [selectedOrderData, setSelectedOrderData] =
    useRecoilState(selectedOrderAtom);

  const [selectedOrderIndex, setSelectedOrderIndex] = useRecoilState(
    selectedOrderIndexAtom
  );

  const [isCreatingNewOrder, setIsCreatingNewOrder] = useRecoilState(
    isCreatingNewOrderAtom
  );

  const [selectedOrderDiscountLineItems, setSelectedOrderDiscountLineItems] =
    useRecoilState(selectedOrderDiscountLineItemsAtom);

  useEffect(() => {
    const index = returnIndexBasedOnUrl();

    setSelectedMenuIndex(index);

    // const index = localStorage.getItem("selectedMenuIndex");
    // if (index) {
    //   setSelectedMenuIndex(parseInt(index));
    // }
  }, []);

  return (
    <div>
      {menu.map((item, index) => (
        <div
          key={item.label}
          className="flex cursor-pointer items-center gap-x-4 p-4 hover:bg-[#14B8A6]"
          onClick={() => {
            item.onClick();
            // ClearSingleOrder();
            setSelectedOrderIndex(null);
            setSelectedOrderData(null);
            setIsCreatingNewOrder(false);
            setSelectedOrderDiscountLineItems([]);
          }}
        >
          <div
            style={{
              backgroundColor:
                selectedMenuIndex === index ? "#ffffff" : "#14B8A6",
              color: selectedMenuIndex === index ? "#0F766E" : "#ffffff",
            }}
            className="flex h-12 w-12 items-center justify-center rounded-full"
          >
            {item.icon}
          </div>
          <span
            style={{
              fontWeight: selectedMenuIndex === index ? "bold" : "normal",
            }}
            className="hidden text-lg font-bold text-white 2xl:block 2xl:text-xl"
          >
            {item.label}
          </span>
        </div>
      ))}
    </div>
  );
};

export default SidebarMenu;

// Path: src/shared/ui/components/SidebarMenu.tsx
