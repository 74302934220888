import { atom } from "recoil";
import { CustomerProps } from "../../../shared/types/sharedTypes";
import { OrderData } from "../../orders/types/orders";
import { ExhibitionsListingProps } from "../screens/ExhibitionsScreen";

// interface ExhibitionProps extends CustomerProps {
//   adminComments?: string;
//   newsletter?: boolean;
//   orders?: OrderData[];
// }

export const exhibitionsAtom = atom<ExhibitionsListingProps[]>({
  key: "exhibitionsAtom",
  default: [],
});

export const filteredExhibitionsAtom = atom<ExhibitionsListingProps[] | null>({
  key: "filteredExhibitionsAtom",
  default: null,
});

export const selectedExhibitionAtom = atom<ExhibitionsListingProps | null>({
  key: "selectedExhibitionAtom",
  default: null,
});

export const isEditingExhibitionInvoiceAddressAtom = atom<boolean>({
  key: "isEditingExhibitionInvoiceAddressAtom",
  default: false,
});

export const isEditingExhibitionActivityAddressAtom = atom<boolean>({
  key: "isEditingExhibitionActivityAddressAtom",
  default: false,
});
