import React, { useState, ChangeEvent, useEffect } from "react";

export interface Option {
  label: string;
  value: number;
}

interface DropdownProps {
  options: Option[];
  onChange?: (selectedOption: Option) => void;
  defaultValue?: Option; // Add this
  disabled?: boolean;
}

const defaultOption: Option = { label: "Select...", value: 0 };

const Dropdown: React.FC<DropdownProps> = ({
  options = [],
  onChange,
  defaultValue = defaultOption,
  disabled = false,
}) => {
  const [selectedOption, setSelectedOption] = useState<Option>(defaultValue);

  useEffect(() => {
    if (options.length > 0 && defaultValue === defaultOption) {
      setSelectedOption(options[0]);
    }
  }, [options, defaultValue]);

  const handleOptionChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = parseInt(e.target.value, 10);
    const selectedOption = options.find(
      (option) => option.value === selectedValue
    );

    if (!selectedOption) {
      console.warn(`Could not find option with value ${selectedValue}`);
      return;
    }

    setSelectedOption(selectedOption);
    onChange?.(selectedOption);
  };

  return (
    <div className="inline-flex justify-end">
      <select
        disabled={disabled}
        value={selectedOption.value}
        onChange={handleOptionChange}
        className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-2 py-2 text-gray-900 focus:z-10 focus:border-[#14B8A6] focus:outline-none focus:ring-1 focus:ring-[#14B8A6]"
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Dropdown;
