import { OrderData } from "../types/orders";

export const emptyOrder: OrderData = {
  orderId: 0,
  customer: {
    activityType: "",
    haveAlreadyParticipated: false,
    website: "",
    email: "",
    id: 0,
    phone: "",
    activityAddress: {
      addressActivity: "",
      cityActivity: "",
      companyActivity: "",
      countryActivity: "",
      lastNameActivity: "",
      firstNameActivity: "",
      zipCodeActivity: "",
      address2Activity: "",
    },
    invoiceAddress: {
      addressInvoice: "",
      cityInvoice: "",
      companyInvoice: "",
      countryInvoice: "",
      lastNameInvoice: "",
      firstNameInvoice: "",
      zipCodeInvoice: "",
      address2Invoice: "",
    },
  },
  mainOrderInfo: {
    date: "",
    email: "",
    dateTime: new Date(),
    orderId: 0,
    phone: "",
    website: "",
    time: "",
  },
  activityAddress: {
    addressActivity: "",
    cityActivity: "",
    companyActivity: "",
    countryActivity: "",
    lastNameActivity: "",
    firstNameActivity: "",
    zipCodeActivity: "",
    address2Activity: "",
  },
  invoiceAddress: {
    addressInvoice: "",
    cityInvoice: "",
    companyInvoice: "",
    countryInvoice: "",
    lastNameInvoice: "",
    firstNameInvoice: "",
    zipCodeInvoice: "",
    address2Invoice: "",
  },
  paymentAndInvoiceInfo: {
    actions: {
      downloadInvoice: {
        label: "Télécharger la facture",
        link: "#",
      },
      paymentLink: {
        label: "Lien de paiement",
        link: "#",
      },
      resendEmail: {
        label: "Renvoyer le mail",
        link: "#",
      },
    },
    invoiceNumber: "",
    paymentMethod: "",
    status: "",
  },
  products: [],
  company: "",
  date: "",
  status: "",
  originalStatus: "",
  total: 0,
  totalWithoutVat: 0,
  vat: 0,
  standNumber: "",
  exhibition: {
    id: 0,
    name: "",
    primaryColor: "",
    secondaryColor: "",
  },
  notes: [],
  fees: [],
};
