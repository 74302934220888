import { ArrowDownOnSquareIcon } from "@heroicons/react/24/outline";
import { createColumnHelper } from "@tanstack/react-table";
import apiFetch from "@wordpress/api-fetch";
import { useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { isLoadingAtom } from "../../../shared/atoms/ui-atoms";
import Pill from "../../../shared/ui/components/Pill";
import PageLayout from "../../../shared/ui/layouts/PageLayout";
import { returnRootUrlBasedOnAppLocation } from "../../../shared/utils/checkIfIsInlocal";
import TableWithTitle from "../../orders/layouts/TableWithTitle";
import { fetchAllTickets } from "../api/fetch-tickets";
import {
  filteredTicketsDataAtom,
  selectedTicketAtom,
  selectedTicketIndexAtom,
  ticketsDataAtom,
} from "../atoms/tickets-atoms";
import FiltersSelects from "../layouts/FiltersSelects";
import { Ticket } from "../types/ticket";

const columnHelper = createColumnHelper<Ticket>();

interface CheckButton {
  id: string;
  date: string | null;
}

const TicketsScreen = () => {
  // const [tickets, setTickets] = useRecoilState(ticketsDataAtom);
  const [tickets, setTickets] = useRecoilState(ticketsDataAtom);
  const [filteredTickets, setFilteredTickets] = useRecoilState(
    filteredTicketsDataAtom
  );
  const setSelectedTicketIndex = useSetRecoilState(selectedTicketIndexAtom);
  const [selectedTicket, setSelectedTicket] =
    useRecoilState(selectedTicketAtom);
  const [numberOfTickets, setNumberOfTickets] = useState("");
  const [isLoading, setIsLoading] = useRecoilState(isLoadingAtom);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [isUpdatingTicket, setIsUpdatingTicket] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  let token = "";
  if (localStorage.getItem("token-dashboard")) {
    token = JSON.parse(localStorage.getItem("token-dashboard") as string);
  }

  const CheckInButton = ({ id, date }: CheckButton) => {
    const [loading, setLoading] = useState(false);
    // const [loading, setLoading] = useRecoilState(isUpdatingTicketAtom);

    const checkTicketById = (id: string, checkin: boolean) => {
      if (loading) return;
      setIsUpdatingTicket(true);
      setSelectedId(id);
      setLoading(true);
      const date = new Date();
      const options: Intl.DateTimeFormatOptions = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      };
      const formattedDate = date.toLocaleString("fr-FR", options);
      const updatedTickets = tickets?.map((ticket) => {
        if (ticket.id === id) {
          return { ...ticket, date: checkin ? formattedDate : null };
        }
        return ticket;
      });
      apiFetch({
        path: `${returnRootUrlBasedOnAppLocation()}satn/v1/billet/${id}`,
        method: "POST",
        data: {
          id: id,
          date: checkin ? formattedDate : "",
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((data) => {
          updatedTickets && setTickets(updatedTickets);
          filteredTickets && setFilteredTickets([...filteredTickets]);
          // Used to set the real order Id when creating an order
        })
        .catch((error: any) => {
          console.log("error", error);
          alert("Une erreur est survenue");
          if (error.code.includes("jwt")) {
            localStorage.removeItem("token-dashboard");
            window.location.replace("/");
          }
        })
        .finally(() => {
          setIsUpdatingTicket(false);
          setLoading(false);
        });
    };

    const bgColor = date === null ? "bg-green-500" : "bg-red-500";
    const textColor = date === null ? "text-green-100" : "text-red-100";
    const buttonTitle = date === null ? "Check-in" : "Check-out";

    return (
      // <div onClick={() => !loading ? date === null ? checkInTicketById(id) : checkOutTicketById(id) : null} className={`px-4 py-2 font-semibold ${bgColor} shadow cursor-pointer ${textColor}`}>
      <div className={`${isUpdatingTicket && "cursor-wait"}`}>
        <div
          style={{ pointerEvents: isUpdatingTicket ? "none" : "auto" }}
          onClick={() =>
            !loading
              ? date === null
                ? checkTicketById(id, true)
                : checkTicketById(id, false)
              : null
          }
          className={`flex w-[170px] items-center justify-center py-2 font-semibold ${bgColor} shadow ${
            isUpdatingTicket || loading ? "cursor-wait" : "cursor-pointer"
          } ${textColor}`}
        >
          {/* <span>{!loading ? buttonTitle : 'Checking...'}</span> */}
          {(loading || isUpdatingTicket) && selectedId == id ? (
            <span>Checking...</span>
          ) : (
            <span>{buttonTitle}</span>
          )}
          {/* {!loading && <span>{buttonTitle}</span>} */}
        </div>
      </div>
    );
  };

  useEffect(() => {
    console.log(localStorage.getItem("token-dashboard"));
    if (!token) {
      window.location.replace("/");
    }

    if (tickets) {
      if (tickets.length > 0) return;
    }
    setIsLoading(true);
    fetchAllTickets()
      .then((tickets) => {
        setTickets(tickets);
        setNumberOfTickets(tickets.length.toString());
      })
      .catch((err) => {
        if (err.code.includes("jwt")) {
          console.log(err);
          localStorage.removeItem("token-dashboard");
          window.location.replace("/");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
    if (!tickets) {
      setTickets([]);
    }
    // setData(defaultData);
  }, []);

  const columns = [
    columnHelper.accessor("invoice_last_name", {
      header: "Nom",
      cell: ({ getValue, row }) => {
        return (
          <div className="flex flex-col">
            <span>
              {row.original.invoice_first_name}{" "}
              <b>{row.original.invoice_last_name}</b>
            </span>
            <span>
              {row.original.invoice_phone
                ? row.original.invoice_phone
                : "Tel. -"}{" "}
              |{" "}
              {row.original.invoice_email ? (
                <a
                  className="underline"
                  href={"mailto:" + row.original.invoice_email}
                >
                  {row.original.invoice_email}
                </a>
              ) : (
                "Mail -"
              )}
            </span>
          </div>
        );
      },
    }),
    columnHelper.accessor("product_name", {
      header: "Billet",
      cell: ({ getValue, row }) => {
        return (
          <div className="flex flex-col items-center justify-center gap-3">
            <span>{row.original.product_name}</span>
            {row.original.applied_coupons_description ? (
              <span
                className="w-fit text-xs"
                data-tooltip={row.original.applied_coupons}
              >
                {row.original.applied_coupons_description
                  ? row.original.applied_coupons_description
                  : "Aucun coupon appliqué"}
              </span>
            ) : (
              <span className="w-fit text-xs">
                {row.original.applied_coupons_description
                  ? row.original.applied_coupons_description
                  : "Aucun coupon appliqué"}
              </span>
            )}
          </div>
        );
      },
    }),
    columnHelper.accessor("pdf_url", {
      header: "Télécharger",
      cell: ({ getValue }) => {
        return { getValue } ? (
          <a
            href={getValue()}
            target="_blank"
            rel="noreferrer"
            className="flex items-center justify-center"
          >
            <ArrowDownOnSquareIcon width={40} color="#64748b" />
          </a>
        ) : (
          <span>Ce billet n'existe pas, veuillez nous contacter</span>
        );
      },
    }),
    // columnHelper.accessor("order_id", {
    //     header: "Commande",
    //     cell: ({ getValue }) => {
    //         return <span>{getValue()}</span>;
    //     },
    // }),
    columnHelper.accessor("exhibition", {
      header: "Salon",
      // cell: ({ getValue }) => <span>{}</span>,
      cell: ({ getValue }) => (
        <Pill
          label={getValue().name}
          bgColor={getValue().primaryColor}
          textColor={getValue().secondaryColor}
        />
      ),
    }),
    columnHelper.accessor("date", {
      header: "Scanné",
      cell: ({ getValue }) => {
        return <span>{getValue() !== null ? getValue() : "-"}</span>;
      },
    }),
    columnHelper.accessor("invoice_first_name", {
      header: "Action",
      cell: ({ getValue, row }) => {
        return (
          <div className="flex flex-col items-center gap-y-4">
            {/* {row.original.date === null && <div onClick={() => checkInTicketById(row.original.id)} className="px-4 py-2 font-semibold bg-green-400 shadow cursor-pointer text-green-50"><span>Check-in</span></div>} */}
            {/* {row.original.date === null && (
                        <CheckInButton
                            id={row.original.id}
                            bgColor="bg-green-500"
                            textColor="text-white"
                            buttonTitle="Check In"
                            date={row.original.date}
                        />
                    )} */}
            {/* {row.original.date !== null && <div onClick={() => checkOutTicketById(row.original.id, row.original.date)} className="px-4 py-2 font-semibold bg-red-400 shadow cursor-pointer text-red-50"><span>Check-out</span></div>} */}
            <CheckInButton id={row.original.id} date={row.original.date} />
          </div>
        );
      },
    }),
  ];

  useEffect(() => {
    //Display the number of data displayed for each filter
    if (filteredTickets) {
      setNumberOfTickets(filteredTickets.length.toString());
    } else {
      if (tickets) setNumberOfTickets(tickets.length.toString());
    }
  }, [filteredTickets]);

  useEffect(() => {
    //Display the number of data displayed for each filter
    if (filteredTickets) {
      setNumberOfTickets(filteredTickets.length.toString());
    } else {
      if (tickets) setNumberOfTickets(tickets.length.toString());
    }
  }, [filteredTickets]);
  return (
    <PageLayout>
      <TableWithTitle
        title={`Liste des billets`}
        subtittle={
          numberOfTickets ? `Nombre total de billets : ${numberOfTickets}` : ""
        }
        tableData={
          filteredTickets !== null ? filteredTickets : tickets ? tickets : []
        }
        tableColumns={columns}
        filtersComponents={<FiltersSelects />}
        isLoading={isLoading}
      />
    </PageLayout>
  );
};

export default TicketsScreen;
