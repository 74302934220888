import { atom } from "recoil";
import { CustomerProps } from "../../../shared/types/sharedTypes";
import { OrderData } from "../../orders/types/orders";
import { StandProductsNamesProps } from "../api/fetchStands";
// import { StandProductsNamesProps } from "../api/fetchStands";
import { StandProps } from "../types/stands-types";

// interface StandProps extends CustomerProps {
//   adminComments?: string;
//   newsletter?: boolean;
//   orders?: OrderData[];
// }

export const standsAtom = atom<StandProps[]>({
  key: "standsAtom",
  default: [],
});

export const filteredStandsAtom = atom<StandProps[] | null>({
  key: "filteredStandsAtom",
  // default: null,
  default: [],
});

export const availableStandsProductsAtom = atom<string[]>({
  key: "availableStandsProductsAtom",
  default: ["Tous les stands"],
});

export const selectedStandAtom = atom<StandProps | null>({
  key: "selectedStandAtom",
  default: null,
});

export const isEditingStandInvoiceAddressAtom = atom<boolean>({
  key: "isEditingStandInvoiceAddressAtom",
  default: false,
});

export const isEditingStandActivityAddressAtom = atom<boolean>({
  key: "isEditingStandActivityAddressAtom",
  default: false,
});
