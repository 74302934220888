import {
  ArrowLeftCircleIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";
import DatePicker, { CalendarContainer } from "react-datepicker";
import fr from "date-fns/locale/fr";
import { Fragment, useRef, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Dialog } from "@headlessui/react";
import {
  EyeIcon,
  PencilIcon,
  CheckCircleIcon,
  TrashIcon,
  XMarkIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/solid";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import CardWithData from "../../../shared/ui/components/CardWithData";
import Pill from "../../../shared/ui/components/Pill";
import PageLayout from "../../../shared/ui/layouts/PageLayout";
import axios from "axios";
import { returnRootUrlBasedOnAppLocation } from "../../../shared/utils/checkIfIsInlocal";
import ActionPopup from "../../../shared/ui/components/ActionPopup";
import {
  selectedWorkshopAtom,
  WorkshopDataProps,
} from "../atoms/workshops-atoms";
import CustomDropzone from "../../../shared/ui/components/ImageDropzone";
import Loader from "../../../shared/ui/components/Loader";
import SinglePostInfoCardsItemData from "../../../shared/ui/components/SinglePostInfoCardsItemData";
import { ConferenceFlagAtom } from "../../../shared/atoms/flag-atoms";
import { selectedConferenceAtom } from "../../conferences/atoms/conferences-atoms";
import { selectedPostAtom } from "../../../shared/atoms/single-data-atoms";

interface CardWithHeaderProps {
  title: string;
  children: React.ReactNode;
  isEditable?: boolean;
  centerElements?: boolean;
  justifyCenter?: boolean;
  width?: string;
  headerChildren?: React.ReactNode;
}

interface EditableDataActionButtonsProps {
  handleEdit: () => void;
  handleValidate: () => void;
  handleCancel: () => void;
  isEditing: boolean;
}

const EditableDataActionButtons = ({
  handleEdit,
  handleValidate,
  handleCancel,
  isEditing,
}: EditableDataActionButtonsProps) => {
  return (
    <div>
      {/* Default state */}
      {!isEditing && (
        <div className="flex flex-row gap-x-2">
          <div
            className="cursor-pointer p-[6px] text-gray-200 transition hover:text-gray-600"
            onClick={() => {
              handleEdit();
            }}
          >
            <PencilIcon className="w-5" />
          </div>
        </div>
      )}
      {/* Editing state  */}
      {isEditing && (
        <div className="flex flex-row items-center gap-x-2">
          <div
            onClick={() => {
              handleValidate();
            }}
            className="p-1 text-green-400 transition rounded-full cursor-pointer hover:bg-green-100 hover:shadow"
          >
            <CheckIcon className="w-6" />
          </div>
          <div className="p-1 text-red-400 transition rounded-full cursor-pointer hover:bg-red-100 hover:shadow">
            <XMarkIcon className="w-6" onClick={() => handleCancel()} />
          </div>
        </div>
      )}
    </div>
  );
};

const CardWithHeader = ({
  title,
  children,
  isEditable = false,
  centerElements = false,
  justifyCenter = true,
  width = "",
  headerChildren = null,
}: CardWithHeaderProps) => {
  return (
    <div className={`${width} flex grow flex-col rounded-lg bg-white shadow`}>
      <div className="flex w-full flex-row items-center justify-between rounded-lg rounded-b-none bg-[#14B8A6] px-6 py-3 font-bold text-white">
        <div className="flex flex-row items-center justify-between ">
          <span className="text-xl">{title}</span>
          {isEditable && <PencilIcon className="w-5" />}
        </div>
        <>{headerChildren}</>
      </div>
      <div
        className={`flex grow flex-col ${
          justifyCenter && "justify-center"
        } px-6 py-4 ${centerElements && "mx-auto"}`}
      >
        {children}
      </div>
    </div>
  );
};
interface WorkshopStatus {
  id: number;
  name: string;
}
const SingleWorkshopScreen = () => {
  let token = "";
  if (localStorage.getItem("token-dashboard")) {
    token = JSON.parse(localStorage.getItem("token-dashboard") as string);
  }

  const [selectedWorkshopData, setSelectedWorkshopData] =
    useRecoilState(selectedWorkshopAtom);
  // Time slots
  const [startDate1, setStartDate1] = useState<Date | null>();
  const [startDate2, setStartDate2] = useState<Date | null>();
  const [startDate3, setStartDate3] = useState<Date | null>();
  const [startDate4, setStartDate4] = useState<Date | null>();
  const [startDate5, setStartDate5] = useState<Date | null>();

  const [isDatepicker1Open, setIsDatepicker1Open] = useState(false);
  const [isDatepicker2Open, setIsDatepicker2Open] = useState(false);
  const [isDatepicker3Open, setIsDatepicker3Open] = useState(false);
  const [isDatepicker4Open, setIsDatepicker4Open] = useState(false);
  const [isDatepicker5Open, setIsDatepicker5Open] = useState(false);
  const selected = useRecoilValue(ConferenceFlagAtom);
  const [selectedPost, setSelectedPost] = useRecoilState(selectedPostAtom);

  let dateString1 = "";
  let dateString2 = "";
  let dateString3 = "";
  let dateString4 = "";
  let dateString5 = "";
  if (startDate1) {
    dateString1 = startDate1?.toLocaleDateString("fr-FR", {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  } else {
    if (selectedWorkshopData?.timeSlot1) {
      dateString1 = selectedWorkshopData?.timeSlot1;
    } else {
      dateString1 = "Aucune date";
    }
  }
  if (startDate2) {
    dateString2 = startDate2?.toLocaleDateString("fr-FR", {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  } else {
    if (selectedWorkshopData?.timeSlot2) {
      dateString2 = selectedWorkshopData?.timeSlot2;
    } else {
      dateString2 = "Aucune date";
    }
  }
  if (startDate3) {
    dateString3 = startDate3?.toLocaleDateString("fr-FR", {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  } else {
    if (selectedWorkshopData?.timeSlot3) {
      dateString3 = selectedWorkshopData?.timeSlot3;
    } else {
      dateString3 = "Aucune date";
    }
  }
  if (startDate4) {
    dateString4 = startDate4?.toLocaleDateString("fr-FR", {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  } else {
    if (selectedWorkshopData?.timeSlot4) {
      dateString4 = selectedWorkshopData?.timeSlot4;
    } else {
      dateString4 = "Aucune date";
    }
  }
  if (startDate5) {
    dateString5 = startDate5?.toLocaleDateString("fr-FR", {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  } else {
    if (selectedWorkshopData?.timeSlot5) {
      dateString5 = selectedWorkshopData?.timeSlot5;
    } else {
      dateString5 = "Aucune date";
    }
  }

  const returnFormattedDate = (date: Date) => {
    return `${date.getDate().toString().padStart(2, "0")}-${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${date.getFullYear()} ${date
      .getHours()
      .toString()
      .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}:${date
      .getSeconds()
      .toString()
      .padStart(2, "0")}`;
  };

  useEffect(() => {
    if (selectedWorkshopData && startDate1) {
      const formattedDate = returnFormattedDate(startDate1);
      setSelectedWorkshopData({
        ...selectedWorkshopData,
        timeSlot1: formattedDate,
      });
    }
    if (selectedWorkshopData && startDate2) {
      const formattedDate = returnFormattedDate(startDate2);
      setSelectedWorkshopData({
        ...selectedWorkshopData,
        timeSlot2: formattedDate,
      });
    }
    if (selectedWorkshopData && startDate3) {
      const formattedDate = returnFormattedDate(startDate3);
      setSelectedWorkshopData({
        ...selectedWorkshopData,
        timeSlot3: formattedDate,
      });
    }
    if (selectedWorkshopData && startDate4) {
      const formattedDate = returnFormattedDate(startDate4);
      setSelectedWorkshopData({
        ...selectedWorkshopData,
        timeSlot4: formattedDate,
      });
    }
    if (selectedWorkshopData && startDate5) {
      const formattedDate = returnFormattedDate(startDate5);
      setSelectedWorkshopData({
        ...selectedWorkshopData,
        timeSlot5: formattedDate,
      });
    }
  }, [startDate1, startDate2, startDate3, startDate4, startDate5]);
  useEffect(() => {
    console.log(selectedWorkshopData);
  }, [selectedWorkshopData]);
  //
  const workshopId = window.location.href.split("/").pop();
  const [workshopStatuses, setWorkshopStatuses] = useState<WorkshopStatus[]>([
    {
      id: 0,
      name: "-",
    },
  ]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedWorkshopStatus, setSelectedWorkshopStatus] =
    useState<WorkshopStatus>(workshopStatuses[0]);
  const [isUpdatingWorkshop, setIsUpdatingWorkshop] = useState(false);
  const [isDeletingWorkshop, setIsDeletingWorkshop] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenWorkshop, setIsOpenWorkshop] = useState(false);

  const [isEditingWorkshopNumber, setIsEditingWorkshopNumber] = useState(false);
  const [isEditingStandNumber, setIsEditingStandNumber] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isConfirmDeletionPopupVisible, setIsConfirmDeletionPopupVisible] =
    useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const inputRefStandNumber = useRef<HTMLInputElement>(null);

  const [filesExhibitor, setFilesExhibitor] = useState<Object[]>();
  const [filesWorkshop, setFilesWorkshop] = useState<Object[]>();
  const handleEdit = () => {
    setIsEditingWorkshopNumber(true);
    inputRef.current?.focus();
  };
  const handleValidate = () => {
    setIsEditingWorkshopNumber(false);

    if (selectedWorkshopData && inputRef.current?.value) {
      setSelectedWorkshopData({
        ...selectedWorkshopData,
        workshopGeneralInfo: {
          ...selectedWorkshopData.workshopGeneralInfo,
          salle: inputRef.current.value,
        },
      });
    }
  };
  const handleCancel = () => {
    setIsEditingWorkshopNumber(false);
  };

  const handleEditStandNumber = () => {
    setIsEditingStandNumber(true);
    inputRefStandNumber.current?.focus();
  };

  const handleValidateStandNumber = () => {
    setIsEditingStandNumber(false);

    if (selectedWorkshopData && inputRefStandNumber.current?.value) {
      setSelectedWorkshopData({
        ...selectedWorkshopData,
        orderAndWorkshopInfo: {
          ...selectedWorkshopData.orderAndWorkshopInfo,
          standNumber: inputRefStandNumber.current.value,
        },
      });
    }
  };

  const handleCancelStandNumber = () => {
    setIsEditingStandNumber(false);
  };

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  function closeModalWorkshop() {
    setIsOpenWorkshop(false);
  }

  function openModalWorkshop() {
    setIsOpenWorkshop(true);
  }

  const fetchWorkshopsAndStatusesData = async () => {
    setIsLoading(true);
    await axios
      .get(
        `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v1/workshops?id=${workshopId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setSelectedWorkshopData(res.data[0]);
        setSelectedPost(res.data[0]);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.code.includes("jwt")) {
          console.log(err);
          localStorage.removeItem("token-dashboard");
          window.location.replace("/");
        }
      });
    // Fetch admin-dashboard/v1/workshops-statuses
    axios
      .get(
        `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v1/workshops-statuses`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setWorkshopStatuses(res.data);
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.code.includes("jwt")) {
          console.log(err);
          localStorage.removeItem("token-dashboard");
          window.location.replace("/");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (selectedWorkshopData) {
      setIsLoading(false);
    }
    fetchWorkshopsAndStatusesData();
  }, []);

  useEffect(() => {
    if (workshopStatuses)
      selectedWorkshopData &&
        setSelectedWorkshopStatus(
          workshopStatuses.find(
            (workshopStatus) =>
              workshopStatus.name ===
              selectedWorkshopData.orderAndWorkshopInfo.workshopStatus
          ) as WorkshopStatus
        );
  }, [workshopStatuses]);

  const navigate = useNavigate();
  const popups = [
    <ActionPopup
      actionRunning={isDeletingWorkshop}
      key={Math.random()}
      title="Supprimer l'atelier ?"
      children={
        <p>
          Voulez-vous vraimer supprimer l'atelier {selectedWorkshopData?.id} ?
        </p>
      }
      isVisible={isConfirmDeletionPopupVisible}
      setIsVisible={() => {
        setIsConfirmDeletionPopupVisible(!isConfirmDeletionPopupVisible);
      }}
      action={() => {
        !isDeletingWorkshop && deleteWorkshop();
      }}
      cancelAction={() => {
        setIsConfirmDeletionPopupVisible(false);
      }}
      actionButtonDisabled={false}
      actionButtonTitle={
        <div
          className={`flex flex-row items-center ${
            isDeletingWorkshop && "cursor-wait"
          }`}
        >
          {isDeletingWorkshop && (
            <svg
              className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}
          {isDeletingWorkshop ? "Suppression..." : "Supprimer"}
        </div>
      }
      cancelButtonVisible={true}
      actionButtonBackgroundColor="#f87171"
    />,
    <ActionPopup
      key={Math.random()}
      title="Atelier mis à jour"
      children={
        <p>L'atelier {selectedWorkshopData?.id} a été mis à jour avec succès</p>
      }
      isVisible={isPopupVisible}
      setIsVisible={() => {
        setIsPopupVisible(!isPopupVisible);
        window.location.reload();
      }}
      action={() => {
        setIsPopupVisible(false);
        window.location.reload();
      }}
      cancelAction={() => {
        setIsPopupVisible(false);
        window.location.reload();
      }}
      actionButtonDisabled={false}
      actionButtonTitle="Fermer"
      cancelButtonVisible={false}
    />,
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-[1200px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="flex justify-end mb-4">
                  <button
                    type="button"
                    // className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={closeModal}
                  >
                    <XCircleIcon className="w-8 text-[#0F766E]" />
                  </button>
                </div>
                <div className="flex items-center justify-center">
                  <img
                    src={
                      selectedWorkshopData?.workshopGeneralInfo
                        .photoDeLorganisateurDeLatelier?.url
                    }
                    alt=""
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>,
    <Transition appear show={isOpenWorkshop} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModalWorkshop}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-[1200px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="flex justify-end mb-4">
                  <button
                    type="button"
                    // className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={closeModalWorkshop}
                  >
                    <XCircleIcon className="w-8 text-[#0F766E]" />
                  </button>
                </div>
                <div className="flex items-center justify-center">
                  <img
                    src={
                      selectedWorkshopData?.workshopGeneralInfo.photoDeLatelier
                        ?.url
                    }
                    alt=""
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>,
  ];
  const deleteWorkshop = () => {
    if (selectedWorkshopData) {
      setIsDeletingWorkshop(true);
      axios
        .post(
          `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v1/delete-workshop/`,
          {
            id: selectedWorkshopData?.id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((data) => {
          console.log("data", data);
          navigate(`/ateliers`);
          window.location.reload();
        })
        .catch((error) => {
          console.log("error", error);
          if (error.code.includes("jwt")) {
            localStorage.removeItem("token-dashboard");
            window.location.replace("/");
          }
        })
        .finally(() => {
          setIsDeletingWorkshop(false);
          // setIsPopupVisible(true);
        });
    }
  };

  const phoneWithPrefix = selectedWorkshopData?.exhibitorInfo?.phone;

  const phoneWithoutPrefix = phoneWithPrefix?.replace(/^\+\d+/, '');

  const [areOldTimeSlotsDisplayed, setAreOldTimeSlotsDisplayed] =
    useState(false);
  return (
    <PageLayout>
      <>
        {popups}
        {isLoading ? (
          <Loader />
        ) : (
          <div className="flex flex-col gap-y-16">
            <div className="flex justify-between w-full">
              <Link
                className="flex flex-row items-center gap-x-4 text-2xl text-[#3c7179]"
                to={`/ateliers`}
                onClick={() => {}}
              >
                <ArrowLeftCircleIcon className="color-[#3C7179] w-10" /> Retour
                à la liste des ateliers
              </Link>
              <div className="flex flex-row items-center gap-x-8">
                <button
                  disabled={isDeletingWorkshop}
                  // className="flex flex-row items-center gap-x-4 rounded border-none bg-[#14b8a6] px-4 py-2 text-lg text-gray-50 shadow"
                  className={`${
                    isDeletingWorkshop && "opacity-40"
                  } flex flex-row items-center rounded-md border-none bg-red-400 px-8 py-4 text-lg font-semibold text-white shadow`}
                  onClick={() => {
                    setIsConfirmDeletionPopupVisible(true);
                  }}
                  type="button"
                >
                  Supprimer
                  {/* {isDeletingWorkshop && (
                    <svg
                      className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                  {isDeletingWorkshop ? "Suppression..." : "Supprimer"} */}
                </button>
                <button
                  disabled={isUpdatingWorkshop}
                  // className="flex flex-row items-center gap-x-4 rounded border-none bg-[#14b8a6] px-4 py-2 text-lg text-gray-50 shadow"
                  className={`${
                    isUpdatingWorkshop && "opacity-40"
                  } flex flex-row items-center rounded-md border-none bg-[#14b8a6] px-8 py-4 text-lg font-semibold text-white shadow`}
                  onClick={() => {
                    console.log("FLAG WORKSHOP ", selected);
                    if (selectedWorkshopData) {
                      setIsUpdatingWorkshop(true);
                      axios
                        .post(
                          `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v1/workshops/`,
                          {
                            workshopId: selectedWorkshopData.id,
                            workshopStatus: selectedWorkshopStatus.name,
                            adminComments: selectedWorkshopData.adminComments,
                            orderId:
                              selectedWorkshopData.orderAndWorkshopInfo
                                .orderNumber,
                            workshopTitle: selectedWorkshopData.title,
                            workshopDescription:
                              selectedWorkshopData.workshopGeneralInfo
                                .workshopDescription,
                            userId:
                              selectedWorkshopData.workshopGeneralInfo.userId,
                            salle:
                              selectedWorkshopData.workshopGeneralInfo.salle,
                            exhibitorImage:
                              selectedWorkshopData.workshopGeneralInfo
                                .photoDeLorganisateurDeLatelier?.url || "",
                            workshopImage:
                              selectedWorkshopData.workshopGeneralInfo
                                .photoDeLatelier?.url || "",
                            standNumber:
                              selectedWorkshopData.orderAndWorkshopInfo
                                .standNumber,
                            timeSlot1: selectedWorkshopData.timeSlot1,
                            timeSlot2: selectedWorkshopData.timeSlot2,
                            timeSlot3: selectedWorkshopData.timeSlot3,
                            timeSlot4: selectedWorkshopData.timeSlot4,
                            timeSlot5: selectedWorkshopData.timeSlot5,
                            creneauxEtSalles:
                              selectedWorkshopData.creneauxEtSalles,
                            speakerLastName:
                              selectedWorkshopData.speakerLastName,
                            speakerFirstName:
                              selectedWorkshopData.speakerFirstName,
                            speakerJob: selectedWorkshopData.speakerJob,
                            workshopInformationsEtInscriptions:
                              selectedWorkshopData.workshopGeneralInfo
                                .informationsEtInscriptions,
                            topCardsInfo: {
                              company:
                                selectedWorkshopData.workshopGeneralInfo
                                  .company,
                              firstName:
                                selectedWorkshopData.workshopGeneralInfo
                                  .firstName,
                              lastName:
                                selectedWorkshopData.workshopGeneralInfo
                                  .lastName,
                              email: selectedWorkshopData.exhibitorInfo.email,
                              phone: selectedWorkshopData.exhibitorInfo.phone,
                              website:
                                selectedWorkshopData.exhibitorInfo.website,
                              activityType:
                                selectedWorkshopData.exhibitorInfo.activityType,
                              hasAllreadyBeenToExhibition:
                                selectedWorkshopData.workshopGeneralInfo
                                  .hasAllreadyBeenToExhibition,
                            },
                            billing_country_code: selected,
                          },
                          {
                            headers: {
                              Authorization: `Bearer ${token}`,
                            },
                          }
                        )
                        .then((data) => {
                          console.log("data", data);
                        })
                        .catch((error) => {
                          console.log("error", error);
                          if (error.code.includes("jwt")) {
                            localStorage.removeItem("token-dashboard");
                            window.location.replace("/");
                          }
                        })
                        .finally(() => {
                          setIsUpdatingWorkshop(false);
                          setIsPopupVisible(true);
                        });
                    }
                  }}
                  type="button"
                >
                  {isUpdatingWorkshop && (
                    <svg
                      className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                  {isUpdatingWorkshop ? "Mise à jour..." : "Mettre à jour"}
                </button>
              </div>
            </div>
            <>
              {/* Content */}
              <div className="flex flex-col gap-y-11">
                <div className="grid grid-cols-3 gap-x-16">
                  {/* Left */}
                  <div className="flex flex-col col-span-2 gap-y-11">
                    {/* Top */}
                    <div className="flex flex-row gap-x-6">
                      <CardWithData width="33%">
                        <SinglePostInfoCardsItemData
                          isEditable={true}
                          title="Raison sociale"
                          setValue={(value) => {
                            setSelectedWorkshopData(
                              (prevSelectedWorkshopData) => {
                                if (!prevSelectedWorkshopData) return null;
                                return {
                                  ...prevSelectedWorkshopData,
                                  workshopGeneralInfo: {
                                    ...prevSelectedWorkshopData.workshopGeneralInfo,
                                    company: value,
                                  },
                                };
                              }
                            );
                          }}
                          value={
                            selectedWorkshopData?.workshopGeneralInfo
                              ?.company || "-"
                          }
                        />
                        <SinglePostInfoCardsItemData
                          isEditable={true}
                          title="Prénom"
                          value={
                            selectedWorkshopData?.workshopGeneralInfo
                              ?.firstName || "-"
                          }
                          setValue={(value) => {
                            setSelectedWorkshopData(
                              (prevSelectedWorkshopData) => {
                                if (!prevSelectedWorkshopData) return null;
                                return {
                                  ...prevSelectedWorkshopData,
                                  workshopGeneralInfo: {
                                    ...prevSelectedWorkshopData.workshopGeneralInfo,
                                    firstName: value,
                                  },
                                };
                              }
                            );
                          }}
                        />
                        <SinglePostInfoCardsItemData
                          isEditable={true}
                          title="Nom"
                          value={
                            selectedWorkshopData?.workshopGeneralInfo
                              ?.lastName || "-"
                          }
                          setValue={(value) => {
                            setSelectedWorkshopData(
                              (prevSelectedWorkshopData) => {
                                if (!prevSelectedWorkshopData) return null;
                                return {
                                  ...prevSelectedWorkshopData,
                                  workshopGeneralInfo: {
                                    ...prevSelectedWorkshopData.workshopGeneralInfo,
                                    lastName: value,
                                  },
                                };
                              }
                            );
                          }}
                        />
                        <div className="mt-5"></div>
                        <SinglePostInfoCardsItemData
                          isEditable={true}
                          isSelect={true}
                          title="Déjà venu au salon"
                          value={
                            selectedWorkshopData?.workshopGeneralInfo
                              ?.hasAllreadyBeenToExhibition
                              ? "Oui"
                              : "Non"
                          }
                          setValue={(value) => {
                            setSelectedWorkshopData(
                              (prevSelectedWorkshopData) => {
                                if (!prevSelectedWorkshopData) return null;
                                return {
                                  ...prevSelectedWorkshopData,
                                  workshopGeneralInfo: {
                                    ...prevSelectedWorkshopData.workshopGeneralInfo,
                                    hasAllreadyBeenToExhibition:
                                      value === "true" ? true : false,
                                  },
                                };
                              }
                            );
                          }}
                        />
                      </CardWithData>
                      <CardWithData width="33%">
                        <SinglePostInfoCardsItemData
                          isEditable={true}
                          email={selectedWorkshopData?.exhibitorInfo?.email}
                          title="Email"
                          value={
                            selectedWorkshopData?.exhibitorInfo?.email || "-"
                          }
                          setValue={(value) => {
                            setSelectedWorkshopData(
                              (prevSelectedWorkshopData) => {
                                if (!prevSelectedWorkshopData) return null;
                                return {
                                  ...prevSelectedWorkshopData,
                                  exhibitorInfo: {
                                    ...prevSelectedWorkshopData.exhibitorInfo,
                                    email: value,
                                  },
                                };
                              }
                            );
                          }}
                        />
                        <SinglePostInfoCardsItemData
                          isEditable={true}
                          phone={phoneWithPrefix}
                          title="Téléphone"
                          value={phoneWithoutPrefix}
                          setValue={(value) => {
                            setSelectedWorkshopData(
                              (prevSelectedWorkshopData) => {
                                if (!prevSelectedWorkshopData) return null;
                                return {
                                  ...prevSelectedWorkshopData,
                                  exhibitorInfo: {
                                    ...prevSelectedWorkshopData.exhibitorInfo,
                                    phone: value,
                                  },
                                };
                              }
                            );
                          }}
                        />
                        <SinglePostInfoCardsItemData
                          isEditable={true}
                          website={selectedWorkshopData?.exhibitorInfo?.website}
                          title="Site internet"
                          value={
                            selectedWorkshopData?.exhibitorInfo?.website || "-"
                          }
                          setValue={(value) => {
                            setSelectedWorkshopData(
                              (prevSelectedWorkshopData) => {
                                if (!prevSelectedWorkshopData) return null;
                                return {
                                  ...prevSelectedWorkshopData,
                                  exhibitorInfo: {
                                    ...prevSelectedWorkshopData.exhibitorInfo,
                                    website: value,
                                  },
                                };
                              }
                            );
                          }}
                        />
                        <div className="mt-5"></div>
                        <SinglePostInfoCardsItemData
                          isEditable={true}
                          title="Type d'activité"
                          value={
                            selectedWorkshopData?.exhibitorInfo?.activityType ||
                            "-"
                          }
                          setValue={(value) => {
                            setSelectedWorkshopData(
                              (prevSelectedWorkshopData) => {
                                if (!prevSelectedWorkshopData) return null;
                                return {
                                  ...prevSelectedWorkshopData,
                                  exhibitorInfo: {
                                    ...prevSelectedWorkshopData.exhibitorInfo,
                                    activityType: value,
                                  },
                                };
                              }
                            );
                          }}
                        />
                      </CardWithData>
                      <CardWithData width="33%">
                        <SinglePostInfoCardsItemData
                          isEditable={false}
                          title="Numéro de commande"
                          value={
                            // selectedWorkshopData?.orderAndWorkshopInfo?.orderNumber || "-"
                            selectedWorkshopData?.orderAndWorkshopInfo
                              ?.orderNumber ? (
                              <Link
                                to={`/commandes/${selectedWorkshopData?.orderAndWorkshopInfo?.orderNumber}`}
                                onClick={() =>
                                  localStorage.setItem("selectedMenuIndex", "0")
                                }
                              >
                                <span className="underline">
                                  {selectedWorkshopData?.orderAndWorkshopInfo
                                    ?.orderNumber || "-"}
                                </span>
                              </Link>
                            ) : (
                              "-"
                            )
                          }
                        />
                        <SinglePostInfoCardsItemData
                          isEditable={false}
                          title="Statut de commande"
                          value={
                            selectedWorkshopData?.orderAndWorkshopInfo
                              ?.orderStatus || "-"
                          }
                        />
                        <SinglePostInfoCardsItemData
                          isEditable={false}
                          title="Statut de l'atelier"
                          // value={selectedWorkshopData?.orderAndWorkshopInfo?.workshopStatus}
                          value={
                            <Listbox
                              value={selectedWorkshopStatus}
                              onChange={setSelectedWorkshopStatus}
                            >
                              <div className="relative mt-1">
                                <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border rounded-lg cursor-pointer focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                  <span className="block truncate">
                                    {selectedWorkshopStatus &&
                                      selectedWorkshopStatus.name}
                                  </span>
                                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                    <ChevronUpDownIcon
                                      className="w-5 h-5 text-gray-400"
                                      aria-hidden="true"
                                    />
                                  </span>
                                </Listbox.Button>
                                <Transition
                                  as={Fragment}
                                  leave="transition ease-in duration-100"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                >
                                  <Listbox.Options className="absolute z-[1] mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {workshopStatuses.map(
                                      (person, personIdx) => (
                                        <Listbox.Option
                                          key={personIdx}
                                          className={({ active }) =>
                                            `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                                              active
                                                ? "bg-amber-100 text-amber-900"
                                                : "text-gray-900"
                                            }`
                                          }
                                          value={person}
                                        >
                                          {({ selected }) => (
                                            <>
                                              <span
                                                className={`block truncate ${
                                                  selected
                                                    ? "font-medium"
                                                    : "font-normal"
                                                }`}
                                              >
                                                {person.name}
                                              </span>
                                              {selected ? (
                                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                  <CheckIcon
                                                    className="w-5 h-5"
                                                    aria-hidden="true"
                                                  />
                                                </span>
                                              ) : null}
                                            </>
                                          )}
                                        </Listbox.Option>
                                      )
                                    )}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            </Listbox>
                          }
                        />
                        <div className="mt-5"></div>
                        {/* <div>
                        {selectedWorkshopData?.orderAndWorkshopInfo
                          ?.badgeExhibitorUrl ? (
                          <SinglePostInfoCardsItemData
                            isEditable={false}
                            title="Télécharger le badge exposant"
                            link={
                              selectedWorkshopData?.orderAndWorkshopInfo
                                ?.badgeExhibitorUrl || "#"
                            }
                          />
                        ) : null}
                        {selectedWorkshopData?.orderAndWorkshopInfo
                          ?.badgeParkingUrl ? (
                          <SinglePostInfoCardsItemData
                            isEditable={false}
                            title="Télécharger le badge parking"
                            link={
                              selectedWorkshopData?.orderAndWorkshopInfo
                                ?.badgeParkingUrl || "#"
                            }
                          />
                        ) : null}
                      </div> */}
                      </CardWithData>
                    </div>
                    {/* Bottom */}
                    <CardWithHeader
                      justifyCenter={false}
                      title={
                        selectedWorkshopData?.workshopGeneralInfo.company ||
                        selectedWorkshopData?.workshopGeneralInfo?.fullName ||
                        ""
                      }
                      headerChildren={
                        <div className="flex flex-row items-center gap-x-8">
                          {selectedWorkshopData?.workshopGeneralInfo?.exhibition
                            .name ? (
                            <Pill
                              label={
                                selectedWorkshopData?.workshopGeneralInfo
                                  ?.exhibition.name
                              }
                              bgColor={
                                selectedWorkshopData?.workshopGeneralInfo
                                  .exhibition.primaryColor
                              }
                              textColor={
                                selectedWorkshopData?.workshopGeneralInfo
                                  .exhibition.secondaryColor
                              }
                            />
                          ) : (
                            "-"
                          )}
                          {/* <div className="mx-auto rounded-full bg-[#f7f5ef] px-4 py-1 text-center">
                          <div className="flex flex-row items-center text-base font-bold gap-x-2">
                            <div className="text-[#0F766E]">Workshop n°</div>
                            <div className="text-[#0F766E]">
                              {selectedWorkshopData?.orderAndWorkshopInfo
                                .workshopNumber || "-"}
                            </div>
                          </div>
                        </div> */}
                          <div className="flex flex-row items-center gap-x-2">
                            {selectedWorkshopData?.orderAndWorkshopInfo
                              .orderHasStand && (
                              <>
                                {!isEditingStandNumber && (
                                  <div className="mx-auto rounded-full bg-[#f7f5ef] px-4 py-1 text-center">
                                    <div className="flex flex-row items-center text-base font-bold gap-x-2">
                                      <span className="text-[#0F766E]">
                                        Stand n°
                                        {
                                          selectedWorkshopData
                                            ?.orderAndWorkshopInfo.standNumber
                                        }
                                      </span>
                                    </div>
                                  </div>
                                )}
                                {isEditingStandNumber && (
                                  <input
                                    type="text"
                                    className="max-h-[32px] w-full max-w-[100px] grow rounded-full border-2 bg-gray-50 p-1 text-center text-[#0F766E] focus:outline-none"
                                    placeholder={
                                      selectedWorkshopData?.orderAndWorkshopInfo
                                        .standNumber !== "-"
                                        ? selectedWorkshopData
                                            ?.orderAndWorkshopInfo.standNumber
                                        : ""
                                    }
                                    ref={inputRefStandNumber}
                                    onChange={(e) => {
                                      // selectedWorkshopData &&
                                      //   setSelectedWorkshopData({
                                      //     ...selectedWorkshopData,
                                      //     orderAndWorkshopInfo: {
                                      //       ...selectedWorkshopData?.orderAndWorkshopInfo,
                                      //       standNumber: e.target.value,
                                      //     },
                                      //   });
                                    }}
                                  />
                                )}
                                <EditableDataActionButtons
                                  isEditing={isEditingStandNumber}
                                  handleEdit={handleEditStandNumber}
                                  handleValidate={handleValidateStandNumber}
                                  handleCancel={handleCancelStandNumber}
                                />
                              </>
                            )}
                            <>
                              {!isEditingWorkshopNumber && (
                                <div className="mx-auto rounded-full bg-[#f7f5ef] px-4 py-1 text-center">
                                  <div className="flex flex-row items-center text-base font-bold gap-x-2">
                                    <span className="text-[#0F766E]">
                                      Salle{" "}
                                      {selectedWorkshopData?.workshopGeneralInfo
                                        .salle || "-"}
                                    </span>
                                  </div>
                                </div>
                              )}
                              {isEditingWorkshopNumber && (
                                <input
                                  type="text"
                                  className="max-h-[32px] w-full max-w-[100px] grow rounded-full border-2 bg-gray-50 p-1 text-center text-[#0F766E] focus:outline-none"
                                  placeholder={
                                    selectedWorkshopData?.workshopGeneralInfo
                                      .salle !== "-"
                                      ? selectedWorkshopData
                                          ?.workshopGeneralInfo.salle
                                      : ""
                                  }
                                  ref={inputRef}
                                  onChange={(e) => {
                                    // selectedWorkshopData &&
                                    //   setSelectedWorkshopData({
                                    //     ...selectedWorkshopData,
                                    //     workshopGeneralInfo: {
                                    //       ...selectedWorkshopData.workshopGeneralInfo,
                                    //       salle: e.target.value,
                                    //     },
                                    //   });
                                  }}
                                />
                              )}
                              <EditableDataActionButtons
                                isEditing={isEditingWorkshopNumber}
                                handleEdit={handleEdit}
                                handleValidate={handleValidate}
                                handleCancel={handleCancel}
                              />
                            </>
                            {/* <div
              className={`flex cursor-pointer flex-row gap-x-2 ${
                isEditingWorkshopNumber && ""
              }`}
            >
              <div
                className="p-[6px] text-gray-400 transition hover:text-gray-600"
                onClick={() => {
                  setIdEditingWorkshopNumber(!isEditingWorkshopNumber);
                  // inputRef.current?.focus();
                }}
              >
                <PencilIcon className="w-5" />
              </div>
            </div> */}
                          </div>
                        </div>
                      }
                    >
                      <div className="flex flex-col gap-y-8">
                        {/* Workshop */}
                        {/* <div className="flex flex-row items-center justify-between">
                        <SinglePostInfoCardsItemData
                          isEditable={false}
                          title="Type de workshop"
                          value={
                            selectedWorkshopData?.workshopGeneralInfo
                              .workshopType ? (
                              <span>
                                {
                                  selectedWorkshopData?.workshopGeneralInfo
                                    .workshopType
                                }
                              </span>
                            ) : (
                              "-"
                            )
                          }
                        />
                        <div className="flex flex-row items-center gap-x-6">
                          <SinglePostInfoCardsItemData
                            itemsCenter={true}
                            isEditable={false}
                            title="Parking"
                            value={
                              selectedWorkshopData?.parkingProduct ? (
                                <CheckCircleIcon className="w-8 text-green-500" />
                              ) : (
                                <XCircleIcon className="w-8 text-red-500" />
                              )
                            }
                          />
                          <SinglePostInfoCardsItemData
                            itemsCenter={true}
                            isEditable={false}
                            title="Meilleure visibilité"
                            value={
                              selectedWorkshopData?.betterVisibilityProduct ? (
                                <CheckCircleIcon className="w-8 text-green-500" />
                              ) : (
                                <XCircleIcon className="w-8 text-gray-400" />
                              )
                            }
                          />
                        </div>
                      </div> */}
                        {/* Comment exhibitor */}
                        <SinglePostInfoCardsItemData
                          isEditable={true}
                          title="Titre atelier"
                          value={selectedWorkshopData?.title || "-"}
                          setValue={(value) => {
                            selectedWorkshopData &&
                              setSelectedWorkshopData({
                                ...selectedWorkshopData,
                                title: value,
                              });
                          }}
                        />
                        <SinglePostInfoCardsItemData
                          isEditable={true}
                          title="Nom de l'organisateur"
                          value={selectedWorkshopData?.speakerLastName || "-"}
                          setValue={(value) => {
                            selectedWorkshopData &&
                              setSelectedWorkshopData({
                                ...selectedWorkshopData,
                                speakerLastName: value,
                              });
                          }}
                        />
                        <SinglePostInfoCardsItemData
                          isEditable={true}
                          title="Prénom de l'organisateur"
                          value={selectedWorkshopData?.speakerFirstName || "-"}
                          setValue={(value) => {
                            selectedWorkshopData &&
                              setSelectedWorkshopData({
                                ...selectedWorkshopData,
                                speakerFirstName: value,
                              });
                          }}
                        />
                        <SinglePostInfoCardsItemData
                          isEditable={true}
                          title="Métier de l'organisateur"
                          value={selectedWorkshopData?.speakerJob || "-"}
                          setValue={(value) => {
                            selectedWorkshopData &&
                              setSelectedWorkshopData({
                                ...selectedWorkshopData,
                                speakerJob: value,
                              });
                          }}
                        />
                        <SinglePostInfoCardsItemData
                          isEditable={true}
                          setValue={(value) => {
                            selectedWorkshopData &&
                              setSelectedWorkshopData({
                                ...selectedWorkshopData,
                                workshopGeneralInfo: {
                                  ...selectedWorkshopData.workshopGeneralInfo,
                                  workshopDescription: value,
                                },
                              });
                          }}
                          title="Description atelier"
                          value={
                            selectedWorkshopData?.workshopGeneralInfo
                              .workshopDescription || "-"
                          }
                        />
                        <SinglePostInfoCardsItemData
                          isEditable={true}
                          setValue={(value) => {
                            selectedWorkshopData &&
                              setSelectedWorkshopData({
                                ...selectedWorkshopData,
                                workshopGeneralInfo: {
                                  ...selectedWorkshopData.workshopGeneralInfo,
                                  informationsEtInscriptions: value,
                                },
                              });
                          }}
                          title="Informations et inscriptions"
                          value={
                            selectedWorkshopData?.workshopGeneralInfo
                              .informationsEtInscriptions || "-"
                          }
                        />
                        {/*  */}
                        <SinglePostInfoCardsItemData
                          isEditable={true}
                          setValue={(value) => {
                            selectedWorkshopData &&
                              setSelectedWorkshopData({
                                ...selectedWorkshopData,
                                creneauxEtSalles: value,
                              });
                          }}
                          title="
                          Créneaux et salles
                          "
                          value={selectedWorkshopData?.creneauxEtSalles || "-"}
                        />
                        {/*  */}
                        {/* <SinglePostInfoCardsItemData
                        isEditable={false}
                        title="Créneau souhaité"
                        value={
                          selectedWorkshopData?.workshopGeneralInfo
                            .workshopCreneauSouhaite || "-"
                        }
                      /> */}
                        <div
                          className="flex flex-row items-center justify-between gap-4 cursor-pointer"
                          onClick={() => {
                            setAreOldTimeSlotsDisplayed(
                              !areOldTimeSlotsDisplayed
                            );
                          }}
                        >
                          <div>
                            <span className="font-bold text-[#3C7179]">
                              Afficher les anciennes valeurs de créneaux ?
                            </span>
                            <span className="block text-sm font-normal text-[#3C7179]">
                              Si "Créneaux et salles" est renseigné, les champs
                              "Créneau X" ci-dessous ne seront pas pris en
                              compte.
                            </span>
                          </div>
                          {areOldTimeSlotsDisplayed ? (
                            <ChevronUpIcon className="text-gray-400 w-7" />
                          ) : (
                            <ChevronDownIcon className="text-gray-400 w-7" />
                          )}
                        </div>
                        {areOldTimeSlotsDisplayed && (
                          <div className="flex flex-col gap-y-8">
                            <div className="flex flex-col gap-y-4">
                              <div className="flex flex-row items-center gap-x-4">
                                <span className="font-bold text-[#3C7179]">
                                  Créneau 1
                                </span>
                                {dateString1 && (
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => {
                                      setStartDate1(null);

                                      selectedWorkshopData &&
                                        setSelectedWorkshopData({
                                          ...selectedWorkshopData,
                                          timeSlot1: "",
                                        });
                                    }}
                                  >
                                    <XCircleIcon className="w-5 text-red-500" />
                                  </span>
                                )}
                                <span
                                  className="cursor-pointer"
                                  onClick={() =>
                                    setIsDatepicker1Open(!isDatepicker1Open)
                                  }
                                >
                                  <PencilIcon className="w-5 text-gray-400" />
                                </span>
                              </div>
                              <DatePicker
                                open={isDatepicker1Open}
                                className="p-1 border-2 border-gray-300 rounded-md"
                                selected={startDate1}
                                onChange={(date) => setStartDate1(date)}
                                onClickOutside={() =>
                                  setIsDatepicker1Open(!isDatepicker1Open)
                                }
                                customInput={
                                  <input
                                    className="max-h-[32px] w-full max-w-[300px] grow cursor-pointer select-none rounded-full border-2 bg-gray-50 p-1 text-center text-[#0F766E] focus:outline-none"
                                    placeholder={
                                      selectedWorkshopData?.timeSlot1 !== "-"
                                        ? selectedWorkshopData?.timeSlot1
                                        : "Aucune date"
                                    }
                                  />
                                }
                                onInputClick={() =>
                                  setIsDatepicker1Open(!isDatepicker1Open)
                                }
                                // value={startDate ? dateString : "Aucune date"}
                                value={dateString1}
                                locale={fr}
                                timeFormat="HH:mm"
                                dateFormat="dd/MM/yyyy HH:mm"
                                showTimeInput
                                calendarContainer={CalendarContainer}
                              />
                            </div>
                            <div className="flex flex-col gap-y-4">
                              <div className="flex flex-row items-center gap-x-4">
                                <span className="font-bold text-[#3C7179]">
                                  Créneau 2
                                </span>
                                {dateString2 && (
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => {
                                      setStartDate2(null);

                                      selectedWorkshopData &&
                                        setSelectedWorkshopData({
                                          ...selectedWorkshopData,
                                          timeSlot2: "",
                                        });
                                    }}
                                  >
                                    <XCircleIcon className="w-5 text-red-500" />
                                  </span>
                                )}
                                <span
                                  className="cursor-pointer"
                                  onClick={() =>
                                    setIsDatepicker2Open(!isDatepicker2Open)
                                  }
                                >
                                  <PencilIcon className="w-5 text-gray-400" />
                                </span>
                              </div>
                              <DatePicker
                                open={isDatepicker2Open}
                                className="p-1 border-2 border-gray-300 rounded-md"
                                selected={startDate2}
                                onChange={(date) => setStartDate2(date)}
                                onClickOutside={() =>
                                  setIsDatepicker2Open(!isDatepicker2Open)
                                }
                                customInput={
                                  <input
                                    className="max-h-[32px] w-full max-w-[300px] grow cursor-pointer select-none rounded-full border-2 bg-gray-50 p-1 text-center text-[#0F766E] focus:outline-none"
                                    placeholder={
                                      selectedWorkshopData?.timeSlot2 !== "-"
                                        ? selectedWorkshopData?.timeSlot2
                                        : "Aucune date"
                                    }
                                  />
                                }
                                onInputClick={() =>
                                  setIsDatepicker2Open(!isDatepicker2Open)
                                }
                                // value={startDate ? dateString : "Aucune date"}
                                value={dateString2}
                                locale={fr}
                                timeFormat="HH:mm"
                                dateFormat="dd/MM/yyyy HH:mm"
                                showTimeInput
                                calendarContainer={CalendarContainer}
                              />
                            </div>
                            <div className="flex flex-col gap-y-4">
                              <div className="flex flex-row items-center gap-x-4">
                                <span className="font-bold text-[#3C7179]">
                                  Créneau 3
                                </span>
                                {dateString3 && (
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => {
                                      setStartDate3(null);

                                      selectedWorkshopData &&
                                        setSelectedWorkshopData({
                                          ...selectedWorkshopData,
                                          timeSlot3: "",
                                        });
                                    }}
                                  >
                                    <XCircleIcon className="w-5 text-red-500" />
                                  </span>
                                )}
                                <span
                                  className="cursor-pointer"
                                  onClick={() =>
                                    setIsDatepicker3Open(!isDatepicker3Open)
                                  }
                                >
                                  <PencilIcon className="w-5 text-gray-400" />
                                </span>
                              </div>
                              <DatePicker
                                open={isDatepicker3Open}
                                className="p-1 border-2 border-gray-300 rounded-md"
                                selected={startDate3}
                                onChange={(date) => setStartDate3(date)}
                                onClickOutside={() =>
                                  setIsDatepicker3Open(!isDatepicker3Open)
                                }
                                customInput={
                                  <input
                                    className="max-h-[32px] w-full max-w-[300px] grow cursor-pointer select-none rounded-full border-2 bg-gray-50 p-1 text-center text-[#0F766E] focus:outline-none"
                                    placeholder={
                                      selectedWorkshopData?.timeSlot3 !== "-"
                                        ? selectedWorkshopData?.timeSlot3
                                        : "Aucune date"
                                    }
                                  />
                                }
                                onInputClick={() =>
                                  setIsDatepicker3Open(!isDatepicker3Open)
                                }
                                // value={startDate ? dateString : "Aucune date"}
                                value={dateString3}
                                locale={fr}
                                timeFormat="HH:mm"
                                dateFormat="dd/MM/yyyy HH:mm"
                                showTimeInput
                                calendarContainer={CalendarContainer}
                              />
                            </div>
                            <div className="flex flex-col gap-y-4">
                              <div className="flex flex-row items-center gap-x-4">
                                <span className="font-bold text-[#3C7179]">
                                  Créneau 4
                                </span>
                                {dateString4 && (
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => {
                                      setStartDate4(null);

                                      selectedWorkshopData &&
                                        setSelectedWorkshopData({
                                          ...selectedWorkshopData,
                                          timeSlot4: "",
                                        });
                                    }}
                                  >
                                    <XCircleIcon className="w-5 text-red-500" />
                                  </span>
                                )}
                                <span
                                  className="cursor-pointer"
                                  onClick={() =>
                                    setIsDatepicker4Open(!isDatepicker4Open)
                                  }
                                >
                                  <PencilIcon className="w-5 text-gray-400" />
                                </span>
                              </div>
                              <DatePicker
                                open={isDatepicker4Open}
                                className="p-1 border-2 border-gray-300 rounded-md"
                                selected={startDate4}
                                onChange={(date) => setStartDate4(date)}
                                onClickOutside={() =>
                                  setIsDatepicker4Open(!isDatepicker4Open)
                                }
                                customInput={
                                  <input
                                    className="max-h-[32px] w-full max-w-[300px] grow cursor-pointer select-none rounded-full border-2 bg-gray-50 p-1 text-center text-[#0F766E] focus:outline-none"
                                    placeholder={
                                      selectedWorkshopData?.timeSlot4 !== "-"
                                        ? selectedWorkshopData?.timeSlot4
                                        : "Aucune date"
                                    }
                                  />
                                }
                                onInputClick={() =>
                                  setIsDatepicker4Open(!isDatepicker4Open)
                                }
                                // value={startDate ? dateString : "Aucune date"}
                                value={dateString4}
                                locale={fr}
                                timeFormat="HH:mm"
                                dateFormat="dd/MM/yyyy HH:mm"
                                showTimeInput
                                calendarContainer={CalendarContainer}
                              />
                            </div>
                            <div className="flex flex-col gap-y-4">
                              <div className="flex flex-row items-center gap-x-4">
                                <span className="font-bold text-[#3C7179]">
                                  Créneau 5
                                </span>
                                {dateString5 && (
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => {
                                      setStartDate5(null);

                                      selectedWorkshopData &&
                                        setSelectedWorkshopData({
                                          ...selectedWorkshopData,
                                          timeSlot5: "",
                                        });
                                    }}
                                  >
                                    <XCircleIcon className="w-5 text-red-500" />
                                  </span>
                                )}
                                <span
                                  className="cursor-pointer"
                                  onClick={() =>
                                    setIsDatepicker5Open(!isDatepicker5Open)
                                  }
                                >
                                  <PencilIcon className="w-5 text-gray-400" />
                                </span>
                              </div>
                              <DatePicker
                                open={isDatepicker5Open}
                                className="p-1 border-2 border-gray-300 rounded-md"
                                selected={startDate5}
                                onChange={(date) => setStartDate5(date)}
                                onClickOutside={() =>
                                  setIsDatepicker5Open(!isDatepicker5Open)
                                }
                                customInput={
                                  <input
                                    className="max-h-[32px] w-full max-w-[300px] grow cursor-pointer select-none rounded-full border-2 bg-gray-50 p-1 text-center text-[#0F766E] focus:outline-none"
                                    placeholder={
                                      selectedWorkshopData?.timeSlot5 !== "-"
                                        ? selectedWorkshopData?.timeSlot5
                                        : "Aucune date"
                                    }
                                  />
                                }
                                onInputClick={() =>
                                  setIsDatepicker5Open(!isDatepicker5Open)
                                }
                                // value={startDate ? dateString : "Aucune date"}
                                value={dateString5}
                                locale={fr}
                                timeFormat="HH:mm"
                                dateFormat="dd/MM/yyyy HH:mm"
                                showTimeInput
                                calendarContainer={CalendarContainer}
                              />
                            </div>
                          </div>
                        )}
                        {/* <SinglePostInfoCardsItemData
                        isEditable={false}
                        title="Horaire validé"
                        value={selectedWorkshopData?.info || "-"}
                      /> */}
                        <hr />
                        <SinglePostInfoCardsItemData
                          isEditable={false}
                          title="Commentaire exposant"
                          value={selectedWorkshopData?.exhibitorComments || "-"}
                        />
                        {/* Comment admin */}
                        <SinglePostInfoCardsItemData
                          isEditable={true}
                          title="Commentaire admin"
                          value={selectedWorkshopData?.adminComments || "-"}
                          setValue={(value) => {
                            selectedWorkshopData &&
                              setSelectedWorkshopData({
                                ...selectedWorkshopData,
                                adminComments: value,
                              });
                          }}
                        />
                      </div>
                    </CardWithHeader>
                  </div>
                  {/* Right */}
                  <div className="flex flex-col col-span-1">
                    <CardWithHeader title="Photos" justifyCenter={false}>
                      {/* {selectedWorkshopData?.adsProduct ? ( */}
                      <div className="flex flex-col gap-y-10">
                        <div className="flex flex-col gap-y-4">
                          {/* Photo conferencier */}
                          <div className="flex flex-col gap-y-4">
                            <div className="flex flex-row items-center gap-x-2 gap-y-0">
                              <span className="font-bold text-[#3C7179]">
                                Photo de l'organisateur
                              </span>
                              {selectedWorkshopData?.workshopGeneralInfo
                                .photoDeLorganisateurDeLatelier?.url && (
                                <div className="flex flex-col items-start">
                                  <button
                                    className="flex items-center justify-center rounded-full bg-[#14B8A6] p-2 text-white shadow"
                                    onClick={() => {
                                      openModal();
                                    }}
                                  >
                                    <EyeIcon className="w-5" />
                                  </button>
                                </div>
                              )}
                            </div>
                            <div className="flex flex-col justify-between w-full">
                              <div className="flex flex-row gap-x-2">
                                <CustomDropzone
                                  files={
                                    selectedWorkshopData?.workshopGeneralInfo
                                      .photoDeLorganisateurDeLatelier?.url
                                      ? [
                                          {
                                            name: selectedWorkshopData
                                              ?.workshopGeneralInfo
                                              .photoDeLorganisateurDeLatelier
                                              ?.url,
                                            preview:
                                              selectedWorkshopData
                                                ?.workshopGeneralInfo
                                                .photoDeLorganisateurDeLatelier
                                                ?.url,
                                          },
                                        ]
                                      : []
                                  }
                                  setFiles={setFilesExhibitor}
                                  setValues={(value) =>
                                    selectedWorkshopData &&
                                    setSelectedWorkshopData({
                                      ...selectedWorkshopData,
                                      workshopGeneralInfo: {
                                        ...selectedWorkshopData.workshopGeneralInfo,
                                        photoDeLorganisateurDeLatelier: {
                                          url: value,
                                        },
                                      },
                                    })
                                  }
                                />
                              </div>
                            </div>
                            {/* <div className="flex flex-row items-stretch gap-x-2">
                            <div className="">
                              <img
                                src={
                                  selectedWorkshopData?.workshopGeneralInfo
                                    .photoDeLorganisateurDeLatelier?.url
                                }
                                alt=""
                              />
                            </div>
                            <div className="flex flex-col justify-between">
                              <div className="flex flex-row gap-x-2">
                                <button className="flex items-center justify-center rounded-full bg-[#14B8A6] p-2 text-white shadow">
                                  <PencilIcon className="w-5" />
                                </button>
                                <button className="flex items-center justify-center rounded-full bg-[#14B8A6] p-2 text-white shadow">
                                  <TrashIcon className="w-5" />
                                </button>
                              </div>
                              <div className="flex flex-col items-start">
                                <button
                                  className="flex items-center justify-center rounded-full bg-[#14B8A6] p-2 text-white shadow"
                                  onClick={() => {
                                    openModal();
                                  }}
                                >
                                  <EyeIcon className="w-5" />
                                </button>
                              </div>
                            </div>
                          </div> */}
                          </div>
                        </div>
                        <div className="flex flex-col gap-y-4">
                          {/* Photo conferencier */}
                          <div className="flex flex-col gap-y-4">
                            <div className="flex flex-row items-center gap-x-2 gap-y-0">
                              <span className="font-bold text-[#3C7179]">
                                Photo de l'atelier
                              </span>
                              {selectedWorkshopData?.workshopGeneralInfo
                                .photoDeLatelier?.url && (
                                <div className="flex flex-col items-start">
                                  <button
                                    className="flex items-center justify-center rounded-full bg-[#14B8A6] p-2 text-white shadow"
                                    onClick={() => {
                                      openModalWorkshop();
                                    }}
                                  >
                                    <EyeIcon className="w-5" />
                                  </button>
                                </div>
                              )}
                            </div>
                            <div className="flex flex-col justify-between w-full">
                              <div className="flex flex-row gap-x-2">
                                <CustomDropzone
                                  files={
                                    selectedWorkshopData?.workshopGeneralInfo
                                      .photoDeLatelier?.url
                                      ? [
                                          {
                                            name: selectedWorkshopData
                                              ?.workshopGeneralInfo
                                              .photoDeLatelier?.url,
                                            preview:
                                              selectedWorkshopData
                                                ?.workshopGeneralInfo
                                                .photoDeLatelier?.url,
                                          },
                                        ]
                                      : []
                                  }
                                  setFiles={setFilesWorkshop}
                                  setValues={(value) =>
                                    selectedWorkshopData &&
                                    setSelectedWorkshopData({
                                      ...selectedWorkshopData,
                                      workshopGeneralInfo: {
                                        ...selectedWorkshopData.workshopGeneralInfo,
                                        photoDeLatelier: {
                                          url: value,
                                        },
                                      },
                                    })
                                  }
                                />
                              </div>
                            </div>
                            {/* <div className="flex flex-row items-stretch gap-x-2">
                            <div className="">
                              <img
                                src={
                                  selectedWorkshopData?.workshopGeneralInfo
                                    .photoDeLatelier?.url
                                }
                                alt=""
                              />
                            </div>
                            <div className="flex flex-col justify-between">
                              <div className="flex flex-row gap-x-2">
                                <button className="flex items-center justify-center rounded-full bg-[#14B8A6] p-2 text-white shadow">
                                  <PencilIcon className="w-5" />
                                </button>
                                <button className="flex items-center justify-center rounded-full bg-[#14B8A6] p-2 text-white shadow">
                                  <TrashIcon className="w-5" />
                                </button>
                              </div>
                              <div className="flex flex-col items-start">
                                <button
                                  className="flex items-center justify-center rounded-full bg-[#14B8A6] p-2 text-white shadow"
                                  onClick={() => {
                                    openModalWorkshop();
                                  }}
                                >
                                  <EyeIcon className="w-5" />
                                </button>
                              </div>
                            </div>
                          </div> */}
                          </div>
                        </div>
                      </div>
                      {/* ) : null} */}
                    </CardWithHeader>
                  </div>
                </div>
              </div>
            </>
            {/* )} */}
          </div>
        )}
      </>
    </PageLayout>
  );
};

export default SingleWorkshopScreen;
