import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  SortingState,
} from "@tanstack/react-table";
import { TableProps } from "../components/Table";
import { useRecoilState } from "recoil";
import { isLoadingAtom } from "../../atoms/ui-atoms";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import SectionTitle from "../components/SectionTitle";

interface TableWithTitleProps extends TableProps {
  title?: string;
  subtitle?: string;
  numberOfItems?: number;
  filtersComponents?: JSX.Element;
  paginationButtons?: JSX.Element;
  dropdown?: JSX.Element;
}

const LoadingTable = ({
  data,
  columns,
  sorting,
  setSorting,
  title,
  subtitle,
  // numberOfItems,
  filtersComponents,
  paginationButtons,
  dropdown,
}: TableWithTitleProps) => {
  const [isLoading, setIsLoading] = useRecoilState(isLoadingAtom);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  return (
    <div className="flex flex-col gap-y-6">
      <div className="relative z-10 flex flex-col">
        <>
          {title && <SectionTitle title={title} />}
          {subtitle && <p className="text-sm text-gray-500">{subtitle}</p>}
        </>
        {/* {filters && <FiltersSelects />} */}
        {filtersComponents && <div className="">{filtersComponents}</div>}
        {dropdown && <div className="mb-4 text-right">{dropdown}</div>}
        {paginationButtons && paginationButtons}
      </div>
      <div className="relative">
        <table className="w-full border-collapse overflow-hidden rounded-lg bg-[#14B8A6] shadow-md">
          <thead className="">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    className="border-none p-6 text-lg font-bold uppercase text-[#ffffff] 2xl:text-xl"
                    key={header.id}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? "cursor-pointer select-none flex flex-row items-center justify-center gap-x-2"
                            : "flex flex-row items-center justify-center gap-x-2",
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: <ChevronUpIcon className="w-6 h-6" />,
                          desc: <ChevronDownIcon className="w-6 h-6" />,
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="bg-white">
            {/* {Array.from(Array(numberOfItems ?? 10).keys()).map((i) => ( */}
            {Array.from(Array(25).keys()).map((i) => (
              <tr key={i} className="h-[76px]">
                {table.getRowModel().rows.map((row, index) => (
                  <td
                    key={index}
                    className="border-none p-6 text-lg font-bold uppercase text-[#ffffff] 2xl:text-xl"
                    style={{
                      backgroundColor: i % 2 === 0 ? "#fff" : "#f7f5ef",
                    }}
                  >
                    <div className="flex flex-row items-center justify-center animate-pulse gap-x-2">
                      <div className="w-20 h-4 bg-gray-200 rounded-full"></div>
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LoadingTable;
