interface SingleOrderFooterTableButtonProps {
  title: string;
  action: () => void;
  disabled?: boolean;
}

const SingleOrderFooterTableButton = ({
  title,
  action,
  disabled,
}: SingleOrderFooterTableButtonProps) => {
  return (
    <button
      type="button"
      className="text-md rounded-md border-none bg-[#3C7179] py-4 px-8 font-semibold text-white shadow"
      onClick={action}
      disabled={disabled}
    >
      {title}
    </button>
  );
};

export default SingleOrderFooterTableButton;

// Path: src/features/orders/components/SingleOrderFooterTableButton.tsx
