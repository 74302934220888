export const returnPriceFormattedCHF = (
  price: number,
  displayCurrency = true
) => {
  let isNegative = false;
  // Remove sign if negative
  if (price < 0) {
    price = price * -1;
    isNegative = true;
  }
  const formattedNumber = price.toLocaleString("de-CH", {
    style: "currency",
    currency: "CHF",
    currencyDisplay: displayCurrency ? "symbol" : undefined,
  });

  // Remove 'CHF' from the string
  // const formattedNumberWithoutCHF = formattedNumber.replace("CHF", "");

  // If negative append '-' to the beginning of the string
  if (isNegative) {
    return `- ${formattedNumber}`;
  }

  return formattedNumber;
};

export const returnPriceFormattedCHFForInvoice = (
  price: number,
  displayCurrency = true
) => {
  let isNegative = false;
  // Remove sign if negative
  if (price < 0) {
    price = price * -1;
    isNegative = true;
  }
  const formattedNumber = price.toLocaleString("de-CH", {
    style: "currency",
    currency: "CHF",
    currencyDisplay: displayCurrency ? "symbol" : undefined,
  });

  // Remove 'CHF' from the string
  const formattedNumberWithoutCHF = formattedNumber.replace("CHF", "");

  // If negative append '-' to the beginning of the string
  if (isNegative) {
    // return `- ${formattedNumber}`;
    return `- ${formattedNumberWithoutCHF}`;
  }

  // return formattedNumber;
  return formattedNumberWithoutCHF;
};
