import { XMarkIcon } from "@heroicons/react/24/outline";
import { ReactNode } from "react";

interface ActionPopupProps {
  title: string;
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  children: React.ReactNode;
  action?: () => void;
  cancelAction?: () => void;
  actionButtonTitle?: string | ReactNode;
  actionButtonDisabled?: boolean;
  cancelButtonVisible?: boolean;
  actionButtonsVisible?: boolean;
  forQuickView?: boolean;
  actionButtonBackgroundColor?: string;
  actionRunning?: boolean;
}

const ActionPopup = ({
  title,
  isVisible,
  setIsVisible,
  children,
  action,
  cancelAction,
  actionButtonTitle,
  actionButtonDisabled,
  cancelButtonVisible = true,
  actionButtonsVisible = true,
  forQuickView = false,
  actionButtonBackgroundColor = "#3C7179",
  actionRunning = false,
}: ActionPopupProps) => {
  return (
    <div
      // style={{ display: isVisible ? "fixed" : "none" }}
      className={`fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center ${
        isVisible ? "fixed" : "hidden"
      }`}
    >
      <div
        className="fixed top-0 left-0 z-0 w-full h-full bg-black opacity-50"
        onClick={() => setIsVisible(false)}
      />
      {/* <div className="relative z-10 flex flex-col w-1/2 p-8 bg-white rounded-lg shadow-lg gap-y-6"> */}
      <div className="relative z-10 flex w-[90%] max-w-[900px] flex-col gap-y-6 rounded-lg bg-white p-8 shadow-lg">
        {/* Title and close */}
        <div
          className={`flex flex-row ${
            // forQuickView ? "items-start" : "items-center"
            "items-center"
          } justify-between`}
        >
          {!forQuickView && <h2 className="text-2xl font-semibold">{title}</h2>}
          {forQuickView && (
            <div className="mx-4 w-full rounded bg-[#3C7179] px-2 py-4 text-center uppercase tracking-widest text-white shadow">
              <h2 className="text-2xl font-semibold">{title}</h2>
            </div>
          )}
          <button
            title="Fermer le popup"
            type="button"
            className="border-none bg-none"
            onClick={() => setIsVisible(false)}
          >
            <XMarkIcon className="w-6 h-6" />
          </button>
        </div>
        {/* Content */}
        <div className="flex flex-col flex-1 gap-y-4">{children}</div>
        {/* Close */}
        {actionButtonsVisible && (
          <div className="flex flex-row justify-end mt-4 gap-x-6">
            {cancelButtonVisible && (
              <button
                type="button"
                disabled={actionRunning}
                className={` ${
                  actionRunning && "cursor-not-allowed opacity-40"
                } border-none bg-none`}
                onClick={cancelAction}
              >
                <span className="text-lg font-semibold text-[#3C7179]">
                  Annuler
                </span>
              </button>
            )}
            <button
              type="button"
              className={`rounded-md border-none px-8 py-4 text-lg font-semibold text-white`}
              style={{
                backgroundColor: actionButtonBackgroundColor,
              }}
              onClick={action}
              disabled={actionButtonDisabled}
            >
              {actionButtonTitle}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ActionPopup;

// Path: src/shared/ui/components/ActionPopup.tsx
