interface SectionTitleProps {
  title: string;
}

const SectionTitle = ({ title }: SectionTitleProps) => {
  return <span className="text-3xl font-bold text-[#3C7179]">{title}</span>;
};

export default SectionTitle;

// Path: src/shared/ui/components/OverviewBoxComponent.tsx
