import { atom } from "recoil";

export const exportConferencesOptionsAtom = atom<string[]>({
  key: "exportConferencesOptionsAtom",
  default: [],
});

export const exportWorkshopOptionsAtom = atom<string[]>({
  key: "exportWorkshopOptionsAtom",
  default: [],
});

export const exportCaresOptionsAtom = atom<string[]>({
  key: "exportCaresOptionsAtom",
  default: [],
});

export const exportStandsOptionsAtom = atom<string[]>({
  key: "exportStandsOptionsAtom",
  default: [],
});

export const exportOrdersOptionsAtom = atom<string[]>({
  key: "exportOrdersOptionsAtom",
  default: [],
});

export const exportAdsOptionsAtom = atom<string[]>({
  key: "exportAdsOptionsAtom",
  default: [],
});

export const exportUsersOptionsAtom = atom<string[]>({
  key: "exportUsersOptionsAtom",
  default: [],
});

export const exportVisitorsOptionsAtom = atom<string[]>({
  key: "exportVisitorsOptionsAtom",
  default: [],
});

export const exportTicketsOptionsAtom = atom<string[]>({
  key: "exportTicketsOptionsAtom",
  default: [],
});
