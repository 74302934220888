import apiFetch from "@wordpress/api-fetch";
import { CustomerProps } from "../../../shared/types/sharedTypes";
import { returnRootUrlBasedOnAppLocation } from "../../../shared/utils/checkIfIsInlocal";
import { ExhibitionsListingProps } from "../screens/ExhibitionsScreen";

// Create an api call with @wordpress/api-fetch for the endpoint admin-dashboard/v1/orders/
export const fetchAllExhibitions = async (): Promise<
  ExhibitionsListingProps[]
> => {
  let token = "";
  if (localStorage.getItem("token-dashboard")) {
    token = JSON.parse(localStorage.getItem("token-dashboard") as string);
  }
  const response = await apiFetch({
    path: `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v1/exhibitions-listing`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response as ExhibitionsListingProps[];
};
