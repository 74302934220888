import React, { useState, useEffect, useRef, useCallback } from "react";
import axios, { CancelTokenSource } from "axios";
import { returnRootUrlBasedOnAppLocation } from "../../utils/checkIfIsInlocal";
import { useNavigate } from "react-router-dom";

interface SearchResult {
  id: string;
  type: string;
  typeBgColor: string;
  typeTextColor: string;
  title: string;
  exhibition: string;
  url: string;
  exhibitionPrimaryColor: string;
  exhibitionSecondaryColor: string;
}

const debounce = <T extends (...args: any[]) => any>(
  func: T,
  delay: number
): T => {
  let timer: ReturnType<typeof setTimeout>;
  return ((...args: any[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => func(...args), delay);
  }) as T;
};

interface SearchResultItemProps {
  id: string;
  title: string;
  type: string;
  url: string;
  typeBgColor: string;
  typeTextColor: string;
  exhibition: string;
  exhibitionPrimaryColor: string;
  exhibitionSecondaryColor: string;
}
const SearchResultsItem = ({
  id,
  title = "-",
  type = "-",
  url,
  typeBgColor = "#000",
  typeTextColor = "#fff",
  exhibition,
  exhibitionPrimaryColor,
  exhibitionSecondaryColor,
}: SearchResultItemProps) => {
  return (
    <div className="flex flex-col items-start justify-start">
      <div className="flex flex-row items-center gap-2">
        <span className={`text-lg font-bold text-gray-800`}>{title}</span>
      </div>
      <div className="flex flex-row items-center gap-x-4">
        <span
          //   style={{ backgroundColor: typeBgColor, color: typeTextColor }}
          className={`inline rounded-md border border-gray-400 p-2 text-xs font-semibold uppercase text-gray-400 shadow`}
        >
          {type}
        </span>
        {exhibition && (
          <span
            style={{
              backgroundColor: exhibitionPrimaryColor,
              //   color: exhibitionSecondaryColor,
            }}
            className={`inline rounded-md p-2 text-xs font-semibold uppercase text-white shadow`}
          >
            {exhibition}
          </span>
        )}
      </div>
    </div>
  );
};

interface LoadingProps {
  isFetching: boolean;
}

const Loading = ({ isFetching }: LoadingProps) => {
  return (
    <>
      {isFetching && (
        <div className="flex w-full flex-col items-center justify-center">
          <span>Chargement...</span>
        </div>
      )}
    </>
  );
};

interface NoDataProps {
  searchTerm: string;
  searchResults: SearchResult[];
  isFetching: boolean;
  previousSearchTerm: string;
  lastCompletedRequestNumber: number;
  currentRequestNumber: number;
}

const NoData = ({
  searchTerm,
  searchResults,
  isFetching,
  previousSearchTerm,
  lastCompletedRequestNumber,
  currentRequestNumber,
}: NoDataProps) => {
  return (
    <>
      {searchTerm && (
        <div className="flex w-full flex-col items-center justify-center">
          {searchTerm.length < 3 ? (
            <span>
              Veuillez saisir au moins 3 caractères pour effectuer une recherche
            </span>
          ) : searchResults.length === 0 &&
            !isFetching &&
            lastCompletedRequestNumber === currentRequestNumber &&
            searchTerm === previousSearchTerm ? (
            <span>Aucun résultat</span>
          ) : null}
        </div>
      )}
    </>
  );
};

interface SearchResultsProps {
  searchResults: SearchResult[];
  navigate: any;
  setSearchResults: any;
  setSearchTerm: any;
  isFetching: boolean; // Define isFetching as a prop
}

const SearchResults = ({
  searchResults,
  navigate,
  setSearchResults,
  setSearchTerm,
  isFetching, // Add isFetching as a prop
}: SearchResultsProps) => {
  return (
    <>
      {!isFetching &&
        searchResults.map((result, index) => {
          return (
            <div
              key={index}
              className="duration-3 cursor-pointer p-4 transition-all hover:bg-gray-100"
              onClick={() => {
                navigate("../" + result.url, { replace: true });
                window.location.reload();
                setSearchResults([]);
                setSearchTerm(null);
              }}
            >
              <SearchResultsItem
                id={result.id}
                type={result.type}
                typeBgColor={result.typeBgColor}
                typeTextColor={result.typeTextColor}
                title={result.title}
                exhibition={result.exhibition}
                url={result.url}
                key={result.id}
                exhibitionPrimaryColor={result.exhibitionPrimaryColor}
                exhibitionSecondaryColor={result.exhibitionSecondaryColor}
              />
            </div>
          );
        })}
    </>
  );
};

// ... (import statements remain unchanged)

const SearchComponent: React.FC = () => {
  let navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const searchResultsRef = useRef<HTMLDivElement | null>(null);
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const axiosCancelToken = useRef<CancelTokenSource | null>(null);
  const [hasSearched, setHasSearched] = useState<boolean>(false);
  const [isPending, setIsPending] = useState<boolean>(false);

  const fetchResults = useCallback((searchTerm: string) => {
    setIsLoading(true);
    setIsPending(false); // Ajoutez cette ligne
    setHasSearched(true);

    if (axiosCancelToken.current) {
      axiosCancelToken.current.cancel("Canceled due to new request");
    }

    axiosCancelToken.current = axios.CancelToken.source();

    axios
      .get(`${returnRootUrlBasedOnAppLocation()}admin-dashboard/v2/search`, {
        params: {
          query_string: searchTerm,
        },
        cancelToken: axiosCancelToken.current.token,
      })
      .then((res) => {
        setSearchResults(res.data);
        setIsLoading(false); // Mettez à jour ici, indépendamment du nombre de résultats
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("Request was canceled", err.message);
        } else {
          console.error("Error fetching results:", err);
        }
      });
  }, []);

  const debouncedFetchResults = debounce(fetchResults, 500);

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (
      searchResultsRef.current &&
      !searchResultsRef.current.contains(event.target as Node)
    ) {
      setSearchTerm(null);
      setSearchResults([]);
      if (searchInputRef.current) {
        searchInputRef.current.value = "";
      }
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <div className="z-100 relative grow">
      <input
        type="text"
        ref={searchInputRef}
        placeholder="Rechercher"
        className="w-full rounded-md bg-white p-2 shadow"
        onChange={(e) => {
          setSearchTerm(e.currentTarget.value);
          setHasSearched(true);
          if (e.currentTarget.value.length >= 3) {
            setIsLoading(true); // Ajoutez cette ligne ici
            setIsPending(true);
            debouncedFetchResults(e.currentTarget.value);
          } else {
            setSearchResults([]);
            setIsPending(false);
            setIsLoading(false); // Réinitialisez isLoading ici aussi
          }
        }}
      />
      {searchTerm && (
        <div
          ref={searchResultsRef}
          className="absolute inset-x-0 z-50 mt-2 flex min-h-[400px] w-full justify-between rounded-md bg-white shadow"
        >
          <div id="searchResults" className="w-full">
            <div className="p-4">
              <span>
                Résultats de recherche pour "{searchTerm ? searchTerm : "..."}"
              </span>
            </div>
            <hr className="h-1" />
            <div
              id="search-results"
              className="flex h-full max-h-[350px] min-h-[350px] flex-col overflow-auto"
            >
              {isLoading ? (
                <Loading isFetching={isLoading} />
              ) : !isLoading &&
                !isPending &&
                searchResults.length === 0 &&
                hasSearched ? (
                <NoData
                  searchTerm={searchTerm}
                  searchResults={searchResults}
                  isFetching={isLoading}
                  previousSearchTerm={searchTerm}
                  lastCompletedRequestNumber={0}
                  currentRequestNumber={0}
                />
              ) : (
                <SearchResults
                  isFetching={isLoading}
                  searchResults={searchResults}
                  navigate={navigate}
                  setSearchResults={setSearchResults}
                  setSearchTerm={setSearchTerm}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchComponent;
