import { useRecoilState } from "recoil";
import {
  recalculateVatButtonClickedAtom,
  singleOrderLineItemsDataAtom,
} from "../../../../atoms/orders-atoms";

const SingleOrderPopupRecalculate = () => {
  const [singleOrderLineItemsData, setSingleOrderLineItemsData] =
    useRecoilState(singleOrderLineItemsDataAtom);
  const [recalculateVatButtonClicked, setRecalculateVatButtonClicked] =
    useRecoilState(recalculateVatButtonClickedAtom);
  return (
    <div className="flex flex-col gap-y-6">
      <span>
        {/* En recalculant la TVA, les modifications de TVA apportées aux produits
        seront écrasées par le montant de TVA correspondant au prix HT du
        produit. */}
        Recalculer les totaux ? Cela calculera la taxe sur la base de 7.7% et
        actualisera les totaux.
      </span>
      <div className="mt-4 flex flex-row justify-end gap-x-6">
        <button
          type="button"
          className="border-none bg-none"
          onClick={() => {
            // setSelectedOrderAddNoteButtonClicked(
            //   !selectedOrderAddNoteButtonClicked
            // );
            setRecalculateVatButtonClicked(false);
          }}
        >
          <span className="text-lg font-semibold text-[#3C7179]">Annuler</span>
        </button>
        <button
          type="button"
          className="rounded-md border-none bg-[#3C7179] py-4 px-8 text-lg font-semibold text-white disabled:opacity-50"
          onClick={() => {
            const newSingleOrderLineItemsData = singleOrderLineItemsData.map(
              (item) => {
                const vat = item.regularPrice * 0.077;
                return {
                  ...item,
                  vat,
                };
              }
            );
            setSingleOrderLineItemsData(newSingleOrderLineItemsData);
            setRecalculateVatButtonClicked(false);
          }}
        >
          {/* Ajouter */}
          Valider
        </button>
      </div>
    </div>
  );
};

export default SingleOrderPopupRecalculate;
