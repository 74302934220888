import { useEffect, useState } from "react";
import OverviewBoxesWithTitleLayout from "./OverviewBoxesWithTitleLayout";
import { fetchExhibitionIdAndOrdersCount } from "../../../features/orders/api/fetchOrders";
import { useNavigate } from "react-router-dom";
import { fetchPostsCount } from "../../api/fetchPostsCount";

interface ExhibitionCountProps {
  exhibition_name: string;
  exhibition_year: string;
  exhibition_primary_color: string;
  exhibition_secondary_color: string;
  postsCount: number;
  loading: boolean;
  id: number;
}

interface OverviewBoxesLayoutProps {
  title?: string;
  customGlobalLabel?: string;
  // exhibitions?: ExhibitionCountProps[];
  postType?: string;
}

const init: ExhibitionCountProps[] = [
  {
    exhibition_name: "Morges",
    exhibition_year: "-",
    exhibition_primary_color: "#89a8c9",
    exhibition_secondary_color: "#e1e7f4",
    postsCount: 0,
    loading: true,
    id: 0,
  },
  {
    exhibition_name: "Bulle",
    exhibition_year: "-",
    exhibition_primary_color: "#c87575",
    exhibition_secondary_color: "#f1d9d9",
    postsCount: 0,
    loading: true,
    id: 0,
  },
  {
    exhibition_name: "Aigle",
    exhibition_year: "-",
    exhibition_primary_color: "#e3ab50",
    exhibition_secondary_color: "#fee5b8",
    postsCount: 0,
    loading: true,
    id: 0,
  },
  {
    exhibition_name: "Genève",
    exhibition_year: "-",
    exhibition_primary_color: "#87ac96",
    exhibition_secondary_color: "#d6e9be",
    postsCount: 0,
    loading: true,
    id: 0,
  },
];
const OverviewBoxesLayout = ({
  title = "Commandes en cours",
  customGlobalLabel = "",
  postType = "stand",
}: // exhibitions = init,
  OverviewBoxesLayoutProps) => {
  const [exhibitions, setExhibitions] = useState<any[]>(init);

  useEffect(() => {
    const fetchAllExhibitionsData = async () => {
      fetchPostsCount(postType).then((data) => {
        setExhibitions(data as any);
      });
    };

    fetchAllExhibitionsData();
  }, []);

  let navigate = useNavigate();

  const clickOnSeeExhibition = (exhibitionId: number) => {
    if (exhibitionId !== 0) {
      navigate(`/salons/${exhibitionId}`);
    } else {
      alert("Ce salon n'est pas encore ouvert");
    }
  };

  const children = exhibitions.map((exhibition) => {
    return {
      title: customGlobalLabel || exhibition.exhibition_name,
      label: exhibition.exhibition_year,
      primaryColor: exhibition.exhibition_primary_color,
      secondaryColor: exhibition.exhibition_secondary_color,
      value: exhibition.postsCount,
      loading: exhibition.loading,
      actionFunction: () => {
        exhibition.id ? clickOnSeeExhibition(exhibition.id) : alert("Une erreur est survenue");
      },
    };
  });

  return <OverviewBoxesWithTitleLayout title={title} children={children} />;
};

export default OverviewBoxesLayout;
