import apiFetch from "@wordpress/api-fetch";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { sortableOrderStatuses } from "../../../shared/constants/orderStatuses";
import ClearTableFilters from "../../../shared/ui/components/ClearTableFilters";
import FiltersSelectComponent from "../../../shared/ui/components/FiltersSelectComponent";
import { returnRootUrlBasedOnAppLocation } from "../../../shared/utils/checkIfIsInlocal";
import {
  exhibitionsAtom,
  filteredExhibitionsAtom,
} from "../atoms/exhibitions-atoms";

const exhibitions = ["Tous les salons", "Bulle", "Morges", "Aigle", "Genève"];
const statuses = [
  "Tous les statuts",
  "Non renseigné",
  "En attente de validation",
  "Approuvé",
  "À modifier",
];
let years = ["Toutes les années"];

const FiltersExhibitionsSelects = () => {
  const [selectedExhibitions, setSelectedExhibitions] = useState(
    exhibitions[0]
  );
  const [selectedStatus, setSelectedStatus] = useState(statuses[0]);
  const [exhibitionsData, setExhibitionsData] = useRecoilState(exhibitionsAtom);
  const exhibitionsYears = exhibitionsData.map(
    (exhibition) => exhibition.exhibition.year
  );
  // Remove duplicates
  const exhibitionsYearsUnique = exhibitionsYears.filter(
    (year, index) => exhibitionsYears.indexOf(year) === index
  ) as string[];

  // Merge the years array with the years from the exhibitions
  years = years.concat(exhibitionsYearsUnique);

  const [selectedExhibitionYear, setSelectedExhibitionYear] = useState(
    years[0]
  );
  // Insert 'Toutes les années' at the beginning of the array
  exhibitionsYearsUnique.unshift("Toutes les années");
  // Sort the array in descending order
  exhibitionsYearsUnique.sort((a, b) => {
    return parseInt(b) - parseInt(a);
  });
  const [filteredExhibitionsData, setFilteredExhibitionsData] = useRecoilState(
    filteredExhibitionsAtom
  );

  useEffect(() => {
    if (exhibitionsData) {
      if (exhibitionsData.length > 0) {
        if (localStorage.getItem("exhibition-selected-year")) {
          setSelectedExhibitionYear(
            localStorage.getItem("exhibition-selected-year") as string
          );
        }
        if (localStorage.getItem("exhibition-selected-exhibition")) {
          setSelectedExhibitions(
            localStorage.getItem("exhibition-selected-exhibition") as string
          );
        }
      }
    }
  }, [exhibitionsData]);

  // Get all exhibitions exhibition name to extract the years and save them in an array

  useEffect(() => {
    if (selectedExhibitions === exhibitions[0]) {
      if (selectedStatus === statuses[0]) {
        if (selectedExhibitionYear === exhibitionsYearsUnique[0]) {
          setFilteredExhibitionsData(null);
        } else {
          setFilteredExhibitionsData(
            exhibitionsData.filter((exhibition) =>
              exhibition.exhibition.year?.includes(selectedExhibitionYear)
            )
          );
        }
      } else {
        if (selectedExhibitionYear === exhibitionsYearsUnique[0]) {
          setFilteredExhibitionsData(
            exhibitionsData.filter((exhibition) =>
              exhibition.status?.includes(selectedStatus)
            )
          );
        } else {
          setFilteredExhibitionsData(
            exhibitionsData.filter(
              (exhibition) =>
                exhibition.status?.includes(selectedStatus) &&
                exhibition.exhibition.year?.includes(selectedExhibitionYear)
            )
          );
        }
      }
    } else {
      if (selectedStatus === statuses[0]) {
        if (selectedExhibitionYear === exhibitionsYearsUnique[0]) {
          setFilteredExhibitionsData(
            exhibitionsData.filter((exhibition) =>
              exhibition.exhibition.name.includes(selectedExhibitions)
            )
          );
        } else {
          setFilteredExhibitionsData(
            exhibitionsData.filter(
              (exhibition) =>
                exhibition.exhibition.name.includes(selectedExhibitions) &&
                exhibition.exhibition.year?.includes(selectedExhibitionYear)
            )
          );
        }
      } else {
        if (selectedExhibitionYear === exhibitionsYearsUnique[0]) {
          setFilteredExhibitionsData(
            exhibitionsData.filter(
              (exhibition) =>
                exhibition.status?.includes(selectedStatus) &&
                exhibition.exhibition.name.includes(selectedExhibitions)
            )
          );
        } else {
          setFilteredExhibitionsData(
            exhibitionsData.filter(
              (exhibition) =>
                exhibition.status?.includes(selectedStatus) &&
                exhibition.exhibition.name.includes(selectedExhibitions) &&
                exhibition.exhibition.year?.includes(selectedExhibitionYear)
            )
          );
        }
      }
    }
  }, [selectedExhibitions, selectedExhibitionYear]);

  const resetFilters = () => {
    setSelectedExhibitions(exhibitions[0]);
    setSelectedExhibitionYear(exhibitionsYearsUnique[0]);
  };

  const localStorageFiltersKeys = [
    "exhibition-selected-year",
    "exhibition-selected-exhibition",
  ];

  return (
    <div className="grow">
      <div className="flex flex-row items-center justify-end gap-x-6">
        <ClearTableFilters
          resetFilters={resetFilters}
          localStorageFiltersKeys={localStorageFiltersKeys}
        />
        <FiltersSelectComponent
          options={exhibitionsYearsUnique}
          selected={selectedExhibitionYear}
          setSelected={setSelectedExhibitionYear}
          type="year"
          postType="exhibition"
        />
        <FiltersSelectComponent
          options={exhibitions}
          selected={selectedExhibitions}
          setSelected={setSelectedExhibitions}
          type="exhibition"
          postType="exhibition"
        />
      </div>
    </div>
  );
};

export default FiltersExhibitionsSelects;
