import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  availableProductsAtoms,
  singleOrderLineItemsNewProductsSelectedInPopupAtom,
} from "../../../../atoms/orders-atoms";
import { Product } from "../../../../types/orders";
import SingleOrderPopUpAddProductLineItem from "./SingleOrderPopUpAddProductLineItem";

const SingleOrderPopupAddProduct = () => {
  // const [availableProducts, setAvailableProducts] =
  //   useState<Product[]>(defaultProducts);
  // const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
  const availableProducts = useRecoilValue<Product[]>(availableProductsAtoms);
  const [
    singleOrderLineItemsNewDataSelected,
    setSingleOrderLineItemsNewDataSelected,
  ] = useRecoilState(singleOrderLineItemsNewProductsSelectedInPopupAtom);

  return (
    <div className="flex flex-col gap-y-6">
      {/* Header */}
      <div className="flex flex-col gap-y-4">
        <div className="flex flex-row items-center justify-between">
          <span>Produit</span>
          <span>
            {singleOrderLineItemsNewDataSelected.length > 0 && `Quantité`}
          </span>
        </div>
        <hr />
      </div>
      {/* Content */}
      {singleOrderLineItemsNewDataSelected.map((product, i) => (
        <SingleOrderPopUpAddProductLineItem
          key={i}
          index={i}
          product={product}
          availableProducts={availableProducts}
          selectedProducts={singleOrderLineItemsNewDataSelected}
          setSelectedProducts={setSingleOrderLineItemsNewDataSelected}
        />
      ))}
      {singleOrderLineItemsNewDataSelected.length === 0 && (
        <div className="">
          <SingleOrderPopUpAddProductLineItem
            index={singleOrderLineItemsNewDataSelected.length}
            availableProducts={availableProducts}
            selectedProducts={singleOrderLineItemsNewDataSelected}
            setSelectedProducts={setSingleOrderLineItemsNewDataSelected}
          />
        </div>
      )}
    </div>
  );
};

export default SingleOrderPopupAddProduct;

// Path: src/features/orders/components/SingleOrderTable/PopupActionsContent/SingleOrderPopupAddProduct.tsx
