import CardWithData from "../../../../../shared/ui/components/CardWithData";
import { XMarkIcon, CheckIcon } from "@heroicons/react/20/solid";
import { PencilIcon } from "@heroicons/react/24/outline";
import {
  isEditingActivityAddressAtom,
  selectedOrderAtom,
} from "../../../atoms/orders-atoms";
import { useRecoilState } from "recoil";
import { useRef } from "react";
import { useForm, UseFormRegister } from "react-hook-form";
import AddressInput from "./AddressInput";
import { ActivityAddressType } from "../../../../../shared/types/sharedTypes";

const ActivityAddress = ({
  companyActivity,
  firstNameActivity,
  lastNameActivity,
  addressActivity,
  address2Activity,
  zipCodeActivity,
  cityActivity,
  countryActivity,
}: ActivityAddressType) => {
  // Atoms
  const [isEditingActivityAddress, setIsEditingActivityAddress] =
    useRecoilState(isEditingActivityAddressAtom);
  const [selectedOrderData, setSelectedOrderData] =
    useRecoilState(selectedOrderAtom);

  // Refs
  const inputRef = useRef<HTMLInputElement>(null);

  // React Hook Form
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ActivityAddressType>();

  // Methods
  const handleEdit = () => {
    setIsEditingActivityAddress(true);
    inputRef.current?.focus();
  };
  const handleValidate = () => {
    setIsEditingActivityAddress(false);
    selectedOrderData &&
      setSelectedOrderData({
        ...selectedOrderData,
        activityAddress: {
          ...selectedOrderData.activityAddress,
          ...watch(),
        },
      });
  };
  const handleCancel = () => {
    setIsEditingActivityAddress(false);
    if (selectedOrderData) {
      setValue(
        "companyActivity",
        selectedOrderData.activityAddress.companyActivity
      );
      setValue(
        "lastNameActivity",
        selectedOrderData.activityAddress.lastNameActivity
      );
      setValue(
        "firstNameActivity",
        selectedOrderData.activityAddress.firstNameActivity
      );
      setValue(
        "addressActivity",
        selectedOrderData.activityAddress.addressActivity
      );
      setValue(
        "address2Activity",
        selectedOrderData.activityAddress.address2Activity
      );
      setValue(
        "zipCodeActivity",
        selectedOrderData.activityAddress.zipCodeActivity
      );
      setValue("cityActivity", selectedOrderData.activityAddress.cityActivity);
      setValue(
        "countryActivity",
        selectedOrderData.activityAddress.countryActivity
      );
    }
  };

  // Function to check if selectedOrderData only contains empty strings
  const isOrderActivityAddressDataEmpty = () => {
    if (selectedOrderData) {
      const {
        companyActivity,
        firstNameActivity,
        lastNameActivity,
        addressActivity,
        address2Activity,
        zipCodeActivity,
        cityActivity,
        countryActivity,
      } = selectedOrderData.activityAddress;
      return (
        companyActivity === "" &&
        firstNameActivity === "" &&
        lastNameActivity === "" &&
        addressActivity === "" &&
        address2Activity === "" &&
        zipCodeActivity === "" &&
        cityActivity === "" &&
        countryActivity === ""
      );
    }
    return true;
  };

  // Render methods
  const renderForm = () => {
    return (
      <form className="flex flex-col gap-y-4">
        <AddressInput
          label="Entreprise"
          title="companyActivity"
          value={companyActivity}
          register={register}
        />
        <div className="flex flex-row items-center gap-x-4">
          <div className="w-full">
            <AddressInput
              label="Nom"
              title="lastNameActivity"
              value={lastNameActivity}
              register={register}
            />
          </div>
          <div className="w-full">
            <AddressInput
              label="Prénom"
              title="firstNameActivity"
              value={firstNameActivity}
              register={register}
            />
          </div>
        </div>
        <AddressInput
          label="Adresse"
          title="addressActivity"
          value={addressActivity}
          register={register}
        />
        <AddressInput
          label="Adresse 2"
          title="address2Activity"
          value={address2Activity || ""}
          register={register}
        />
        <div className="flex flex-row items-center gap-x-4">
          <div className="w-full">
            <AddressInput
              label="Code postal"
              title="zipCodeActivity"
              value={zipCodeActivity}
              register={register}
            />
          </div>
          <div className="w-full">
            <AddressInput
              label="Ville"
              title="cityActivity"
              value={cityActivity}
              register={register}
            />
          </div>
        </div>
        <AddressInput
          label="Pays"
          title="countryActivity"
          value={countryActivity}
          register={register}
        />
      </form>
    );
  };

  const renderData = () => {
    return (
      <div className="flex flex-col gap-y-2">
        <span>{companyActivity}</span>
        <span>{`${firstNameActivity} ${lastNameActivity}`}</span>
        <span>{addressActivity}</span>
        {address2Activity && <span>{address2Activity}</span>}
        <span>{`${zipCodeActivity} ${cityActivity}`}</span>
        <span>{countryActivity}</span>
      </div>
    );
  };

  interface EditableDataActionButtonsProps {
    handleEdit: () => void;
    handleValidate: () => void;
    handleCancel: () => void;
    isEditing: boolean;
  }

  const EditableDataActionButtons = ({
    handleEdit,
    handleValidate,
    handleCancel,
    isEditing,
  }: EditableDataActionButtonsProps) => {
    return (
      <div>
        {/* Default state */}
        {!isEditing && (
          <div className="flex flex-row gap-x-2">
            <div
              className="cursor-pointer p-[6px] text-gray-400 transition hover:text-gray-600"
              onClick={() => {
                handleEdit();
              }}
            >
              <PencilIcon className="w-5" />
            </div>
          </div>
        )}
        {/* Editing state  */}
        {isEditing && (
          <div className="flex flex-row items-center gap-x-2">
            <div
              onClick={() => {
                handleValidate();
              }}
              className="cursor-pointer rounded-full p-1 text-green-400 transition hover:bg-green-100 hover:shadow"
            >
              <CheckIcon className="w-6" />
            </div>
            <div className="cursor-pointer rounded-full p-1 text-red-400 transition hover:bg-red-100 hover:shadow">
              <XMarkIcon className="w-6" onClick={() => handleCancel()} />
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <CardWithData gapY="0">
      <div className="flex flex-col">
        {/* Header */}
        <div
          className={`flex flex-row gap-x-2 ${
            isEditingActivityAddress && ""
          } items-center`}
        >
          <span className="font-bold text-[#3C7179]">Adresse activité</span>
          {/* Action buttons */}
          <EditableDataActionButtons
            isEditing={isEditingActivityAddress}
            handleEdit={handleEdit}
            handleValidate={handleValidate}
            handleCancel={handleCancel}
          />
        </div>
        {/* Form */}
        {isEditingActivityAddress && renderForm()}
        {/* Data */}
        {!isEditingActivityAddress &&
          !isOrderActivityAddressDataEmpty() &&
          renderData()}
        {/* Empty data */}
        {!isEditingActivityAddress && isOrderActivityAddressDataEmpty() && (
          <span className="text-gray-400">Aucune adresse</span>
        )}
      </div>
    </CardWithData>
  );
};

export default ActivityAddress;

// Path: src/features/orders/components/ActivityAddress.tsx
