import { atom } from "recoil";
import { Discount } from "../types/discount";

export const filteredDiscountsDataAtom = atom<Discount[] | null>({
  key: "filteredDiscountsDataAtom",
  default: null,
});

export const discountsDataAtom = atom<Discount[] | null>({
  key: "discountsDataAtom",
  default: [],
});

export const selectedDiscountIndexAtom = atom<number | null>({
  key: "selectedDiscountIndexAtom",
  default: null,
});

export const selectedDiscountAtom = atom<Discount | null>({
  key: "selectedDiscountAtom",
  default: null,
});

export const isUpdatingDiscountAtom = atom<boolean>({
  key: "isUpdatingDiscountAtom",
  default: false,
});
