import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { ordersDataAtom } from "../../../features/orders/atoms/orders-atoms";
import ImageBox from "../components/ImageBox";
import Sidebar from "./Sidebar";
import TopBar, { AdminName } from "./TopBar";

interface PageLayoutProps {
  children: React.ReactNode;
}

const adminName: AdminName = {
  firstName: "Bernard",
  lastName: "Halil",
};

const PageLayout = ({ children }: PageLayoutProps) => {
  return (
    <div className="">
      {/* <TopBar adminName={adminName} /> */}
      <div className="flex flex-row bg-[#F1F5F9]">
        <Sidebar adminName={adminName} />
        <div className="w-full grow">
          <div className="flex flex-col h-full min-h-screen grow">
            <div className="flex w-full items-center justify-between bg-[#14B8A6] px-8 py-4">
              <div className="opacity-0">
                <ImageBox
                  imageSrc={""}
                  imageAlt="logo"
                  title="Bonjour,"
                  subtitle={adminName.firstName + " " + adminName.lastName}
                />
              </div>
            </div>
            <div className="relative flex flex-col h-full px-6 py-16 grow gap-y-20">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageLayout;

// Path: src/shared/ui/layouts/PageLayout.tsx
