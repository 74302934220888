import { XMarkIcon, CheckIcon } from "@heroicons/react/20/solid";
import { PencilIcon } from "@heroicons/react/24/outline";
import { useRecoilState } from "recoil";
import { useRef } from "react";
import { useForm, UseFormRegister } from "react-hook-form";
import AddressInput from "./AddressInput";
import { InvoiceAddressType } from "../../../../shared/types/sharedTypes";
import {
  isEditingExhibitorInvoiceAddressAtom,
  selectedExhibitorAtom,
} from "../../atoms/exhibitors-atoms";
import CardWithData from "../../../../shared/ui/components/CardWithData";

const ExhibitorInvoiceAddress = ({
  companyInvoice,
  firstNameInvoice,
  lastNameInvoice,
  addressInvoice,
  address2Invoice,
  zipCodeInvoice,
  cityInvoice,
  countryInvoice,
}: InvoiceAddressType) => {
  // Atoms
  const [isEditingInvoiceAddress, setIsEditingInvoiceAddress] = useRecoilState(
    isEditingExhibitorInvoiceAddressAtom
  );
  const [selectedExhibitorData, setSelectedExhibitorData] = useRecoilState(
    selectedExhibitorAtom
  );

  // Refs
  const inputRef = useRef<HTMLInputElement>(null);

  // React Hook Form
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm<InvoiceAddressType>();

  // Methods
  const handleEdit = () => {
    setIsEditingInvoiceAddress(true);
    inputRef.current?.focus();
  };
  const handleValidate = () => {
    setIsEditingInvoiceAddress(false);
    selectedExhibitorData &&
      setSelectedExhibitorData({
        ...selectedExhibitorData,
        singleInfo: {
          ...selectedExhibitorData.singleInfo,
          invoiceAddress: {
            ...selectedExhibitorData.singleInfo.invoiceAddress,
            ...watch(),
          },
        },
      });
  };
  const handleCancel = () => {
    setIsEditingInvoiceAddress(false);
    if (selectedExhibitorData) {
      setValue(
        "companyInvoice",
        selectedExhibitorData.singleInfo.invoiceAddress.companyInvoice
      );
      setValue(
        "lastNameInvoice",
        selectedExhibitorData.singleInfo.invoiceAddress.lastNameInvoice
      );
      setValue(
        "firstNameInvoice",
        selectedExhibitorData.singleInfo.invoiceAddress.firstNameInvoice
      );
      setValue(
        "addressInvoice",
        selectedExhibitorData.singleInfo.invoiceAddress.addressInvoice
      );
      setValue(
        "address2Invoice",
        selectedExhibitorData.singleInfo.invoiceAddress.address2Invoice
      );
      setValue(
        "zipCodeInvoice",
        selectedExhibitorData.singleInfo.invoiceAddress.zipCodeInvoice
      );
      setValue(
        "cityInvoice",
        selectedExhibitorData.singleInfo.invoiceAddress.cityInvoice
      );
      setValue(
        "countryInvoice",
        selectedExhibitorData.singleInfo.invoiceAddress.countryInvoice
      );
    }
  };

  // Function to check if selectedExhibitorData only contains empty strings
  const isOrderInvoiceAddressDataEmpty = () => {
    if (selectedExhibitorData) {
      const {
        companyInvoice,
        firstNameInvoice,
        lastNameInvoice,
        addressInvoice,
        address2Invoice,
        zipCodeInvoice,
        cityInvoice,
        countryInvoice,
      } = selectedExhibitorData.singleInfo.invoiceAddress;
      return (
        companyInvoice === "" &&
        firstNameInvoice === "" &&
        lastNameInvoice === "" &&
        addressInvoice === "" &&
        address2Invoice === "" &&
        zipCodeInvoice === "" &&
        cityInvoice === "" &&
        countryInvoice === ""
      );
    }
    return true;
  };

  // Render methods
  const renderForm = () => {
    return (
      <form className="flex flex-col gap-y-4">
        <AddressInput
          label="Entreprise"
          title="companyInvoice"
          value={companyInvoice}
          register={register}
        />
        <div className="flex flex-row items-center gap-x-4">
          <div className="w-full">
            <AddressInput
              label="Nom"
              title="lastNameInvoice"
              value={lastNameInvoice}
              register={register}
            />
          </div>
          <div className="w-full">
            <AddressInput
              label="Prénom"
              title="firstNameInvoice"
              value={firstNameInvoice}
              register={register}
            />
          </div>
        </div>
        <AddressInput
          label="Adresse"
          title="addressInvoice"
          value={addressInvoice}
          register={register}
        />
        <AddressInput
          label="Adresse 2"
          title="address2Invoice"
          value={address2Invoice || ""}
          register={register}
        />
        <div className="flex flex-row items-center gap-x-4">
          <div className="w-full">
            <AddressInput
              label="Code postal"
              title="zipCodeInvoice"
              value={zipCodeInvoice}
              register={register}
            />
          </div>
          <div className="w-full">
            <AddressInput
              label="Ville"
              title="cityInvoice"
              value={cityInvoice}
              register={register}
            />
          </div>
        </div>
        <AddressInput
          label="Pays"
          title="countryInvoice"
          value={countryInvoice}
          register={register}
        />
      </form>
    );
  };

  const renderData = () => {
    return (
      <div className="flex flex-col gap-y-2">
        <span>{companyInvoice}</span>
        <span>{`${firstNameInvoice} ${lastNameInvoice}`}</span>
        <span>{addressInvoice}</span>
        {address2Invoice && <span>{address2Invoice}</span>}
        <span>{`${zipCodeInvoice} ${cityInvoice}`}</span>
        <span>{countryInvoice}</span>
      </div>
    );
  };

  interface EditableDataActionButtonsProps {
    handleEdit: () => void;
    handleValidate: () => void;
    handleCancel: () => void;
    isEditing: boolean;
  }

  const EditableDataActionButtons = ({
    handleEdit,
    handleValidate,
    handleCancel,
    isEditing,
  }: EditableDataActionButtonsProps) => {
    return (
      <div>
        {/* Default state */}
        {!isEditing && (
          <div className="flex flex-row gap-x-2">
            <div
              className="cursor-pointer p-[6px] text-gray-400 transition hover:text-gray-600"
              onClick={() => {
                handleEdit();
              }}
            >
              <PencilIcon className="w-5" />
            </div>
          </div>
        )}
        {/* Editing state  */}
        {isEditing && (
          <div className="flex flex-row items-center gap-x-2">
            <div
              onClick={() => {
                handleValidate();
              }}
              className="cursor-pointer rounded-full p-1 text-green-400 transition hover:bg-green-100 hover:shadow"
            >
              <CheckIcon className="w-6" />
            </div>
            <div className="cursor-pointer rounded-full p-1 text-red-400 transition hover:bg-red-100 hover:shadow">
              <XMarkIcon className="w-6" onClick={() => handleCancel()} />
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <CardWithData gapY="0">
      <div className="flex flex-col">
        {/* Header */}
        <div
          className={`flex flex-row gap-x-2 ${
            isEditingInvoiceAddress && ""
          } items-center`}
        >
          <span className="font-bold text-[#3C7179]">Adresse facturation</span>
          {/* Action buttons */}
          <EditableDataActionButtons
            isEditing={isEditingInvoiceAddress}
            handleEdit={handleEdit}
            handleValidate={handleValidate}
            handleCancel={handleCancel}
          />
        </div>
        {/* Form */}
        {isEditingInvoiceAddress && renderForm()}
        {/* Data */}
        {!isEditingInvoiceAddress &&
          !isOrderInvoiceAddressDataEmpty() &&
          renderData()}
        {/* Empty data */}
        {!isEditingInvoiceAddress && isOrderInvoiceAddressDataEmpty() && (
          <span className="text-gray-400">Aucune adresse</span>
        )}
      </div>
    </CardWithData>
  );
};

export default ExhibitorInvoiceAddress;

// Path: src/features/orders/components/ExhibitorInvoiceAddress.tsx
