import { ArrowLeftCircleIcon } from "@heroicons/react/24/outline";
import { EyeIcon, PencilIcon } from "@heroicons/react/24/solid";
import { createColumnHelper } from "@tanstack/react-table";
import axios from "axios";
import { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import ActionPopup from "../../../shared/ui/components/ActionPopup";
import CardWithData from "../../../shared/ui/components/CardWithData";
import Loader from "../../../shared/ui/components/Loader";
import Pill from "../../../shared/ui/components/Pill";

import PageLayout from "../../../shared/ui/layouts/PageLayout";
import { returnRootUrlBasedOnAppLocation } from "../../../shared/utils/checkIfIsInlocal";
import { returnPriceFormattedCHF } from "../../../shared/utils/numbersManipulations";
import ActivityAddress from "../../orders/components/SingleOrder/SingleOrderDetails/ActivityAddress";
import InvoiceAddress from "../../orders/components/SingleOrder/SingleOrderDetails/InvoiceAddress";
import TableWithTitle from "../../orders/layouts/TableWithTitle";
import { OrderData } from "../../orders/types/orders";
import { selectedExhibitorAtom } from "../atoms/exhibitors-atoms";
import ExhibitorActivityAddress from "../components/Archive/ExhibitorActivityAddress";
import ExhibitorInvoiceAddress from "../components/Archive/ExhibitorInvoiceAddress";
import SinglePostInfoCardsItemData from "../../../shared/ui/components/SinglePostInfoCardsItemData";
import { ConferenceFlagAtom } from "../../../shared/atoms/flag-atoms";
import { selectedPostAtom } from "../../../shared/atoms/single-data-atoms";
interface CardWithHeaderProps {
  title: string;
  children: React.ReactNode;
  isEditable?: boolean;
  centerElements?: boolean;
  justifyCenter?: boolean;
  width?: string;
  headerChildren?: React.ReactNode;
}
const CardWithHeader = ({
  title,
  children,
  isEditable = false,
  centerElements = false,
  justifyCenter = true,
  width = "",
  headerChildren = null,
}: CardWithHeaderProps) => {
  return (
    <div className={`${width} flex grow flex-col rounded-lg bg-white shadow`}>
      <div className="flex w-full flex-row items-center justify-between rounded-lg rounded-b-none bg-[#14B8A6] px-6 py-3 font-bold text-white">
        <div className="flex flex-row items-center justify-between ">
          <span className="text-xl">{title}</span>
          {isEditable && <PencilIcon className="w-5" />}
        </div>
        <>{headerChildren}</>
      </div>
      <div
        className={`flex grow flex-col ${justifyCenter && "justify-center"
          } px-6 py-4 ${centerElements && "mx-auto"}`}
      >
        {children}
      </div>
    </div>
  );
};

const SingleExhibitorScreen = () => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const columnHelper = createColumnHelper<OrderData>();
  let navigate = useNavigate();
  const columns = [
    columnHelper.accessor("orderId", {
      header: "Numéro",
      cell: ({ getValue }) => {
        return (
          <a
            className="cursor-pointer underline"
            href={`${window.location.origin}/commandes/${getValue()}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {getValue()}
          </a>
        );
      },
      // <Link
      //   className="underline"
      //   to={`/commandes/${getValue()}`}
      //   onClick={() => {
      //     // Find order in orders array with the same id as the selected order
      //     // and set it as the selected order
      //     const selectedOrder =
      //       orders.find((order, index) => order.orderId === getValue()) ||
      //       null;
      //     // Set the index of the selected order
      //     selectedOrder &&
      //       setSelectedOrderIndex(orders.indexOf(selectedOrder));

      //     selectedOrder && setSelectedOrder(selectedOrder);

      //     // Scroll to top
      //     window.scrollTo(0, 0);
      //     // setSelectedOrderData(selectedOrder);
      //   }}
      // >
      //   {getValue()}
      // </Link>
    }),
    columnHelper.accessor("company", {
      header: "Nom entreprise",
      cell: ({ getValue, row }) => (
        <div className="flex items-center justify-center gap-x-2">
          <span>{getValue()}</span>
        </div>
      ),
    }),
    columnHelper.accessor("date", {
      header: "Date",
      cell: ({ getValue }) => <span>{getValue()}</span>,
    }),
    columnHelper.accessor("status", {
      header: "Statut",
      cell: ({ getValue }) => <span>{getValue()}</span>,
    }),
    columnHelper.accessor("total", {
      header: "Total",
      cell: ({ getValue }) => (
        <span>{returnPriceFormattedCHF(getValue())}</span>
      ),
    }),
    columnHelper.accessor("standNumber", {
      header: "N° Stand",
      cell: ({ getValue }) => <span>{getValue() || "-"}</span>,
    }),
    columnHelper.accessor("exhibition", {
      header: "Salon",
      // cell: ({ getValue }) => <span>{}</span>,
      cell: ({ getValue }) => (
        <Pill
          label={getValue().name}
          bgColor={getValue().primaryColor}
          textColor={getValue().secondaryColor}
        />
      ),
    }),
  ];
  const [selectedExhibitorData, setSelectedExhibitorData] = useRecoilState(
    selectedExhibitorAtom
  );
  const [selectedPost, setSelectedPost] = useRecoilState(selectedPostAtom);
  let token = "";
  if (localStorage.getItem("token-dashboard")) {
    token = JSON.parse(localStorage.getItem("token-dashboard") as string);
  }
  const userId = window.location.href.split("/").pop();
  const [isLoading, setIsLoading] = useState(true);
  const selected = useRecoilValue(ConferenceFlagAtom);

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsLoading(true);
    axios
      .get(
        `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v1/exhibitors?id=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setSelectedExhibitorData(res.data[0]);
        setSelectedPost(res.data[0]);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.code.includes("jwt")) {
          console.log(err);
          setIsLoading(false);
          localStorage.removeItem("token-dashboard");
          window.location.replace("/");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
    // setIsLoading(true);
    // if (!selectedExhibitorData) {
    //   setIsLoading(true);
    //   axios
    //     .get(
    //       `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v1/exhibitors?id=${userId}`,
    //       {
    //         headers: {
    //           Authorization: `Bearer ${token}`,
    //         },
    //       }
    //     )
    //     .then((res) => {
    //       setSelectedExhibitorData(res.data[0]);
    //       console.log(res.data);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       if (err.code.includes("jwt")) {
    //         console.log(err);
    //         localStorage.removeItem("token-dashboard");
    //         window.location.replace("/");
    //       }
    //     })
    //     .finally(() => {
    //       setIsLoading(false);
    //     });
    // } else {
    //   setIsLoading(false);
    // }
  }, []);

  const popups = [
    <ActionPopup
      key={Math.random()}
      title="Client mis à jour"
      children={<p>Le client a été mis à jour avec succès</p>}
      isVisible={isPopupVisible}
      setIsVisible={() => {
        setIsPopupVisible(!isPopupVisible);
        window.location.reload();
      }}
      action={() => {
        setIsPopupVisible(false);
        window.location.reload();
      }}
      cancelAction={() => {
        setIsPopupVisible(false);
        window.location.reload();
      }}
      actionButtonDisabled={false}
      actionButtonTitle="Fermer"
      cancelButtonVisible={false}
    />,
  ];

  const phoneWithPrefix = selectedExhibitorData?.phone;

  const phoneWithoutPrefix = phoneWithPrefix?.replace(/^\+\d+/, '');

  return (
    <PageLayout>
      <>
        {popups}
        {isLoading ? (
          <Loader />
        ) : (
          <div className="flex flex-col gap-y-16">
            <div className="flex w-full justify-between">
              <Link
                className="flex flex-row items-center gap-x-4 text-2xl text-[#3c7179]"
                to={`/clients`}
                onClick={() => { }}
              >
                <ArrowLeftCircleIcon className="color-[#3C7179] w-10" /> Retour
                à la liste des clients
              </Link>
              <button
                disabled={isUpdating}
                // className="flex flex-row items-center gap-x-4 rounded border-none bg-[#14b8a6] px-4 py-2 text-lg text-gray-50 shadow"
                className={`${isUpdating && "opacity-40"
                  } flex flex-row items-center rounded-md border-none bg-[#14b8a6] px-8 py-4 text-lg font-semibold text-white shadow`}
                onClick={() => {
                  setIsUpdating(true);
                  selectedExhibitorData &&
                    axios
                      .post(
                        `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v1/exhibitors/`,
                        {
                          id: selectedExhibitorData.id,
                          companyName: selectedExhibitorData.company,
                          lastName:
                            selectedExhibitorData.singleInfo.invoiceAddress
                              .lastNameInvoice,
                          firstName:
                            selectedExhibitorData.singleInfo.invoiceAddress
                              .firstNameInvoice,
                          hasAllreadyCameToExhibition:
                            selectedExhibitorData.singleInfo
                              .haveAlreadyParticipated,
                          email: selectedExhibitorData.email,
                          phone: selectedExhibitorData.phone,
                          website: selectedExhibitorData.singleInfo.website,
                          activityType:
                            selectedExhibitorData.singleInfo.activityType,
                          invoiceAddress:
                            selectedExhibitorData.singleInfo.invoiceAddress,
                          activityAddress:
                            selectedExhibitorData.singleInfo.activityAddress,
                          adminComments:
                            selectedExhibitorData.singleInfo.adminComments,
                          newsletter:
                            selectedExhibitorData.singleInfo.newsletter,
                          topCardsInfo: {
                            company: selectedExhibitorData.company,
                            firstName:
                              selectedExhibitorData.singleInfo.firstName,
                            lastName: selectedExhibitorData.singleInfo.lastName,
                            email: selectedExhibitorData.singleInfo.email,
                            phone: selectedExhibitorData.singleInfo.phone,
                            website: selectedExhibitorData.singleInfo.website,
                            activityType:
                              selectedExhibitorData.singleInfo.activityType,
                            hasAllreadyBeenToExhibition:
                              selectedExhibitorData.singleInfo
                                .haveAlreadyParticipated,
                          },
                          billing_country_code: selected,
                        },
                        {
                          headers: {
                            Authorization: `Bearer ${token}`,
                          },
                        }
                      )
                      .then((data) => {
                        console.log("data", data);
                      })
                      .catch((error) => {
                        console.log("error", error);
                        if (error.code.includes("jwt")) {
                          localStorage.removeItem("token-dashboard");
                          window.location.replace("/");
                        }
                      })
                      .finally(() => {
                        setIsUpdating(false);
                        setIsPopupVisible(true);
                      });
                }}
                type="button"
              >
                {isUpdating && (
                  <svg
                    className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                )}
                {isUpdating ? "Mise à jour..." : "Mettre à jour"}
              </button>
            </div>
            <div className="flex flex-col gap-y-11">
              <div className="flex w-full flex-row justify-between gap-x-6">
                {/* Card 1 */}
                <CardWithData width="25%" gapY="8px">
                  {/* Raison sociale */}
                  <SinglePostInfoCardsItemData
                    isEditable={false}
                    title="Identifiant client"
                    value={
                      selectedExhibitorData?.singleInfo?.id.toString() || "-"
                    }
                  />
                  <SinglePostInfoCardsItemData
                    title="Raison sociale"
                    setValue={(value) => {
                      selectedExhibitorData &&
                        setSelectedExhibitorData({
                          ...selectedExhibitorData,
                          company: value,
                          singleInfo: {
                            ...selectedExhibitorData.singleInfo,
                            company: value,
                            invoiceAddress: {
                              ...selectedExhibitorData.singleInfo
                                .invoiceAddress,
                              companyInvoice: value,
                            },
                          },
                        });
                    }}
                    value={
                      selectedExhibitorData?.singleInfo?.invoiceAddress
                        .companyInvoice || "-"
                    }
                  />
                  {/* Prénom */}
                  <SinglePostInfoCardsItemData
                    title="Prénom"
                    value={
                      selectedExhibitorData?.singleInfo?.invoiceAddress
                        .firstNameInvoice || "-"
                    }
                    setValue={(value) => {
                      selectedExhibitorData &&
                        setSelectedExhibitorData({
                          ...selectedExhibitorData,
                          firstName: value,
                          singleInfo: {
                            ...selectedExhibitorData.singleInfo,
                            firstName: value,
                            invoiceAddress: {
                              ...selectedExhibitorData.singleInfo
                                .invoiceAddress,
                              firstNameInvoice: value,
                            },
                          },
                        });
                    }}
                  />
                  {/* Nom */}
                  <SinglePostInfoCardsItemData
                    title="Nom"
                    value={
                      selectedExhibitorData?.singleInfo?.invoiceAddress
                        .lastNameInvoice || "-"
                    }
                    setValue={(value) => {
                      selectedExhibitorData &&
                        setSelectedExhibitorData({
                          ...selectedExhibitorData,
                          lastName: value,
                          singleInfo: {
                            ...selectedExhibitorData.singleInfo,
                            lastName: value,
                            invoiceAddress: {
                              ...selectedExhibitorData.singleInfo
                                .invoiceAddress,
                              lastNameInvoice: value,
                            },
                          },
                        });
                    }}
                  />
                  <div className="mt-4"></div>
                  {/* Deja venu au salon */}
                  <SinglePostInfoCardsItemData
                    isSelect={true}
                    title="Déjà venu au salon"
                    value={
                      selectedExhibitorData?.singleInfo.haveAlreadyParticipated.toString() ===
                        "true"
                        ? "Oui"
                        : "Non"
                    }
                    setValue={(value) => {
                      selectedExhibitorData &&
                        setSelectedExhibitorData({
                          ...selectedExhibitorData,
                          singleInfo: {
                            ...selectedExhibitorData.singleInfo,
                            haveAlreadyParticipated:
                              value === "true" ? true : false,
                          },
                        });
                    }}
                  />
                </CardWithData>
                {/* Card 2 */}
                <CardWithData width="25%" gapY="8px">
                  {/* Email */}
                  <SinglePostInfoCardsItemData
                    email={selectedExhibitorData?.email}
                    title="E-mail"
                    value={selectedExhibitorData?.singleInfo?.email || "-"}
                    setValue={(value) => {
                      selectedExhibitorData &&
                        setSelectedExhibitorData({
                          ...selectedExhibitorData,
                          email: value,
                          singleInfo: {
                            ...selectedExhibitorData.singleInfo,
                            email: value,
                          },
                        });
                    }}
                  />
                  {/* Telephone */}
                  <SinglePostInfoCardsItemData
                    phone={phoneWithPrefix}
                    title="Téléphone"
                    value={phoneWithoutPrefix || "-"}
                    setValue={(value) => {
                      selectedExhibitorData &&
                        setSelectedExhibitorData({
                          ...selectedExhibitorData,
                          phone: value,
                          singleInfo: {
                            ...selectedExhibitorData.singleInfo,
                            phone: value,
                          },
                        });
                    }}
                  />
                  {/* Site internet */}
                  <SinglePostInfoCardsItemData
                    website={selectedExhibitorData?.singleInfo?.website}
                    title="Site internet"
                    value={selectedExhibitorData?.singleInfo?.website || "-"}
                    setValue={(value) => {
                      selectedExhibitorData &&
                        setSelectedExhibitorData({
                          ...selectedExhibitorData,
                          singleInfo: {
                            ...selectedExhibitorData.singleInfo,
                            website: value,
                          },
                        });
                    }}
                  />
                  {/* Type d'activite */}
                  <SinglePostInfoCardsItemData
                    title="Type d'activité"
                    value={
                      selectedExhibitorData?.singleInfo?.activityType || "-"
                    }
                    setValue={(value) => {
                      selectedExhibitorData &&
                        setSelectedExhibitorData({
                          ...selectedExhibitorData,
                          singleInfo: {
                            ...selectedExhibitorData.singleInfo,
                            activityType: value,
                          },
                        });
                    }}
                  />
                </CardWithData>
                {/* Card 3 */}
                {selectedExhibitorData?.singleInfo?.invoiceAddress && (
                  <ExhibitorInvoiceAddress
                    {...selectedExhibitorData.singleInfo.invoiceAddress}
                  />
                )}
                {/* Card 3 */}
                {selectedExhibitorData?.singleInfo?.activityAddress && (
                  <ExhibitorActivityAddress
                    {...selectedExhibitorData.singleInfo.activityAddress}
                  />
                )}
              </div>
            </div>
            {/*  */}
            {/* Comments and newsletter */}
            <div className="flex flex-row items-stretch gap-x-6">
              <CardWithHeader title="Commentaire admin" width="w-[50%]">
                <SinglePostInfoCardsItemData
                  title="Commentaire"
                  value={
                    selectedExhibitorData?.singleInfo?.adminComments || "-"
                  }
                  setValue={(value) => {
                    selectedExhibitorData &&
                      setSelectedExhibitorData({
                        ...selectedExhibitorData,
                        singleInfo: {
                          ...selectedExhibitorData.singleInfo,
                          adminComments: value,
                        },
                      });
                  }}
                />
              </CardWithHeader>
              {/* Newsletter */}
              <CardWithHeader
                title="Newsletter"
                width="w-[25%]"
              // isEditable={true}
              >
                <SinglePostInfoCardsItemData
                  isSelect={true}
                  title="Newsletter"
                  value={
                    selectedExhibitorData?.singleInfo.newsletter.toString() ===
                      "true"
                      ? "Oui"
                      : "Non"
                  }
                  setValue={(value) => {
                    selectedExhibitorData &&
                      setSelectedExhibitorData({
                        ...selectedExhibitorData,
                        singleInfo: {
                          ...selectedExhibitorData.singleInfo,
                          newsletter: value === "true" ? true : false,
                        },
                      });
                  }}
                />
                {/* <div className="px-6 py-4">
                {selectedExhibitorData?.singleInfo.newsletter
                  ? selectedExhibitorData?.singleInfo.newsletter
                  : "Non"}
              </div> */}
              </CardWithHeader>
              <CardWithHeader
                title="CA"
                width="w-[25%]"
              // isEditable={true}
              >
                <SinglePostInfoCardsItemData
                  // isSelect={true}
                  isEditable={false}
                  title="CA"
                  value={returnPriceFormattedCHF(
                    selectedExhibitorData?.singleInfo.turnover || 0
                  )}
                // setValue={(value) => {
                //   selectedExhibitorData &&
                //     setSelectedExhibitorData({
                //       ...selectedExhibitorData,
                //       singleInfo: {
                //         ...selectedExhibitorData.singleInfo,
                //         newsletter: value === "true" ? true : false,
                //       },
                //     });
                // }}
                />
                {/* <div className="px-6 py-4">
                {selectedExhibitorData?.singleInfo.newsletter
                  ? selectedExhibitorData?.singleInfo.newsletter
                  : "Non"}
              </div> */}
              </CardWithHeader>
            </div>
            {/* Orders */}
            {selectedExhibitorData?.singleInfo.orders && (
              <TableWithTitle
                // filters={false}
                title=""
                tableData={selectedExhibitorData.singleInfo.orders}
                tableColumns={columns}
                isLoading={isLoading}
              />
            )}
          </div>
        )}
      </>
    </PageLayout>
  );
};

export default SingleExhibitorScreen;
