import { createColumnHelper } from "@tanstack/react-table";
import Table from "../../../../shared/ui/components/Table";
import { OrderData, Product } from "../../types/orders";
import { PencilIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import SingleOrderLineItemsTotalPriceTable from "../../components/SingleOrder/SingleOrderTable/SingleOrderLineItemsTotalPriceTable";
import SingleOrderFooterTableActionButtons from "../SingleOrderFooterTableActionButtons";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  // editProductSelectedProductAtom,
  ordersDataAtom,
  recalculateVatButtonClickedAtom,
  selectedEditProductDataAtom,
  selectedEditProductIndexAtom,
  // selectedEditProductIndexAtom,
  selectedOrderAtom,
  selectedOrderDiscountLineItemsAtom,
  selectedOrderIndexAtom,
  selectedOrderMainTaxRateAtom,
  singleOrderLineItemsDataAtom,
} from "../../atoms/orders-atoms";
import { returnPriceFormattedCHF } from "../../../../shared/utils/numbersManipulations";
import SingleOrderDiscountLineItems from "../../components/SingleOrder/SingleOrderDiscountLineItems";

const columnHelper = createColumnHelper<Product>();

const SingleOrderLineItemsTable = () => {
  const [singleOrderLineItemsData, setSingleOrderLineItemsData] =
    useRecoilState(singleOrderLineItemsDataAtom);

  const [selectedEditProductData, setSelectedEditProductData] = useRecoilState(
    selectedEditProductDataAtom
  );

  const [selectedEditProductIndex, setSelectedEditProductIndex] =
    useRecoilState(selectedEditProductIndexAtom);

  const [ordersData, setOrdersData] = useRecoilState(ordersDataAtom);

  const [selectedOrderIndex, setSelectedOrderIndex] = useRecoilState(
    selectedOrderIndexAtom
  );
  const [singleOrderData, setSingleOrderData] =
    useRecoilState(selectedOrderAtom);

  const [recalculateVatButtonClicked, setRecalculateVatButtonClicked] =
    useRecoilState(recalculateVatButtonClickedAtom);

  const [totalWithoutTaxes, setTotalWithoutTaxes] = useState(0);
  const [totalVat, setTotalVat] = useState(0);
  const [totalOrder, setTotalOrder] = useState(0);
  const [selectedOrder, setSelectedOrder] = useRecoilState(selectedOrderAtom);
  const [selectedOrderMainTaxRate, setSelectedOrderMainTaxRate] = useRecoilState(selectedOrderMainTaxRateAtom)
  // const [selectedProduct, setSelectedProduct] = useRecoilState(
  //   editProductSelectedProductAtom
  // );

  const determineTaxRate = () => {
    const products = singleOrderData?.products || [];
    const taxRates = products.map((product) => product.taxRate);
    // Find the most present taxRate value in the taxRates array not using reduce
    const taxRate = taxRates.sort((a, b) =>
      taxRates.filter((v) => v === a).length -
      taxRates.filter((v) => v === b).length
    ).pop();
    return taxRate;
  };



  const [selectedOrderDiscountLineItems, setSelectedOrderDiscountLineItems] =
    useRecoilState(selectedOrderDiscountLineItemsAtom);

  const [discounts, setDiscounts] = useState<number>(0);

  const columns = [
    columnHelper.accessor("name", {
      header: "Article",
      cell: ({ getValue, row }) => (
        <div className="flex flex-row items-start gap-x-4">
          <div className="max-w-[127px]">
            {/* <img src={getValue().thumbnail} alt="" /> */}
            <img
              className="rounded shadow"
              src={row.original.thumbnail}
              alt=""
            />
          </div>
          <div className="flex flex-col items-start gap-y-2">
            <span className="font-bold text-left">{getValue()}</span>
            {row.original.variationId && row.original.variationId !== "0" ? (
              <span>ID variation : {row.original.variationId}</span>
            ) : null}
            {/* {row.original.id} */}
            {row.original.attributes.length > 0 &&
              row.original.attributes.map((attribute, index) => (
                <span key={index}>{attribute}</span>
              ))}
          </div>
        </div>
      ),
    }),
    columnHelper.accessor("price", {
      header: "Coût",
      cell: ({ getValue, row }) => (
        <div className="flex items-center justify-center">
          <span>{returnPriceFormattedCHF(row.original.regularPrice)}</span>
        </div>
      ),
    }),
    columnHelper.accessor("thumbnail", {
      header: "Unit. TTC",
      cell: ({ getValue, row }) => (
        <div className="flex items-center justify-center">
          <span>
            {returnPriceFormattedCHF(
              row.original.regularPrice +
              row.original.vat / row.original.quantity
            )}
          </span>
        </div>
      ),
    }),
    columnHelper.accessor("quantity", {
      header: "Qté",
      cell: ({ getValue }) => (
        <div className="flex items-center justify-center">
          <span>x{getValue()}</span>
        </div>
      ),
    }),
    columnHelper.accessor("regularPrice", {
      header: "Total HT",
      cell: ({ getValue, row }) => (
        <div className="relative flex items-center justify-center">
          <span>
            {returnPriceFormattedCHF(row.original.price)}
            <br />
            {getValue() * row.original.quantity - row.original.price >=
              0.01 && (
                <span className="absolute inset-x-0 w-full text-xs">
                  Rabais de{" "}
                  {returnPriceFormattedCHF(
                    // row.original.regularPrice * row.original.quantity - getValue()
                    // row.original.regularPrice * row.original.quantity - getValue()
                    // row.original.price * row.original.quantity - getValue()
                    getValue() * row.original.quantity - row.original.price
                  )}
                  {/* {getValue() * row.original.quantity - row.original.price} */}
                </span>
              )}
          </span>
        </div>
      ),
    }),
    columnHelper.accessor("vat", {
      header: "TVA",
      cell: ({ getValue }) => (
        <div className="flex items-center justify-center">
          <span>{returnPriceFormattedCHF(getValue())}</span>
        </div>
      ),
    }),
    columnHelper.accessor("id", {
      header: "Actions",
      cell: ({ getValue, row }) => (
        <div className="flex flex-row items-center justify-center gap-x-6">
          <button
            type="button"
            className="flex flex-row border-none bg-none text-[#3C7179]"
            onClick={() => {
              const index = row.index;
              const selectedEditProductData = singleOrderLineItemsData[index];
              setSelectedEditProductIndex(index);
              setSelectedEditProductData([selectedEditProductData]);
            }}
          >
            <span className="sr-only">Modifier produit</span>
            <PencilIcon className="w-8" />
          </button>
          <button
            type="button"
            className="border-none bg-none text-[#3C7179]"
            onClick={() => {
              const index = row.index;
              // const newSingleOrderLineItemsData = [
              //   ...singleOrderLineItemsData.slice(0, index),
              //   ...singleOrderLineItemsData.slice(index + 1),
              // ];

              // if (selectedOrderIndex) {

              if (singleOrderData) {
                // TO UPDATE ALL ORDERS DATA
                // const singleOrderLineItemsData =
                //   ordersData[selectedOrderIndex].products;
                const singleOrderLineItemsData = singleOrderData.products;
                // TO UPDATE ALL ORDERS DATA AND SINGLE ORDER DATA
                const newSingleOrderLineItemsData = [
                  ...singleOrderLineItemsData.slice(0, index),
                  ...singleOrderLineItemsData.slice(index + 1),
                ];

                // TO UPDATE ALL ORDERS DATA
                // const newOrdersData = [
                //   ...ordersData.slice(0, selectedOrderIndex),
                //   {
                //     ...ordersData[selectedOrderIndex],
                //     products: newSingleOrderLineItemsData,
                //   },
                //   ...ordersData.slice(selectedOrderIndex + 1),
                // ];
                // setOrdersData(newOrdersData);
                // TO UPDATE SINGLE ORDER DATA
                const newOrderData = {
                  ...singleOrderData,
                  products: newSingleOrderLineItemsData,
                };
                setSingleOrderData(newOrderData);
                // TO UPDATE ALL ORDERS DATA AND SINGLE ORDER DATA
                setSelectedOrderIndex(selectedOrderIndex);

                // Update order total, totalWithoutVat, vat based on what has been removed
                const removedProduct = singleOrderLineItemsData[index];
                const newTotal =
                  singleOrderData.total -
                  (removedProduct.price + removedProduct.vat);
                const newTotalWithoutVat =
                  singleOrderData.totalWithoutVat -
                  removedProduct.regularPrice * removedProduct.quantity;
                const newVat = singleOrderData.vat - removedProduct.vat;
                const newOrderDataWithUpdatedTotal = {
                  ...newOrderData,
                  total: newTotal,
                  totalWithoutVat: newTotalWithoutVat,
                  vat: newVat,
                };
                setSingleOrderData(newOrderDataWithUpdatedTotal);
              }

              // setSingleOrderLineItemsData(newSingleOrderLineItemsData);
            }}
          >
            <span className="sr-only">Retirer produit</span>
            <XCircleIcon className="w-8" />
          </button>
        </div>
      ),
    }),
  ];

  // Total HT avant rabais
  const returnTotalWithoutTaxesOfOrderBeforeFees = (order: OrderData) => {
    let totalWithoutTaxes = 0;
    order.products.forEach((product) => {
      totalWithoutTaxes += product.regularPrice * product.quantity;
    });
    return totalWithoutTaxes;
  };

  // Total HT des rabais
  const returnTotalOfOrderFees = (order: OrderData) => {
    let total = 0;
    order.fees.forEach((fee) => {
      // total += fee.amount - fee.vat;
      // total += fee.amount;
      // Add only if positive
      if (fee.amount > 0) {
        total += fee.amount;
      }
    });
    return total;
  };

  const returnTotalWithoutTaxesOfOrderAfterFees = (order: OrderData) => {
    let totalWithoutTaxes = 0;
    order.products.forEach((product) => {
      totalWithoutTaxes += product.regularPrice * product.quantity;
    });
    // Add / remove fees
    order.fees.forEach((fee) => {
      // totalWithoutTaxes += fee.amount - fee.vat;
      totalWithoutTaxes += fee.amount;
    });
    return totalWithoutTaxes;
    // const total =
    //   returnTotalWithoutTaxesOfOrderBeforeFees(order) -
    //   discounts +
    //   returnTotalOfOrderFees(order);
    // return total;
  };

  // Total TVA (calculé par rapport au total HT)
  const returnTotalVatOfOrder = (order: OrderData) => {
    let totalVat = 0;
    order.products.forEach((product) => {
      totalVat += product.vat;
    });
    // Add / remove fees vat
    order.fees.forEach((fee) => {
      totalVat += fee.vat;
    });
    return totalVat;
    // const total = returnTotalWithoutTaxesOfOrderAfterFees(order) * 0.077;
    // return total;
  };

  const returnTotalOfOrder = (order: OrderData) => {
    let total = 0;
    order.products.forEach((product) => {
      total += product.regularPrice * product.quantity + product.vat;
    });
    // Add / remove fees
    order.fees.forEach((fee) => {
      total += fee.amount + fee.vat;
    });
    return total;
  };

  useEffect(() => {
    if (singleOrderData) {
      // setTotalOrder(singleOrderData.total);
      const orderProductsTaxRate = determineTaxRate();
      setSelectedOrderMainTaxRate(orderProductsTaxRate || 8.1);
      console.log("orderProductsTaxRate", orderProductsTaxRate);
      setTotalOrder(0);
      setTotalVat(0);
      setTotalWithoutTaxes(0);
      singleOrderLineItemsData.forEach((item) => {
        const regularPrice = item.regularPrice;
        const vat = item.vat;
        setTotalWithoutTaxes((prev) => prev + regularPrice);
        setTotalVat((prev) => prev + vat);
        setTotalOrder((prev) => prev + regularPrice + vat);
      });
      selectedOrderDiscountLineItems.forEach((item) => {
        const regularPrice = item.amount;
        const vat = item.vat;
        // setTotalWithoutTaxes((prev) => prev + regularPrice);
        setTotalVat((prev) => prev + vat);
        setTotalOrder((prev) => prev + regularPrice + vat);
      });

      let d = 0;
      // singleOrderData.products.forEach((product, index) => {
      //   // d += product.price * product.quantity - product.regularPrice;
      //   d += product.regularPrice * product.quantity - product.price;
      //   const discount =
      //     product.regularPrice * product.quantity - product.price;
      // });
      // selectedOrderDiscountLineItems.forEach((item) => {
      //   // If item amount is negative, it's a discount
      //   if (item.amount < 0) {
      //     d -= item.amount;
      //   }
      // });
      // This fixes the issue where the discount is not applied after updating an order
      // d === 0 &&
      singleOrderData.fees.forEach((fee) => {
        // If fee is negative, it's a discount
        if (fee.amount < 0) {
          d -= fee.amount;
        }
      });
      setDiscounts(d);
    }
  }, [singleOrderData]);

  useEffect(() => {
    // if (singleOrderData) {
    //   singleOrderData.products.forEach((product, index) => {
    //     const discount =
    //       product.regularPrice * product.quantity - product.price;
    //     setSingleOrderData({
    //       ...singleOrderData,
    //       products: [
    //         ...singleOrderData.products.slice(0, index),
    //         {
    //           ...singleOrderData.products[index],
    //           discount,
    //         },
    //         ...singleOrderData.products.slice(index + 1),
    //       ],
    //     });
    //   });
    // }
    // console.log("SINGLE ORDER DATA AFTER DISCOUNTS", singleOrderData);
  }, [discounts]);

  // Function that check if the totalVat is equal to 7.7% of the totalWithoutTaxes
  const checkTotalVat = (totalVat: number) => {
    const totalVatIsCorrect =
      returnPriceFormattedCHF(totalVat) ===
      returnPriceFormattedCHF(totalWithoutTaxes * 0.077);

    return totalVatIsCorrect;
  };

  return singleOrderData ? (
    <div id="orders" className="flex flex-col gap-y-8">
      <Table
        isLoading={false}
        data={singleOrderLineItemsData}
        columns={columns}
      />
      <SingleOrderDiscountLineItems />
      <div className="flex items-start justify-between px-2 gap-x-6">
        <SingleOrderFooterTableActionButtons />
        {/* <div className="flex w-[33%] flex-col items-start gap-y-4"> */}
        <div className="flex min-w-[450px] flex-col gap-y-4">
          {/* <SingleOrderLineItemsTotalPriceTable
            // totalWithoutTaxes={totalWithoutTaxes}
            // totalVat={totalVat}
            // totalOrder={totalOrder}
            discountTotal={discounts}
            totalWithoutTaxesBeforeFees={returnTotalWithoutTaxesOfOrderBeforeFees(
              singleOrderData
            )}
            totalFees={returnTotalOfOrderFees(singleOrderData)}
            totalWithoutTaxesAfterFees={returnTotalWithoutTaxesOfOrderAfterFees(
              singleOrderData
            )}
            totalVat={singleOrderData.vat}
            totalOrder={singleOrderData.total}
          /> */}
          <SingleOrderLineItemsTotalPriceTable
            // totalWithoutTaxes={totalWithoutTaxes}
            // totalVat={totalVat}
            // totalOrder={totalOrder}
            discountTotal={discounts}
            totalWithoutTaxesBeforeFees={returnTotalWithoutTaxesOfOrderBeforeFees(
              singleOrderData
            )}
            totalFees={returnTotalOfOrderFees(singleOrderData)}
            totalWithoutTaxesAfterFees={returnTotalWithoutTaxesOfOrderAfterFees(
              singleOrderData
            )}
            totalVat={returnTotalVatOfOrder(singleOrderData)}
            totalOrder={returnTotalOfOrder(singleOrderData)}
          />
          {/* {!checkTotalVat(totalVat) ? (
            <button
              type="button"
              className="rounded-md border-none bg-[#3C7179] py-3 px-6 text-sm font-semibold text-white"
              onClick={() => {
                setRecalculateVatButtonClicked(true);
              }}
            >
              Recalculer
            </button>
          ) : null} */}
        </div>
      </div>
    </div>
  ) : (
    <div>
      <p>Chargement...</p>
    </div>
  );
};

export default SingleOrderLineItemsTable;
