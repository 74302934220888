import { atom } from "recoil";
import { CustomerProps, Exhibition } from "../../../shared/types/sharedTypes";
import { OrderData } from "../../orders/types/orders";
import { CaresListingProps } from "../api/fetchCares";

interface CareProps extends CustomerProps {
  adminComments?: string;
  newsletter?: boolean;
  orders?: OrderData[];
}

interface SingleInfoProps {
  id: number;
  orderId: number;
  orderStatus: string;
  exhibition: Exhibition;
  status: string;
  cares: { type: string; description: string }[];
  bookingLink: string;
  desiredTimeSlots: string[];
  validatedTimeSlots: string[];
  comments: string;
  insurance: string;
  therapistImage: any;
  caresImage: any;
  date: string;
  company: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  website: string;
  activityType: string;
  hasAllreadyBeenToExhibition: boolean;
  adminComments: string;
  userId: number;
  orderHasStand: boolean;
  standNumber: string;
}

export interface CareInfoProps {
  id: number;
  title: string;
  titleCare1: string;
  titleCare2: string;
  titleNormalized: string;
  date: string;
  exhibition: Exhibition;
  exhibitionNormalized: string;
  user: string;
  userNormalized: string;
  status: string;
  statusNormalized: string;
  singleInfo: SingleInfoProps;
  validatedTimeSlots: string[];
}

// export const caresAtom = atom<CaresListingProps[]>({
export const caresAtom = atom<CareInfoProps[]>({
  key: "caresAtom",
  default: [],
});

export const filteredCaresAtom = atom<CareInfoProps[] | null>({
  key: "filteredCaresAtom",
  default: null,
});

export const selectedCareAtom = atom<CareInfoProps | null>({
  key: "selectedCareAtom",
  default: null,
});

export const isEditingCareInvoiceAddressAtom = atom<boolean>({
  key: "isEditingCareInvoiceAddressAtom",
  default: false,
});

export const isEditingCareActivityAddressAtom = atom<boolean>({
  key: "isEditingCareActivityAddressAtom",
  default: false,
});
