import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Fragment, useEffect } from "react";

interface FiltersSelectComponentProps {
  options: string[];
  selected: string;
  setSelected: (value: string, index: number) => void;
  type?: string;
  postType?: string;
  isLoading?: boolean;
}

const FiltersSelectComponent = ({
  options,
  selected,
  setSelected,
  type,
  postType,
  isLoading,
}: FiltersSelectComponentProps) => {
  const handleChange = (value: string, index: number) => {
    setSelected(value, index);

    if (type && postType) {
      localStorage.setItem(`${postType}-selected-${type}`, value);
    }
  };

  return (
    <Listbox
      value={selected}
      onChange={(value) => handleChange(value, options.indexOf(value))}
      disabled={isLoading}
    >
      <div className="relative mt-1 w-full max-w-[230px] xl:max-w-[250px]">
        <Listbox.Button
          className={`${
            isLoading ? "cursor-wait" : "cursor-default"
          } relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm`}
        >
          <span className="block truncate">{selected}</span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <ChevronUpDownIcon
              className="w-5 h-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute z-[1] mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm min-w-fit">
            {options.map((option, statusIdx) => (
              <Listbox.Option
                key={statusIdx}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                    active ? "bg-amber-100 text-amber-900" : "text-gray-900"
                  }`
                }
                value={option}
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`block truncate ${
                        selected ? "font-medium" : "font-normal"
                      }`}
                    >
                      {option}
                    </span>
                    {selected ? (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                        <CheckIcon className="w-5 h-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export default FiltersSelectComponent;
