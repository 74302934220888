import apiFetch from "@wordpress/api-fetch";
import { returnRootUrlBasedOnAppLocation } from "../../../shared/utils/checkIfIsInlocal";
import { OrderData } from "../types/orders";
import { addQueryArgs } from "@wordpress/url";

export interface OrderDataPropsV2 {
  order_id: string;
  billing_company_name: string;
  order_date: string;
  order_status: string;
  order_total: string;
  numero_de_stand: string;
  exhibition_name: string;
  exhibition_primary_color: string;
  exhibition_secondary_color: string;
  billing_first_name: string;
  billing_last_name: string;
  billing_phone: string;
  billing_email: string;
  user_website: string;
  activityType: string;
  has_been_to_exhibition: boolean;
}

// Create an api call with @wordpress/api-fetch for the endpoint admin-dashboard/v1/orders/
// export const fetchAllOrders = async (): Promise<any> => {
export const fetchAllOrders = async (): Promise<OrderData[]> => {
  let token = "";
  if (localStorage.getItem("token-dashboard")) {
    token = JSON.parse(localStorage.getItem("token-dashboard") as string);
  }
  const response = await apiFetch({
    path: `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v1/orders`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response as OrderData[];
};

interface fetchOrdersByExhibitionNameProps {
  exhibition_name: string | null;
}
export interface fetchOrdersByExhibitionNameResponse {
  id: number;
  exhibition_name: string;
  exhibition_year: string;
  exhibition_primary_color: string;
  exhibition_secondary_color: string;
  orders: number;
}

export const fetchExhibitionIdAndOrdersCount = async () => {
  let token = "";
  if (localStorage.getItem("token-dashboard")) {
    token = JSON.parse(localStorage.getItem("token-dashboard") as string);
  }
  const response = await apiFetch({
    path: `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v2/orders-count-for-exhibition`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response as fetchOrdersByExhibitionNameResponse;
};

interface OrdersQueryParams {
  salon_name?: string | null;
  per_page?: number;
  annee?: string | null;
  order_status?: string | null;
  page?: number;
  only_non_assigned_stands?: boolean;
  sorting_params?: string;
}
interface OrdersResponse {
  orders: OrderDataPropsV2[];
  total_pages: number;
  total_elements: number;
  total_count?: number;
}

export const fetchAllOrdersV2 = async ({
  salon_name,
  per_page,
  annee,
  order_status,
  page,
  only_non_assigned_stands,
  sorting_params,
}: OrdersQueryParams): Promise<OrdersResponse> => {
  const queryParams = {
    salon_name,
    per_page,
    annee,
    order_status,
    page,
    only_non_assigned_stands,
    sorting_params,
  };
  let token = "";
  if (localStorage.getItem("token-dashboard")) {
    token = JSON.parse(localStorage.getItem("token-dashboard") as string);
  }
  console.log("queryParams", queryParams);
  const response = await apiFetch({
    path: addQueryArgs(
      `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v2/orders`,
      queryParams
    ),
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response as OrdersResponse;
};
