import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AnalyticsScreen from "./features/analytics/screens/AnalyticsScreen";
import SingleCareScreen from "./features/careArea/screens/SingleCareScreen";
import useCaresAreasScreenConfig from "./features/careArea/screens/useCaresAreasScreenConfig";
import SingleConferenceScreen from "./features/conferences/screens/SingleConferenceScreen";
import useConferenceScreenConfig from "./features/conferences/screens/useConferenceScreenConfig";
import CouponsScreen from "./features/coupons/screens/CouponsScreen";
import SingleCouponScreen from "./features/coupons/screens/SingleCouponScreen";
import ExhibitionsScreen from "./features/exhibitions/screens/ExhibitionsScreen";
import SingleExhibitionScreen from "./features/exhibitions/screens/SingleExhibitionScreen";
import ExhibitorsScreen from "./features/exhibitors/screens/ExhibitorsScreen";
import SingleExhibitorScreen from "./features/exhibitors/screens/SingleExhibitorScreen";
import LoginPage from "./features/login/Login";
import OrdersScreenV2 from "./features/orders/screens/OrdersScreenV2";
import SingleOrderScreen from "./features/orders/screens/SingleOrderScreen";
import SingleStandScreen from "./features/stands/screens/SingleStandScreen";
import useStandsScreenConfig from "./features/stands/screens/useStandsScreenConfig";
import TicketsScreen from "./features/tickets/screens/TicketsScreen";
import VisitorsScreen from "./features/visitors/screens/VisitorsScreen";
import SingleWorkshopScreen from "./features/workshops/screens/SingleWorkshopScreen";
import useWorkshopsScreenConfig from "./features/workshops/screens/useWorkshopsScreenConfig";
import OverviewBoxesLayout from "./shared/ui/layouts/OverviewBoxesLayout";
import TableLayout from "./shared/ui/layouts/TableLayout";

let token = localStorage.getItem("token-dashboard");
if (token) {
  token = JSON.parse(token);
}

function AppAdminDashboard() {
  const conferenceScreenProps = useConferenceScreenConfig();
  const standScreenProps = useStandsScreenConfig();
  const workshopScreenProps = useWorkshopsScreenConfig();
  const caresAreasScreenProps = useCaresAreasScreenConfig();

  const router = createBrowserRouter([
    // {
    //   path: "/",
    //   element: <LoginPage />,
    // },
    {
      path: "/",
      element: token ? <OrdersScreenV2 /> : <LoginPage />,
    },
    {
      path: "/commandes",
      element: <OrdersScreenV2 />,
    },
    {
      path: "/commandes/:id",
      element: <SingleOrderScreen />,
    },
    {
      path: "/commandes/nouvelle-commande",
      element: <SingleOrderScreen />,
    },
    // Exhibitors
    {
      path: "/clients",
      element: <ExhibitorsScreen />,
    },
    {
      path: "visiteurs",
      element: <VisitorsScreen />,
    },
    {
      path: "/clients/:id",
      element: <SingleExhibitorScreen />,
    },
    // Stands
    {
      path: "/stands",
      element: (
        <div key={"stands"}>
          <TableLayout
            type="stand"
            overviewBoxes={
              <OverviewBoxesLayout title="Stands réservés" postType="stand" />
            }
            {...standScreenProps}
          />
        </div>
      ),
      // element: <StandsScreenV2 />,
    },
    {
      path: "/stands/:id",
      element: <SingleStandScreen />,
    },
    // Exhibitions
    {
      path: "/salons",
      element: <ExhibitionsScreen />,
    },
    {
      path: "/salons/:id",
      element: <SingleExhibitionScreen />,
    },
    // Conferences
    {
      path: "/conferences",
      element: (
        <div key={"conference"}>
          <TableLayout
            type="conference"
            overviewBoxes={
              <OverviewBoxesLayout
                title="Conférences à venir"
                postType="conference"
              />
            }
            {...conferenceScreenProps}
          />
        </div>
      ),
    },
    {
      path: "/conferences/:id",
      element: <SingleConferenceScreen />,
    },
    // Workshops
    {
      path: "/ateliers",
      element: (
        <div key={"workshop"}>
          <TableLayout
            type="atelier"
            overviewBoxes={
              <OverviewBoxesLayout
                title="Ateliers à venir"
                postType="atelier"
              />
            }
            {...workshopScreenProps}
          />
        </div>
      ),
    },
    {
      path: "/ateliers/:id",
      element: <SingleWorkshopScreen />,
    },
    // CareArea
    {
      path: "/espaces-soins",
      element: (
        <div key={"espaces-soins"}>
          <TableLayout
            type="espaces-soins"
            overviewBoxes={
              <OverviewBoxesLayout
                title="Espaces Soins à venir"
                postType="espace-soins"
              />
            }
            {...caresAreasScreenProps}
          />
        </div>
      ),
    },
    {
      path: "/espaces-soins/:id",
      element: <SingleCareScreen />,
    },
    {
      path: "/export",
      element: <AnalyticsScreen />,
    },
    {
      path: "/billets",
      element: <TicketsScreen />,
    },
    {
      path: "/coupons",
      element: <CouponsScreen />,
    },
    {
      path: "/coupons/:id",
      element: <SingleCouponScreen />,
    },
  ]);

  return <RouterProvider router={router} />;
}

export default AppAdminDashboard;
