import axios from "axios";
import { returnRootUrlBasedOnAppLocation } from "../../../shared/utils/checkIfIsInlocal";

interface deleteOrderProps {
    setIsDeletingOrder: React.Dispatch<React.SetStateAction<boolean>>;
    selectedOrderData: any;
    token: string;
    navigate: any;
}
export const deleteOrder = ({
    setIsDeletingOrder,
    selectedOrderData,
    token,
    navigate,
}: deleteOrderProps
) => {
    if (selectedOrderData) {
        setIsDeletingOrder(true);
        axios
            .post(
                `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v1/delete-order/`,
                {
                    id: selectedOrderData?.orderId,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((data) => {
                console.log("data", data);
                navigate(`/commandes`);
                window.location.reload();
            })
            .catch((error) => {
                console.log("error", error);
                if (error.code.includes("jwt")) {
                    localStorage.removeItem("token-dashboard");
                    window.location.replace("/");
                }
            })
            .finally(() => {
                setIsDeletingOrder(false);
            });
    }
};