import { useRecoilState, useRecoilValue } from "recoil";
import {
  selectedOrderAddDiscountProductButtonClickedAtom,
  selectedOrderAddProductButtonClickedAtom,
  selectedOrderAddRabaisProductButtonClickedAtom,
  selectedOrderAtom,
} from "../atoms/orders-atoms";
import SingleOrderInfoCardsItemData from "../components/SingleOrder/SingleOrderInfoCardsItemData";
import SingleOrderFooterTableButton from "../components/SingleOrder/SingleOrderTable/SingleOrderFooterTableButton";
import { CardWithHeader } from "../screens/SingleOrderScreen";

const SingleOrderFooterTableActionButtons = () => {
  const [
    selectedOrderAddProductButtonClicked,
    setSelectedOrderAddProductButtonClicked,
  ] = useRecoilState(selectedOrderAddProductButtonClickedAtom);

  const [
    selectedOrderAddDiscountProductButtonClicked,
    setSelectedOrderAddDiscountProductButtonClicked,
  ] = useRecoilState(selectedOrderAddDiscountProductButtonClickedAtom);

  const [
    selectedOrderAddRabaisProductButtonClicked,
    setSelectedOrderAddRabaisProductButtonClicked,
  ] = useRecoilState(selectedOrderAddRabaisProductButtonClickedAtom);

  const selectedOrder = useRecoilValue(selectedOrderAtom);

  const [selectedOrderData, setSelectedOrderData] =
    useRecoilState(selectedOrderAtom);

  return (
    <div className="flex flex-col gap-y-6">
      <div className="flex flex-row gap-x-4">
        <SingleOrderFooterTableButton
          title="Ajout produit"
          action={() => {
            setSelectedOrderAddProductButtonClicked(
              !selectedOrderAddProductButtonClicked
            );
          }}
        />
        {/* {selectedOrder && selectedOrder.products.length > 0 && ( */}
        <SingleOrderFooterTableButton
          // title="Ajouter un frais (annuler montant negatif force)"
          title="Ajouter un frais"
          action={() => {
            setSelectedOrderAddDiscountProductButtonClicked(
              !selectedOrderAddDiscountProductButtonClicked
            );
          }}
        />
        <SingleOrderFooterTableButton
          // title="Ajouter un frais (annuler montant negatif force)"
          title="Ajouter un rabais"
          action={() => {
            setSelectedOrderAddRabaisProductButtonClicked(
              !selectedOrderAddRabaisProductButtonClicked
            );
          }}
        />
        {/* )} */}
        {/* <SingleOrderFooterTableButton
        title="Ajout code promo"
        action={() => {
          console.log("Ajout code promo");
        }}
      />
      <SingleOrderFooterTableButton
        title="Remboursement"
        action={() => {
          console.log("Remboursement");
        }}
      />
      <SingleOrderFooterTableButton
        title="Ajout frais"
        action={() => {
          console.log("Ajout frais");
        }}
      /> */}
      </div>
      {/* <Invoice notes /> */}
      <CardWithHeader title="Commentaire facture">
        <SingleOrderInfoCardsItemData
          isEditable={true}
          setValue={(value) => {
            selectedOrderData &&
              setSelectedOrderData({
                ...selectedOrderData,
                // adminComments: value,
                invoiceComments: value,
              });
          }}
          title="Commentaire"
          value={selectedOrderData?.invoiceComments || "-"}
        />
      </CardWithHeader>
    </div>
  );
};

export default SingleOrderFooterTableActionButtons;

// Path: src/features/orders/layouts/SingleOrderFooterTableActionButtons.tsx
