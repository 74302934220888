import { StyleSheet } from "@react-pdf/renderer";

function pxCm(px: number): string {
  const dpi = 300;
  return (px / dpi) * 2.54 + "cm";
}

export const styles = StyleSheet.create({
  page: {
    // flexDirection: "row",
    // backgroundColor: "#fff",
    // paddingTop: 55,
    // paddingLeft: 100,
    // paddingRight: 100,
    fontFamily: "Poppins",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingBottom: 100,
  },
  pageFooter: {
    fontSize: pxCm(30),
    textAlign: "center",
    color: "#3D7077",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    marginBottom: pxCm(50),
  },
  section: {
    // margin: 10,
    // padding: 10,
    flexGrow: 1,
    color: "#3D7077",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0 auto",
    width: pxCm(2240),
    marginTop: pxCm(110),
  },
  logo: {
    width: pxCm(900),
    textAlign: "left",
  },
  // Right
  rightHeader: {
    textAlign: "center",
    width: pxCm(900),
  },
  exhibitionsLogos: {
    marginBottom: pxCm(50),
  },
  headerCompany: {
    fontSize: pxCm(45),
    color: "#3B7079",
    fontWeight: "bold",
  },
  headerAddress: {
    fontSize: pxCm(36),
    color: "#77ABAB",
  },
  // Content
  invoiceInfo: {
    width: pxCm(2240),
    margin: "0 auto",
    marginTop: pxCm(100),
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "stretch",
  },
  customerInfo: {
    backgroundColor: "#F7F5EF",
    width: pxCm(900),
    height: pxCm(535),
    fontSize: pxCm(36),
    paddingVertical: pxCm(32),
    paddingHorizontal: pxCm(48),
  },
  customerInfoTitle: {
    // fontWeight: 700,
    fontWeight: "bold",
    fontSize: pxCm(36),
    marginBottom: pxCm(23),
  },
  customerInfoText: {
    fontSize: pxCm(40),
    color: "#1e293b",
  },
  generalInfo: {
    width: pxCm(900),
    flexDirection: "column",
    justifyContent: "space-between",
    fontSize: pxCm(36),
    // paddingTop: pxCm(18),
    // backgroundColor: "#F7F5EF",
  },
  generalInfoCard: {
    backgroundColor: "#F7F5EF",
    width: pxCm(900),
    paddingVertical: pxCm(32),
    paddingHorizontal: pxCm(26),
  },
  generalInfoCardData: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  generalInfoCardDataMt: {
    marginTop: pxCm(4),
  },
  generalInfoCardTitle: {
    fontWeight: 700,
  },
  generalInfoCardText: {},
  // Table
  table: {
    fontSize: pxCm(36),
    fontWeight: "bold",
    margin: "0 auto",
    width: pxCm(2240),
    marginTop: pxCm(100),
  },
  tableHeader: {
    backgroundColor: "#F7F5EF",
    flexDirection: "row",
    paddingVertical: pxCm(32),
    paddingHorizontal: pxCm(26),
  },
  tableBody: {},
  tableHeaderOtherCol: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    width: "100%",
    // backgroundColor: "#ffffff",
    // justifyContent: "space-between",
  },
  tableHeaderOtherColItem: {
    // backgroundColor: "#000000",
    // border: "4px solid rgba(71, 112, 120)",
    textAlign: "right",
    // marginRight: 20,
    paddingHorizontal: pxCm(50),
    width: "20%",
    // width: 70,
  },
  tableRow: {
    flexDirection: "row",
    paddingVertical: pxCm(40),
    paddingHorizontal: pxCm(26),
    borderBottom: "0.1px solid rgba(71, 112, 120)",
  },
  tableRowProductName: {
    width: "33%",
    paddingHorizontal: pxCm(50),
    paddingLeft: pxCm(0),
    // paddingRight: pxCm(50),
  },
  tableRowOtherCol: {
    flexDirection: "row",
    flexGrow: 1,
    display: "flex",
    width: "100%",
    // justifyContent: "space-between",
    // textAlign: "center",
  },
  tableHeaderItem: {
    // paddingHorizontal: pxCm(50),
  },
  tableHeaderQty: {
    textAlign: "center",
    // width: pxCm(100),
    // width: "12%",
  },
  tableHeaderProducts: {
    width: "33%",
    paddingHorizontal: pxCm(0),
    // paddingRight: pxCm(50),
    paddingLeft: pxCm(0),
  },
  tableRowOtherColItem: {
    width: "20%",
    // textAlign: "right",
    paddingHorizontal: pxCm(50),
    // textAlign: "center",
    // fontSize: pxCm(33),
    // backgroundColor: "#000000",
    // border: "4px solid rgba(71, 112, 120)",
    textAlign: "right",
  },
  tableRowOtherColItemQty: {
    // width: "12%",
    textAlign: "center",
  },
  tableHeaderHT: {
    width: "133%",
  },
  tableRowHT: {
    width: "133%",
  },
  tableFooter: {
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: pxCm(44),
  },
  tableFooterDiscounts: {
    color: "#fff",
    backgroundColor: "#2E9499",
    width: pxCm(680),
    paddingVertical: pxCm(23),
    paddingHorizontal: pxCm(16),
  },
  tableFooterCard: {
    backgroundColor: "#F7F5EF",
    width: pxCm(1010),
    paddingTop: pxCm(4),
    // paddingVertical: pxCm(23),
    // paddingHorizontal: pxCm(16),
  },
  tableFooterCardData: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: pxCm(26),
  },
  tableFooterCardDataMt: {
    marginTop: pxCm(12),
  },
  tableFooterDiscount: {
    backgroundColor: "#8AA9AE",
    color: "#fff",
    paddingVertical: pxCm(4),
  },
  tableFooterCardTitle: {
    fontWeight: 700,
    // width: "70%",
    flexGrow: 1,
    textAlign: "right",
    marginRight: pxCm(30),
    // textTransform: "uppercase",
  },
  tableFooterCardText: {
    // width: pxCm(1010),
    // marginLeft: pxCm(28),
    fontWeight: 300,
  },
  tableFooterTotalPrice: {
    fontWeight: 700,
  },
  howToPay: {
    // backgroundColor: "#8AA9AE",
    borderColor: "#477078",
    borderWidth: pxCm(5),
    borderStyle: "solid",
    // color: "#8AA9AE",
    color: "#1e293b",
    margin: "0 auto",
    width: pxCm(2240),
    marginTop: pxCm(192),
    paddingHorizontal: pxCm(72),
    paddingVertical: pxCm(36),
  },
  howToPayTitle: {
    fontSize: pxCm(36),
    fontWeight: "bold",
    textTransform: "uppercase",
    marginBottom: pxCm(16),
  },
  howToPayTextBold: {
    fontWeight: "bold",
  },
  howToPayTextUnderline: {
    // textDecoration: "underline",
    fontWeight: 700,
    fontSize: pxCm(38),
  },
  howToPayTextP1: {
    fontSize: pxCm(36),
  },
  howToPayTextP2: {
    marginTop: pxCm(40),
    fontSize: pxCm(36),
  },
  howToPayTextP3: {
    marginTop: pxCm(40),
    fontSize: pxCm(40),
  },
  flower: {
    position: "absolute",
    bottom: 0,
    right: 0,
    width: pxCm(1760),
  },
  qrPage: {
    flexDirection: "column",
    justifyContent: "space-between",
    // justifyContent: "flex-end",
    alignItems: "center",
    paddingBottom: 0,
  },
  tableFooterTotal: {
    backgroundColor: "#ffffff",
    borderColor: "#477078",
    borderWidth: pxCm(5),
    borderStyle: "solid",
    paddingVertical: pxCm(16),
    paddingHorizontal: pxCm(16),
    fontSize: pxCm(36),
  },
});
