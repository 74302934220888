import apiFetch from "@wordpress/api-fetch";
import { addQueryArgs } from "@wordpress/url";
import { returnRootUrlBasedOnAppLocation } from "../../../shared/utils/checkIfIsInlocal";

export const fetchExhibitionsYears = async (): Promise<string[]> => {
  let token = "";
  if (localStorage.getItem("token-dashboard")) {
    token = JSON.parse(localStorage.getItem("token-dashboard") as string);
  }
  const response = await apiFetch({
    path: addQueryArgs(
      `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v2/exhibitions-annees`
    ),
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response as string[];
};
