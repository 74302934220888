export interface PillProps {
  label: string;
  bgColor?: string;
  textColor?: string;
}

const Pill = ({ label, bgColor, textColor }: PillProps) => {
  return (
    <div
      className="mx-auto w-32 rounded-full px-2 py-1 text-center"
      style={{ backgroundColor: bgColor, color: textColor }}
    >
      <span className="text-base font-bold">{label}</span>
    </div>
  );
};

export default Pill;
