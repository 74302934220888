import { Dialog, Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { ArrowLeftCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { EyeIcon, PencilIcon, XMarkIcon } from "@heroicons/react/24/solid";
import axios from "axios";
import { Fragment, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { ConferenceFlagAtom } from "../../../shared/atoms/flag-atoms";
import { selectedPostAtom } from "../../../shared/atoms/single-data-atoms";
import ActionPopup from "../../../shared/ui/components/ActionPopup";
import CardWithData from "../../../shared/ui/components/CardWithData";
import CustomDropzone from "../../../shared/ui/components/ImageDropzone";
import Loader from "../../../shared/ui/components/Loader";
import Pill from "../../../shared/ui/components/Pill";
import SinglePostInfoCardsItemData from "../../../shared/ui/components/SinglePostInfoCardsItemData";
import PageLayout from "../../../shared/ui/layouts/PageLayout";
import { returnRootUrlBasedOnAppLocation } from "../../../shared/utils/checkIfIsInlocal";
import { selectedCareAtom } from "../atoms/care-atoms";

interface CardWithHeaderProps {
  title: string;
  children: React.ReactNode;
  isEditable?: boolean;
  centerElements?: boolean;
  justifyCenter?: boolean;
  width?: string;
  headerChildren?: React.ReactNode;
}
const CardWithHeader = ({
  title,
  children,
  isEditable = false,
  centerElements = false,
  justifyCenter = true,
  width = "",
  headerChildren = null,
}: CardWithHeaderProps) => {
  return (
    <div className={`${width} flex grow flex-col rounded-lg bg-white shadow`}>
      <div className="flex w-full flex-row items-center justify-between rounded-lg rounded-b-none bg-[#14B8A6] px-6 py-3 font-bold text-white">
        <div className="flex flex-row items-center justify-between ">
          <span className="text-xl">{title}</span>
          {isEditable && <PencilIcon className="w-5" />}
        </div>
        <>{headerChildren}</>
      </div>
      <div
        className={`flex grow flex-col ${
          justifyCenter && "justify-center"
        } px-6 py-4 ${centerElements && "mx-auto"}`}
      >
        {children}
      </div>
    </div>
  );
};

interface EditableDataActionButtonsProps {
  handleEdit: () => void;
  handleValidate: () => void;
  handleCancel: () => void;
  isEditing: boolean;
}

const EditableDataActionButtons = ({
  handleEdit,
  handleValidate,
  handleCancel,
  isEditing,
}: EditableDataActionButtonsProps) => {
  return (
    <div>
      {/* Default state */}
      {!isEditing && (
        <div className="flex flex-row gap-x-2">
          <div
            className="cursor-pointer p-[6px] text-gray-200 transition hover:text-gray-600"
            onClick={() => {
              handleEdit();
            }}
          >
            <PencilIcon className="w-5" />
          </div>
        </div>
      )}
      {/* Editing state  */}
      {isEditing && (
        <div className="flex flex-row items-center gap-x-2">
          <div
            onClick={() => {
              handleValidate();
            }}
            className="cursor-pointer rounded-full p-1 text-green-400 transition hover:bg-green-100 hover:shadow"
          >
            <CheckIcon className="w-6" />
          </div>
          <div className="cursor-pointer rounded-full p-1 text-red-400 transition hover:bg-red-100 hover:shadow">
            <XMarkIcon className="w-6" onClick={() => handleCancel()} />
          </div>
        </div>
      )}
    </div>
  );
};
interface CareStatus {
  id: number;
  name: string;
}
const SingleCareScreen = () => {
  let token = "";
  if (localStorage.getItem("token-dashboard")) {
    token = JSON.parse(localStorage.getItem("token-dashboard") as string);
  }
  const careId = window.location.href.split("/").pop();
  const [selectedCareData, setSelectedCareData] =
    useRecoilState(selectedCareAtom);
  const [careStatuses, setCareStatuses] = useState<CareStatus[]>([
    {
      id: 0,
      name: "-",
    },
  ]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCareStatus, setSelectedCareStatus] = useState<CareStatus>(
    careStatuses[0]
  );
  const [isUpdatingCare, setIsUpdatingCare] = useState(false);
  const [isDeletingCare, setIsDeletingCare] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenCare, setIsOpenCare] = useState(false);
  const [isOpenTherapist, setIsOpenTherapist] = useState(false);

  const [files, setFiles] = useState<Object[]>();

  const [isEditingCareNumber, setIsEditingCareNumber] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isConfirmDeletionPopupVisible, setIsConfirmDeletionPopupVisible] =
    useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const availableTimeSlots = [
    "Vendredi 15h - 19h",
    "Samedi 10h30 - 14h30",
    "Samedi 15h - 19h",
    "Dimanche 10h30 - 14h30",
    "Dimanche 15h - 18h",
  ];
  const [selectedTimeSlots, setSelectedTimeSlots] = useState<string[] | null>(
    null
  );
  const [isEditingStandNumber, setIsEditingStandNumber] = useState(false);
  const inputRefStandNumber = useRef<HTMLInputElement>(null);
  const selected = useRecoilValue(ConferenceFlagAtom);
  const [selectedPost, setSelectedPost] = useRecoilState(selectedPostAtom);

  const handleEditStandNumber = () => {
    setIsEditingStandNumber(true);
    inputRefStandNumber.current?.focus();
  };

  const handleValidateStandNumber = () => {
    setIsEditingStandNumber(false);

    if (selectedCareData && inputRefStandNumber.current?.value) {
      setSelectedCareData({
        ...selectedCareData,
        singleInfo: {
          ...selectedCareData.singleInfo,
          standNumber: inputRefStandNumber.current.value,
        },
      });
    }
  };

  const handleCancelStandNumber = () => {
    if (selectedCareData) {
      setSelectedCareData({
        ...selectedCareData,
        singleInfo: {
          ...selectedCareData.singleInfo,
          standNumber: selectedCareData.singleInfo.standNumber,
        },
      });
    }
    setIsEditingStandNumber(false);
  };

  // const handleEdit = () => {
  //   setIsEditingCareNumber(true);
  //   inputRef.current?.focus();
  // };
  // const handleValidate = () => {
  //   setIsEditingCareNumber(false);

  //   if (selectedCareData && inputRef.current?.value) {
  //     setSelectedCareData({
  //       ...selectedCareData,
  //       singleInfo: {
  //         ...selectedCareData.singleInfo,
  //         salle: inputRef.current.value,
  //       },
  //     });
  //   }
  // };
  // const handleCancel = () => {
  //   setIsEditingCareNumber(false);
  // };

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  function closeModalCare() {
    setIsOpenCare(false);
  }

  function openModalCare() {
    setIsOpenCare(true);
  }

  function closeModalTherapist() {
    setIsOpenTherapist(false);
  }

  function openModalTherapist() {
    setIsOpenTherapist(true);
  }

  const fetchCaresAndStatusesData = async () => {
    setIsLoading(true);
    await axios
      .get(
        `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v1/cares?id=${careId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setSelectedCareData(res.data[0]);
        setSelectedPost(res.data[0]);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.code.includes("jwt")) {
          console.log(err);
          localStorage.removeItem("token-dashboard");
          window.location.replace("/");
        }
      });
    // Fetch admin-dashboard/v1/cares-statuses
    axios
      .get(
        `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v1/cares-statuses`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setCareStatuses(res.data);
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.code.includes("jwt")) {
          console.log(err);
          localStorage.removeItem("token-dashboard");
          window.location.replace("/");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (selectedCareData) {
      setIsLoading(false);
    }
    fetchCaresAndStatusesData();
  }, []);

  useEffect(() => {
    console.log(selectedCareData);
    console.log(selectedCareData?.singleInfo.phone);
  }, [selectedCareData]);

  useEffect(() => {
    if (careStatuses)
      selectedCareData &&
        setSelectedCareStatus(
          careStatuses.find(
            (careStatus) => careStatus.name === selectedCareData.status
          ) as CareStatus
        );
  }, [careStatuses]);

  const navigate = useNavigate();
  const popups = [
    <ActionPopup
      actionRunning={isDeletingCare}
      key={Math.random()}
      title="Supprimer l'espace soin ?"
      children={
        <p>
          Voulez-vous vraimer supprimer l'espace soin {selectedCareData?.id} ?
        </p>
      }
      isVisible={isConfirmDeletionPopupVisible}
      setIsVisible={() => {
        setIsConfirmDeletionPopupVisible(!isConfirmDeletionPopupVisible);
      }}
      action={() => {
        !isDeletingCare && deleteCare();
      }}
      cancelAction={() => {
        setIsConfirmDeletionPopupVisible(false);
      }}
      actionButtonDisabled={false}
      actionButtonTitle={
        <div
          className={`flex flex-row items-center ${
            isDeletingCare && "cursor-wait"
          }`}
        >
          {isDeletingCare && (
            <svg
              className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}
          {isDeletingCare ? "Suppression..." : "Supprimer"}
        </div>
      }
      cancelButtonVisible={true}
      actionButtonBackgroundColor="#f87171"
    />,
    <ActionPopup
      key={Math.random()}
      title="Espace soin mis à jour"
      children={
        <p>L'espace soin {selectedCareData?.id} a été mis à jour avec succès</p>
      }
      isVisible={isPopupVisible}
      setIsVisible={() => {
        setIsPopupVisible(!isPopupVisible);
        // Reload page
        window.location.reload();
      }}
      action={() => {
        setIsPopupVisible(false);
        // Reload page
        window.location.reload();
      }}
      cancelAction={() => {
        setIsPopupVisible(false);
        // Reload page
        window.location.reload();
      }}
      actionButtonDisabled={false}
      actionButtonTitle="Fermer"
      cancelButtonVisible={false}
    />,
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-[1200px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="mb-4 flex justify-end">
                  <button
                    type="button"
                    // className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={closeModal}
                  >
                    <XCircleIcon className="w-8 text-[#0F766E]" />
                  </button>
                </div>
                <div className="flex items-center justify-center">
                  <img src={selectedCareData?.singleInfo.caresImage} alt="" />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>,
    <Transition appear show={isOpenCare} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModalCare}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-[1200px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="mb-4 flex justify-end">
                  <button
                    type="button"
                    // className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={closeModalCare}
                  >
                    <XCircleIcon className="w-8 text-[#0F766E]" />
                  </button>
                </div>
                <div className="flex items-center justify-center">
                  <img src={selectedCareData?.singleInfo.caresImage} alt="" />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>,
    <Transition appear show={isOpenTherapist} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModalTherapist}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-[1200px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="mb-4 flex justify-end">
                  <button
                    type="button"
                    // className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={closeModalTherapist}
                  >
                    <XCircleIcon className="w-8 text-[#0F766E]" />
                  </button>
                </div>
                <div className="flex items-center justify-center">
                  <img
                    src={selectedCareData?.singleInfo.therapistImage}
                    alt=""
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>,
  ];

  const deleteCare = () => {
    if (selectedCareData) {
      setIsDeletingCare(true);
      axios
        .post(
          `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v1/delete-cares/`,
          {
            id: selectedCareData?.id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((data) => {
          console.log("data", data);
          navigate(`/espaces-soins`);
          window.location.reload();
        })
        .catch((error) => {
          console.log("error", error);
          if (error.code.includes("jwt")) {
            localStorage.removeItem("token-dashboard");
            window.location.replace("/");
          }
        })
        .finally(() => {
          setIsDeletingCare(false);
          // setIsPopupVisible(true);
        });
    }
  };

  const phoneWithPrefix = selectedCareData?.singleInfo.phone;

  const phoneWithoutPrefix = phoneWithPrefix?.replace(/^\+\d+/, "");
  return (
    <PageLayout>
      <>
        {popups}
        {isLoading ? (
          <Loader />
        ) : (
          <div className="flex flex-col gap-y-16">
            <div className="flex w-full justify-between">
              <Link
                className="flex flex-row items-center gap-x-4 text-2xl text-[#3c7179]"
                to={`/espaces-soins`}
                onClick={() => {}}
              >
                <ArrowLeftCircleIcon className="color-[#3C7179] w-10" /> Retour
                à la liste des Espaces Soins
              </Link>
              <div className="flex flex-row items-center gap-x-8">
                <button
                  disabled={isDeletingCare}
                  // className="flex flex-row items-center gap-x-4 rounded border-none bg-[#14b8a6] px-4 py-2 text-lg text-gray-50 shadow"
                  className={`${
                    isDeletingCare && "opacity-40"
                  } flex flex-row items-center rounded-md border-none bg-red-400 px-8 py-4 text-lg font-semibold text-white shadow`}
                  onClick={() => {
                    setIsConfirmDeletionPopupVisible(true);
                  }}
                  type="button"
                >
                  Supprimer
                </button>
                <button
                  disabled={isUpdatingCare}
                  // className="flex flex-row items-center gap-x-4 rounded border-none bg-[#14b8a6] px-4 py-2 text-lg text-gray-50 shadow"
                  className={`${
                    isUpdatingCare && "opacity-40"
                  } flex flex-row items-center rounded-md border-none bg-[#14b8a6] px-8 py-4 text-lg font-semibold text-white shadow`}
                  onClick={() => {
                    if (selectedCareData) {
                      console.log("selectedCareData", selectedCareData);

                      setIsUpdatingCare(true);
                      axios
                        .post(
                          `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v1/cares`,
                          {
                            careId: selectedCareData.id,
                            careTitle:
                              selectedCareData.singleInfo.cares[0]?.type,
                            cares: selectedCareData.singleInfo.cares,
                            careDescription:
                              selectedCareData.singleInfo.cares[0]?.description,
                            validatedTimeSlots:
                              selectedCareData.singleInfo.validatedTimeSlots,
                            careStatus: selectedCareStatus.name,
                            adminComments:
                              selectedCareData.singleInfo.adminComments,
                            userId: selectedCareData.singleInfo.userId,
                            caresImage: selectedCareData.singleInfo.caresImage,
                            therapistImage:
                              selectedCareData.singleInfo.therapistImage,
                            standNumber:
                              selectedCareData.singleInfo.standNumber,
                            orderId: selectedCareData.singleInfo.orderId,
                            bookingLink:
                              selectedCareData.singleInfo.bookingLink,
                            topCardsInfo: {
                              company: selectedCareData.singleInfo.company,
                              firstName: selectedCareData.singleInfo.firstName,
                              lastName: selectedCareData.singleInfo.lastName,
                              email: selectedCareData.singleInfo.email,
                              phone: selectedCareData.singleInfo.phone,
                              website: selectedCareData.singleInfo.website,
                              activityType:
                                selectedCareData.singleInfo.activityType,
                              hasAllreadyBeenToExhibition:
                                selectedCareData.singleInfo
                                  .hasAllreadyBeenToExhibition,
                            },
                            billing_country_code: selected,
                          },
                          {
                            headers: {
                              Authorization: `Bearer ${token}`,
                            },
                          }
                        )
                        .then((data) => {
                          console.log("data", data);
                        })
                        .catch((error) => {
                          console.log("error", error);
                          if (error.code.includes("jwt")) {
                            localStorage.removeItem("token-dashboard");
                            window.location.replace("/");
                          }
                        })
                        .finally(() => {
                          setIsUpdatingCare(false);
                          setIsPopupVisible(true);
                        });
                    }
                  }}
                  type="button"
                >
                  {isUpdatingCare && (
                    <svg
                      className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                  {isUpdatingCare ? "Mise à jour..." : "Mettre à jour"}
                </button>
              </div>
            </div>
            {/* {selectedStandData && ( */}
            <>
              {/* Content */}
              <div className="flex flex-col gap-y-11">
                <div className="grid grid-cols-3 gap-x-16">
                  {/* Left */}
                  <div className="col-span-2 flex flex-col gap-y-11">
                    {/* Top */}
                    <div className="flex flex-row gap-x-6">
                      <CardWithData width="33%">
                        <SinglePostInfoCardsItemData
                          isEditable={true}
                          title="Raison sociale"
                          value={selectedCareData?.singleInfo?.company || "-"}
                          setValue={(value) => {
                            setSelectedCareData((prevSelectedCareData) => {
                              if (!prevSelectedCareData) return null;
                              return {
                                ...prevSelectedCareData,
                                singleInfo: {
                                  ...prevSelectedCareData.singleInfo,
                                  company: value,
                                },
                              };
                            });
                          }}
                        />
                        <SinglePostInfoCardsItemData
                          isEditable={true}
                          title="Prénom"
                          value={selectedCareData?.singleInfo?.firstName || "-"}
                          setValue={(value) => {
                            setSelectedCareData((prevSelectedCareData) => {
                              if (!prevSelectedCareData) return null;
                              return {
                                ...prevSelectedCareData,
                                singleInfo: {
                                  ...prevSelectedCareData.singleInfo,
                                  firstName: value,
                                },
                              };
                            });
                          }}
                        />
                        <SinglePostInfoCardsItemData
                          isEditable={true}
                          title="Nom"
                          value={selectedCareData?.singleInfo?.lastName || "-"}
                          setValue={(value) => {
                            setSelectedCareData((prevSelectedCareData) => {
                              if (!prevSelectedCareData) return null;
                              return {
                                ...prevSelectedCareData,
                                singleInfo: {
                                  ...prevSelectedCareData.singleInfo,
                                  lastName: value,
                                },
                              };
                            });
                          }}
                        />
                        <div className="mt-5"></div>
                        <SinglePostInfoCardsItemData
                          isEditable={true}
                          isSelect={true}
                          title="Déjà venu au salon"
                          value={
                            selectedCareData?.singleInfo
                              ?.hasAllreadyBeenToExhibition
                              ? "Oui"
                              : "Non"
                          }
                          setValue={(value) => {
                            setSelectedCareData((prevSelectedCareData) => {
                              if (!prevSelectedCareData) return null;
                              return {
                                ...prevSelectedCareData,
                                singleInfo: {
                                  ...prevSelectedCareData.singleInfo,
                                  hasAllreadyBeenToExhibition:
                                    value === "true" ? true : false,
                                },
                              };
                            });
                          }}
                        />
                      </CardWithData>
                      <CardWithData width="33%">
                        <SinglePostInfoCardsItemData
                          isEditable={true}
                          title="Email"
                          email={selectedCareData?.singleInfo?.email}
                          value={selectedCareData?.singleInfo?.email || "-"}
                          setValue={(value) => {
                            setSelectedCareData((prevSelectedCareData) => {
                              if (!prevSelectedCareData) return null;
                              return {
                                ...prevSelectedCareData,
                                singleInfo: {
                                  ...prevSelectedCareData.singleInfo,
                                  email: value,
                                },
                              };
                            });
                          }}
                        />
                        <SinglePostInfoCardsItemData
                          isEditable={true}
                          phone={phoneWithPrefix}
                          title="Téléphone"
                          value={phoneWithoutPrefix}
                          setValue={(value) => {
                            setSelectedCareData((prevSelectedCareData) => {
                              if (!prevSelectedCareData) return null;
                              return {
                                ...prevSelectedCareData,
                                singleInfo: {
                                  ...prevSelectedCareData.singleInfo,
                                  phone: value,
                                },
                              };
                            });
                          }}
                        />
                        <SinglePostInfoCardsItemData
                          isEditable={true}
                          title="Site internet"
                          website={selectedCareData?.singleInfo?.website}
                          value={selectedCareData?.singleInfo?.website}
                          setValue={(value) => {
                            setSelectedCareData((prevSelectedCareData) => {
                              if (!prevSelectedCareData) return null;
                              return {
                                ...prevSelectedCareData,
                                singleInfo: {
                                  ...prevSelectedCareData.singleInfo,
                                  website: value,
                                },
                              };
                            });
                          }}
                        />
                        <div className="mt-5"></div>
                        <SinglePostInfoCardsItemData
                          isEditable={true}
                          title="Type d'activité"
                          value={
                            selectedCareData?.singleInfo?.activityType || "-"
                          }
                          setValue={(value) => {
                            setSelectedCareData((prevSelectedCareData) => {
                              if (!prevSelectedCareData) return null;
                              return {
                                ...prevSelectedCareData,
                                singleInfo: {
                                  ...prevSelectedCareData.singleInfo,
                                  activityType: value,
                                },
                              };
                            });
                          }}
                        />
                      </CardWithData>
                      <CardWithData width="33%">
                        <SinglePostInfoCardsItemData
                          isEditable={false}
                          title="Numéro de commande"
                          value={
                            // selectedCareData?.orderAndCareInfo?.orderNumber || "-"
                            selectedCareData?.singleInfo?.orderId ? (
                              <Link
                                to={`/commandes/${selectedCareData?.singleInfo?.orderId}`}
                                onClick={() =>
                                  localStorage.setItem("selectedMenuIndex", "0")
                                }
                              >
                                <span className="underline">
                                  {selectedCareData?.singleInfo?.orderId || "-"}
                                </span>
                              </Link>
                            ) : (
                              "-"
                            )
                          }
                        />
                        <SinglePostInfoCardsItemData
                          isEditable={false}
                          title="Statut de commande"
                          value={
                            selectedCareData?.singleInfo?.orderStatus || "-"
                          }
                        />
                        <SinglePostInfoCardsItemData
                          isEditable={false}
                          title="Statut de l'Espace Soins"
                          // value={selectedCareData?.orderAndCareInfo?.careStatus}
                          value={
                            <Listbox
                              value={selectedCareStatus}
                              onChange={setSelectedCareStatus}
                            >
                              <div className="relative mt-1">
                                <Listbox.Button className="relative w-full cursor-pointer rounded-lg border bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                  <span className="block truncate">
                                    {selectedCareStatus &&
                                      selectedCareStatus.name}
                                  </span>
                                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronUpDownIcon
                                      className="h-5 w-5 text-gray-400"
                                      aria-hidden="true"
                                    />
                                  </span>
                                </Listbox.Button>
                                <Transition
                                  as={Fragment}
                                  leave="transition ease-in duration-100"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                >
                                  <Listbox.Options className="absolute z-[1] mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {careStatuses.map((person, personIdx) => (
                                      <Listbox.Option
                                        key={personIdx}
                                        className={({ active }) =>
                                          `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                                            active
                                              ? "bg-amber-100 text-amber-900"
                                              : "text-gray-900"
                                          }`
                                        }
                                        value={person}
                                      >
                                        {({ selected }) => (
                                          <>
                                            <span
                                              className={`block truncate ${
                                                selected
                                                  ? "font-medium"
                                                  : "font-normal"
                                              }`}
                                            >
                                              {person.name}
                                            </span>
                                            {selected ? (
                                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                <CheckIcon
                                                  className="h-5 w-5"
                                                  aria-hidden="true"
                                                />
                                              </span>
                                            ) : null}
                                          </>
                                        )}
                                      </Listbox.Option>
                                    ))}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            </Listbox>
                          }
                        />
                        <div className="mt-5"></div>
                        {/* <div>
                        {selectedCareData?.orderAndCareInfo
                          ?.badgeExhibitorUrl ? (
                          <SinglePostInfoCardsItemData
                            isEditable={false}
                            title="Télécharger le badge exposant"
                            link={
                              selectedCareData?.orderAndCareInfo
                                ?.badgeExhibitorUrl || "#"
                            }
                          />
                        ) : null}
                        {selectedCareData?.orderAndCareInfo
                          ?.badgeParkingUrl ? (
                          <SinglePostInfoCardsItemData
                            isEditable={false}
                            title="Télécharger le badge parking"
                            link={
                              selectedCareData?.orderAndCareInfo
                                ?.badgeParkingUrl || "#"
                            }
                          />
                        ) : null}
                      </div> */}
                      </CardWithData>
                    </div>
                    {/* Bottom */}
                    <CardWithHeader
                      title={selectedCareData?.singleInfo.company || "-"}
                      headerChildren={
                        <div className="flex flex-row items-center gap-x-8">
                          {selectedCareData?.singleInfo?.exhibition.name ? (
                            <Pill
                              label={
                                selectedCareData?.singleInfo?.exhibition.name
                              }
                              bgColor={
                                selectedCareData?.singleInfo.exhibition
                                  .primaryColor
                              }
                              textColor={
                                selectedCareData?.singleInfo.exhibition
                                  .secondaryColor
                              }
                            />
                          ) : (
                            "-"
                          )}
                          <div className="flex flex-row items-center gap-x-2 break-words">
                            {selectedCareData?.singleInfo.orderHasStand && (
                              <>
                                {!isEditingStandNumber && (
                                  <div className="mx-auto rounded-full bg-[#f7f5ef] px-4 py-1 text-center">
                                    <div className="flex flex-row items-center gap-x-2 text-base font-bold">
                                      <span className="text-[#0F766E]">
                                        Stand n°
                                        {
                                          selectedCareData?.singleInfo
                                            .standNumber
                                        }
                                      </span>
                                    </div>
                                  </div>
                                )}
                                {isEditingStandNumber && (
                                  <input
                                    type="text"
                                    className="max-h-[32px] w-full max-w-[100px] grow rounded-full border-2 bg-gray-50 p-1 text-center text-[#0F766E] focus:outline-none"
                                    placeholder={
                                      selectedCareData?.singleInfo
                                        .standNumber !== "-"
                                        ? selectedCareData?.singleInfo
                                            .standNumber
                                        : ""
                                    }
                                    ref={inputRefStandNumber}
                                    onChange={(e) => {
                                      // selectedCareData &&
                                      //   setSelectedCareData({
                                      //     ...selectedCareData,
                                      //     singleInfo: {
                                      //       ...selectedCareData.singleInfo,
                                      //       standNumber: e.target.value,
                                      //     },
                                      //   });
                                    }}
                                  />
                                )}
                                <EditableDataActionButtons
                                  isEditing={isEditingStandNumber}
                                  handleEdit={handleEditStandNumber}
                                  handleValidate={handleValidateStandNumber}
                                  handleCancel={handleCancelStandNumber}
                                />
                              </>
                            )}
                            {/* <div
              className={`flex cursor-pointer flex-row gap-x-2 ${
                isEditingWorkshopNumber && ""
              }`}
            >
              <div
                className="p-[6px] text-gray-400 transition hover:text-gray-600"
                onClick={() => {
                  setIdEditingWorkshopNumber(!isEditingWorkshopNumber);
                  // inputRef.current?.focus();
                }}
              >
                <PencilIcon className="w-5" />
              </div>
            </div> */}
                          </div>
                          {/* <div className="mx-auto rounded-full bg-[#f7f5ef] px-4 py-1 text-center">
                          <div className="flex flex-row items-center text-base font-bold gap-x-2">
                            <div className="text-[#0F766E]">Care n°</div>
                            <div className="text-[#0F766E]">
                              {selectedCareData?.orderAndCareInfo
                                .careNumber || "-"}
                            </div>
                          </div>
                        </div> */}
                          {/* <div className="flex flex-row items-center gap-x-2">
                          {!isEditingCareNumber && (
                            <div className="mx-auto rounded-full bg-[#f7f5ef] px-4 py-1 text-center">
                              <div className="flex flex-row items-center text-base font-bold gap-x-2">
                                <span className="text-[#0F766E]">
                                  Salle{" "}
                                  {selectedCareData?.singleInfo.salle || "-"}
                                </span>
                              </div>
                            </div>
                          )}
                          {isEditingCareNumber && (
                            <input
                              type="text"
                              className="max-h-[32px] w-full max-w-[100px] grow rounded-full border-2 bg-gray-50 p-1 text-center text-[#0F766E] focus:outline-none"
                              placeholder={
                                selectedCareData?.singleInfo.salle !== "-"
                                  ? selectedCareData?.singleInfo.salle
                                  : ""
                              }
                              ref={inputRef}
                              onChange={(e) => {
                                selectedCareData &&
                                  setSelectedCareData({
                                    ...selectedCareData,
                                    singleInfo: {
                                      ...selectedCareData.singleInfo,
                                      salle: e.target.value,
                                    },
                                  });
                              }}
                            />
                          )}
                          <EditableDataActionButtons
                            isEditing={isEditingCareNumber}
                            handleEdit={handleEdit}
                            handleValidate={handleValidate}
                            handleCancel={handleCancel}
                          />
                        </div> */}
                        </div>
                      }
                    >
                      <div className="flex flex-col gap-y-8">
                        {/* Care */}
                        {/* <div className="flex flex-row items-center justify-between">
                        <SinglePostInfoCardsItemData
                          isEditable={false}
                          title="Type de care"
                          value={
                            selectedCareData?.singleInfo
                              .careType ? (
                              <span>
                                {
                                  selectedCareData?.singleInfo
                                    .careType
                                }
                              </span>
                            ) : (
                              "-"
                            )
                          }
                        />
                        <div className="flex flex-row items-center gap-x-6">
                          <SinglePostInfoCardsItemData
                            itemsCenter={true}
                            isEditable={false}
                            title="Parking"
                            value={
                              selectedCareData?.parkingProduct ? (
                                <CheckCircleIcon className="w-8 text-green-500" />
                              ) : (
                                <XCircleIcon className="w-8 text-red-500" />
                              )
                            }
                          />
                          <SinglePostInfoCardsItemData
                            itemsCenter={true}
                            isEditable={false}
                            title="Meilleure visibilité"
                            value={
                              selectedCareData?.betterVisibilityProduct ? (
                                <CheckCircleIcon className="w-8 text-green-500" />
                              ) : (
                                <XCircleIcon className="w-8 text-gray-400" />
                              )
                            }
                          />
                        </div>
                      </div> */}
                        {/* Soins */}
                        {/*  */}
                        {selectedCareData?.singleInfo.cares &&
                          Array.isArray(selectedCareData?.singleInfo.cares) && (
                            <>
                              {/* Soin 1 */}
                              {selectedCareData?.singleInfo.cares.length >
                                0 && (
                                <div key={0} className="flex flex-col gap-4">
                                  <SinglePostInfoCardsItemData
                                    isEditable={true}
                                    setValue={(value) => {
                                      selectedCareData &&
                                        setSelectedCareData({
                                          ...selectedCareData,
                                          title: value,
                                          singleInfo: {
                                            ...selectedCareData.singleInfo,
                                            cares:
                                              selectedCareData.singleInfo.cares.map(
                                                (currentCare, currentIndex) =>
                                                  currentIndex === 0
                                                    ? {
                                                        ...currentCare,
                                                        type: value,
                                                      }
                                                    : currentCare
                                              ),
                                          },
                                        });
                                    }}
                                    title="Titre du soin 1"
                                    value={
                                      selectedCareData?.singleInfo.cares[0]
                                        .type || "-"
                                    }
                                  />
                                  <SinglePostInfoCardsItemData
                                    isEditable={true}
                                    setValue={(value) => {
                                      selectedCareData &&
                                        setSelectedCareData({
                                          ...selectedCareData,
                                          singleInfo: {
                                            ...selectedCareData.singleInfo,
                                            cares:
                                              selectedCareData.singleInfo.cares.map(
                                                (currentCare, currentIndex) =>
                                                  currentIndex === 0
                                                    ? {
                                                        ...currentCare,
                                                        description: value,
                                                      }
                                                    : currentCare
                                              ),
                                          },
                                        });
                                    }}
                                    title="Description du soin 1"
                                    value={
                                      selectedCareData?.singleInfo.cares[0]
                                        .description || "-"
                                    }
                                  />
                                </div>
                              )}

                              {/* Soin 2 */}
                              {selectedCareData?.singleInfo.cares.length > 1 ? (
                                <div key={1} className="flex flex-col gap-4">
                                  <SinglePostInfoCardsItemData
                                    isEditable={true}
                                    setValue={(value) => {
                                      selectedCareData &&
                                        setSelectedCareData({
                                          ...selectedCareData,
                                          singleInfo: {
                                            ...selectedCareData.singleInfo,
                                            cares:
                                              selectedCareData.singleInfo.cares.map(
                                                (currentCare, currentIndex) =>
                                                  currentIndex === 1
                                                    ? {
                                                        ...currentCare,
                                                        type: value,
                                                      }
                                                    : currentCare
                                              ),
                                          },
                                        });
                                    }}
                                    title="Titre du soin 2"
                                    value={
                                      selectedCareData?.singleInfo.cares[1]
                                        .type || "-"
                                    }
                                  />
                                  <SinglePostInfoCardsItemData
                                    isEditable={true}
                                    setValue={(value) => {
                                      selectedCareData &&
                                        setSelectedCareData({
                                          ...selectedCareData,
                                          singleInfo: {
                                            ...selectedCareData.singleInfo,
                                            cares:
                                              selectedCareData.singleInfo.cares.map(
                                                (currentCare, currentIndex) =>
                                                  currentIndex === 1
                                                    ? {
                                                        ...currentCare,
                                                        description: value,
                                                      }
                                                    : currentCare
                                              ),
                                          },
                                        });
                                    }}
                                    title="Description du soin 2"
                                    value={
                                      selectedCareData?.singleInfo.cares[1]
                                        .description || "-"
                                    }
                                  />
                                </div>
                              ) : (
                                // Create the second element in the array if it does not exist
                                <div key={1} className="flex flex-col gap-4">
                                  <span className="text-red-300">
                                    (Le thérapeute n'a commandé qu'un seul soin.
                                    Vous pouvez en rajouter un deuxième via les
                                    champs ci-dessous)
                                  </span>
                                  <div className="flex-col gap-4">
                                    <SinglePostInfoCardsItemData
                                      isEditable={true}
                                      setValue={(value) => {
                                        selectedCareData &&
                                          setSelectedCareData({
                                            ...selectedCareData,
                                            singleInfo: {
                                              ...selectedCareData.singleInfo,
                                              cares: [
                                                ...(selectedCareData.singleInfo
                                                  .cares || []),
                                                {
                                                  type: value,
                                                  description: "",
                                                },
                                              ],
                                            },
                                          });
                                      }}
                                      title="Titre du soin 2"
                                      value={"-"}
                                    />
                                    <SinglePostInfoCardsItemData
                                      isEditable={true}
                                      setValue={(value) => {
                                        selectedCareData &&
                                          setSelectedCareData({
                                            ...selectedCareData,
                                            singleInfo: {
                                              ...selectedCareData.singleInfo,
                                              cares: [
                                                ...(selectedCareData.singleInfo
                                                  .cares || []),
                                                {
                                                  type: "",
                                                  description: value,
                                                },
                                              ],
                                            },
                                          });
                                      }}
                                      title="Description du soin 2"
                                      value={"-"}
                                    />
                                  </div>
                                </div>
                              )}
                            </>
                          )}

                        {/*  */}
                        <SinglePostInfoCardsItemData
                          isEditable={false}
                          title="Créneau(x) souhaité(s)"
                          value={
                            selectedCareData?.singleInfo.desiredTimeSlots.length
                              ? selectedCareData?.singleInfo.desiredTimeSlots
                                ? selectedCareData?.singleInfo.desiredTimeSlots.map(
                                    (timeSlot, index) => {
                                      return (
                                        <span key={index}>
                                          {timeSlot}
                                          {index <
                                            selectedCareData?.singleInfo
                                              .desiredTimeSlots.length -
                                              1 && ", "}{" "}
                                        </span>
                                      );
                                    }
                                  )
                                : "-"
                              : "-"
                          }
                        />
                        <SinglePostInfoCardsItemData
                          isEditable={false}
                          title="Créneau(x) validé(s)"
                          value={
                            // Create a list of checkboxes with availableTimeSlots and on click use the setSelectedTimeSlot method to set the selected time slot. If selectedTimeSlot is the timeSlot clicked then set it to null
                            <div className="mt-2 flex flex-col items-start gap-y-2">
                              {availableTimeSlots?.map((timeSlot, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="flex select-none flex-row items-center gap-x-2 hover:cursor-pointer"
                                    onClick={() => {
                                      // if (selectedTimeSlots?.includes(timeSlot)) {
                                      //   setSelectedTimeSlots(
                                      //     selectedTimeSlots?.filter(
                                      //       (selectedTimeSlot) =>
                                      //         selectedTimeSlot !== timeSlot
                                      //     )
                                      //   );
                                      // } else {
                                      //   // If the time slot is not in the array, add it
                                      //   setSelectedTimeSlots([
                                      //     ...(selectedTimeSlots || []),
                                      //     timeSlot,
                                      //   ]);
                                      // }

                                      if (selectedCareData) {
                                        if (
                                          selectedCareData.singleInfo.validatedTimeSlots.includes(
                                            timeSlot
                                          )
                                        ) {
                                          setSelectedCareData({
                                            ...selectedCareData,
                                            singleInfo: {
                                              ...selectedCareData.singleInfo,
                                              validatedTimeSlots:
                                                selectedCareData.singleInfo.validatedTimeSlots.filter(
                                                  (selectedTimeSlot) =>
                                                    selectedTimeSlot !==
                                                    timeSlot
                                                ),
                                            },
                                          });
                                        } else {
                                          setSelectedCareData({
                                            ...selectedCareData,
                                            singleInfo: {
                                              ...selectedCareData.singleInfo,
                                              validatedTimeSlots: [
                                                ...selectedCareData.singleInfo
                                                  .validatedTimeSlots,
                                                timeSlot,
                                              ],
                                            },
                                          });
                                        }
                                      }
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      className="h-4 w-4 rounded-md border-2 border-[#0F766E] text-[#0F766E] focus:outline-none"
                                      checked={
                                        // Add the time slot to the selectedTimeSlots array if it is not already in it
                                        // selectedTimeSlots?.includes(timeSlot) ||
                                        // false
                                        selectedCareData?.singleInfo.validatedTimeSlots.includes(
                                          timeSlot
                                        ) || false
                                      }
                                      onChange={() => {
                                        // If the time slot is already in the array, remove it
                                        // if (
                                        //   selectedTimeSlots?.includes(timeSlot)
                                        // ) {
                                        //   setSelectedTimeSlots(
                                        //     selectedTimeSlots?.filter(
                                        //       (selectedTimeSlot) =>
                                        //         selectedTimeSlot !== timeSlot
                                        //     )
                                        //   );
                                        // } else {
                                        //   // If the time slot is not in the array, add it
                                        //   setSelectedTimeSlots([
                                        //     ...(selectedTimeSlots || []),
                                        //     timeSlot,
                                        //   ]);
                                        // }

                                        if (selectedCareData) {
                                          if (
                                            selectedCareData.singleInfo.validatedTimeSlots.includes(
                                              timeSlot
                                            )
                                          ) {
                                            setSelectedCareData({
                                              ...selectedCareData,
                                              singleInfo: {
                                                ...selectedCareData.singleInfo,
                                                validatedTimeSlots:
                                                  selectedCareData.singleInfo.validatedTimeSlots.filter(
                                                    (selectedTimeSlot) =>
                                                      selectedTimeSlot !==
                                                      timeSlot
                                                  ),
                                              },
                                            });
                                          } else {
                                            setSelectedCareData({
                                              ...selectedCareData,
                                              singleInfo: {
                                                ...selectedCareData.singleInfo,
                                                validatedTimeSlots: [
                                                  ...selectedCareData.singleInfo
                                                    .validatedTimeSlots,
                                                  timeSlot,
                                                ],
                                              },
                                            });
                                          }
                                        }
                                      }}
                                    />
                                    <span className="">{timeSlot}</span>
                                  </div>
                                );
                              }) || "-"}
                            </div>
                          }
                        />
                        <SinglePostInfoCardsItemData
                          isEditable={true}
                          setValue={(value) => {
                            selectedCareData &&
                              setSelectedCareData({
                                ...selectedCareData,
                                singleInfo: {
                                  ...selectedCareData.singleInfo,
                                  bookingLink: value,
                                },
                              });
                          }}
                          title="Lien de réservation"
                          value={
                            selectedCareData?.singleInfo.bookingLink || "-"
                          }
                        />
                        {/* <SinglePostInfoCardsItemData
                        isEditable={false}
                        title="Description conférence"
                        value={
                          selectedCareData?.singleInfo. || "-"
                        }
                      /> */}
                        {/* <SinglePostInfoCardsItemData
                        isEditable={false}
                        title="Créneau souhaité"
                        value={
                          selectedCareData?.singleInfo
                            .careCreneauSouhaite || "-"
                        }
                      /> */}
                        {/* <SinglePostInfoCardsItemData
                        isEditable={false}
                        title="Horaire validé"
                        value={selectedCareData?.info || "-"}
                      /> */}
                        <hr />
                        <SinglePostInfoCardsItemData
                          isEditable={false}
                          title="Commentaire exposant"
                          value={selectedCareData?.singleInfo.comments || "-"}
                        />
                        <SinglePostInfoCardsItemData
                          isEditable={true}
                          setValue={(value) => {
                            selectedCareData &&
                              setSelectedCareData({
                                ...selectedCareData,
                                singleInfo: {
                                  ...selectedCareData.singleInfo,
                                  adminComments: value,
                                },
                              });
                          }}
                          title="Commentaire admin"
                          value={
                            selectedCareData?.singleInfo.adminComments || "-"
                          }
                        />
                        {/* Comment admin */}
                        {/* <SinglePostInfoCardsItemData
                        isEditable={true}
                        title="Commentaire admin"
                        value={selectedCareData?.adminComments || "-"}
                        setValue={(value) => {
                          selectedCareData &&
                            setSelectedCareData({
                              ...selectedCareData,
                              adminComments: value,
                            });
                        }}
                      /> */}
                      </div>
                    </CardWithHeader>
                  </div>
                  {/* Right */}
                  <div className="col-span-1 flex flex-col">
                    <CardWithHeader title="Photos" justifyCenter={false}>
                      {/* {selectedCareData?.adsProduct ? ( */}
                      <div className="flex flex-col gap-y-10">
                        {/* <div className="flex flex-col gap-y-4">
                        <div className="flex flex-col gap-y-4">
                          <div className="flex flex-col gap-y-0">
                            <span className="font-bold text-[#3C7179]">
                              Photo du thérapeute
                            </span>
                          </div>
                          <div className="flex flex-row items-stretch gap-x-2">
                            <div className="">
                              <img
                                src={
                                  selectedCareData?.singleInfo.therapistImage
                                    ?.url
                                }
                                alt=""
                              />
                            </div>
                            <div className="flex flex-col justify-between">
                              <div className="flex flex-row gap-x-2">
                                <button className="flex items-center justify-center rounded-full bg-[#14B8A6] p-2 text-white shadow">
                                  <PencilIcon className="w-5" />
                                </button>
                                <button className="flex items-center justify-center rounded-full bg-[#14B8A6] p-2 text-white shadow">
                                  <TrashIcon className="w-5" />
                                </button>
                              </div>
                              <div className="flex flex-col items-start">
                                <button
                                  className="flex items-center justify-center rounded-full bg-[#14B8A6] p-2 text-white shadow"
                                  onClick={() => {
                                    openModal();
                                  }}
                                >
                                  <EyeIcon className="w-5" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                        <div className="flex flex-col gap-y-4">
                          {/* Photo soin */}
                          <div className="flex flex-col gap-y-4">
                            <div className="flex flex-row items-center gap-x-2 gap-y-0">
                              <span className="font-bold text-[#3C7179]">
                                Photo du soin
                              </span>
                              {selectedCareData?.singleInfo.caresImage && (
                                <div className="flex flex-col items-start">
                                  <button
                                    className="flex items-center justify-center rounded-full bg-[#14B8A6] p-2 text-white shadow"
                                    onClick={() => {
                                      openModalCare();
                                    }}
                                  >
                                    <EyeIcon className="w-5" />
                                  </button>
                                </div>
                              )}
                            </div>
                            <div className="flex flex-row items-stretch gap-x-2">
                              <div className="flex w-full flex-col justify-between">
                                <div className="flex flex-row gap-x-2">
                                  <CustomDropzone
                                    files={
                                      selectedCareData?.singleInfo?.caresImage
                                        ? [
                                            {
                                              name: selectedCareData?.singleInfo
                                                .caresImage,
                                              preview:
                                                selectedCareData?.singleInfo
                                                  .caresImage,
                                            },
                                          ]
                                        : []
                                    }
                                    setFiles={setFiles}
                                    setValues={(value) =>
                                      selectedCareData &&
                                      setSelectedCareData({
                                        ...selectedCareData,
                                        singleInfo: {
                                          ...selectedCareData.singleInfo,
                                          caresImage: value,
                                        },
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              {/* )} */}
                            </div>
                          </div>
                          {/* Photo conferencier */}
                          {/* <div className="flex flex-col gap-y-4">
                            <div className="flex flex-row items-center gap-x-2 gap-y-0">
                              <span className="font-bold text-[#3C7179]">
                                Photo du thérapeute
                              </span>
                              {selectedCareData?.singleInfo.therapistImage && (
                                <div className="flex flex-col items-start">
                                  <button
                                    className="flex items-center justify-center rounded-full bg-[#14B8A6] p-2 text-white shadow"
                                    onClick={() => {
                                      openModalTherapist();
                                    }}
                                  >
                                    <EyeIcon className="w-5" />
                                  </button>
                                </div>
                              )}
                            </div>
                            <div className="flex flex-row items-stretch gap-x-2">
                              <div className="flex flex-col justify-between w-full">
                                <div className="flex flex-row gap-x-2">
                                  <CustomDropzone
                                    files={
                                      selectedCareData?.singleInfo
                                        ?.therapistImage
                                        ? [
                                            {
                                              name: selectedCareData?.singleInfo
                                                .therapistImage,
                                              preview:
                                                selectedCareData?.singleInfo
                                                  .therapistImage,
                                            },
                                          ]
                                        : []
                                    }
                                    setFiles={setFiles}
                                    setValues={(value) =>
                                      selectedCareData &&
                                      setSelectedCareData({
                                        ...selectedCareData,
                                        singleInfo: {
                                          ...selectedCareData.singleInfo,
                                          therapistImage: value,
                                        },
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                      {/* ) : null} */}
                    </CardWithHeader>
                  </div>
                </div>
              </div>
            </>
            {/* )} */}
          </div>
        )}
      </>
    </PageLayout>
  );
};

export default SingleCareScreen;
