import { atom } from "recoil";
import { Ticket } from "../types/ticket";

export const filteredTicketsDataAtom = atom<Ticket[] | null>({
  key: "filteredTicketsDataAtom",
  default: null,
});

export const ticketsDataAtom = atom<Ticket[] | null>({
  key: "ticketsDataAtom",
  default: null,
});

export const selectedTicketIndexAtom = atom<number | null>({
  key: "selectedTicketIndexAtom",
  default: null,
});

export const selectedTicketAtom = atom<Ticket | null>({
  key: "selectedTicketAtom",
  default: null,
});

export const isUpdatingTicketAtom = atom<boolean>({
  key: "isUpdatingTicketAtom",
  default: false,
});
