import apiFetch from "@wordpress/api-fetch";
import { returnRootUrlBasedOnAppLocation } from "../utils/checkIfIsInlocal";
import { addQueryArgs } from "@wordpress/url";

interface PostsCountProps {
  exhibition_name: string;
  exhibition_year: string;
  postsCount: number;
  exhibition_primary_color: string;
  exhibition_secondary_color: string;
  id: number;
  loading: boolean;
}
export const fetchPostsCount = async (post_type: string) => {
  let token = "";
  if (localStorage.getItem("token-dashboard")) {
    token = JSON.parse(localStorage.getItem("token-dashboard") as string);
  }
  const response = await apiFetch({
    path: `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v3/posts-count?post_type=${post_type}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response as PostsCountProps;
};
