import { PencilIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { returnPriceFormattedCHF } from "../../../../shared/utils/numbersManipulations";
import {
  DiscountLineItem,
  selectedOrderAddDiscountProductButtonClickedAtom,
  selectedOrderAtom,
  selectedOrderDiscountLineItemsAtom,
  selectedOrderSelectedDiscountLineItemAtom,
  selectedOrderAddRabaisProductButtonClickedAtom,
} from "../../atoms/orders-atoms";
import { OrderData } from "../../types/orders";

const SingleOrderDiscountLineItems = () => {
  const [
    selectedOrderAddDiscountProductButtonClicked,
    setSelectedOrderAddDiscountProductButtonClicked,
  ] = useRecoilState<boolean>(selectedOrderAddDiscountProductButtonClickedAtom);
  const [
    selectedOrderAddRabaisProductButtonClicked,
    setSelectedOrderAddRabaistProductButtonClicked,
  ] = useRecoilState<boolean>(selectedOrderAddRabaisProductButtonClickedAtom);

  // const [selectedOrderData, setSelectedOrderData] =
  //   useRecoilState<any>(selectedOrderAtom);

  const [discountLineItems, setDiscountLineItems] = useRecoilState<
    DiscountLineItem[]
  >(selectedOrderDiscountLineItemsAtom);

  const [selectedOrderData, setSelectedOrderData] =
    useRecoilState<OrderData | null>(selectedOrderAtom);

  const [
    selectedOrderSelectedDiscountLineItem,
    setSelectedOrderSelectedDiscountLineItem,
  ] = useRecoilState(selectedOrderSelectedDiscountLineItemAtom);

  const deleteDiscountLineItem = (index: number) => {
    if (selectedOrderData) {
      // const newDiscountLineItems = [...discountLineItems];
      const newDiscountLineItems = [...selectedOrderData.fees];
      newDiscountLineItems.splice(index, 1);
      setDiscountLineItems(newDiscountLineItems);
      selectedOrderData &&
        setSelectedOrderData({
          ...selectedOrderData,
          fees: newDiscountLineItems,
        });
      setSelectedOrderSelectedDiscountLineItem(null);
      // Get the total for this line item
      // const total = discountLineItems[index].amount;
      const total = selectedOrderData?.fees[index].amount;
      // Convert negative total to positive
      // const positiveTotal = total * -1;
      // Remove the sign from the total
      const totalWithoutSign = Math.abs(total ?? 0);

      if (selectedOrderData) {
        const newTotal = selectedOrderData.total + totalWithoutSign;

        setSelectedOrderData({
          ...selectedOrderData,
          total: newTotal,
          fees: newDiscountLineItems,
        });
      }
    }

    // Update selectedOrderData vat by adding the total
    // setSelectedOrderData({
    //   ...selectedOrderData,
    //   vat: selectedOrderData.vat - total * 0.077,
    // });
  };

  useEffect(() => {
    if (selectedOrderData) {
      if (selectedOrderData.products.length === 0) {
        setDiscountLineItems([]);
        setSelectedOrderData({
          ...selectedOrderData,
          fees: [],
        });
      }
    }
  }, [selectedOrderAtom]);

  const editDiscountLineItemInPopup = (index: number) => {
    // setSelectedOrderSelectedDiscountLineItem(discountLineItems[index]);
    selectedOrderData &&
      setSelectedOrderSelectedDiscountLineItem(selectedOrderData.fees[index]);
    // setSelectedOrderAddDiscountProductButtonClicked(true);
    selectedOrderData && selectedOrderData.fees[index].amount > 0
      ? setSelectedOrderAddDiscountProductButtonClicked(true)
      : setSelectedOrderAddRabaistProductButtonClicked(true);
  };

  // return discountLineItems.length > 0 ? (
  return selectedOrderData && selectedOrderData.fees.length > 0 ? (
    <div className="flex flex-col gap-y-2 px-2">
      <div className="flex flex-row items-center justify-between">
        <p className="text-2xl font-semibold text-[#334155]">Frais et rabais</p>
      </div>
      <div className="flex flex-col gap-y-4">
        {selectedOrderData.fees.map((discountLineItem, index) => (
          <div
            key={discountLineItem.id}
            className="flex flex-row items-center justify-between border-b-2 border-gray-200 py-2"
          >
            <div className="flex w-full flex-col gap-y-2">
              <div className="flex flex-row items-center justify-between">
                <div className="flex w-1/5 flex-col gap-y-2">
                  <span className="font-bold text-[#3C7179]">Montant HT</span>
                  <span className="text-lg font-semibold">
                    {/* {" -"}
                    {returnPriceFormattedCHF(discountLineItem.amount).replace(
                      "-",
                      " "
                    )} */}
                    {returnPriceFormattedCHF(discountLineItem.amount)}
                  </span>
                </div>
                <div className="flex w-1/5 flex-col gap-y-2">
                  <span className="font-bold text-[#3C7179]">TVA</span>
                  <span className="text-lg font-semibold">
                    {/* {" -"}
                    {returnPriceFormattedCHF(discountLineItem.vat).replace(
                      "-",
                      " "
                    )} */}
                    {returnPriceFormattedCHF(discountLineItem.vat)}
                  </span>
                </div>
                <div className="flex grow flex-col gap-y-2">
                  <span className="font-bold text-[#3C7179]">Motif</span>
                  <span className="text-lg font-semibold">
                    {discountLineItem.name === "" ? (
                      <span className="text-gray-400">
                        {discountLineItem.amount < 0
                          ? `Rabais de `
                          : `Frais de `}
                        {returnPriceFormattedCHF(discountLineItem.amount)}
                      </span>
                    ) : (
                      <span>{discountLineItem.name}</span>
                    )}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-row gap-x-4">
              <button
                type="button"
                className="border-none bg-none"
                onClick={() => editDiscountLineItemInPopup(index)}
              >
                <span className="sr-only">Modifier frais</span>
                <PencilIcon className="w-6" />
              </button>
              <button
                type="button"
                className="border-none bg-none"
                onClick={() => deleteDiscountLineItem(index)}
              >
                <span className="sr-only">Supprimer frais</span>
                <XCircleIcon className="w-6" />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  ) : null;
};

export default SingleOrderDiscountLineItems;
