import { useState, useCallback, useEffect, useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { Link } from "react-router-dom";
import Pill from "../../../shared/ui/components/Pill";
import FilterComponents from "./FilterComponent";
import {
  ConferenceDataPropsV2,
  fetchAllConferencesV2,
} from "../api/fetchConferences";
import { fetchExhibitionsYears } from "../../orders/api/fetchExhibitionsYears";
import {
  QuickViewOrderItemProps,
  quickViewOrderItemAtom,
} from "../../orders/atoms/orders-atoms";
import { useRecoilState } from "recoil";
import { EyeIcon } from "@heroicons/react/20/solid";
import CustomHeaderComponent from "../../../shared/ui/components/CustomHeaderComponent";

const useConferenceScreenConfig = () => {
  const post = "conference";
  const columnHelper = createColumnHelper<ConferenceDataPropsV2>();
  const [orderStatusAvailableFilters] = useState<string[]>([
    "Tous les statuts",
    "Non rensigné",
    "En attente de validation",
    "Approuvé",
    "À modifier",
  ]);
  const [exhibitionsAvailableFilters] = useState<string[]>([
    "Tous les salons",
    "Bulle",
    "Morges",
    "Aigle",
    "Genève",
  ]);
  // const [standsAssignedAvailableFilters, setStandsAssignedAvailableFilters] =
  //   useState<string[]>(["Toutes les commandes", "Stand non affecté"]);
  const [
    exhibitionsYearsAvailableFilters,
    setExhibitionsYearsAvailableFilters,
  ] = useState<string[]>(["Toutes les années"]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<string>(
    orderStatusAvailableFilters[0]
  );
  const [selectedExhibitions, setSelectedExhibitions] = useState<string>(
    exhibitionsAvailableFilters[0]
  );
  const [selectedExhibitionYear, setSelectedExhibitionYear] = useState<string>(
    exhibitionsYearsAvailableFilters[0]
  );
  const [selectedSortingColumn, setSelectedSortingColumn] =
    useState<string>("");

  // Ajoutez d'autres états nécessaires...
  const localStorageFiltersKeys = [
    `${post}-selected-status`,
    `${post}-selected-exhibition`,
    `${post}-selected-year`,
    `sorting-${post}-column`,
  ];

  useEffect(() => {
    async function getYears() {
      try {
        const years = await fetchExhibitionsYears();
        setExhibitionsYearsAvailableFilters(["Toutes les années", ...years]);
      } catch (error) {
        console.error("Error fetching years:", error);
      }
    }

    getYears();
  }, []);

  // Logique pour mettre à jour les filtres en fonction du localStorage
  const updateFiltersBasedOnLocalStorage = useCallback(() => {
    const status = localStorage.getItem(`${post}-selected-status`);
    if (status && status !== selectedStatus) {
      setSelectedStatus(status);
    }

    const exhibition = localStorage.getItem(`${post}-selected-exhibition`);
    if (exhibition && exhibition !== selectedExhibitions) {
      setSelectedExhibitions(exhibition);
    }

    const year = localStorage.getItem(`${post}-selected-year`);
    if (year && year !== selectedExhibitionYear) {
      setSelectedExhibitionYear(year);
    }
  }, [post]);

  useEffect(() => {
    return () => {
      console.log("year", selectedExhibitionYear);
      console.log("exibition", selectedExhibitions);
      console.log("status", selectedStatus);
      console.log("sortingLOG", selectedSortingColumn);
    };
  }, []);

  // Logique pour réinitialiser les filtres
  const resetFilters = useCallback(() => {
    setSelectedStatus(orderStatusAvailableFilters[0]);
    setSelectedExhibitions(exhibitionsAvailableFilters[0]);
    setSelectedExhibitionYear(exhibitionsYearsAvailableFilters[0]);
    setSelectedSortingColumn("");
  }, []);

  const [quickViewOrderItem, setQuickViewOrderItem] = useRecoilState(
    quickViewOrderItemAtom
  );

  useEffect(() => {
    console.log("selectedSortingColumn", selectedSortingColumn);
  }, [selectedSortingColumn]);

  // Définition des colonnes pour la table des conférences
  const columns = [
    columnHelper.accessor("speaker", {
      header: () => (
        <CustomHeaderComponent
          title="conférencier"
          sortName="conférencier"
          post={post}
          selectedSortingColumn={selectedSortingColumn}
          setSelectedSortingColumn={setSelectedSortingColumn}
        />
      ),
      cell: ({ getValue, row }) => (
        <div className="flex items-center justify-center gap-x-2">
          <Link
            className="underline"
            to={
              row.original.conference_id
                ? `/conferences/${row.original.conference_id}`
                : `/conferences/`
            }
            onClick={() => {}}
          >
            {row.original.speaker || "-"}
          </Link>
          <div
            className="hover:cursor-pointer"
            onClick={() => {
              // const selectedOrder =
              //   orders.find(
              //     (order, index) =>
              //       order.orderId.toString() === row.original.orderId.toString()
              //   ) || null;
              // console.log(selectedOrder);

              // if (selectedOrder) {
              const quickViewOrderItem: QuickViewOrderItemProps = {
                company: row.original.user_meta.company || "",
                firstName: row.original.user_meta.firstName || "",
                lastName: row.original.user_meta.lastName || "",
                email: row.original.user_meta.email || "",
                phone: row.original.user_meta.phone || "",
                website: row.original.user_meta.website || "",
                activityType: row.original.user_meta.activityType || "",
                haveAlreadyParticipated:
                  row.original.user_meta.hasAllreadyBeenToExhibition || false,
              };
              setQuickViewOrderItem(quickViewOrderItem);
              // } else {
              //   setQuickViewOrderItem(null);
              // }
            }}
          >
            <EyeIcon className="h-4 w-4" />
          </div>
        </div>
      ),
      enableSorting: true,
      enableColumnFilter: true,
    }),
    columnHelper.accessor("title", {
      header: () => (
        <CustomHeaderComponent
          title="titre"
          sortName="titre"
          post={post}
          selectedSortingColumn={selectedSortingColumn}
          setSelectedSortingColumn={setSelectedSortingColumn}
        />
      ),
      cell: ({ getValue }) => <span>{getValue()}</span>,
      enableSorting: true,
      enableColumnFilter: true,
    }),
    columnHelper.accessor(`${post}_status`, {
      header: "Status",
      cell: ({ getValue }) => <span>{getValue()}</span>,
      enableSorting: false,
    }),
    columnHelper.accessor(`${post}_date`, {
      header: () => (
        <CustomHeaderComponent
          title="Infos"
          sortName="infos"
          post={post}
          selectedSortingColumn={selectedSortingColumn}
          setSelectedSortingColumn={setSelectedSortingColumn}
        />
      ),
      cell: ({ getValue }) => <span>{getValue()}</span>,
    }),
    columnHelper.accessor("exhibition_name", {
      header: "Salon",
      cell: ({ getValue, row }) => {
        const labelValue = getValue();

        // Normalisez la chaîne de caractères pour supprimer les accents
        const normalizedLabel = labelValue.normalize("NFD");

        // Supprimez les caractères diacritiques (accents)
        const labelWithoutAccents = normalizedLabel.replace(
          /[\u0300-\u036f]/g,
          ""
        );

        // Définissez ici votre logique pour déterminer la couleur en fonction de labelValue
        let bgColor = "#737373"; // Couleur par défaut

        // Exemple de logique de couleur basée sur labelValue
        if (labelWithoutAccents.toLowerCase().includes("geneve")) {
          bgColor = "rgb(135, 172, 150)";
        } else if (labelWithoutAccents.toLowerCase().includes("morges")) {
          bgColor = "rgb(137, 168, 201)";
        } else if (labelWithoutAccents.toLowerCase().includes("aigle")) {
          bgColor = "rgb(227, 171, 80)";
        } else if (labelWithoutAccents.toLowerCase().includes("bulle")) {
          bgColor = "rgb(200, 117, 117)";
        }
        return <Pill label={labelValue} bgColor={bgColor} textColor={"#fff"} />;
      },
      enableSorting: false,
    }),
  ];

  const filterSelectComponents = [
    {
      options:
        orderStatusAvailableFilters.length > 0
          ? orderStatusAvailableFilters
          : ["Tous les statuts de commande"],
      selected: selectedStatus,
      setSelected: setSelectedStatus,
      localStorageKey: `${post}-selected-status`,
      type: "status",
      postType: `${post}`,
    },
    {
      options:
        exhibitionsYearsAvailableFilters.length > 0
          ? exhibitionsYearsAvailableFilters
          : ["Toutes les années"],
      selected: selectedExhibitionYear,
      setSelected: setSelectedExhibitionYear,
      localStorageKey: `${post}-selected-year`,
      type: "year",
      postType: `${post}`,
    },
    {
      options:
        exhibitionsAvailableFilters.length > 0
          ? exhibitionsAvailableFilters
          : ["Tous les salons"],
      selected: selectedExhibitions,
      setSelected: setSelectedExhibitions,
      localStorageKey: `${post}-selected-exhibition`,
      type: "exhibition",
      postType: `${post}`,
    },
    // Ajoutez d'autres objets au besoin pour d'autres composants FiltersSelectComponent
  ];

  // Configuration pour le composant FilterComponents
  const filterComponents = (
    <FilterComponents
      isLoading={isLoading}
      resetFilters={resetFilters}
      localStorageFiltersKeys={localStorageFiltersKeys}
      availableExhibitionsFilters={exhibitionsAvailableFilters}
      availableStatusFilters={orderStatusAvailableFilters}
      availableYearsFilters={exhibitionsYearsAvailableFilters}
      filtersSelectComponents={filterSelectComponents}
    />
  );

  const queryParamsValue = {
    name: "salon_name",
    status: "conference_status",
  };

  const localStorageData = useMemo(
    () => ({
      perPageNumberLocalStorageName: "perPageNumberConferences",
      currentPageNumberLocalStorageName: `${post}-current-page-number`,
      selectedExhibition: `${post}-selected-exhibition`,
      selectedStatus: `${post}-selected-status`,
      selectedYear: `${post}-selected-year`,
      sortingColumn: `sorting-${post}-column`,
    }),
    [post]
  );

  return {
    tableTitle: "Liste des conférences",
    queryParamsValue: queryParamsValue,
    columns,
    updateFiltersBasedOnLocalStorage,
    filterComponents,
    filters: {
      localStorageFiltersKeys,
      resetFilters,
      isLoading,
      availableExhibitionsFilters: exhibitionsAvailableFilters,
      availableStatusFilters: orderStatusAvailableFilters,
      availableYearsFilters: exhibitionsYearsAvailableFilters,
      filtersSelectComponents: { filterSelectComponents },
    },
    localStorageData: {
      perPageNumberLocalStorageName:
        localStorageData.perPageNumberLocalStorageName,
      currentPageNumberLocalStorageName:
        localStorageData.currentPageNumberLocalStorageName,
      selectedExhibition: localStorageData.selectedExhibition,
      selectedStatus: localStorageData.selectedStatus,
      selectedYear: localStorageData.selectedYear,
      sortingColumn: localStorageData.sortingColumn,
    },
    fetchingFunction: fetchAllConferencesV2,
  };
};

export default useConferenceScreenConfig;
