import { forwardRef, Fragment, useEffect, useRef, useState } from "react";
import CardWithData from "../../../../../shared/ui/components/CardWithData";
import { MainOrderInfoType } from "../../../types/orders";
import fr from "date-fns/locale/fr";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { PencilIcon } from "@heroicons/react/24/outline";
import {
  availableCustomersAtom,
  availableExhibitionsNamesAtom,
  selectedOrderAtom,
  selectedOrderCustomerAtom,
} from "../../../atoms/orders-atoms";
import { useRecoilState, useRecoilValue } from "recoil";
import { Combobox, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ChevronUpDownIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { CustomerProps } from "../../../../../shared/types/sharedTypes";
import SinglePostInfoCardsItemData from "../../../../../shared/ui/components/SinglePostInfoCardsItemData";
import { is } from "date-fns/locale";

// interface ExampleCustomInputProps {
//   date: Date | null;
//   value: string;
//   onChange: (e: any) => void;
// }
// const ExampleCustomTimeInput = ({
//   date,
//   value,
//   onChange,
// }: ExampleCustomInputProps) => (
//   <input
//     value={value}
//     onChange={(e) => onChange(e.target.value)}
//     style={{ border: "solid 1px pink" }}
//   />
// );

// function timeStringToDate(timeString: any) {
//   var timeRegex = /(\d+)h(\d+)/;
//   var timeArray = timeString.match(timeRegex);
//   var hour = parseInt(timeArray[1]);
//   var minute = parseInt(timeArray[2]);

//   var date = new Date();
//   date.setHours(hour);
//   date.setMinutes(minute);
//   return date;
// }

// interface CustomerProps {
//   id: number;
//   invoiceAddress: InvoiceAddressType;
//   activityAdress: ActivityAddressType;
//   phone: string;
//   email: string;
// }
// const customers: CustomerProps[] = [
//   {
//     id: 1,
//     invoiceAddress: {
//       addressInvoice: "1 rue de la paix",
//       address2Invoice: "1 rue de la paix",
//       cityInvoice: "Paris",
//       zipCodeInvoice: "75000",
//       companyInvoice: "Société",
//       countryInvoice: "France",
//       firstNameInvoice: "Jean",
//       lastNameInvoice: "Dupont",
//     },
//     activityAdress: {
//       addressActivity: "1 rue de la paix act",
//       address2Activity: "1 rue de la paix 2 act",
//       cityActivity: "Paris Act",
//       companyActivity: "Société act",
//       countryActivity: "Suisse",
//       firstNameActivity: "Jean Act",
//       lastNameActivity: "Dupon ACTt",
//       zipCodeActivity: "93000",
//     },
//     phone: "06 06 06 06 06",
//     email: "dev@sdfsdf.fr",
//   },
// ];

// const CustomerSelect = () => {
//   return ();
// }
interface UsersSelectorProps {
  selected: CustomerProps | null;
  setSelected: (user: CustomerProps) => void;
  query: string;
  setQuery: (query: string) => void;
  filteredUsers: CustomerProps[];
  isLoading: boolean;
}
const UsersSelector = ({
  selected,
  setSelected,
  query,
  setQuery,
  filteredUsers,
  isLoading,
}: UsersSelectorProps) => {
  const [selectedOrderCustomer, setSelectedOrderCustomer] = useRecoilState(
    selectedOrderCustomerAtom
  );
  const [selectedOrderData, setSelectedOrderData] =
    useRecoilState(selectedOrderAtom);
  useEffect(() => {
    console.log("selected", selected);
    // If selected then update the selectedOrderData activityAddress and invoiceAddress
    if (selected && selectedOrderData) {
      setSelectedOrderData({
        ...selectedOrderData,
        activityAddress: selected.activityAddress,
        invoiceAddress: selected.invoiceAddress,
        mainOrderInfo: {
          ...selectedOrderData.mainOrderInfo,
          email: selected.email,
          phone: selected.phone,
        },
        customer: selected,
      });
      setSelectedOrderCustomer(selected);
    }
    // //console.log(("selectedOrderData", selectedOrderData);
    //console.log(("====================================");
    //console.log((selected?.activityType);
    //console.log(("====================================");
  }, [selected]);

  // const updateSelected = () => {
  //   // If selected then update the selectedOrderData activityAddress and invoiceAddress
  //   if (selected && selectedOrderData) {
  //     setSelectedOrderData({
  //       ...selectedOrderData,
  //       activityAddress: selected.activityAddress,
  //       invoiceAddress: selected.invoiceAddress,
  //       mainOrderInfo: {
  //         ...selectedOrderData.mainOrderInfo,
  //         email: selected.email,
  //         phone: selected.phone,
  //       },
  //     });
  //     setSelectedOrderCustomer(selected);
  //   }
  // };


  return (
    <div className="w-full">
      <Combobox defaultValue={selected} onChange={setSelected} nullable>
        <div className="relative mt-1">
          <div className="relative w-full overflow-hidden text-left bg-white rounded-lg cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
            <Combobox.Input
              className="w-full py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 border-none focus:ring-0 focus-visible:ring-0"
              displayValue={
                (user: CustomerProps) =>
                  user
                    ? user.id !== 0
                      ? user.invoiceAddress.firstNameInvoice +
                        " " +
                        user.invoiceAddress.lastNameInvoice +
                        " (n°" +
                        user.id +
                        " - " +
                        user.email +
                        ")"
                      : ""
                    : ""
                // person
                //   ? person.invoiceAddress.firstNameInvoice +
                //     " " +
                //     person.invoiceAddress.lastNameInvoice
                //   : ""
              }
              placeholder="Saisissez le nom d'un client"
              onChange={(event) => setQuery(event.target.value)}
              onFocusCapture={() => setQuery("")}
              />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery("")}
          >
            <Combobox.Options className="absolute mt-1 max-h-60 w-[150%] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {isLoading && (
                <div className="relative px-4 py-2 text-gray-700 cursor-default select-none">
                  Chargement...
                </div>
              )} 
              {filteredUsers.length === 0 && query !== "" && query.length > 3 ? (
                <div className="relative px-4 py-2 text-gray-700 cursor-default select-none">
                  Aucune correspondance.
                </div>
              ) : query.length < 3 ? (
                <div className="relative px-4 py-2 text-gray-700 cursor-default select-none">
                  Veuillez saisir au moins 3 caractères.
                </div>
              ) : (
                filteredUsers.map((user) => (
                  <Combobox.Option
                    key={user.id}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? "bg-teal-600 text-white" : "text-gray-900"
                      }`
                    }
                    value={user}
                  >
                    {({ selected, active }) => {
                      return (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {/* Display user invoice first name, invoice last name, id and email in the fornat `firstName lastname (n°id - email)` */}
                            {user.invoiceAddress.firstNameInvoice +
                              " " +
                              user.invoiceAddress.lastNameInvoice +
                              " (n°" +
                              user.id +
                              " - " +
                              user.email +
                              ")"}
                          </span>
                          {selected ? (
                            <span
                              className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                active ? "text-white" : "text-teal-600"
                              }`}
                            >
                              <CheckIcon
                                className="w-5 h-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      );
                    }}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
};

const MainOrderInfo = ({
  date,
  time,
  email,
  website,
  phone,
  dateTime,
}: MainOrderInfoType) => {
  const selectedOrderData = useRecoilValue(selectedOrderAtom);
  const urlIncludesNewOrder =
    window.location.href.includes("nouvelle-commande");
  const [startDate, setStartDate] = useState<Date | null>(
    !urlIncludesNewOrder
      ? new Date((dateTime as any) * 1000)
      : new Date(dateTime as any)
  );

  let emailOrder = selectedOrderData?.mainOrderInfo.email;

  const [isDatepickerForDateOpen, setIsDatepickerForDateOpen] = useState(false);
  const [isDatepickerForTimeOpen, setIsDatepickerForTimeOpen] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [selectedOrder, setSelectedOrder] = useRecoilState(selectedOrderAtom);

  const [selected, setSelected] = useState<CustomerProps | null>();
  const [query, setQuery] = useState("");
  // const [availableExhibitionsNames, setAvailableExhibitionsNames] =
  //   useRecoilState(availableExhibitionsNamesAtom);
  const [availableCustomers, setAvailableCustomers] = useRecoilState<
    CustomerProps[]
  >(availableCustomersAtom);
  const [loading, setLoading] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState<CustomerProps[]>([]);

  const removeAccents = (str: string) => {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  };

  useEffect(() => {
    //console.log((startDate);
  }, [startDate]);
    useEffect(() => {
      if (query === "" || query.length < 3) {
        setFilteredUsers([]);
        setLoading(false);
        return;
      }

      setLoading(true);

      // Simuler un délai de traitement
      setTimeout(() => {
        const filtered = availableCustomers
          .filter(
            (customer) =>
              removeAccents(customer.invoiceAddress.lastNameInvoice).includes(
                removeAccents(query)
              ) ||
              removeAccents(customer.invoiceAddress.firstNameInvoice).includes(
                removeAccents(query)
              ) ||
              removeAccents(customer.invoiceAddress.companyInvoice).includes(
                removeAccents(query)
              ) ||
              removeAccents(customer.activityAddress.firstNameActivity).includes(
                removeAccents(query)
              ) ||
              removeAccents(customer.activityAddress.lastNameActivity).includes(
                removeAccents(query)
              ) ||
              removeAccents(customer.activityAddress.companyActivity).includes(
                removeAccents(query)
              ) ||
              removeAccents(customer.email).includes(removeAccents(query)) ||
              removeAccents(customer.phone).includes(removeAccents(query))
          )
          .slice(0, 10);
        
        setFilteredUsers(filtered);
        setLoading(false);
      }, 500); // Ajouter un délai pour simuler un chargement (optionnel)
    }, [query, availableCustomers]);
  // const filteredUsers =
  //   query === "" || query.length < 3
  //     ? []
  //     : availableCustomers.filter(
  //         (customer) =>
  //           // Remove accents from the query and the customer name
  //           // customer.invoiceAddress.lastNameInvoice
  //           //   .toLowerCase()
  //           //   .replace(/\s+/g, "")
  //           removeAccents(customer.invoiceAddress.lastNameInvoice).includes(
  //             // query.toLowerCase().replace(/\s+/g, "")
  //             removeAccents(query)
  //           ) ||
  //           // customer.invoiceAddress.firstNameInvoice
  //           //   .toLowerCase()
  //           //   .replace(/\s+/g, "")
  //           removeAccents(customer.invoiceAddress.firstNameInvoice).includes(
  //             // query.toLowerCase().replace(/\s+/g, "")
  //             removeAccents(query)
  //           ) ||
  //           // customer.invoiceAddress.companyInvoice
  //           //   .toLowerCase()
  //           //   .replace(/\s+/g, "")
  //           removeAccents(customer.invoiceAddress.companyInvoice).includes(
  //             // query.toLowerCase().replace(/\s+/g, "")
  //             removeAccents(query)
  //           ) ||
  //           // customer.activityAddress.firstNameActivity
  //           //   .toLowerCase()
  //           //   .replace(/\s+/g, "")
  //           removeAccents(customer.activityAddress.firstNameActivity).includes(
  //             // query.toLowerCase().replace(/\s+/g, "")
  //             removeAccents(query)
  //           ) ||
  //           // customer.activityAddress.lastNameActivity
  //           //   .toLowerCase()
  //           //   .replace(/\s+/g, "")
  //           removeAccents(customer.activityAddress.lastNameActivity).includes(
  //             // query.toLowerCase().replace(/\s+/g, "")
  //             removeAccents(query)
  //           ) ||
  //           // customer.activityAddress.companyActivity
  //           //   .toLowerCase()
  //           //   .replace(/\s+/g, "")
  //           removeAccents(customer.activityAddress.companyActivity).includes(
  //             // query.toLowerCase().replace(/\s+/g, "")
  //             removeAccents(query)
  //           ) ||
  //           // removeAccents(customer.website).includes(
  //           //   // query.toLowerCase().replace(/\s+/g, "")
  //           //   removeAccents(query)
  //           // ) ||
  //           // customer.email
  //           //   .toLowerCase()
  //           //   .replace(/\s+/g, "")
  //           removeAccents(customer.email).includes(
  //             // query.toLowerCase().replace(/\s+/g, "")
  //             removeAccents(query)
  //           ) ||
  //           // customer.phone
  //           //   .toLowerCase()
  //           //   .replace(/\s+/g, "")
  //           removeAccents(customer.phone).includes(
  //             // query.toLowerCase().replace(/\s+/g, "")
  //             removeAccents(query)
  //           )
  //       ).slice(0, 10);

  let dateString = startDate?.toLocaleDateString("fr-FR", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
  let timeString = startDate
    ?.toLocaleTimeString("fr-FR", {
      hour: "2-digit",
      minute: "2-digit",
    })
    .replace(":", "h");
  // timeString.replace(":", "h");

  const CustomInputForDate = (props: any) => {
    return (
      <div
        className="select-none"
        onClick={() => {
          props.onClick();
          setIsDatepickerForDateOpen(!isDatepickerForDateOpen);
        }}
      >
        <PencilIcon
          // type="text"
          // value={props.value}
          className="w-5"
        />
      </div>
    );
  };
  const CustomInputForTime = (props: any) => {
    return (
      <div
        className="select-none"
        onClick={() => {
          props.onClick();
          setIsDatepickerForTimeOpen(!isDatepickerForTimeOpen);
        }}
      >
        <PencilIcon
          // type="text"
          // value={props.value}
          className="w-5"
        />
      </div>
    );
  };

  useEffect(() => {
    //console.log(("selectedOrder phone", selectedOrder?.customer.phone);
    console.log("EMAIL ORDER", selectedOrder?.mainOrderInfo.emailOrder);
  }, [selectedOrder]);

  // const ref = useRef(null);

  const phoneWithPrefix = selectedOrderData?.mainOrderInfo?.phone;

  const phoneWithoutPrefix = phoneWithPrefix?.replace(/^\+\d+/, '');
  return (
    <CardWithData>
      {/* Date */}
      {/* <SinglePostInfoCardsItemData
        title="Date"
        value={date || "Non renseigné"}
      /> */}
      <div className="flex flex-row items-center justify-between">
        <div>
          <div className="flex flex-row items-center gap-x-4">
            <span className="font-bold text-[#3C7179]">Date</span>

            <div className={`flex cursor-pointer flex-row gap-x-2`}>
              <div
                className="p-[6px] text-gray-400 transition hover:text-gray-600"
                onClick={() => {
                  // setIsEditing(true);
                  // inputRef.current?.focus();
                }}
              >
                {/* <DatePicker
                  open={isDatepickerForDateOpen}
                  onFocus={() => setIsDatepickerForDateOpen(true)}
                  onBlur={() => setIsDatepickerForDateOpen(false)}
                  onClickOutside={() => setIsDatepickerForDateOpen(false)}
                  showTimeInput
                  locale={fr}
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                    if (selectedOrderData) {
                      setSelectedOrderData({
                        ...selectedOrderData,
                        mainOrderInfo: {
                          ...selectedOrderData.mainOrderInfo,
                          dateTime: date as Date,
                        },
                      });
                    }
                  }}
                  customInput={<CustomInputForDate />}
                /> */}
              </div>
            </div>
          </div>
          <span>{dateString}</span>
        </div>
        {/* Time */}
        {/* <SinglePostInfoCardsItemData
        title="Heure"
        value={time || "Non renseigné"}
      /> */}
        <div>
          <div className="flex flex-row items-center gap-x-4">
            <span className="font-bold text-[#3C7179]">Heure</span>

            <div className={`flex cursor-pointer flex-row gap-x-2`}>
              <div
                className="p-[6px] text-gray-400 transition hover:text-gray-600"
                onClick={() => {
                  // setIsEditing(true);
                  // inputRef.current?.focus();
                }}
              >
                {/* <DatePicker
                  open={isDatepickerForTimeOpen}
                  onFocus={() => setIsDatepickerForTimeOpen(true)}
                  onBlur={() => setIsDatepickerForTimeOpen(false)}
                  onClickOutside={() => setIsDatepickerForTimeOpen(false)}
                  showTimeInput
                  locale={fr}
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                    if (selectedOrderData) {
                      setSelectedOrderData({
                        ...selectedOrderData,
                        mainOrderInfo: {
                          ...selectedOrderData.mainOrderInfo,
                          dateTime: date as Date,
                        },
                      });
                    }
                  }}
                  customInput={<CustomInputForTime />}
                /> */}
              </div>
            </div>
          </div>
          <span>{timeString}</span>
        </div>
      </div>
      <span className="font-bold text-[#3C7179]">Client</span>
      <div className="flex flex-row items-center gap-x-4">
        <UsersSelector
          filteredUsers={filteredUsers}
          query={query}
          setQuery={setQuery}
          setSelected={setSelected}
          selected={
            selected ? selected : selectedOrder ? selectedOrder.customer : null
            // selectedOrder ? selectedOrder?.customer : null
          }
          isLoading={loading}
        />
      </div>
      {/* Email */}
      <SinglePostInfoCardsItemData
        title="Email"
        value={emailOrder || "Non renseigné"}
        email={emailOrder}
        setValue={(value) => {
          selectedOrder &&
            setSelectedOrder({
              ...selectedOrder,
              customer: {
                ...selectedOrder.customer,
                email: value,
              },
              mainOrderInfo: {
                ...selectedOrder.mainOrderInfo,
                email: value,
                emailOrder: value,
              },
            });
        }}
      />
      {/* Site web */}
      <SinglePostInfoCardsItemData
        title="Site web"
        website={website}
        value={website || "Non renseigné"}
        setValue={(value) => {
          selectedOrder &&
            setSelectedOrder({
              ...selectedOrder,
              customer: {
                ...selectedOrder.customer,
                website: value,
              },
              mainOrderInfo: {
                ...selectedOrder.mainOrderInfo,
                website: value,
              },
            });
        }}
      />
      {/* Phone */}
      <SinglePostInfoCardsItemData
        title="Téléphone"
        phone={phoneWithPrefix}
        value={phoneWithoutPrefix || "Non renseigné"}
        setValue={(value) => {
          selectedOrder &&
            setSelectedOrder({
              ...selectedOrder,
              customer: {
                ...selectedOrder.customer,
                phone: value,
              },
              mainOrderInfo: {
                ...selectedOrder.mainOrderInfo,
                phone: value,
              },
            });
        }}
      />
      {/* Type d'activite */}
      <SinglePostInfoCardsItemData
        title="Type d'activité"
        value={selectedOrder?.customer.activityType || "Non renseigné"}
        setValue={(value) => {
          selectedOrder &&
            setSelectedOrder({
              ...selectedOrder,
              customer: {
                ...selectedOrder.customer,
                activityType: value,
              },
            });
        }}
      />
      {/* Déja venu au salo */}
      <SinglePostInfoCardsItemData
        title="Déjà venu au salon"
        value={selectedOrder?.customer.haveAlreadyParticipated ? "Oui" : "Non"}
        isEditable={true}
        isSelect={true}
        setValue={(value) => {
          selectedOrder &&
            setSelectedOrder({
              ...selectedOrder,
              customer: {
                ...selectedOrder.customer,
                haveAlreadyParticipated: value === "true" ? true : false,
              },
            });
        }}
      />
    </CardWithData>
  );
};

export default MainOrderInfo;

// Path: src/features/orders/layouts/MainOrderInfo.tsx
