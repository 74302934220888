import { XCircleIcon } from "@heroicons/react/24/solid";
import React, { useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const maxSize = 1048576;

function sizeValidator(file: any) {
  if (file.size > maxSize) {
    return {
      code: "file-too-large",
      message: `File is larger than ${maxSize} bytes`,
    };
  }

  return null;
}

interface CustomDropZoneProps {
  setValues: (values: any) => void;
  files: Object[];
  setFiles: (files: Object[]) => void;
}
const CustomDropzone = ({
  setValues,
  files,
  setFiles,
}: CustomDropZoneProps) => {
  // const [files, setFiles] = useState<Object[]>();
  const dropZoneProperties = {
    accept: { "image/*": [] },
    maxFiles: 1,
    validator: sizeValidator,
    onDrop: (acceptedFiles: any) => {
      acceptedFiles.forEach((file: any) => {
        const reader = new FileReader();
        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          // Do whatever you want with the file contents
          const binaryStr = reader.result;
          console.log(binaryStr);
          setFiles([
            {
              name: file.name,
              preview: URL.createObjectURL(file),
            },
          ]);
          setValues(
            // ...newValues,
            binaryStr
          );
        };
        reader.readAsDataURL(file);
      });
      // setFiles(
      //   acceptedFiles.map((file: any) => {
      //     return Object.assign(file, {
      //       preview: URL.createObjectURL(file),
      //     });
      //   })
      // );
    },
  };
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone(dropZoneProperties);

  const thumbs = files?.map((file: any) => (
    <div key={file.name}>
      <div>
        <div
          className="flex justify-end hover:cursor-pointer"
          onClick={() => {
            // setFiles([]);
            setValues("");
          }}
        >
          <XCircleIcon className="w-6 h-6 text-red-500" />
        </div>
        <img
          className="block object-cover w-auto h-full mx-auto max-h-96"
          alt={file.name}
          src={file.preview}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));

  const style: any = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      files?.forEach((file: any) => URL.revokeObjectURL(file.preview));
  }, []);

  // useEffect(() => {
  //   console.log(files);
  // }, [files]);

  return (
    <div className="container hover:cursor-pointer">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p className="select-none">Cliquez ou glissez-déposez votre image</p>
      </div>
      {/* <aside style={thumbsContainer}>{thumbs}</aside> */}
      <div className="mt-4">{thumbs}</div>
    </div>
  );
};

export default CustomDropzone;
