import { atom } from "recoil";
import { Exhibition } from "../../../shared/types/sharedTypes";
import { QuickView } from "../../workshops/api/fetchWorkshops";

type ConferenceGeneralInfo = {
  conferenceName: string;
  conferenceDescription: string;
  company: string;
  firstName: string;
  lastName: string;
  hasAllreadyBeenToExhibition: boolean;
  exhibition: Exhibition;
  conferenceCreneauSouhaite: string;
  photoDuConferencier: any;
  photoAjouteeParLAdministrateur: any;
  userId: number;
  // conferenceDateEtHeure?: Date;
  conferenceDateEtHeure?: string;
  fullName: string;
  // conferenceDateEtHeure?: any;
};
type ExhibitorInfo = {
  email: string;
  phone: string;
  website: string;
  activityType: string;
  phone_country_code: string;
};
type OrderAndConferenceInfo = {
  orderNumber: string;
  orderStatus: string;
  conferenceStatus: string;
  conferenceFeatured: string;
  standNumber: string;
  orderHasStand: boolean;
};
export interface ConferenceDataProps {
  id: string;
  title: string;
  info: string;
  salle: string;
  conferenceStatus: string;
  conferenceFeatured: string;
  conferenceStatusNormalized: string;
  conferenceName: string;
  conferenceNameNormalized: string;
  conferenceGeneralInfo: ConferenceGeneralInfo;
  exhibitorInfo: ExhibitorInfo;
  orderAndConferenceInfo: OrderAndConferenceInfo;
  exhibitorComments: string;
  adminComments: string;
  quickView: QuickView;
  speaker: string;
  speakerNormalized: string;
  exhibitionNameNormalized: string;
  speakerLastName: string;
  speakerFirstName: string;
  speakerFullName: string;
  speakerJob: string;
}

export const conferencesAtom = atom<ConferenceDataProps[]>({
  key: "conferencesAtom",
  default: [],
});

export const filteredConferencesAtom = atom<ConferenceDataProps[] | null>({
  key: "filteredConferencesAtom",
  default: null,
});

// export const selectedConferenceAtom = atom<ConferenceDataProps | null>({
//   key: "selectedConferenceAtom",
//   default: null,
// });

export const isEditingConferenceInvoiceAddressAtom = atom<boolean>({
  key: "isEditingConferenceInvoiceAddressAtom",
  default: false,
});

export const isEditingConferenceActivityAddressAtom = atom<boolean>({
  key: "isEditingConferenceActivityAddressAtom",
  default: false,
});

// V2 : Pagination

export const isCreatingNewConferenceAtom = atom<boolean>({
  key: "isCreatingNewConferenceAtom",
  default: false,
});

export const selectedConferenceAtom = atom<ConferenceDataProps | null>({
  key: "selectedConferenceAtom",
  default: null,
});
