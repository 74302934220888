import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import { format } from "date-fns";
import { Fragment, useEffect, useState } from "react";
import { Resolver, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { returnRootUrlBasedOnAppLocation } from "../../../shared/utils/checkIfIsInlocal";
import {
  fetchAllAccountEmails,
  fetchAllProducts,
} from "../api/fetch-discounts";
import { Product } from "../types/discount";

type FormValues = {
  coupon_code: string;
  coupon_title: string;
  discount_type: string;
  coupon_amount: string;
  description: string;
  expiry_date: string;
  usage_limit: string;
  usage_limit_per_user: string;
  limit_usage_to_x_items: string;
  product_ids: string[];
  email_restrictions: string[];
};

const resolver: Resolver<FormValues> = async (values) => {
  return {
    values: values,
    errors: {
      title: {
        type: "required",
        message: "Champs requis",
      },
      type: {
        type: "required",
        message: "Champs requis",
      },
      amount: {
        montant: "required",
        message: "Champs requis",
      },
    },
  };
};
interface CreateCouponFunctionProps {
  isCreatingCoupon: boolean;
  setIsCreatingCoupon: (value: boolean) => void;
  couponCreated: boolean;
  setCouponCreated: (value: boolean) => void;
  coupon_title: string;
  discount_type: string;
  coupon_amount: string;
  description: string;
  expiry_date: string;
  usage_limit: string;
  usage_limit_per_user: string;
  limit_usage_to_x_items: string;
  product_ids: string[];
  email_restrictions: string[];
}

interface AddCouponPopupProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}
export const AddCoupon = ({ isOpen, setIsOpen }: AddCouponPopupProps) => {
  const {
    register,
    watch,
    formState: { errors },
  } = useForm<FormValues>({ resolver });
  const [isCreatingCoupon, setIsCreatingCoupon] = useState(false);
  const [couponCreated, setCouponCreated] = useState(false);
  // let [isOpen, setIsOpen] = useState(isPopupOpen);
  const navigate = useNavigate();

  const isDisabled = () => {
    if (
      watch("coupon_title") &&
      watch("discount_type") &&
      watch("coupon_amount")
    ) {
      return false;
    }
    return true;
  };

  function closeModal() {
    setIsOpen(false);
  }

  const createCoupon = ({
    isCreatingCoupon,
    setIsCreatingCoupon,
    couponCreated,
    setCouponCreated,
    coupon_title,
    discount_type,
    coupon_amount,
    description,
    expiry_date,
    usage_limit,
    usage_limit_per_user,
    limit_usage_to_x_items,
    product_ids,
    email_restrictions,
  }: CreateCouponFunctionProps) => {
    let token = "";
    let formattedDate = null;
    if (localStorage.getItem("token-dashboard")) {
      token = JSON.parse(localStorage.getItem("token-dashboard") as string);
    }
    setIsCreatingCoupon(true);
    if (expiry_date !== "") {
      formattedDate = format(new Date(expiry_date), "yyyy-MM-dd");
    }
    axios
      .post(
        `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v2/discount`,
        {
          coupon_code: coupon_title,
          coupon_title,
          discount_type,
          coupon_amount,
          description,
          expiry_date: formattedDate,
          usage_limit,
          usage_limit_per_user,
          limit_usage_to_x_items,
          product_ids,
          email_restrictions,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        setIsCreatingCoupon(false);
        setCouponCreated(true);
        setTimeout(() => {
          setCouponCreated(false);
        }, 2000);
        setIsOpen(false);

        window.location.reload();
      })
      .catch((err) => {
        console.log(err.response.data.message);
        // Check if the message includes 'already exists'
        alert("Un compte avec cette adresse email existe déjà.");
        // if (err.response.data.message.includes("already exists")) {
        //   setIsErrorOpen(true);
        // }
      })
      .finally(() => {
        // setIsCreatingCoupon(false);
        isCreatingCoupon && setIsCreatingCoupon(false);
        couponCreated && setCouponCreated(false);
      });
    // console.log({
    //   coupon_code: coupon_title,
    //   coupon_title,
    //   discount_type,
    //   coupon_amount,
    //   description,
    //   expiry_date,
    //   usage_limit,
    //   product_ids,
    //   email_restrictions,
    // });
  };

  const [products, setProducts] = useState<Product[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<Product[]>(
    [] as Product[]
  );
  const [productQuery, setProductQuery] = useState<string>("");
  const filteredProducts =
    productQuery === ""
      ? products
      : products.filter((product) =>
          product.name.toLowerCase().includes(productQuery.toLowerCase())
        );
  let selectedProductsIds = selectedProduct.map((product) => product.id);
  const [emails, setEmails] = useState<string[]>([]);
  const [selectedEmail, setSelectedEmail] = useState<string[]>([]);
  const [searchParameters, setSearchParameters] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [filteredEmailsList, setFilteredEmailList] = useState<string[]>([]);

  let token = "";
  if (localStorage.getItem("token-dashboard")) {
    token = JSON.parse(localStorage.getItem("token-dashboard") as string);
  }

  useEffect(() => {
    console.log(localStorage.getItem("token-dashboard"));
    if (!token) {
      window.location.replace("/");
    }

    if (products && emails) {
      if (products.length && emails.length > 0) return;
    }
    setIsLoading(true);
    fetchAllProducts()
      .then((products) => {
        setProducts(products);
      })
      .catch((err) => {
        if (err.code.includes("jwt")) {
          console.log(err);
          localStorage.removeItem("token-dashboard");
          window.location.replace("/");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
    fetchAllAccountEmails()
      .then((emails) => {
        setEmails(emails);
      })
      .catch((err) => {
        if (err.code.includes("jwt")) {
          console.log(err);
          localStorage.removeItem("token-dashboard");
          window.location.replace("/");
        }
      });
    if (!products) {
      setProducts([]);
    } else if (!emails) {
      setEmails([]);
    }
  }, []);

  useEffect(() => {
    console.log(selectedProductsIds);
    console.log(selectedEmail);
  }, [selectedEmail, selectedProductsIds]);

  useEffect(() => {
    // Update the value of the filtered list
    if (searchParameters.length > 2) {
      setFilteredEmailList(
        emails.filter((email) =>
          email.toLowerCase().includes(searchParameters.toLowerCase())
        )
      );
    } else {
      setFilteredEmailList([]);
    }
  }, [searchParameters, emails]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <DialogTitle
                  as="h3"
                  className="text-xl font-medium leading-6 text-gray-900"
                >
                  Ajout d'un coupon
                </DialogTitle>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Renseignez ici les informations du coupon à créer. Seuls les
                    champs marqués d'un <span className="text-red-400">*</span>{" "}
                    sont obligatoires.
                  </p>
                  <form className="mt-4 flex flex-col gap-4">
                    <div
                      id="title_type"
                      className="flex flex-row items-start justify-between gap-4"
                    >
                      <div id="title" className="flex w-full flex-col gap-1">
                        <label>
                          Titre <span className="text-red-400">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          className="block w-full rounded-md border border-gray-300 px-2 py-2 shadow-sm focus:border-[#14B8A6] focus:ring-[#14B8A6] sm:text-sm"
                          placeholder="Titre"
                          {...register("coupon_title")}
                        />
                        {errors?.coupon_title && (
                          <p>{errors.coupon_title.message}</p>
                        )}
                      </div>
                      <div id="type" className="flex w-full flex-col gap-1">
                        <label>
                          Type <span className="text-red-400">*</span>{" "}
                        </label>
                        <div className="flex w-full flex-col gap-1 rounded-md border border-gray-300 px-2 py-2 shadow-sm focus:border-[#14B8A6] focus:ring-[#14B8A6] sm:text-sm">
                          <div className="flex flex-row gap-2">
                            <input
                              type="radio"
                              id="percent"
                              value="percent"
                              {...register("discount_type")}
                            />
                            <label htmlFor="percent">
                              Remise en pourcentage
                            </label>
                          </div>
                          <div className="flex flex-row gap-2">
                            <input
                              type="radio"
                              id="fixed_product"
                              value="fixed_product"
                              {...register("discount_type")}
                            />
                            <label htmlFor="fixed_product">
                              Remise produit fixe
                            </label>
                          </div>
                          <div className="flex flex-row gap-2">
                            <input
                              type="radio"
                              id="fixed_cart"
                              value="fixed_cart"
                              {...register("discount_type")}
                            />
                            <label htmlFor="fixed_cart">
                              Remise panier fixe
                            </label>
                          </div>
                        </div>
                        {errors?.discount_type && (
                          <p>{errors.discount_type.message}</p>
                        )}
                      </div>
                    </div>
                    <div
                      id="amount_description"
                      className="flex flex-row items-start justify-between gap-4"
                    >
                      <div id="amount" className="flex w-full flex-col gap-1">
                        <label>
                          Montant <span className="text-red-400">*</span>{" "}
                        </label>
                        <input
                          type="number"
                          className="block w-full rounded-md border border-gray-300 px-2 py-2 shadow-sm focus:border-[#14B8A6] focus:ring-[#14B8A6] sm:text-sm"
                          placeholder="Montant"
                          {...register("coupon_amount")}
                        />
                        {errors?.coupon_amount && (
                          <p>{errors.coupon_amount.message}</p>
                        )}
                      </div>
                      <div
                        id="description"
                        className="flex w-full flex-col gap-1"
                      >
                        <label>Description</label>
                        <input
                          type="string"
                          className="block w-full rounded-md border border-gray-300 px-2 py-2 shadow-sm focus:border-[#14B8A6] focus:ring-[#14B8A6] sm:text-sm"
                          placeholder="Description"
                          {...register("description")}
                        />
                        {errors?.description && (
                          <p>{errors.description.message}</p>
                        )}
                      </div>
                    </div>
                    <div
                      id="expiration_date_utilisation_number"
                      className="flex flex-row items-start justify-between gap-4"
                    >
                      <div
                        id="expiration_date"
                        className="flex w-full flex-col gap-1"
                      >
                        <label>Date d'expiration </label>
                        <input
                          type="date"
                          className="block w-full rounded-md border border-gray-300 px-2 py-2 shadow-sm focus:border-[#14B8A6] focus:ring-[#14B8A6] sm:text-sm"
                          placeholder="Date d'expiration"
                          {...register("expiry_date")}
                        />
                        {errors?.expiry_date?.type === "required" &&
                          !watch("expiry_date") && (
                            <p>{errors.expiry_date.message}</p>
                          )}
                      </div>
                      <div
                        id="utilisation_number"
                        className="flex w-full flex-col gap-1"
                      >
                        <label>Limite d’utilisation par code</label>
                        <input
                          type="number"
                          className="block w-full rounded-md border border-gray-300 px-2 py-2 shadow-sm focus:border-[#14B8A6] focus:ring-[#14B8A6] sm:text-sm"
                          placeholder="Limite d’utilisation par code"
                          {...register("usage_limit")}
                        />
                        {errors?.usage_limit && (
                          <p>{errors.usage_limit.message}</p>
                        )}
                      </div>
                    </div>
                    <div
                      id="usage_limit_per_user_limit_usage_to_x_items"
                      className="flex flex-row items-start justify-between gap-4"
                    >
                      <div
                        id="usage_limit_per_user"
                        className="flex w-full flex-col gap-1"
                      >
                        <label>Limite d’utilisation par utilisateur</label>
                        <input
                          type="number"
                          className="block w-full rounded-md border border-gray-300 px-2 py-2 shadow-sm focus:border-[#14B8A6] focus:ring-[#14B8A6] sm:text-sm"
                          placeholder="Limite d’utilisation par utilisateur"
                          {...register("usage_limit_per_user")}
                        />
                        {errors?.usage_limit && (
                          <p>{errors.usage_limit.message}</p>
                        )}
                      </div>
                      {watch("discount_type") !== "fixed_cart" && (
                        <div
                          id="limit_usage_to_x_items"
                          className="flex w-full flex-col gap-1"
                        >
                          <label>Limite d’utilisation à X articles</label>
                          <input
                            type="number"
                            className="block w-full rounded-md border border-gray-300 px-2 py-2 shadow-sm focus:border-[#14B8A6] focus:ring-[#14B8A6] sm:text-sm"
                            placeholder="Limite d’utilisation à X articles"
                            {...register("limit_usage_to_x_items")}
                          />
                          {errors?.usage_limit && (
                            <p>{errors.usage_limit.message}</p>
                          )}
                        </div>
                      )}
                    </div>
                    <div
                      id="products_email"
                      className="flex flex-row items-start justify-between gap-4"
                    >
                      <div id="amount" className="flex w-full flex-col gap-1">
                        <label>Produits affectés par ce coupon</label>
                        <Combobox
                          value={selectedProduct}
                          multiple
                          onChange={setSelectedProduct}
                          onClose={() => setProductQuery("")}
                        >
                          <div className="relative">
                            {selectedProduct.length > 0 && (
                              <ul className="mb-2 block w-full rounded-md border border-gray-300 px-2 py-2 shadow-sm focus:border-[#14B8A6] focus:ring-[#14B8A6] sm:text-sm">
                                {selectedProduct.map((product) => (
                                  <li
                                    key={product.id}
                                    className="flex flex-row justify-between"
                                  >
                                    <span>{product.name}</span>
                                    <button
                                      // On press of the button, remove the product from the selectedProduct array
                                      onClick={(event) => {
                                        event.preventDefault();
                                        setSelectedProduct(
                                          selectedProduct.filter(
                                            (selectedProduct) =>
                                              selectedProduct.id !== product.id
                                          )
                                        );
                                      }}
                                    >
                                      <XCircleIcon
                                        fontSize={20}
                                        className="h-5 w-5"
                                        color="red"
                                      />
                                    </button>
                                  </li>
                                ))}
                              </ul>
                            )}

                            <ComboboxInput
                              aria-label="Assignee"
                              displayValue={(product: any) => product?.name}
                              onChange={(event) =>
                                setProductQuery(event.target.value)
                              }
                              className="block w-full rounded-md border border-gray-300 px-2 py-2 shadow-sm focus:border-[#14B8A6] focus:ring-[#14B8A6] sm:text-sm"
                              placeholder="Rechercher..."
                            />
                          </div>
                          <Transition
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                            afterLeave={() => setProductQuery("")}
                          >
                            <ComboboxOptions
                              anchor="top"
                              className="w-[var(--input-width)] rounded-xl border border-white/5 bg-white p-1 shadow-lg [--anchor-gap:var(--spacing-1)] empty:hidden focus:outline-none"
                            >
                              {filteredProducts.map((product) => (
                                <ComboboxOption
                                  key={product.id}
                                  value={product}
                                  className="group flex cursor-default select-none items-center gap-2 rounded-lg px-3 py-1.5 data-[focus]:bg-white/10"
                                >
                                  <CheckIcon className="invisible size-4 fill-[#3C7179] group-data-[selected]:visible" />

                                  <div className="text-sm/6 text-[#3C7179]">
                                    {product.name}
                                  </div>
                                </ComboboxOption>
                              ))}
                            </ComboboxOptions>
                          </Transition>
                        </Combobox>
                        {productQuery.length > 0 && (
                          <button
                            type="button"
                            className="w-full justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 shadow transition-all hover:bg-green-200 hover:shadow-none focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                            onClick={() => {
                              setProductQuery("");
                            }}
                          >
                            Valider la sélection
                          </button>
                        )}
                        {errors?.coupon_amount && (
                          <p>{errors.coupon_amount.message}</p>
                        )}
                      </div>
                      <div id="emails" className="flex w-full flex-col gap-1">
                        <label>Emails autorisés à utiliser ce coupon</label>
                        {/* <Input
                          type="text"
                          placeholder="Rechercher... Taper au moins 3 caractères"
                          value={searchParameters}
                          onChange={(e) => setSearchParameters(e.target.value)}
                          className="block w-full rounded-md border border-gray-300 px-2 py-2 shadow-sm focus:border-[#14B8A6] focus:ring-[#14B8A6] sm:text-sm"
                        />
                        {(selectedEmail.length > 0 ||
                          (selectedEmail.length === 0 &&
                            searchParameters.length > 2)) && (
                          <Listbox
                            value={selectedEmail}
                            multiple
                            onChange={setSelectedEmail}
                          >
                            <ListboxButton className="relative w-full py-2 pl-3 pr-10 text-left bg-white border rounded-lg cursor-pointer focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                              {selectedEmail.length
                                ? selectedEmail
                                    .map((selectedEmail) => selectedEmail)
                                    .join(", ")
                                : "Sélectionner un email"}
                              <ChevronDownIcon
                                className="group pointer-events-none absolute right-2.5 top-2.5 size-4 fill-black"
                                aria-hidden="true"
                              />
                            </ListboxButton>
                            <Transition
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <ListboxOptions
                                anchor="bottom start"
                                className="w-[var(--button-width)] rounded-xl border border-white/5 bg-white p-1 shadow-lg [--anchor-gap:var(--spacing-1)] focus:outline-none"
                              >
                                {filteredEmailsList.map(
                                  (filteredEmailsList, i) => (
                                    <ListboxOption
                                      key={i}
                                      value={filteredEmailsList}
                                      className="group flex cursor-default select-none items-center gap-2 rounded-lg px-3 py-1.5 data-[focus]:bg-white/10"
                                    >
                                      <CheckIcon className="invisible size-4 fill-[#3C7179] group-data-[selected]:visible" />

                                      <div className="text-sm/6 text-[#3C7179]">
                                        {filteredEmailsList}
                                      </div>
                                    </ListboxOption>
                                  )
                                )}
                              </ListboxOptions>
                            </Transition>
                          </Listbox>
                        )} */}
                        <Combobox
                          value={selectedEmail}
                          multiple
                          onChange={setSelectedEmail}
                          onClose={() => setSearchParameters("")}
                        >
                          <div className="relative">
                            {selectedEmail.length > 0 && (
                              <ul className="mb-2 block w-full rounded-md border border-gray-300 px-2 py-2 shadow-sm focus:border-[#14B8A6] focus:ring-[#14B8A6] sm:text-sm">
                                {selectedEmail.map((email, i) => (
                                  <li
                                    key={i}
                                    className="flex flex-row justify-between gap-1"
                                  >
                                    <span>{email}</span>
                                    <button
                                      // On press of the button, remove the product from the selectedProduct array
                                      onClick={(event) => {
                                        event.preventDefault();
                                        setSelectedEmail(
                                          selectedEmail.filter(
                                            (selectedEmail) =>
                                              selectedEmail !== email
                                          )
                                        );
                                      }}
                                    >
                                      <XCircleIcon
                                        fontSize={20}
                                        className="h-5 w-5"
                                        color="red"
                                      />
                                    </button>
                                  </li>
                                ))}
                              </ul>
                            )}

                            <ComboboxInput
                              aria-label="Assignee"
                              displayValue={(email: any) => searchParameters}
                              onChange={(event) =>
                                setSearchParameters(event.target.value)
                              }
                              className="block w-full rounded-md border border-gray-300 px-2 py-2 shadow-sm focus:border-[#14B8A6] focus:ring-[#14B8A6] sm:text-sm"
                              placeholder="Rechercher..."
                            />
                          </div>
                          <Transition
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                            afterLeave={() => setSearchParameters("")}
                          >
                            <ComboboxOptions
                              anchor="top"
                              className="w-[var(--input-width)] rounded-xl border border-white/5 bg-white p-1 shadow-lg [--anchor-gap:var(--spacing-1)] empty:hidden focus:outline-none"
                            >
                              {filteredEmailsList.map((email, i) => (
                                <ComboboxOption
                                  key={email}
                                  value={email}
                                  className="group flex cursor-default select-none items-center gap-2 rounded-lg px-3 py-1.5 data-[focus]:bg-white/10"
                                >
                                  <CheckIcon className="invisible size-4 fill-[#3C7179] group-data-[selected]:visible" />

                                  <div className="text-sm/6 text-[#3C7179]">
                                    {email}
                                  </div>
                                </ComboboxOption>
                              ))}
                            </ComboboxOptions>
                          </Transition>
                        </Combobox>
                        {searchParameters.length > 0 && (
                          <button
                            type="button"
                            className="w-full justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 shadow transition-all hover:bg-green-200 hover:shadow-none focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                            onClick={() => {
                              setSearchParameters("");
                            }}
                          >
                            Valider la sélection
                          </button>
                        )}
                        {errors?.description && (
                          <p>{errors.description.message}</p>
                        )}
                      </div>
                    </div>
                    <div>
                      <div className="mt-4 flex items-center gap-4">
                        <button
                          type="button"
                          className="w-full justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 shadow transition-all hover:bg-red-200 hover:shadow-none focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                          onClick={closeModal}
                        >
                          Annuler
                        </button>
                        <span
                          // disabled={isDisabled()}
                          // type="submit"
                          onClick={() => {
                            createCoupon({
                              isCreatingCoupon,
                              setIsCreatingCoupon,
                              couponCreated,
                              setCouponCreated,
                              coupon_title: watch("coupon_title"),
                              discount_type: watch("discount_type"),
                              coupon_amount: watch("coupon_amount"),
                              description: watch("description"),
                              expiry_date: watch("expiry_date"),
                              usage_limit: watch("usage_limit"),
                              usage_limit_per_user: watch(
                                "usage_limit_per_user"
                              ),
                              limit_usage_to_x_items: watch(
                                "limit_usage_to_x_items"
                              ),
                              product_ids: selectedProductsIds,
                              email_restrictions: selectedEmail,
                            });
                          }}
                          className={`flex w-full select-none items-center justify-center rounded-md border border-transparent text-center text-white shadow transition-all hover:shadow-none ${
                            isDisabled() || isCreatingCoupon || couponCreated
                              ? "cursor-not-allowed opacity-40"
                              : "cursor-pointer hover:bg-[#0F766E]"
                          } bg-[#14B8A6] px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2`}
                        >
                          {!isCreatingCoupon &&
                            !couponCreated &&
                            "Ajouter le coupon"}
                          {isCreatingCoupon && "Ajout en cours..."}
                          {couponCreated && (
                            <span className="flex flex-row items-center gap-4">
                              Coupon ajouté <CheckCircleIcon className="w-5" />
                            </span>
                          )}
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
