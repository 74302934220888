import apiFetch from "@wordpress/api-fetch";
import { returnRootUrlBasedOnAppLocation } from "../../../shared/utils/checkIfIsInlocal";
import { Ticket } from "../types/ticket";

// Create an api call with @wordpress/api-fetch for the endpoint admin-dashboard/v1/tickets/
export const fetchAllTickets = async (): Promise<Ticket[]> => {
  let token = "";
  if (localStorage.getItem("token-dashboard")) {
    token = JSON.parse(localStorage.getItem("token-dashboard") as string);
  }
  const response = await apiFetch({
    path: `${returnRootUrlBasedOnAppLocation()}pycofa-scan/v1/billets/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response as Ticket[];
};
