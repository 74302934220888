import { useRecoilState, useRecoilValue } from "recoil";
import { render } from "@react-email/render";
import { ordersDataAtom, selectedOrderMainTaxRateAtom } from "../../atoms/orders-atoms";
import poppinsRegular from "../../../../shared/assets/fonts/Poppins/Poppins-Regular.ttf";
import poppinsBold from "../../../../shared/assets/fonts/Poppins/Poppins-Bold.ttf";
import { OrderData } from "../../types/orders";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  pdf,
  Font,
  Image,
} from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import {
  returnPriceFormattedCHF,
  returnPriceFormattedCHFForInvoice,
} from "../../../../shared/utils/numbersManipulations";
import axios from "axios";
import Email from "../../emails/index";
import { returnRootUrlBasedOnAppLocation } from "../../../../shared/utils/checkIfIsInlocal";

import { styles } from "./style-invoice";
import { returnTaxRate } from "../../components/SingleOrder/SingleOrderTable/SingleOrderLineItemsTotalPriceTable";
const logo = require("../../assets/logo-grand-format-min.png");
// const exhibitionsLogos = require("../assets/exhibitions-buttons.png");
// const exhibitionsLogos = require("../../assets/exhibitions-buttons-min.png");
const exhibitionsLogos = require("../../assets/logos-salons.png");
// const flowerInvoice = require("../assets/flower-invoice.png");
const flowerInvoice = require("../../assets/flower-invoice-min.png");
const qrCh = require("../../assets/qr-ch.png");

interface OrderInvoiceProps {
  order: OrderData;
}

// const Invoice = ({ order }: OrderInvoiceProps): JSX.Element => {
//   const styles = StyleSheet.create({
//     page: {
//       flexDirection: "row",
//       backgroundColor: "#fff",
//     },
//     section: {
//       margin: 10,
//       padding: 10,
//       flexGrow: 1,
//     },
//   });
//   return (
//     <Document>
//       <Page size="A4" style={styles.page} wrap>
//         <View style={styles.section}>
//           <Text>{order.orderId}</Text>
//         </View>
//         {/* <View style={styles.section}>
//       <Text>Section #2</Text>
//     </View> */}
//         {/* Code */}
//         <View break></View>
//       </Page>
//     </Document>
//   );
// };

function pxCm(px: number): string {
  const dpi = 300;
  return (px / dpi) * 2.54 + "cm";
}

// Font.register({
//   family: "Poppins",
//   fonts: [
//     {
//       src: "../assets/fonts/Poppins-Regular.ttf",
//       fontWeight: 400,
//     },
//     {
//       src: "../assets/fonts/Poppins-Bold.ttf",
//       fontWeight: 700,
//     },
//   ],
// });

Font.register({
  family: "Poppins",
  format: "truetype",
  fonts: [
    {
      src: poppinsRegular,
      fontWeight: 400,
    },
    {
      src: poppinsBold,
      fontWeight: 700,
    },
  ],
  // src: poppinsRegular,
});

// Create styles

// export const Invoice = (order: OrderData) => {
//   let rabais = 0;
//   let frais = 0;

//   // Loop on order fees and if the fee amount is positive add it to frais variable else add it to rabais variable
//   order.fees.forEach((fee) => {
//     const numberFeeAmount = Number(fee.amount);
//     if (numberFeeAmount > 0) {
//       frais += numberFeeAmount;
//     } else {
//       rabais += numberFeeAmount;
//     }
//   });

//   let fees = 0;
//   order.fees.forEach((fee) => {
//     const numberFeeAmount = Number(fee.amount);
//     fees += numberFeeAmount;
//   });
//   // let year = order.date ? order.date.match(/\d{4}/) : "";
//   let year = order.exhibition.name ? order.exhibition.name.match(/\d{4}/) : "";
//   year = year ? year[0] : "";

//   // Exhibition name without only the two first characters
//   let exhibition =
//     order.exhibition.name !== "-" && order.exhibition.name !== ""
//       ? order.exhibition.name.split(" ")[0].slice(0, 2).toUpperCase()
//       : "CDE";

//   let discounts = 0;
//   let priceBeforeDiscounts = 0;

//   // const products = invoiceGenerationDateFormatted[
//   //   ...order.products,
//   //   ...order.fees,
//   // ]

//   // Get the number of products in the order to check if the number is odd or even
//   const numberOfProductsIsEven = order.products.length % 2 === 0 ? 2 : 1;

//   const invoiceIdString = `${exhibition}${year}-${order.orderId}`;
//   return (
//     <Document>
//       <Page size="A4" style={styles.page} wrap>
//         <View style={styles.flower}>
//           <Image
//             fixed
//             src={{
//               uri: flowerInvoice,
//               method: "GET",
//               headers: { "Cache-Control": "no-cache" },
//               body: "",
//             }}
//           />
//         </View>
//         <View>
//           {/* HEADER */}
//           <View style={styles.header}>
//             <View style={styles.logo}>
//               <Image
//                 src={{
//                   uri: logo,
//                   method: "GET",
//                   headers: { "Cache-Control": "no-cache" },
//                   body: "",
//                 }}
//               />
//             </View>
//             <View style={styles.rightHeader}>
//               <Image
//                 style={styles.exhibitionsLogos}
//                 src={{
//                   uri: exhibitionsLogos,
//                   method: "GET",
//                   headers: { "Cache-Control": "no-cache" },
//                   body: "",
//                 }}
//               />
//               <View>
//                 {/* <Text style={styles.headerCompany}>ST Organisation Sàrl</Text> */}
//                 {/* <Text style={styles.headerAddress}>
//                   Rue de Jérusalem 1 | CH- 1860 Aigle
//                 </Text>
//                 <Text style={styles.headerAddress}>
//                   info@sdtn.ch | Tél : +41 24/466.12.89
//                 </Text> */}
//               </View>
//             </View>
//           </View>
//           {/* BODY */}
//           <View style={styles.section}>
//             {/* Invoice info */}
//             <View style={styles.invoiceInfo}>
//               <View style={styles.generalInfo}>
//                 {/* Invoice and date */}
//                 <View style={styles.generalInfoCard}>
//                   <View
//                     style={[
//                       styles.generalInfoCardData,
//                       styles.generalInfoCardDataMt,
//                     ]}
//                   >
//                     <Text style={styles.generalInfoCardTitle}>Facture </Text>
//                     <Text style={styles.generalInfoCardText}>
//                       {invoiceIdString}
//                     </Text>
//                   </View>
//                   <View style={styles.generalInfoCardData}>
//                     <Text style={styles.generalInfoCardTitle}>
//                       Date de commande
//                     </Text>
//                     <Text style={styles.generalInfoCardText}>
//                       {/* 22 décembre 2022 */}
//                       {order.formattedDate}
//                     </Text>
//                   </View>
//                   <View style={styles.generalInfoCardData}>
//                     <Text style={styles.generalInfoCardTitle}>
//                       Date de facture
//                     </Text>
//                     <Text style={styles.generalInfoCardText}>
//                       {order.invoiceGenerationDateFormatted}
//                     </Text>
//                   </View>
//                 </View>
//                 <View
//                   style={{
//                     height: pxCm(16),
//                   }}
//                 ></View>
//                 <View
//                   style={{
//                     backgroundColor: "#8AA9AE",
//                     paddingLeft: pxCm(16),
//                     paddingRight: pxCm(16),
//                   }}
//                 >
//                   <View
//                     style={{
//                       display: "flex",
//                       flexDirection: "row",
//                       justifyContent: "space-between",
//                       alignItems: "center",
//                       padding: pxCm(16),
//                       marginTop: pxCm(16),
//                       marginBottom: pxCm(16),
//                       color: "#ffffff",
//                     }}
//                   >
//                     <Text style={{ fontWeight: 700 }}>Salon</Text>
//                     <Text style={{ fontWeight: 700, fontSize: pxCm(50) }}>
//                       {order.exhibition.name}
//                     </Text>
//                   </View>
//                 </View>
//                 <View
//                   style={{
//                     height: pxCm(16),
//                   }}
//                 ></View>
//                 {/* Email and telephone */}
//                 <View style={styles.generalInfoCard}>
//                   <View style={styles.generalInfoCardData}>
//                     <Text style={styles.generalInfoCardTitle}>Email</Text>
//                     <Text style={styles.generalInfoCardText}>
//                       {/* fpiller@akyado.com */}
//                       {order.mainOrderInfo.email}
//                     </Text>
//                   </View>
//                   <View
//                     style={[
//                       styles.generalInfoCardData,
//                       styles.generalInfoCardDataMt,
//                     ]}
//                   >
//                     <Text style={styles.generalInfoCardTitle}>Téléphone</Text>
//                     <Text style={styles.generalInfoCardText}>
//                       {order.mainOrderInfo.phone}
//                     </Text>
//                   </View>
//                 </View>
//               </View>
//               <View style={styles.customerInfo}>
//                 {/* <Text style={styles.customerInfoTitle}>
//                   Adresse de facturation
//                 </Text> */}
//                 <Text
//                   style={[
//                     styles.customerInfoText,
//                     styles.generalInfoCardDataMt,
//                   ]}
//                 >
//                   {/* Frédéric Piller */}
//                   {order.invoiceAddress.firstNameInvoice}{" "}
//                   {order.invoiceAddress.lastNameInvoice}
//                 </Text>
//                 <Text
//                   style={[
//                     styles.customerInfoText,
//                     styles.generalInfoCardDataMt,
//                   ]}
//                 >
//                   {/* Deep Services SA / Akyado */}
//                   {order.invoiceAddress.companyInvoice}
//                 </Text>
//                 <Text
//                   style={[
//                     styles.customerInfoText,
//                     styles.generalInfoCardDataMt,
//                   ]}
//                 >
//                   {/* Route de Tatrel 39 Remaufens 1617 */}
//                   {order.invoiceAddress.addressInvoice}
//                 </Text>
//                 {order.invoiceAddress.address2Invoice && (
//                   <Text
//                     style={[
//                       styles.customerInfoText,
//                       styles.generalInfoCardDataMt,
//                     ]}
//                   >
//                     {/* {invoiceAddress.address2Invoice} */}
//                     {order.invoiceAddress.address2Invoice}
//                   </Text>
//                 )}
//                 <Text
//                   style={[
//                     styles.customerInfoText,
//                     styles.generalInfoCardDataMt,
//                   ]}
//                 >
//                   {/* Suisse */}
//                   {order.invoiceAddress.zipCodeInvoice}{" "}
//                   {order.invoiceAddress.cityInvoice}
//                 </Text>
//                 <Text
//                   style={[
//                     styles.customerInfoText,
//                     styles.generalInfoCardDataMt,
//                   ]}
//                 >
//                   {/* Suisse */}
//                   {order.invoiceAddress.countryInvoice}
//                 </Text>
//               </View>
//             </View>
//             {/* Invoice table */}
//             <View style={styles.table}>
//               {/* Table header */}
//               <View style={styles.tableHeader}>
//                 <View
//                   style={[styles.tableHeaderProducts, styles.tableHeaderItem]}
//                 >
//                   <Text>Produits/Services</Text>
//                 </View>
//                 <View style={styles.tableHeaderOtherCol}>
//                   <View
//                     style={[
//                       styles.tableHeaderOtherColItem,
//                       styles.tableHeaderHT,
//                       styles.tableHeaderItem,
//                     ]}
//                   >
//                     <Text style={{ textAlign: "right" }}>Prix unit. HT</Text>
//                   </View>
//                   <View
//                     style={[
//                       styles.tableHeaderOtherColItem,
//                       styles.tableHeaderItem,
//                       styles.tableHeaderQty,
//                     ]}
//                   >
//                     <Text>Qté</Text>
//                   </View>
//                   {/* Rabais */}
//                   {/* <View
//                     style={[
//                       styles.tableHeaderOtherColItem,
//                       styles.tableHeaderItem,
//                     ]}
//                   >
//                     <Text style={{ textAlign: "right" }}>Rabais</Text>
//                   </View> */}
//                   {/* Rabais */}
//                   <View
//                     style={[
//                       styles.tableHeaderOtherColItem,
//                       styles.tableHeaderItem,
//                     ]}
//                   >
//                     <Text style={{ textAlign: "right" }}>Total HT</Text>
//                   </View>
//                   <View
//                     style={[
//                       styles.tableHeaderOtherColItem,
//                       styles.tableHeaderItem,
//                     ]}
//                   >
//                     <Text style={{ textAlign: "right" }}>TVA</Text>
//                   </View>
//                   {/* TTC */}
//                   <View
//                     style={[
//                       styles.tableHeaderOtherColItem,
//                       styles.tableHeaderItem,
//                     ]}
//                   >
//                     <Text style={{ textAlign: "right" }}>Total TTC</Text>
//                   </View>
//                   {/* TTC */}
//                 </View>
//               </View>
//               {/* Table body */}
//               <View style={styles.tableBody}>
//                 {order.products.map((product, index) => {
//                   discounts +=
//                     product.regularPrice * product.quantity - product.price;
//                   priceBeforeDiscounts +=
//                     product.regularPrice * product.quantity;
//                   return (
//                     <View
//                       style={{
//                         flexDirection: "row",
//                         paddingVertical: pxCm(40),
//                         paddingHorizontal: pxCm(26),
//                         borderBottom: "0.1px solid rgba(71, 112, 120)",
//                         // backgroundColor:
//                         //   index % 2 === 0 ? "#ffffff" : "#f8f8f8",
//                       }}
//                     >
//                       <View style={styles.tableRowProductName}>
//                         <Text>
//                           {/* Stand - 9m² - Ouvert sur 2 côtés */}
//                           {product.name}
//                         </Text>
//                       </View>
//                       <View style={styles.tableRowOtherCol}>
//                         {/* Prix unitaire HT */}
//                         <View
//                           style={[
//                             styles.tableRowOtherColItem,
//                             styles.tableRowHT,
//                           ]}
//                         >
//                           {/* <Text>CHF 1’197,77</Text> */}
//                           {/* <Text>CHF {product.price}</Text> */}
//                           {/* <Text>{returnPriceFormattedCHF(product.price)}</Text> */}
//                           <Text>
//                             {/* {returnPriceFormattedCHF(product.regularPrice)} */}
//                             {returnPriceFormattedCHFForInvoice(
//                               product.regularPrice
//                             )}
//                           </Text>
//                         </View>
//                         {/* Quantity */}
//                         <View
//                           style={[
//                             styles.tableRowOtherColItem,
//                             styles.tableRowOtherColItemQty,
//                           ]}
//                         >
//                           <Text style={{ textAlign: "center" }}>
//                             {/* 1 */}
//                             {product.quantity}
//                           </Text>
//                         </View>
//                         {/* TOTAL HT */}
//                         <View style={styles.tableRowOtherColItem}>
//                           {/* <Text>CHF 1’197,77</Text> */}
//                           {/* <Text>CHF {product.regularPrice}</Text> */}
//                           <Text>
//                             {/* {returnPriceFormattedCHF(product.regularPrice)} */}
//                             <Text>
//                               {/* {returnPriceFormattedCHF(product.price)} */}
//                               {returnPriceFormattedCHFForInvoice(product.price)}
//                             </Text>
//                           </Text>
//                         </View>
//                         {/* TVA */}
//                         <View style={styles.tableRowOtherColItem}>
//                           {/* <Text>CHF 92,23</Text> */}
//                           {/* <Text>CHF {product.vat}</Text> */}
//                           {/* <Text>{returnPriceFormattedCHF(product.vat)}</Text> */}
//                           <Text>
//                             {returnPriceFormattedCHFForInvoice(product.vat)}
//                           </Text>
//                         </View>
//                         {/* TTC */}
//                         <View style={styles.tableRowOtherColItem}>
//                           <Text>
//                             {/* 1 */}
//                             {/* {product.quantity} */}
//                             {returnPriceFormattedCHFForInvoice(
//                               product.price * product.quantity + product.vat
//                             )}
//                             {/* {returnPriceFormattedCHF(
//                               product.price * product.quantity + product.vat
//                             )} */}
//                           </Text>
//                         </View>
//                       </View>
//                     </View>
//                   );
//                 })}
//                 {/* Fees */}
//                 {order.fees.map((fee, index) => (
//                   <View
//                     style={{
//                       flexDirection: "row",
//                       paddingVertical: pxCm(40),
//                       paddingHorizontal: pxCm(26),
//                       borderBottom: "0.1px solid rgba(71, 112, 120)",
//                       // backgroundColor:
//                       //   index === 0
//                       //     ? numberOfProductsIsEven
//                       //       ? "#ffffff"
//                       //       : "#f8f8f8"
//                       //     : index % 2 === 0
//                       //     ? "#ffffff"
//                       //     : "#f8f8f8",
//                     }}
//                   >
//                     <View style={styles.tableRowProductName}>
//                       <Text>
//                         {/* If fee.name inclues 'Frais' and fee.amount includes '-' then replace 'Frais' by 'Rabais' in fee.name */}
//                         {fee.name
//                           ? fee.name
//                           : fee.amount < 0
//                             ? "Rabais"
//                             : "Frais"}
//                         {/* {fee.name.includes("Frais") &&
//                         fee.amount.toString().includes("-")
//                           ? fee.name.replace("Frais", "Rabais")
//                           : fee.name} */}
//                       </Text>
//                     </View>
//                     <View style={styles.tableRowOtherCol}>
//                       {/* Prix unitaire HT */}
//                       <View
//                         style={[styles.tableRowOtherColItem, styles.tableRowHT]}
//                       >
//                         {/* <Text>CHF 1’197,77</Text> */}
//                         {/* <Text>CHF {product.price}</Text> */}
//                         {/* <Text>{returnPriceFormattedCHF(product.price)}</Text> */}
//                         {/* <Text>{returnPriceFormattedCHF(fee.amount)}</Text> */}
//                         <Text>
//                           {returnPriceFormattedCHFForInvoice(fee.amount)}
//                         </Text>
//                       </View>
//                       {/* Quantity */}
//                       <View
//                         style={[
//                           styles.tableRowOtherColItem,
//                           styles.tableRowOtherColItemQty,
//                         ]}
//                       >
//                         <Text style={{ textAlign: "center" }}>1</Text>
//                       </View>
//                       {/* TOTAL HT */}
//                       <View style={styles.tableRowOtherColItem}>
//                         {/* <Text>CHF 1’197,77</Text> */}
//                         {/* <Text>CHF {product.regularPrice}</Text> */}
//                         <Text>
//                           {/* {returnPriceFormattedCHF(product.regularPrice)} */}
//                           {/* {returnPriceFormattedCHF(fee.amount)} */}
//                           {returnPriceFormattedCHFForInvoice(fee.amount)}
//                         </Text>
//                         {/* {fee.amount} */}
//                       </View>
//                       {/* TVA */}
//                       <View style={styles.tableRowOtherColItem}>
//                         {/* <Text>CHF 92,23</Text> */}
//                         {/* <Text>CHF {product.vat}</Text> */}
//                         {/* <Text>{returnPriceFormattedCHF(fee.vat)}</Text> */}
//                         <Text>
//                           {returnPriceFormattedCHFForInvoice(fee.vat)}
//                         </Text>
//                         {/* <Text>{fee.vat}</Text> */}
//                       </View>
//                       {/* TTC */}
//                       <View style={styles.tableRowOtherColItem}>
//                         <Text>
//                           {/* 1 */}
//                           {/* {product.quantity} */}
//                           {/* {returnPriceFormattedCHF(fee.amount + fee.vat)} */}
//                           {returnPriceFormattedCHFForInvoice(
//                             fee.amount + fee.vat
//                           )}
//                         </Text>
//                       </View>
//                     </View>
//                   </View>
//                 ))}
//               </View>
//               {/* Table footer */}
//               <View style={styles.tableFooter}>
//                 <View>
//                   {/* Discounts */}
//                   {/* {fees !== 0 && (
//                     <View style={styles.tableFooterDiscounts}>
//                       <View
//                         style={[
//                           styles.tableFooterCardData,
//                           styles.tableFooterCardDataMt,
//                         ]}
//                       >
//                         <Text style={styles.tableFooterCardTitle}>
//                           RABAIS / code promo
//                         </Text>
//                         <Text style={styles.tableFooterCardText}>
//                           {returnPriceFormattedCHF(fees)}
//                         </Text>
//                       </View>
//                     </View>
//                   )} */}
//                   <View style={styles.tableFooterCard}>
//                     {/* Sous total */}
//                     <View
//                       style={[
//                         styles.tableFooterCardData,
//                         styles.tableFooterCardDataMt,
//                       ]}
//                     >
//                       <Text style={styles.tableFooterCardTitle}>
//                         SOUS-TOTAL (Hors taxes)
//                       </Text>
//                       <Text style={styles.tableFooterCardText}>
//                         {/* CHF 1’197,77 */}
//                         {/* {returnPriceFormattedCHF( */}
//                         {returnPriceFormattedCHF(priceBeforeDiscounts)}
//                       </Text>
//                     </View>
//                     {/* RAbais */}
//                     {rabais <= -0.01 && (
//                       <View
//                         style={[
//                           styles.tableFooterCardData,
//                           styles.tableFooterCardDataMt,
//                           styles.tableFooterDiscount,
//                         ]}
//                       >
//                         <Text style={styles.tableFooterCardTitle}>
//                           RABAIS (Hors taxes)
//                         </Text>
//                         <Text style={styles.tableFooterCardText}>
//                           {/* CHF 1’197,77 */}
//                           {/* {returnPriceFormattedCHF(discounts)} */}
//                           {returnPriceFormattedCHF(rabais)}
//                           {/* {rabais <= 0 ? "true" : "false"} */}
//                           {/* {rabais < 0.01 ? "< 0" : "> 0"} */}
//                         </Text>
//                       </View>
//                     )}
//                     {frais >= 0.01 && (
//                       <View
//                         style={[
//                           styles.tableFooterCardData,
//                           styles.tableFooterCardDataMt,
//                         ]}
//                       >
//                         <Text style={styles.tableFooterCardTitle}>
//                           FRAIS SUPP. (Hors taxes)
//                         </Text>
//                         <Text style={styles.tableFooterCardText}>
//                           {/* {returnPriceFormattedCHF(fees)} */}
//                           {returnPriceFormattedCHF(frais)}
//                         </Text>
//                       </View>
//                     )}
//                     {/*  */}
//                     <View
//                       style={[
//                         styles.tableFooterCardData,
//                         styles.tableFooterCardDataMt,
//                       ]}
//                     >
//                       <Text style={styles.tableFooterCardTitle}>
//                         TOTAL (Hors taxes)
//                       </Text>
//                       <Text style={styles.tableFooterCardText}>
//                         {/* CHF 1’197,77 */}
//                         {returnPriceFormattedCHF(order.totalWithoutVat)}
//                       </Text>
//                     </View>
//                     <View
//                       style={[
//                         styles.tableFooterCardData,
//                         styles.tableFooterCardDataMt,
//                       ]}
//                     >
//                       <Text style={styles.tableFooterCardTitle}>
//                         TOTAL TVA (7,7%)
//                       </Text>
//                       {/* <Text style={styles.tableFooterCardText}>CHF 92,23</Text> */}
//                       <Text style={styles.tableFooterCardText}>
//                         {returnPriceFormattedCHF(order.vat)}
//                       </Text>
//                     </View>
//                     <View
//                       style={[
//                         styles.tableFooterCardData,
//                         styles.tableFooterCardDataMt,
//                         styles.tableFooterTotal,
//                       ]}
//                     >
//                       <Text style={styles.tableFooterCardTitle}>
//                         MONTANT NET DE LA COMMANDE
//                       </Text>
//                       <Text
//                         style={[
//                           styles.tableFooterCardText,
//                           styles.tableFooterTotalPrice,
//                         ]}
//                       >
//                         {returnPriceFormattedCHF(order.total)}
//                         {/* {returnPriceFormattedCHF(order.total + order.vat)} */}
//                       </Text>
//                     </View>
//                   </View>
//                 </View>
//               </View>
//             </View>
//             {/* Comments */}
//             {order.invoiceComments && (
//               <View
//                 style={{
//                   width: pxCm(2240),
//                   marginHorizontal: "auto",
//                   marginTop: pxCm(100),
//                 }}
//               >
//                 <Text style={{ fontSize: 12, fontWeight: 700 }}>
//                   Commentaires
//                 </Text>
//                 <Text style={{ fontSize: pxCm(36) }}>
//                   {order.invoiceComments}
//                   {/* Lorem ipsum dolor sit amet consectetur adipisicing elit.
//                   Dolorum quae eaque at qui soluta ex itaque quos minus
//                   molestiae nesciunt, sequi aspernatur, architecto numquam magni
//                   voluptates necessitatibus accusamus culpa officiis. Lorem
//                   ipsum dolor sit amet consectetur adipisicing elit. Dolorum
//                   quae eaque at qui soluta ex itaque quos minus molestiae
//                   nesciunt, sequi */}
//                 </Text>
//               </View>
//             )}
//             {/* Invoice `How to pay` */}
//             {/* <View style={styles.howToPay}>
//               <Text style={styles.howToPayTitle}>
//                 Comment et quand régler ma facture ?
//               </Text>
//               <View style={[styles.howToPayTextP1]}>
//                 <Text style={styles.howToPayTextBold}>
//                   Votre facture doit être réglée dans les 10 jours.
//                 </Text>
//                 <Text>
//                   En cas de non-paiement dans les délais, votre inscription ne
//                   sera pas validée.
//                 </Text>
//               </View>
//               <View style={styles.howToPayTextP2}>
//                 <Text>Vous pouvez payer votre facture soit :</Text>
//                 <Text>
//                   <Text style={styles.howToPayTextBold}>
//                     - par virement bancaire
//                   </Text>{" "}
//                   à l’aide du QR Code présent sur cette facture.{" "}
//                   <Text style={styles.howToPayTextUnderline}>
//                     Merci de préciser le numéro de facture lors {"   "} de votre
//                     règlement.
//                   </Text>
//                 </Text>
//                 <Text>
//                   <Text style={styles.howToPayTextBold}>
//                     - par carte bancaire
//                   </Text>{" "}
//                   via le lien de paiement reçu dans l’e-mail contenant cette
//                   facture
//                 </Text>
//                 <Text style={[styles.howToPayTextP3, styles.howToPayTextBold]}>
//                   Pour toutes questions concernant la facturation, merci
//                   d’adresser un e-mail à info@satn.ch
//                 </Text>
//               </View>
//             </View> */}
//           </View>
//         </View>
//         <View style={styles.pageFooter} fixed>
//           <View
//             style={{
//               display: "flex",
//               alignItems: "center",
//               flexDirection: "row",
//               justifyContent: "space-between",
//               // width: pxCm(1000),
//               width: 240,
//               margin: "0 auto",
//             }}
//           >
//             <View
//               style={{
//                 textAlign: "right",
//                 display: "flex",
//                 flexDirection: "column",
//                 alignItems: "flex-end",
//               }}
//             >
//               <Text style={{ fontWeight: 700, fontSize: 12 }}>
//                 ST Organisation Sàrl
//               </Text>
//               <Text style={{ fontSize: 9.8 }}> Rue de Jérusalem</Text>
//               <Text style={{ fontSize: 9.8 }}>1 CH- 1860 Aigle</Text>
//             </View>
//             <View>
//               <Text style={{ fontSize: 9.8 }}>info@satn.ch</Text>
//               <Text style={{ fontSize: 9.8 }}>Tél : +41 24 466 12 89</Text>
//               <Text style={{ fontSize: 9.8 }}>www.satn.ch</Text>
//             </View>
//           </View>
//           <View style={{ marginTop: pxCm(10) }}>
//             <Text style={{ fontSize: 10 }}>CHE-480.316.854 TVA</Text>
//           </View>
//           {/* <Text>
//             ST Organisation Sàrl - Rue de Jérusalem 1, CH- 1860 Aigle | N° TVA
//             CHE-480.316.854 TVA
//           </Text>
//           <Text>www.satn.ch | E-mail : info@satn.ch | Tél : 024/466.12.89</Text> */}
//         </View>
//       </Page>
//       <Page size="A4" style={[styles.page, styles.qrPage]} wrap>
//         <View style={styles.howToPay} wrap={false}>
//           <Text style={styles.howToPayTitle}>
//             Comment et quand régler ma facture ?
//           </Text>
//           <View style={[styles.howToPayTextP1]}>
//             <Text style={styles.howToPayTextBold}>
//               Votre facture doit être réglée dans les 10 jours.
//             </Text>
//             <Text>
//               En cas de non-paiement dans les délais, votre inscription ne sera
//               pas validée.
//             </Text>
//           </View>
//           <View style={styles.howToPayTextP2}>
//             <Text>Vous pouvez payer votre facture soit :</Text>
//             <Text>
//               <Text style={styles.howToPayTextBold}>
//                 - par virement bancaire
//               </Text>{" "}
//               à l’aide du QR Code présent sur cette facture.{" "}
//               <Text style={styles.howToPayTextUnderline}>
//                 Merci de préciser le numéro de facture lors {"   "} de votre
//                 règlement.
//               </Text>
//             </Text>
//             <Text>
//               <Text style={styles.howToPayTextBold}>- par carte bancaire</Text>{" "}
//               via le lien de paiement reçu dans l’e-mail contenant cette facture
//             </Text>
//             <Text style={[styles.howToPayTextP3, styles.howToPayTextBold]}>
//               Pour toutes questions concernant la facturation, merci d’adresser
//               un e-mail à info@satn.ch
//             </Text>
//           </View>
//         </View>
//         <Image
//           src={{
//             uri: qrCh,
//             method: "GET",
//             headers: { "Cache-Control": "no-cache" },
//             body: "",
//           }}
//         />
//       </Page>
//     </Document>
//   );
// };

export const Invoice = (order: OrderData) => {
  let rabais = 0;
  let frais = 0;

  // Loop on order fees and if the fee amount is positive add it to frais variable else add it to rabais variable
  order.fees.forEach((fee) => {
    const numberFeeAmount = Number(fee.amount);
    if (numberFeeAmount > 0) {
      frais += numberFeeAmount;
    } else {
      rabais += numberFeeAmount;
    }
  });

  let fees = 0;
  order.fees.forEach((fee) => {
    const numberFeeAmount = Number(fee.amount);
    fees += numberFeeAmount;
  });
  // let year = order.date ? order.date.match(/\d{4}/) : "";
  let year = order.exhibition.name ? order.exhibition.name.match(/\d{4}/) : "";
  year = year ? year[0] : "";

  // Exhibition name without only the two first characters
  let exhibition =
    order.exhibition.name !== "-" && order.exhibition.name !== ""
      ? order.exhibition.name.split(" ")[0].slice(0, 2).toUpperCase()
      : "CDE";

  let discounts = 0;
  let priceBeforeDiscounts = 0;

  // const products = [
  //   ...order.products,
  //   ...order.fees,
  // ]

  // Get the number of products in the order to check if the number is odd or even
  const numberOfProductsIsEven = order.products.length % 2 === 0 ? 2 : 1;
  const [selectedOrderMainTaxRate, setSelectedOrderMainTaxRate] = useRecoilState(selectedOrderMainTaxRateAtom)

  const invoiceIdString = `${exhibition}${year}-${order.orderId}`;
  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <View style={styles.flower}>
          <Image
            fixed
            src={{
              uri: flowerInvoice,
              method: "GET",
              headers: { "Cache-Control": "no-cache" },
              body: "",
            }}
          />
        </View>
        <View>
          {/* HEADER */}
          <View style={styles.header}>
            <View style={styles.logo}>
              <Image
                src={{
                  uri: logo,
                  method: "GET",
                  headers: { "Cache-Control": "no-cache" },
                  body: "",
                }}
              />
            </View>
            <View style={styles.rightHeader}>
              <Image
                style={styles.exhibitionsLogos}
                src={{
                  uri: exhibitionsLogos,
                  method: "GET",
                  headers: { "Cache-Control": "no-cache" },
                  body: "",
                }}
              />
              <View>
                {/* <Text style={styles.headerCompany}>ST Organisation Sàrl</Text> */}
                {/* <Text style={styles.headerAddress}>
                  Rue de Jérusalem 1 | CH- 1860 Aigle
                </Text>
                <Text style={styles.headerAddress}>
                  info@sdtn.ch | Tél : +41 24/466.12.89
                </Text> */}
              </View>
            </View>
          </View>
          {/* BODY */}
          <View style={styles.section}>
            {/* Invoice info */}
            <View style={styles.invoiceInfo}>
              <View style={styles.generalInfo}>
                {/* Invoice and date */}
                <View style={styles.generalInfoCard}>
                  <View
                    style={[
                      styles.generalInfoCardData,
                      styles.generalInfoCardDataMt,
                    ]}
                  >
                    <Text style={styles.generalInfoCardTitle}>Facture </Text>
                    <Text style={styles.generalInfoCardText}>
                      {invoiceIdString}
                    </Text>
                  </View>
                  <View style={styles.generalInfoCardData}>
                    <Text style={styles.generalInfoCardTitle}>
                      Date de commande
                    </Text>
                    <Text style={styles.generalInfoCardText}>
                      {/* 22 décembre 2022 */}
                      {order.formattedDate}
                    </Text>
                  </View>
                  <View style={styles.generalInfoCardData}>
                    <Text style={styles.generalInfoCardTitle}>
                      Date de facture
                    </Text>
                    <Text style={styles.generalInfoCardText}>
                      {order.invoiceGenerationDateFormatted}
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    height: pxCm(16),
                  }}
                ></View>
                <View
                  style={{
                    backgroundColor: "#8AA9AE",
                    paddingLeft: pxCm(16),
                    paddingRight: pxCm(16),
                  }}
                >
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: pxCm(16),
                      marginTop: pxCm(16),
                      marginBottom: pxCm(16),
                      color: "#ffffff",
                    }}
                  >
                    <Text style={{ fontWeight: 700 }}>Salon</Text>
                    <Text style={{ fontWeight: 700, fontSize: pxCm(50) }}>
                      {order.exhibition.name}
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    height: pxCm(16),
                  }}
                ></View>
                {/* Email and telephone */}
                <View style={styles.generalInfoCard}>
                  <View style={styles.generalInfoCardData}>
                    <Text style={styles.generalInfoCardTitle}>Email</Text>
                    <Text style={styles.generalInfoCardText}>
                      {/* fpiller@akyado.com */}
                      {order.mainOrderInfo.email}
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.generalInfoCardData,
                      styles.generalInfoCardDataMt,
                    ]}
                  >
                    <Text style={styles.generalInfoCardTitle}>Téléphone</Text>
                    <Text style={styles.generalInfoCardText}>
                      {order.mainOrderInfo.phone}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={styles.customerInfo}>
                {/* <Text style={styles.customerInfoTitle}>
                  Adresse de facturation
                </Text> */}
                <Text
                  style={[
                    styles.customerInfoText,
                    styles.generalInfoCardDataMt,
                  ]}
                >
                  {/* Frédéric Piller */}
                  {order.invoiceAddress.firstNameInvoice}{" "}
                  {order.invoiceAddress.lastNameInvoice}
                </Text>
                <Text
                  style={[
                    styles.customerInfoText,
                    styles.generalInfoCardDataMt,
                  ]}
                >
                  {/* Deep Services SA / Akyado */}
                  {order.invoiceAddress.companyInvoice}
                </Text>
                <Text
                  style={[
                    styles.customerInfoText,
                    styles.generalInfoCardDataMt,
                  ]}
                >
                  {/* Route de Tatrel 39 Remaufens 1617 */}
                  {order.invoiceAddress.addressInvoice}
                </Text>
                {order.invoiceAddress.address2Invoice && (
                  <Text
                    style={[
                      styles.customerInfoText,
                      styles.generalInfoCardDataMt,
                    ]}
                  >
                    {/* {invoiceAddress.address2Invoice} */}
                    {order.invoiceAddress.address2Invoice}
                  </Text>
                )}
                <Text
                  style={[
                    styles.customerInfoText,
                    styles.generalInfoCardDataMt,
                  ]}
                >
                  {/* Suisse */}
                  {order.invoiceAddress.zipCodeInvoice}{" "}
                  {order.invoiceAddress.cityInvoice}
                </Text>
                <Text
                  style={[
                    styles.customerInfoText,
                    styles.generalInfoCardDataMt,
                  ]}
                >
                  {/* Suisse */}
                  {order.invoiceAddress.countryInvoice}
                </Text>
              </View>
            </View>
            {/* Invoice table */}
            <View style={styles.table}>
              {/* Table header */}
              <View style={styles.tableHeader}>
                <View
                  style={[styles.tableHeaderProducts, styles.tableHeaderItem]}
                >
                  <Text>Produits/Services</Text>
                </View>
                <View style={styles.tableHeaderOtherCol}>
                  <View
                    style={[
                      styles.tableHeaderOtherColItem,
                      // styles.tableHeaderHT,
                      // styles.tableHeaderItem,
                    ]}
                  >
                    <Text>Prix unit. HT</Text>
                  </View>
                  <View
                    style={[
                      styles.tableHeaderOtherColItem,
                      // styles.tableHeaderItem,
                      styles.tableHeaderQty,
                    ]}
                  >
                    <Text>Qté</Text>
                  </View>
                  <View
                    style={[
                      styles.tableHeaderOtherColItem,
                      // styles.tableHeaderItem,
                    ]}
                  >
                    <Text>Total HT</Text>
                  </View>
                  <View
                    style={[
                      styles.tableHeaderOtherColItem,
                      // styles.tableHeaderItem,
                    ]}
                  >
                    <Text>TVA</Text>
                  </View>
                  {/* TTC */}
                  <View
                    style={[
                      styles.tableHeaderOtherColItem,
                      // styles.tableHeaderItem,
                    ]}
                  >
                    <Text>Total TTC</Text>
                  </View>
                  {/* TTC */}
                </View>
              </View>
              {/* Table body */}
              <View style={styles.tableBody}>
                {order.products.map((product, index) => {
                  discounts +=
                    product.regularPrice * product.quantity - product.price;
                  priceBeforeDiscounts +=
                    product.regularPrice * product.quantity;
                  return (
                    <View
                      style={{
                        flexDirection: "row",
                        paddingVertical: pxCm(40),
                        paddingHorizontal: pxCm(26),
                        borderBottom: "0.1px solid rgba(71, 112, 120)",
                        // backgroundColor:
                        //   index % 2 === 0 ? "#ffffff" : "#f8f8f8",
                      }}
                    >
                      <View style={styles.tableRowProductName}>
                        <Text>
                          {/* Stand - 9m² - Ouvert sur 2 côtés */}
                          {product.name}
                        </Text>
                      </View>
                      <View style={styles.tableRowOtherCol}>
                        {/* Prix unitaire HT */}
                        <View
                          style={[
                            styles.tableRowOtherColItem,
                            // styles.tableRowHT,
                          ]}
                        >
                          {/* <Text>CHF 1’197,77</Text> */}
                          {/* <Text>CHF {product.price}</Text> */}
                          {/* <Text>{returnPriceFormattedCHF(product.price)}</Text> */}
                          <Text>
                            {/* {returnPriceFormattedCHF(product.regularPrice)} */}
                            {returnPriceFormattedCHFForInvoice(
                              product.regularPrice
                            )}
                          </Text>
                        </View>
                        {/* Quantity */}
                        <View
                          style={[
                            styles.tableRowOtherColItem,
                            styles.tableRowOtherColItemQty,
                          ]}
                        >
                          <Text>
                            {/* 1 */}
                            {product.quantity}
                          </Text>
                        </View>
                        {/* TOTAL HT */}
                        <View style={styles.tableRowOtherColItem}>
                          {/* <Text>CHF 1’197,77</Text> */}
                          {/* <Text>CHF {product.regularPrice}</Text> */}
                          {/* {returnPriceFormattedCHF(product.regularPrice)} */}
                          <Text>
                            {/* {returnPriceFormattedCHF(product.price)} */}
                            {returnPriceFormattedCHFForInvoice(product.price)}
                          </Text>
                        </View>
                        {/* TVA */}
                        <View style={styles.tableRowOtherColItem}>
                          {/* <Text>CHF 92,23</Text> */}
                          {/* <Text>CHF {product.vat}</Text> */}
                          {/* <Text>{returnPriceFormattedCHF(product.vat)}</Text> */}
                          <Text>
                            {returnPriceFormattedCHFForInvoice(product.vat)}
                          </Text>
                        </View>
                        {/* TTC */}
                        <View style={styles.tableRowOtherColItem}>
                          <Text>
                            {/* 1 */}
                            {/* {product.quantity} */}
                            {returnPriceFormattedCHFForInvoice(
                              product.price + product.vat
                            )}
                            {/* {returnPriceFormattedCHF(
                              product.price * product.quantity + product.vat
                            )} */}
                          </Text>
                        </View>
                      </View>
                    </View>
                  );
                })}
                {/* Fees */}
                {order.fees.map((fee, index) => (
                  <View
                    style={{
                      flexDirection: "row",
                      paddingVertical: pxCm(40),
                      paddingHorizontal: pxCm(26),
                      borderBottom: "0.1px solid rgba(71, 112, 120)",
                      // backgroundColor:
                      //   index === 0
                      //     ? numberOfProductsIsEven
                      //       ? "#ffffff"
                      //       : "#f8f8f8"
                      //     : index % 2 === 0
                      //     ? "#ffffff"
                      //     : "#f8f8f8",
                    }}
                  >
                    <View style={styles.tableRowProductName}>
                      <Text>
                        {/* If fee.name inclues 'Frais' and fee.amount includes '-' then replace 'Frais' by 'Rabais' in fee.name */}
                        {fee.name
                          ? fee.name
                          : fee.amount < 0
                            ? "Rabais"
                            : "Frais"}
                        {/* {fee.name.includes("Frais") &&
                        fee.amount.toString().includes("-")
                          ? fee.name.replace("Frais", "Rabais")
                          : fee.name} */}
                      </Text>
                    </View>
                    <View style={styles.tableRowOtherCol}>
                      {/* Prix unitaire HT */}
                      <View
                        // style={[styles.tableRowOtherColItem, styles.tableRowHT]}
                        style={[styles.tableRowOtherColItem]}
                      >
                        {/* <Text>CHF 1’197,77</Text> */}
                        {/* <Text>CHF {product.price}</Text> */}
                        {/* <Text>{returnPriceFormattedCHF(product.price)}</Text> */}
                        {/* <Text>{returnPriceFormattedCHF(fee.amount)}</Text> */}
                        <Text>
                          {returnPriceFormattedCHFForInvoice(fee.amount)}
                        </Text>
                      </View>
                      {/* Quantity */}
                      <View
                        style={[
                          styles.tableRowOtherColItem,
                          styles.tableRowOtherColItemQty,
                        ]}
                      >
                        <Text style={{ textAlign: "center" }}>1</Text>
                      </View>
                      {/* TOTAL HT */}
                      <View style={styles.tableRowOtherColItem}>
                        {/* <Text>CHF 1’197,77</Text> */}
                        {/* <Text>CHF {product.regularPrice}</Text> */}
                        <Text>
                          {/* {returnPriceFormattedCHF(product.regularPrice)} */}
                          {/* {returnPriceFormattedCHF(fee.amount)} */}
                          {returnPriceFormattedCHFForInvoice(fee.amount)}
                        </Text>
                        {/* {fee.amount} */}
                      </View>
                      {/* TVA */}
                      <View style={styles.tableRowOtherColItem}>
                        {/* <Text>CHF 92,23</Text> */}
                        {/* <Text>CHF {product.vat}</Text> */}
                        {/* <Text>{returnPriceFormattedCHF(fee.vat)}</Text> */}
                        <Text>
                          {returnPriceFormattedCHFForInvoice(fee.vat)}
                        </Text>
                        {/* <Text>{fee.vat}</Text> */}
                      </View>
                      {/* TTC */}
                      <View style={styles.tableRowOtherColItem}>
                        <Text>
                          {/* 1 */}
                          {/* {product.quantity} */}
                          {/* {returnPriceFormattedCHF(fee.amount + fee.vat)} */}
                          {returnPriceFormattedCHFForInvoice(
                            fee.amount + fee.vat
                          )}
                        </Text>
                      </View>
                    </View>
                  </View>
                ))}
              </View>
              {/* Table footer */}
              <View style={styles.tableFooter}>
                <View>
                  {/* Discounts */}
                  {/* {fees !== 0 && (
                    <View style={styles.tableFooterDiscounts}>
                      <View
                        style={[
                          styles.tableFooterCardData,
                          styles.tableFooterCardDataMt,
                        ]}
                      >
                        <Text style={styles.tableFooterCardTitle}>
                          RABAIS / code promo
                        </Text>
                        <Text style={styles.tableFooterCardText}>
                          {returnPriceFormattedCHF(fees)}
                        </Text>
                      </View>
                    </View>
                  )} */}
                  <View style={styles.tableFooterCard}>
                    {/* Sous total */}
                    <View
                      style={[
                        styles.tableFooterCardData,
                        styles.tableFooterCardDataMt,
                      ]}
                    >
                      <Text style={styles.tableFooterCardTitle}>
                        SOUS-TOTAL (Hors taxes)
                      </Text>
                      <Text style={styles.tableFooterCardText}>
                        {/* CHF 1’197,77 */}
                        {/* {returnPriceFormattedCHF( */}
                        {returnPriceFormattedCHF(priceBeforeDiscounts)}
                      </Text>
                    </View>
                    {/* RAbais */}
                    {rabais <= -0.01 && (
                      <View
                        style={[
                          styles.tableFooterCardData,
                          styles.tableFooterCardDataMt,
                          styles.tableFooterDiscount,
                        ]}
                      >
                        <Text style={styles.tableFooterCardTitle}>
                          RABAIS (Hors taxes)
                        </Text>
                        <Text style={styles.tableFooterCardText}>
                          {/* CHF 1’197,77 */}
                          {/* {returnPriceFormattedCHF(discounts)} */}
                          {returnPriceFormattedCHF(rabais)}
                          {/* {rabais <= 0 ? "true" : "false"} */}
                          {/* {rabais < 0.01 ? "< 0" : "> 0"} */}
                        </Text>
                      </View>
                    )}
                    {frais >= 0.01 && (
                      <View
                        style={[
                          styles.tableFooterCardData,
                          styles.tableFooterCardDataMt,
                        ]}
                      >
                        <Text style={styles.tableFooterCardTitle}>
                          FRAIS SUPP. (Hors taxes)
                        </Text>
                        <Text style={styles.tableFooterCardText}>
                          {/* {returnPriceFormattedCHF(fees)} */}
                          {returnPriceFormattedCHF(frais)}
                        </Text>
                      </View>
                    )}
                    {/*  */}
                    <View
                      style={[
                        styles.tableFooterCardData,
                        styles.tableFooterCardDataMt,
                      ]}
                    >
                      <Text style={styles.tableFooterCardTitle}>
                        TOTAL (Hors taxes)
                      </Text>
                      <Text style={styles.tableFooterCardText}>
                        {/* CHF 1’197,77 */}
                        {returnPriceFormattedCHF(order.totalWithoutVat)}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.tableFooterCardData,
                        styles.tableFooterCardDataMt,
                      ]}
                    >
                      <Text style={styles.tableFooterCardTitle}>
                        {returnTaxRate(selectedOrderMainTaxRate)}
                      </Text>
                      {/* <Text style={styles.tableFooterCardText}>CHF 92,23</Text> */}
                      <Text style={styles.tableFooterCardText}>
                        {returnPriceFormattedCHF(order.vat)}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.tableFooterCardData,
                        styles.tableFooterCardDataMt,
                        styles.tableFooterTotal,
                      ]}
                    >
                      <Text style={styles.tableFooterCardTitle}>
                        MONTANT NET DE LA COMMANDE
                      </Text>
                      <Text
                        style={[
                          styles.tableFooterCardText,
                          styles.tableFooterTotalPrice,
                        ]}
                      >
                        {returnPriceFormattedCHF(order.total)}
                        {/* {returnPriceFormattedCHF(order.total + order.vat)} */}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            {/* Comments */}
            {order.invoiceComments && (
              <View
                style={{
                  width: pxCm(2240),
                  marginHorizontal: "auto",
                  marginTop: pxCm(100),
                }}
              >
                <Text style={{ fontSize: 12, fontWeight: 700 }}>
                  Commentaires
                </Text>
                <Text style={{ fontSize: pxCm(36) }}>
                  {order.invoiceComments}
                  {/* Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Dolorum quae eaque at qui soluta ex itaque quos minus
                  molestiae nesciunt, sequi aspernatur, architecto numquam magni
                  voluptates necessitatibus accusamus culpa officiis. Lorem
                  ipsum dolor sit amet consectetur adipisicing elit. Dolorum
                  quae eaque at qui soluta ex itaque quos minus molestiae
                  nesciunt, sequi */}
                </Text>
              </View>
            )}
            {/* Invoice `How to pay` */}
            {/* <View style={styles.howToPay}>
              <Text style={styles.howToPayTitle}>
                Comment et quand régler ma facture ?
              </Text>
              <View style={[styles.howToPayTextP1]}>
                <Text style={styles.howToPayTextBold}>
                  Votre facture doit être réglée dans les 10 jours.
                </Text>
                <Text>
                  En cas de non-paiement dans les délais, votre inscription ne
                  sera pas validée.
                </Text>
              </View>
              <View style={styles.howToPayTextP2}>
                <Text>Vous pouvez payer votre facture soit :</Text>
                <Text>
                  <Text style={styles.howToPayTextBold}>
                    - par virement bancaire
                  </Text>{" "}
                  à l’aide du QR Code présent sur cette facture.{" "}
                  <Text style={styles.howToPayTextUnderline}>
                    Merci de préciser le numéro de facture lors {"   "} de votre
                    règlement.
                  </Text>
                </Text>
                <Text>
                  <Text style={styles.howToPayTextBold}>
                    - par carte bancaire
                  </Text>{" "}
                  via le lien de paiement reçu dans l’e-mail contenant cette
                  facture
                </Text>
                <Text style={[styles.howToPayTextP3, styles.howToPayTextBold]}>
                  Pour toutes questions concernant la facturation, merci
                  d’adresser un e-mail à info@satn.ch
                </Text>
              </View>
            </View> */}
          </View>
        </View>
        <View style={styles.pageFooter} fixed>
          <View
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-between",
              // width: pxCm(1000),
              width: 240,
              margin: "0 auto",
            }}
          >
            <View
              style={{
                textAlign: "right",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <Text style={{ fontWeight: 700, fontSize: 12 }}>
                ST Organisation Sàrl
              </Text>
              <Text style={{ fontSize: 9.8 }}> Rue de Jérusalem</Text>
              <Text style={{ fontSize: 9.8 }}>1 CH- 1860 Aigle</Text>
            </View>
            <View>
              <Text style={{ fontSize: 9.8 }}>info@satn.ch</Text>
              <Text style={{ fontSize: 9.8 }}>Tél : +41 24 466 12 89</Text>
              <Text style={{ fontSize: 9.8 }}>www.satn.ch</Text>
            </View>
          </View>
          <View style={{ marginTop: pxCm(10) }}>
            <Text style={{ fontSize: 10 }}>CHE-480.316.854 TVA</Text>
          </View>
          {/* <Text>
            ST Organisation Sàrl - Rue de Jérusalem 1, CH- 1860 Aigle | N° TVA
            CHE-480.316.854 TVA
          </Text>
          <Text>www.satn.ch | E-mail : info@satn.ch | Tél : 024/466.12.89</Text> */}
        </View>
      </Page>
      <Page size="A4" style={[styles.page, styles.qrPage]} wrap>
        <View style={styles.howToPay} wrap={false}>
          <Text style={styles.howToPayTitle}>
            Comment et quand régler ma facture ?
          </Text>
          <View style={[styles.howToPayTextP1]}>
            <Text style={styles.howToPayTextBold}>
              Votre facture doit être réglée dans les 10 jours.
            </Text>
            <Text>
              En cas de non-paiement dans les délais, votre inscription ne sera
              pas validée.
            </Text>
          </View>
          <View style={styles.howToPayTextP2}>
            <Text>Vous pouvez payer votre facture soit :</Text>
            <Text>
              <Text style={styles.howToPayTextBold}>
                - par virement bancaire
              </Text>{" "}
              à l’aide du QR Code présent sur cette facture.{" "}
              <Text style={styles.howToPayTextUnderline}>
                Merci de préciser le numéro de facture lors {"   "} de votre
                règlement.
              </Text>
            </Text>
            <Text>
              <Text style={styles.howToPayTextBold}>- par carte bancaire</Text>{" "}
              via le lien de paiement reçu dans l’e-mail contenant cette facture
            </Text>
            <Text style={[styles.howToPayTextP3, styles.howToPayTextBold]}>
              Pour toutes questions concernant la facturation, merci d’adresser
              un e-mail à info@satn.ch
            </Text>
          </View>
        </View>
        <Image
          src={{
            uri: qrCh,
            method: "GET",
            headers: { "Cache-Control": "no-cache" },
            body: "",
          }}
        />
      </Page>
    </Document>
  );
};
interface InvoiceWithDataProps {
  pdf: JSX.Element;
  exhibition: string;
  year: string;
  orderId: OrderData["orderId"];
}

const generateAllInvoices = (orders: OrderData[]) => {
  console.log("GENERATING");

  const zip = new JSZip();

  //   For each order, create a pdf document and add it to the zip
  let documentsWithData: InvoiceWithDataProps[] = [];

  orders.forEach((order) => {
    // Date conversion to only year
    // let year = order.date ? order.date.match(/\d{4}/) : "";
    let year = order.exhibition.name
      ? order.exhibition.name.match(/\d{4}/)
      : "";
    year = year ? year[0] : "";

    // Exhibition name without only the two first characters
    let exhibition =
      order.exhibition.name !== "-" && order.exhibition.name !== ""
        ? order.exhibition.name.split(" ")[0].slice(0, 2).toUpperCase()
        : "CDE";

    // console.log(order.exhibition);

    documentsWithData.push({
      // pdf: <Invoice order={order} />,
      pdf: (
        <Invoice
          originalStatus={order.originalStatus}
          company={order.company}
          activityAddress={order.activityAddress}
          customer={order.customer}
          date={order.formattedDate ? order.formattedDate : ""}
          formattedDate={order.formattedDate ? order.formattedDate : ""}
          exhibition={order.exhibition}
          fees={order.fees}
          invoiceAddress={order.invoiceAddress}
          mainOrderInfo={order.mainOrderInfo}
          notes={order.notes}
          orderId={order.orderId}
          paymentAndInvoiceInfo={order.paymentAndInvoiceInfo}
          products={order.products}
          standNumber={order.standNumber}
          status={order.status}
          total={order.total}
          totalWithoutVat={order.totalWithoutVat}
          vat={order.vat}
          invoiceGenerationDateFormatted={order.invoiceGenerationDateFormatted}
          invoiceComments={order.invoiceComments}
        />
      ),
      exhibition: exhibition,
      year: year,
      orderId: order.orderId,
    });
  });

  documentsWithData.forEach((document, index) => {
    zip.file(
      `${document.exhibition}${document.year}-${document.orderId}.pdf`,
      pdf(document.pdf).toBlob()
    );
  });

  zip.generateAsync({ type: "blob" }).then((blob) => {
    saveAs(blob, "factures.zip");
    console.log("DONE");
  });
};

export interface OrderStatusAndEmailData {
  status: string;
  orderId: number;
  pdf: string;
  invoiceName: string;
  html: string;
  updateStatus: boolean;
  customerEmail: string;
}
export const updateOrderStatusAndSendEmailWithInvoice = async ({
  status,
  orderId,
  pdf,
  invoiceName,
  html,
  updateStatus = false,
  customerEmail,
}: OrderStatusAndEmailData) => {
  const body = {
    status: status,
    orderId: orderId,
    pdf: pdf,
    invoiceName: invoiceName,
    html: html,
    customerEmail: customerEmail,
  };

  try {
    const res = await axios.post(
      updateStatus
        ? "https://tileos.pycofa.fr/wp-json/pycofa/v1/update-order-next-version/"
        : "https://tileos.pycofa.fr/wp-json/pycofa/v1/send-email/",
      body
    );
    // console.log(res.data.message);
    console.log(res);
  } catch (error) {
    console.log(error);
  }
};

export const generateSingleInvoice = (
  order: OrderData,
  sendEmail: boolean,
  updateOrderStatus: boolean
) => {
  // Generate a single invoice and save it to the user's computer
  // Date conversion to only year
  // let year = order.date ? order.date.match(/\d{4}/) : "";
  let year = order.exhibition?.year;
  // year = year ? year[0] : "";

  // Exhibition name without only the two first characters
  let exhibition =
    order.exhibition.name !== "-" && order.exhibition.name !== ""
      ? order.exhibition.name.split(" ")[0].slice(0, 2).toUpperCase()
      : "CDE";

  // console.log(order.exhibition);

  const documentWithData: InvoiceWithDataProps = {
    pdf: (
      <Invoice
        originalStatus={order.originalStatus}
        company={order.company}
        activityAddress={order.activityAddress}
        customer={order.customer}
        date={order.date}
        formattedDate={order.formattedDate ? order.formattedDate : ""}
        exhibition={order.exhibition}
        fees={order.fees}
        invoiceAddress={order.invoiceAddress}
        mainOrderInfo={order.mainOrderInfo}
        notes={order.notes}
        orderId={order.orderId}
        paymentAndInvoiceInfo={order.paymentAndInvoiceInfo}
        products={order.products}
        standNumber={order.standNumber}
        status={order.status}
        total={order.total}
        totalWithoutVat={order.totalWithoutVat}
        vat={order.vat}
        invoiceGenerationDateFormatted={order.invoiceGenerationDateFormatted}
        invoiceComments={order.invoiceComments}
      />
    ),
    exhibition: exhibition,
    year: year,
    orderId: order.orderId,
  };

  // console.log(
  //   render(
  //     <Email
  //       previewText={`Votre inscription pour le salon a été acceptée par les organisateurs`}
  //       title="Votre inscription pour le Salon est acceptée"
  //       orderData={order}
  //     />
  //   )
  // );

  // return;
  const email = render(
    <Email
      previewText={`Votre inscription pour le salon a été validé par les organisateurs`}
      title="Votre inscription pour le Salon est validée"
      orderData={order}
    />
  );

  console.log(email);

  // return;

  pdf(documentWithData.pdf)
    .toBlob()
    .then((blob) => {
      // Convert the blob of a pdf to a base64 string
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        console.log(base64data);
        sendEmail &&
          updateOrderStatusAndSendEmailWithInvoice({
            status: order.status,
            orderId: order.orderId,
            pdf: base64data as string,
            // invoiceName: `${documentWithData.exhibition}${documentWithData.year}-${documentWithData.orderId}`,
            invoiceName: `${documentWithData.exhibition}${year}-${documentWithData.orderId}`,
            html: email,
            updateStatus: updateOrderStatus,
            customerEmail: order.customer.email,
          });
      };
      !sendEmail &&
        saveAs(
          blob,
          `${documentWithData.exhibition}${year}-${documentWithData.orderId}.pdf`
        );
    });
};
let token = "";
if (localStorage.getItem("token-dashboard")) {
  token = JSON.parse(localStorage.getItem("token-dashboard") as string);
}

export const sendEmailForProcessingOrder = async (
  orderId: number,
  primaryColor: string,
  secondaryColor: string,
  salonTitle: string,
  email: string
) => {
  axios
    .post(
      `https://tileos.pycofa.fr/wp-json/pycofa/v1/send-email-processing/`,
      {
        orderId: orderId,
        primaryColor: primaryColor,
        secondaryColor: secondaryColor,
        salonTitle: salonTitle,
        email: email,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((data) => {
      console.log("data", data);
    })
    .catch((error) => {
      console.log("error", error);
      if (error.code.includes("jwt")) {
        localStorage.removeItem("token-dashboard");
        window.location.replace("/");
      }
    })
    .finally(() => {
      // setIsUpdatingConference(false);
      // setIsPopupVisible(true);
    });
};

// interface GenerateInvoicesButtonProps {
//   title?: string;
//   orders?: OrderData[];
//   singleOrder?: OrderData;
// }
// const GenerateInvoicesButton = ({
//   title = "Generate invoices",
//   orders,
//   singleOrder,
// }: GenerateInvoicesButtonProps): JSX.Element => {
//   return (
//     <button
//       onClick={() => {
//         !singleOrder
//           ? orders && generateAllInvoices(orders)
//           : singleOrder && generateSingleInvoice(singleOrder, false);
//       }}
//     >
//       {title}
//     </button>
//   );
// };

// export default GenerateInvoicesButton;
