import apiFetch from "@wordpress/api-fetch";
import { returnRootUrlBasedOnAppLocation } from "../../../shared/utils/checkIfIsInlocal";
import { ConferenceDataProps } from "../atoms/conferences-atoms";
import { addQueryArgs } from "@wordpress/url";

export interface ConferenceListingProps {
  conference_id: number;
  speaker: string;
  status: string;
  title: string;
  // exhibition: Exhibition;
  info: string;
  orderId: number;
  // quickView: QuickView;
  exhibition_name: string;
  exhibition_primary_color: string;
  exhibition_secondary_color: string;
}

export interface ConferenceDataPropsV2 {
  conference_id: number;
  speaker: string;
  conference_status: string;
  user_meta: any;
  title: string;
  info: string;
  orderId: number;
  exhibition_name: string;
  exhibition_primary_color: string;
  exhibition_secondary_color: string;
  conference_date: string;
  company?: string;
  //
  // exhibition: Exhibition;
  // quickView: QuickView;
}
// Create an api call with @wordpress/api-fetch for the endpoint admin-dashboard/v1/orders/
export const fetchAllConferences = async (): Promise<ConferenceDataProps[]> => {
  let token = "";
  if (localStorage.getItem("token-dashboard")) {
    token = JSON.parse(localStorage.getItem("token-dashboard") as string);
  }
  const response = await apiFetch({
    path: `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v3/conferences`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response as ConferenceDataProps[];
};

interface ConferencesQueryParams {
  // search?: string;
  // page?: number;
  // per_page?: number;
  // sort_by?: string;
  // sort_order?: string;
  post_name?: string | null;
  per_page?: number;
  annee?: string | null;
  post_status?: string | null;
  page?: number;
  only_non_assigned_stands?: boolean;
  sorting_params?: string;
}
interface ConferencesResponse {
  conferences: ConferenceDataPropsV2[];
  total_pages: number;
  total_elements: number;
  total_count?: number;
}

export const fetchAllConferencesV2 = async ({
  post_name,
  per_page,
  annee,
  post_status,
  page,
  sorting_params,
}: ConferencesQueryParams): Promise<ConferencesResponse> => {
  const queryParams = {
    post_name,
    per_page,
    annee,
    post_status,
    page,
    sorting_params,
  };
  let token = "";
  if (localStorage.getItem("token-dashboard")) {
    token = JSON.parse(localStorage.getItem("token-dashboard") as string);
  }
  console.log("queryParams de fetchAllConferenceV2", queryParams);
  const response = await apiFetch({
    path: addQueryArgs(
      `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v3/conferences`,
      queryParams
    ),
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response as ConferencesResponse;
};
