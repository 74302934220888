import { QuickViewOrderItemProps } from "../../../atoms/orders-atoms";

const returnDahsIfEmpty = (value: string) => {
  if (value === "") {
    return "-";
  }
  return value;
};

const isWebsiteUrl = (value: string) => {
  const regex = new RegExp(
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.(ch|com|de|es|fr|io|net|org|co\.uk)\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  return regex.test(value);
};

// Function to check if a given string includes a substring that could be an email address
const isEmailAddress = (value: string) => {
  const regex = new RegExp(/([a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+)/g);
  return regex.test(value);
};

interface OrdersQuickViewPopupItemProps {
  label: string;
  value: string;
  colSpan?: string;
}
const OrdersQuickViewPopupItem = ({
  label,
  value,
  colSpan = "col-span-1",
}: OrdersQuickViewPopupItemProps) => {
  return (
    <div
      className={`gap-y flex flex-col justify-start text-lg text-[#3C7179] ${colSpan}`}
    >
      <span className="font-bold">{label}</span>
      {isWebsiteUrl(value) && (
        <a
          className="font-regular break-words lowercase underline"
          href={value}
          target="_blank"
          rel="noreferrer"
        >
          {value}
        </a>
      )}
      {isEmailAddress(value) && (
        <a
          className="font-regular break-words lowercase underline"
          href={`mailto:${value}`}
        >
          {value}
        </a>
      )}
      {!isWebsiteUrl(value) && !isEmailAddress(value) && (
        <span className="font-regular break-words">{value}</span>
      )}
    </div>
  );
};
const OrdersQuickViewPopup = (data: QuickViewOrderItemProps) => {
  const formattedData = [
    {
      label: "Prénom",
      value: returnDahsIfEmpty(data.firstName),
    },
    {
      label: "Nom",
      value: returnDahsIfEmpty(data.lastName),
    },
    {
      label: "Raison sociale",
      value: returnDahsIfEmpty(data.company),
    },
    {
      label: "Email",
      value: returnDahsIfEmpty(data.email),
    },
    {
      label: "Téléphone",
      value: returnDahsIfEmpty(data.phone),
    },
    {
      label: "Site web",
      value: returnDahsIfEmpty(data.website),
    },
    {
      label: "Type d'activité",
      value: returnDahsIfEmpty(data.activityType),
    },
    {
      label: "Déjà venu au salon",
      value: data.haveAlreadyParticipated ? "Oui" : "Non",
      // value: data.haveAlreadyParticipated,
    },
  ];
  return (
    <div className="flex flex-col gap-y-6 px-4">
      <div className="grid grid-cols-3 gap-8">
        {formattedData.map((item, index) => (
          <OrdersQuickViewPopupItem
            key={item.label}
            label={item.label}
            value={item.value}
            // If item is the last one or the one before the last one, we want it to span 2 columns
            colSpan={
              index === formattedData.length - 1 ||
              index === formattedData.length - 2
                ? "col-span-3"
                : "col-span-1"
            }
          />
        ))}
      </div>
    </div>
  );
};

export default OrdersQuickViewPopup;
