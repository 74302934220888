import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { returnPriceFormattedCHF } from "../../../../../../shared/utils/numbersManipulations";
import {
  DiscountLineItem,
  selectedOrderAddRabaisProductButtonClickedAtom,
  selectedOrderAtom,
  selectedOrderDiscountLineItemsAtom,
  selectedOrderSelectedDiscountLineItemAtom,
} from "../../../../atoms/orders-atoms";

const taxRate = 0.081;

interface StyledInputForAmountProps {
  value: number;
  onChange: (value: number) => void;
  prefix?: boolean;
  currency?: string;
  inputName: string;
  label?: string;
}
const StyledInputForAmount = ({
  value,
  onChange,
  prefix = true,
  currency = "CHF",
  inputName,
  label,
}: StyledInputForAmountProps) => {
  //   const formattedValue = prefix
  //     ? value.toFixed(2).replace(",", ".")
  //     : value.toString();
  const formattedValue = returnPriceFormattedCHF(value, false).replace(
    "CHF",
    ""
  );
  return (
    <div className="w-1/2">
      <label className="" htmlFor={inputName}>
        {label}
      </label>
      <div className="flex items-center gap-x-2 rounded border-2 px-4">
        {prefix && <span>{currency}</span>}
        <div className="w-full">
          <input
            // pattern="[^-]*"
            name={inputName}
            className="w-full border-none bg-none py-2 focus-visible:border-none focus-visible:outline-none"
            type="number"
            // defaultValue={value ? value.toString() : undefined}
            defaultValue={formattedValue ? formattedValue : undefined}
            value={undefined}
            placeholder={formattedValue}
            onChange={(e) => {
              onChange(Number(e.currentTarget.value));
            }}
          // onKeyDown={(e) => (e.key === "-" ? e.preventDefault() : null)}
          // onInput={(e) => {
          //   onChange(Number(e.currentTarget.value));
          // }}
          />
        </div>
      </div>
    </div>
  );
};

interface SingleOrderPopupAddRabaisProductProps { }
const SingleOrderPopupAddRabaisProduct =
  ({ }: SingleOrderPopupAddRabaisProductProps) => {
    const [
      selectedOrderSelectedDiscountLineItem,
      setSelectedOrderSelectedDiscountLineItem,
    ] = useRecoilState(selectedOrderSelectedDiscountLineItemAtom);
    const [amount, setAmount] = useState<number>(
      selectedOrderSelectedDiscountLineItem
        ? selectedOrderSelectedDiscountLineItem.amount
        : 0
    );
    const [vat, setVat] = useState<number>(
      selectedOrderSelectedDiscountLineItem
        ? selectedOrderSelectedDiscountLineItem.vat
        : 0
    );
    const [name, setName] = useState<string>(
      selectedOrderSelectedDiscountLineItem
        ? selectedOrderSelectedDiscountLineItem.name
        : ""
    );
    const [discountLineItem, setDiscountLineItem] =
      useState<DiscountLineItem>();
    const [selectedOrderDiscountLineItems, setSelectedOrderDiscountLineItems] =
      useRecoilState(selectedOrderDiscountLineItemsAtom);
    const [
      selectedOrderAddRabaisProductButtonClicked,
      setSelectedOrderAddRabaisProductButtonClicked,
    ] = useRecoilState(selectedOrderAddRabaisProductButtonClickedAtom);

    const [selectedOrderData, setSelectedOrderData] =
      useRecoilState(selectedOrderAtom);

    useEffect(() => {
      // amount > 0 && setVat(amount * taxRate);
      // If amount > 0 set vat to be 7.7% of amount
      // amount > 0 && setVat(amount * 0.77);
      amount === 0 && setVat(0);
      selectedOrderData &&
        setDiscountLineItem({
          id: selectedOrderData.fees.length + 1,
          amount: amount,
          vat: vat,
          name,
        });
    }, [amount, name]);

    // Create a fuction that check if a number is negative or not and set it to negative if it is not
    const setNumberToNegative = (number: number) => {
      if (number < 0) {
        return number;
      } else {
        return number * -1;
      }
    };

    // useEffect(() => {
    //   console.log("DISCOUNTLINTEITEM ===> ", discountLineItem);
    // }, [discountLineItem]);
    return (
      <div className="flex flex-col gap-y-6">
        <div className="flex flex-row items-center justify-between gap-x-4">
          <StyledInputForAmount
            inputName="amount"
            value={amount}
            onChange={(e) => {
              setAmount(e);
              setVat(e * taxRate);
            }}
            label="Montant"
          />
          <StyledInputForAmount
            inputName="vat"
            value={vat}
            onChange={(e) => setVat(e)}
            label="TVA"
          />
        </div>
        <div>
          <label className="" htmlFor="name">
            Motif
          </label>
          <div className="flex items-center gap-x-2 rounded border-2 px-4">
            <div className="w-full">
              <textarea
                name="name"
                className="w-full border-none bg-none py-2 focus-visible:border-none focus-visible:outline-none"
                defaultValue={selectedOrderSelectedDiscountLineItem ? name : ""}
                value={undefined}
                // defaultValue={value.toString()}
                placeholder={"Motif du rabais"}
                onInput={(e) => {
                  setName(e.currentTarget.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="mt-4 flex flex-row justify-end gap-x-6">
          <button
            type="button"
            className="border-none bg-none"
            onClick={() => {
              setSelectedOrderAddRabaisProductButtonClicked(false);
              setSelectedOrderSelectedDiscountLineItem(null);
            }}
          >
            <span className="text-lg font-semibold text-[#3C7179]">
              Annuler
            </span>
          </button>
          <button
            type="button"
            className="rounded-md border-none bg-[#3C7179] py-4 px-8 text-lg font-semibold text-white"
            onClick={() => {
              // Get amount sign
              const amountSign = amount > 0 ? -1 : 1;
              // const newTotal = selectedOrderData
              //   ? selectedOrderData.total + amount * amountSign
              //   : 0;
              // const newTotalWithoutVat = selectedOrderData
              //   ? selectedOrderData.totalWithoutVat + amount * amountSign
              //   : 0;
              // const newVat = selectedOrderData
              //   ? selectedOrderData.vat + vat * amountSign
              //   : 0;
              const newTotal = selectedOrderData
                ? selectedOrderData.total + setNumberToNegative(amount)
                : 0;
              const newTotalWithoutVat = selectedOrderData
                ? selectedOrderData.totalWithoutVat +
                setNumberToNegative(amount)
                : 0;
              const newVat = selectedOrderData
                ? selectedOrderData.vat + setNumberToNegative(vat)
                : 0;

              if (selectedOrderData && selectedOrderSelectedDiscountLineItem) {
                console.log("UPDATE");

                // Update discount line item
                const newDiscountLineItems = selectedOrderData.fees.map(
                  (discountLineItem) => {
                    if (
                      discountLineItem.id ===
                      selectedOrderSelectedDiscountLineItem.id
                    ) {
                      return {
                        ...discountLineItem,
                        amount: setNumberToNegative(amount),
                        vat: setNumberToNegative(vat),
                        name,
                      };
                    }
                    return discountLineItem;
                  }
                );
                setSelectedOrderDiscountLineItems(newDiscountLineItems);
                setSelectedOrderData({
                  ...selectedOrderData,
                  total: newTotal,
                  totalWithoutVat: newTotalWithoutVat,
                  vat: newVat,
                  fees: newDiscountLineItems,
                });
              } else {
                selectedOrderData &&
                  discountLineItem &&
                  setSelectedOrderDiscountLineItems([
                    ...selectedOrderDiscountLineItems,
                    // discountLineItem,
                    {
                      ...discountLineItem,
                      name: name ? name : "Rabais",
                      // name,
                      id: selectedOrderData.fees.length + 1,
                      amount: setNumberToNegative(amount),
                      vat: setNumberToNegative(vat),
                    },
                  ]);
                selectedOrderData &&
                  discountLineItem &&
                  setSelectedOrderData({
                    ...selectedOrderData,
                    total: newTotal,
                    totalWithoutVat: newTotalWithoutVat,
                    vat: newVat,
                    fees: [
                      ...selectedOrderData.fees,
                      {
                        ...discountLineItem,
                        name: name ? name : "Rabais",
                        // name,
                        id: selectedOrderData.fees.length + 1,
                        amount: setNumberToNegative(amount),
                        vat: setNumberToNegative(vat),
                      },
                    ],
                  });
              }

              // selectedOrderData &&
              //   setSelectedOrderData({
              //     ...selectedOrderData,
              //     total: newTotal,
              //     totalWithoutVat: newTotalWithoutVat,
              //     vat: newVat,
              //   });

              // selectedOrderData &&
              //   setSelectedOrderData({
              //     ...selectedOrderData,
              //     total: newTotal,
              //     fees: selectedOrderDiscountLineItems,
              //   });
              setSelectedOrderAddRabaisProductButtonClicked(false);
              setSelectedOrderSelectedDiscountLineItem(null);

              // Update order total, totalWithoutVat, vat based on what has been added
              // const newTotal = selectedOrderData
              //   ? selectedOrderData.total + amount
              //   : 0;
            }}
            disabled={false}
          >
            {/* Ajouter */}
            {selectedOrderSelectedDiscountLineItem
              ? "Mettre à jour"
              : "Ajouter"}
          </button>
        </div>
      </div>
    );
  };

export default SingleOrderPopupAddRabaisProduct;

// Path: src/features/orders/components/SingleOrderTable/PopupActionsContent/SingleOrderPopupAddRabais.tsx
