import { FiltersComponentProps } from "../../../shared/types/sharedTypes";
import ClearTableFilters from "../../../shared/ui/components/ClearTableFilters";
import FiltersSelectComponent from "../../../shared/ui/components/FiltersSelectComponent";

const FilterComponents: React.FC<FiltersComponentProps> = ({
  resetFilters,
  localStorageFiltersKeys,
  isLoading,
  filtersSelectComponents,
}) => {
  return (
    <div className="flex items-center mt-2 gap-x-4">
      <ClearTableFilters
        isLoading={isLoading}
        resetFilters={resetFilters}
        localStorageFiltersKeys={localStorageFiltersKeys}
      />
      {filtersSelectComponents.map((component: any, index: any) => (
        <FiltersSelectComponent
          key={index}
          isLoading={isLoading}
          options={component.options}
          selected={component.selected}
          setSelected={(value, selectedIndex) => {
            component.setSelected(value, selectedIndex);
            // set local storage
            localStorage.setItem(component.localStorageKey, value);
          }}
          type={component.type}
          postType={component.postType}
        />
      ))}
    </div>
  );
};

export default FilterComponents;
