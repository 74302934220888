import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Dialog,
  DialogPanel,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/20/solid";
import {
  ArrowLeftCircleIcon,
  CheckIcon,
  ChevronUpDownIcon,
} from "@heroicons/react/24/outline";
import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import ActionPopup from "../../../shared/ui/components/ActionPopup";
import CardWithData from "../../../shared/ui/components/CardWithData";
import Loader from "../../../shared/ui/components/Loader";
import SinglePostInfoCardsItemData from "../../../shared/ui/components/SinglePostInfoCardsItemData";
import PageLayout from "../../../shared/ui/layouts/PageLayout";
import { returnRootUrlBasedOnAppLocation } from "../../../shared/utils/checkIfIsInlocal";
import {
  fetchAllAccountEmails,
  fetchAllProducts,
  fetchDiscount,
} from "../api/fetch-discounts";
import { selectedDiscountAtom } from "../atoms/discount-atoms";
import { DataToSendDiscount, Discount, Product } from "../types/discount";

const SingleCouponScreen = () => {
  let token = "";
  if (localStorage.getItem("token-dashboard")) {
    token = JSON.parse(localStorage.getItem("token-dashboard") as string);
  }

  const [isLoading, setIsLoading] = useState(false);
  const [discount, setDiscount] = useState<Discount>();
  const [selectedDiscount, setSelectedDiscount] =
    useRecoilState(selectedDiscountAtom);
  const [isDeletingDiscount, setIsDeletingDiscount] = useState(false);
  const [isUpdatingDiscount, setIsUpdatingDiscount] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isConfirmDeletionPopupVisible, setIsConfirmDeletionPopupVisible] =
    useState(false);
  const [filteredEmailsList, setFilteredEmailsList] = useState<string[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<Product[]>(
    [] as Product[]
  );
  const [productQuery, setProductQuery] = useState<string>("");
  const filteredProducts =
    productQuery === ""
      ? products
      : products.filter((product) =>
          product.name.toLowerCase().includes(productQuery.toLowerCase())
        );
  let selectedProductsIds = selectedProduct.map((product) => product.id);
  const [emails, setEmails] = useState<string[]>([]);
  const [selectedEmail, setSelectedEmail] = useState<string[]>(
    selectedDiscount?.email_restrictions || []
  );
  const [searchParameters, setSearchParameters] = useState<string>("");
  const [dataToSend, setDataToSend] = useState<DataToSendDiscount>();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDiscount, setIsOpenDiscount] = useState(false);

  const discountTypes = [
    { id: "1", name: "Remise en pourcentage", value: "percent" },
    { id: "2", name: "Remise produit fixe", value: "fixed_product" },
    { id: "3", name: "Remise panier fixe", value: "fixed_cart" },
  ];

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    // Update the value of the filtered list
    if (searchParameters.length > 2) {
      setFilteredEmailsList(
        emails.filter((email) =>
          email.toLowerCase().includes(searchParameters.toLowerCase())
        )
      );
    } else {
      setFilteredEmailsList([]);
    }
  }, [searchParameters, emails]);

  useEffect(() => {
    console.log(localStorage.getItem("token-dashboard"));
    if (!token) {
      window.location.replace("/");
    }

    if (products && emails) {
      if (products.length && emails.length > 0) return;
    }
    setIsLoading(true);
    fetchAllProducts()
      .then((products) => {
        setProducts(products);
        setSelectedProduct(
          products.filter((product) =>
            selectedDiscount?.product_names.includes(product.name)
          )
        );
      })
      .catch((err) => {
        if (err.code.includes("jwt")) {
          console.log(err);
          localStorage.removeItem("token-dashboard");
          window.location.replace("/");
        }
      });
    fetchAllAccountEmails()
      .then((emails) => {
        setEmails(emails);
      })
      .catch((err) => {
        if (err.code.includes("jwt")) {
          console.log(err);
          localStorage.removeItem("token-dashboard");
          window.location.replace("/");
        }
      });
    if (selectedDiscount) {
      fetchDiscount(selectedDiscount?.coupon_id)
        .then((discount) => {
          setSelectedDiscount(discount);
          setDiscount(discount);
        })
        .catch((err) => {
          if (err.code.includes("jwt")) {
            console.log(err);
            localStorage.removeItem("token-dashboard");
            window.location.replace("/");
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    if (!products) {
      setProducts([]);
    } else if (!emails) {
      setEmails([]);
    }
  }, []);

  useEffect(() => {
    console.log("selectedProduct", selectedProduct);
    if (selectedDiscount) {
      setDataToSend({
        coupon_code: selectedDiscount.coupon_code,
        coupon_title: selectedDiscount.coupon_code,
        discount_type:
          selectedDiscount.discount_type === "Remise en pourcentage"
            ? "percent"
            : selectedDiscount.discount_type === "Remise produit fixe"
            ? "fixed_product"
            : "fixed_cart",
        coupon_amount: selectedDiscount.amount,
        description: selectedDiscount.description,
        expiry_date: selectedDiscount.expiry_date,
        usage_limit: selectedDiscount.usage_limit,
        usage_limit_per_user: selectedDiscount.usage_limit_per_user,
        limit_usage_to_x_items: selectedDiscount.limit_usage_to_x_items,
        product_ids: selectedProductsIds,
        email_restrictions: selectedEmail,
      });
    }
  }, [selectedDiscount, selectedProduct, selectedEmail]);

  useEffect(() => {
    console.log("dataToSend", dataToSend);
  }, [dataToSend]);

  function closeModalDiscount() {
    setIsOpenDiscount(false);
  }

  function openModalDiscount() {
    setIsOpenDiscount(true);
  }

  const navigate = useNavigate();
  const popups = [
    <ActionPopup
      actionRunning={isDeletingDiscount}
      key={Math.random()}
      title="Supprimer le coupon ?"
      children={
        <p>
          Voulez-vous vraimer supprimer l'espace soin{" "}
          {selectedDiscount?.coupon_id} ?
        </p>
      }
      isVisible={isConfirmDeletionPopupVisible}
      setIsVisible={() => {
        setIsConfirmDeletionPopupVisible(!isConfirmDeletionPopupVisible);
      }}
      action={() => {
        !isDeletingDiscount && deleteCare();
      }}
      cancelAction={() => {
        setIsConfirmDeletionPopupVisible(false);
      }}
      actionButtonDisabled={false}
      actionButtonTitle={
        <div
          className={`flex flex-row items-center ${
            isDeletingDiscount && "cursor-wait"
          }`}
        >
          {isDeletingDiscount && (
            <svg
              className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}
          {isDeletingDiscount ? "Suppression..." : "Supprimer"}
        </div>
      }
      cancelButtonVisible={true}
      actionButtonBackgroundColor="#f87171"
    />,
    <ActionPopup
      key={Math.random()}
      title="Espace soin mis à jour"
      children={
        <p>
          L'espace soin {selectedDiscount?.coupon_id} a été mis à jour avec
          succès
        </p>
      }
      isVisible={isPopupVisible}
      setIsVisible={() => {
        setIsPopupVisible(!isPopupVisible);
        // Reload page
        window.location.reload();
      }}
      action={() => {
        setIsPopupVisible(false);
        // Reload page
        window.location.reload();
      }}
      cancelAction={() => {
        setIsPopupVisible(false);
        // Reload page
        window.location.reload();
      }}
      actionButtonDisabled={false}
      actionButtonTitle="Fermer"
      cancelButtonVisible={false}
    />,
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-[1200px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="mb-4 flex justify-end">
                  <button
                    type="button"
                    // className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={closeModal}
                  >
                    <XCircleIcon className="w-8 text-[#0F766E]" />
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>,
    <Transition appear show={isOpenDiscount} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModalDiscount}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-[1200px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="mb-4 flex justify-end">
                  <button
                    type="button"
                    // className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={closeModalDiscount}
                  >
                    <XCircleIcon className="w-8 text-[#0F766E]" />
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>,
  ];

  const deleteCare = () => {
    if (selectedDiscount) {
      setIsDeletingDiscount(true);
      axios
        .delete(
          `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v2/discount?coupon_id=${
            selectedDiscount?.coupon_id
          }`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((data) => {
          navigate(`/coupons`);
          window.location.reload();
        })
        .catch((error) => {
          console.log("error", error);
          if (error.code.includes("jwt")) {
            localStorage.removeItem("token-dashboard");
            window.location.replace("/");
          }
        })
        .finally(() => {
          setIsDeletingDiscount(false);
          // setIsPopupVisible(true);
        });
    }
  };

  // let formattedDate = "";
  // if (selectedDiscount?.expiry_date) {
  //   formattedDate = format(
  //     new Date(selectedDiscount.expiry_date),
  //     "dd-MM-yyyy"
  //   );
  // }

  return (
    <PageLayout>
      <>
        {popups}
        {isLoading ? (
          <Loader />
        ) : (
          <div className="flex flex-col gap-y-16">
            <div className="flex w-full justify-between">
              <Link
                className="flex flex-row items-center gap-x-4 text-2xl text-[#3c7179]"
                to={`/coupons`}
                onClick={() => {}}
              >
                <ArrowLeftCircleIcon className="color-[#3C7179] w-10" /> Retour
                à la liste des Coupons
              </Link>
              <div className="flex flex-row items-center gap-x-8">
                <button
                  disabled={isDeletingDiscount}
                  // className="flex flex-row items-center gap-x-4 rounded border-none bg-[#14b8a6] px-4 py-2 text-lg text-gray-50 shadow"
                  className={`${
                    isDeletingDiscount && "opacity-40"
                  } flex flex-row items-center rounded-md border-none bg-red-400 px-8 py-4 text-lg font-semibold text-white shadow`}
                  onClick={() => {
                    setIsConfirmDeletionPopupVisible(true);
                  }}
                  type="button"
                >
                  Supprimer
                </button>
                <button
                  disabled={isUpdatingDiscount}
                  // className="flex flex-row items-center gap-x-4 rounded border-none bg-[#14b8a6] px-4 py-2 text-lg text-gray-50 shadow"
                  className={`${
                    isUpdatingDiscount && "opacity-40"
                  } flex flex-row items-center rounded-md border-none bg-[#14b8a6] px-8 py-4 text-lg font-semibold text-white shadow`}
                  onClick={() => {
                    if (dataToSend) {
                      console.log("dataToSend", dataToSend);

                      setIsUpdatingDiscount(true);
                      axios
                        .post(
                          `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v2/discount-update?coupon_id=${
                            selectedDiscount?.coupon_id
                          }`,
                          dataToSend,
                          {
                            headers: {
                              Authorization: `Bearer ${token}`,
                            },
                          }
                        )
                        .then((data) => {
                          console.log("data", data);
                        })
                        .catch((error) => {
                          console.log("error", error);
                          if (error.code.includes("jwt")) {
                            localStorage.removeItem("token-dashboard");
                            window.location.replace("/");
                          }
                        })
                        .finally(() => {
                          setIsUpdatingDiscount(false);
                          setIsPopupVisible(true);
                        });
                    }
                  }}
                  type="button"
                >
                  {isUpdatingDiscount && (
                    <svg
                      className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                  {isUpdatingDiscount ? "Mise à jour..." : "Mettre à jour"}
                </button>
              </div>
            </div>
            <>
              <div className="flex flex-col gap-y-11">
                <div className="gap-x-16">
                  {/* Left */}
                  <div className="col-span-2 flex flex-col gap-y-11">
                    {/* Top */}
                    <div className="flex flex-row gap-x-6">
                      <CardWithData width="25%">
                        <SinglePostInfoCardsItemData
                          isEditable={false}
                          title="Titre du coupon"
                          value={selectedDiscount?.coupon_code || "-"}
                          setValue={(value) => {
                            setSelectedDiscount((prevSelectedDiscount: any) => {
                              if (!prevSelectedDiscount) return;
                              return {
                                ...prevSelectedDiscount,
                                coupon_title: value,
                              };
                            });
                          }}
                        />
                        <SinglePostInfoCardsItemData
                          isEditable={true}
                          title="Description du coupon"
                          value={selectedDiscount?.description || "-"}
                          setValue={(value) => {
                            setSelectedDiscount((prevSelectedDiscount: any) => {
                              if (!prevSelectedDiscount) return;
                              return {
                                ...prevSelectedDiscount,
                                description: value,
                              };
                            });
                          }}
                        />
                        <SinglePostInfoCardsItemData
                          isEditable={true}
                          title="Montant du coupon"
                          value={`${selectedDiscount?.amount}` || "-"}
                          setValue={(value) => {
                            setSelectedDiscount((prevSelectedDiscount: any) => {
                              if (!prevSelectedDiscount) return;
                              return {
                                ...prevSelectedDiscount,
                                amount: parseFloat(value),
                              };
                            });
                          }}
                        />
                        <div className="mt-5"></div>
                      </CardWithData>
                      <CardWithData width="25%">
                        <SinglePostInfoCardsItemData
                          isEditable={false}
                          title="Type du Coupon"
                          // value={selectedCareData?.orderAndCareInfo?.careStatus}
                          value={
                            <Listbox
                              value={selectedDiscount?.discount_type}
                              onChange={(value) => {
                                setSelectedDiscount(
                                  (prevSelectedDiscount: any) => {
                                    if (!prevSelectedDiscount) return;
                                    return {
                                      ...prevSelectedDiscount,
                                      discount_type: value,
                                    };
                                  }
                                );
                              }}
                            >
                              <div className="relative mt-1">
                                <ListboxButton className="relative w-full cursor-pointer rounded-lg border bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                  <span className="block truncate">
                                    {selectedDiscount?.discount_type}
                                  </span>
                                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronUpDownIcon
                                      className="h-5 w-5 text-gray-400"
                                      aria-hidden="true"
                                    />
                                  </span>
                                </ListboxButton>
                                <Transition
                                  as={Fragment}
                                  leave="transition ease-in duration-100"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                >
                                  <ListboxOptions className="absolute z-[1] mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {discountTypes.map((type, typeId) => (
                                      <ListboxOption
                                        key={typeId}
                                        className={({ active }) =>
                                          `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                                            active
                                              ? "bg-amber-100 text-amber-900"
                                              : "text-gray-900"
                                          }`
                                        }
                                        value={type.name}
                                      >
                                        {({ selected }) => (
                                          <>
                                            <span
                                              className={`block truncate ${
                                                selected
                                                  ? "font-medium"
                                                  : "font-normal"
                                              }`}
                                            >
                                              {type.name}
                                            </span>
                                            {selected ? (
                                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                <CheckIcon
                                                  className="h-5 w-5"
                                                  aria-hidden="true"
                                                />
                                              </span>
                                            ) : null}
                                          </>
                                        )}
                                      </ListboxOption>
                                    ))}
                                  </ListboxOptions>
                                </Transition>
                              </div>
                            </Listbox>
                          }
                        />
                        <SinglePostInfoCardsItemData
                          isEditable={true}
                          title="Limite d’utilisation par code"
                          value={selectedDiscount?.usage_limit || "-"}
                          setValue={(value) => {
                            setSelectedDiscount((prevSelectedDiscount: any) => {
                              if (!prevSelectedDiscount) return;
                              return {
                                ...prevSelectedDiscount,
                                usage_limit: parseFloat(value),
                              };
                            });
                          }}
                        />
                        <SinglePostInfoCardsItemData
                          isEditable={true}
                          title="Limite d’utilisation par utilisateur"
                          value={selectedDiscount?.usage_limit_per_user || "-"}
                          setValue={(value) => {
                            setSelectedDiscount((prevSelectedDiscount: any) => {
                              if (!prevSelectedDiscount) return;
                              return {
                                ...prevSelectedDiscount,
                                usage_limit_per_user: parseFloat(value),
                              };
                            });
                          }}
                        />
                        {selectedDiscount?.discount_type !==
                          "Remise panier fixe" && (
                          <SinglePostInfoCardsItemData
                            isEditable={true}
                            title="Limite d’utilisation à X articles"
                            value={
                              selectedDiscount?.limit_usage_to_x_items || "-"
                            }
                            setValue={(value) => {
                              setSelectedDiscount(
                                (prevSelectedDiscount: any) => {
                                  if (!prevSelectedDiscount) return;
                                  return {
                                    ...prevSelectedDiscount,
                                    limit_usage_to_x_items: parseFloat(value),
                                  };
                                }
                              );
                            }}
                          />
                        )}
                        <SinglePostInfoCardsItemData
                          isEditable={false}
                          date
                          title="Date d'expiration"
                          value={
                            <input
                              type="date"
                              value={selectedDiscount?.expiry_date || ""}
                              onChange={(e) => {
                                setSelectedDiscount(
                                  (prevSelectedDiscount: any) => {
                                    if (!prevSelectedDiscount) return;
                                    return {
                                      ...prevSelectedDiscount,
                                      expiry_date: e.target.value,
                                    };
                                  }
                                );
                              }}
                            />
                          }
                        />
                        <div className="mt-5"></div>
                      </CardWithData>
                      <CardWithData width="25%">
                        <SinglePostInfoCardsItemData
                          isEditable={false}
                          title="E-mails autorisés"
                          value={
                            <Combobox
                              value={selectedEmail}
                              multiple
                              onChange={setSelectedEmail}
                              onClose={() => setSearchParameters("")}
                            >
                              <div className="relative">
                                {selectedEmail && selectedEmail.length > 0 && (
                                  <ul className="mb-2 block w-full rounded-md border border-gray-300 px-2 py-2 shadow-sm focus:border-[#14B8A6] focus:ring-[#14B8A6] sm:text-sm">
                                    {selectedEmail.map((email, i) => (
                                      <li
                                        key={i}
                                        className="flex flex-row justify-between gap-1"
                                      >
                                        <span>{email}</span>
                                        <button
                                          onClick={() => {
                                            setSelectedEmail(
                                              selectedEmail.filter(
                                                (selectedEmail) =>
                                                  selectedEmail !== email
                                              )
                                            );
                                          }}
                                        >
                                          <XCircleIcon
                                            fontSize={20}
                                            className="h-5 w-5"
                                            color="red"
                                          />
                                        </button>
                                      </li>
                                    ))}
                                  </ul>
                                )}

                                <ComboboxInput
                                  aria-label="Assignee"
                                  displayValue={(email: any) =>
                                    searchParameters
                                  }
                                  onChange={(event) =>
                                    setSearchParameters(event.target.value)
                                  }
                                  className="block w-full rounded-md border border-gray-300 px-2 py-2 shadow-sm focus:border-[#14B8A6] focus:ring-[#14B8A6] sm:text-sm"
                                  placeholder="Rechercher..."
                                />
                              </div>
                              <Transition
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                                afterLeave={() => setSearchParameters("")}
                              >
                                <ComboboxOptions
                                  anchor="top"
                                  className="w-[var(--input-width)] rounded-xl border border-white/5 bg-white p-1 shadow-lg [--anchor-gap:var(--spacing-1)] empty:hidden focus:outline-none"
                                >
                                  {filteredEmailsList.map((email, i) => (
                                    <ComboboxOption
                                      key={email}
                                      value={email}
                                      className="group flex cursor-default select-none items-center gap-2 rounded-lg px-3 py-1.5 data-[focus]:bg-white/10"
                                    >
                                      <CheckIcon className="invisible size-4 fill-[#3C7179] group-data-[selected]:visible" />

                                      <div className="text-sm/6 text-[#3C7179]">
                                        {email}
                                      </div>
                                    </ComboboxOption>
                                  ))}
                                </ComboboxOptions>
                              </Transition>
                              {searchParameters.length > 0 && (
                                <button
                                  type="button"
                                  className="w-full justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 shadow transition-all hover:bg-green-200 hover:shadow-none focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                                  onClick={() => {
                                    setSearchParameters("");
                                  }}
                                >
                                  Valider la sélection
                                </button>
                              )}
                            </Combobox>
                          }
                        />
                        <div className="mt-5"></div>
                      </CardWithData>
                      <CardWithData width="25%">
                        <SinglePostInfoCardsItemData
                          isEditable={false}
                          title="Produits sur lesquels le coupon est applicable"
                          value={
                            <Combobox
                              value={selectedProduct}
                              multiple
                              onChange={setSelectedProduct}
                              onClose={() => setProductQuery("")}
                            >
                              <div className="relative">
                                {selectedProduct &&
                                  selectedProduct.length > 0 && (
                                    <ul className="mb-2 block w-full rounded-md border border-gray-300 px-2 py-2 shadow-sm focus:border-[#14B8A6] focus:ring-[#14B8A6] sm:text-sm">
                                      {selectedProduct.map((product, i) => (
                                        <li
                                          key={i}
                                          className="flex flex-row justify-between gap-1"
                                        >
                                          <span>{product.name}</span>
                                          <button
                                            onClick={() => {
                                              setSelectedProduct(
                                                selectedProduct.filter(
                                                  (selectedProduct) =>
                                                    selectedProduct.id !==
                                                    product.id
                                                )
                                              );
                                            }}
                                          >
                                            <XCircleIcon
                                              fontSize={20}
                                              className="h-5 w-5"
                                              color="red"
                                            />
                                          </button>
                                        </li>
                                      ))}
                                    </ul>
                                  )}

                                <ComboboxInput
                                  aria-label="Assignee"
                                  displayValue={(product: any) => productQuery}
                                  onChange={(event) =>
                                    setProductQuery(event.target.value)
                                  }
                                  className="block w-full rounded-md border border-gray-300 px-2 py-2 shadow-sm focus:border-[#14B8A6] focus:ring-[#14B8A6] sm:text-sm"
                                  placeholder="Rechercher..."
                                />
                              </div>
                              <Transition
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                                afterLeave={() => setProductQuery("")}
                              >
                                <ComboboxOptions
                                  anchor="bottom start"
                                  className="w-[var(--input-width)] rounded-xl border border-white/5 bg-white p-1 shadow-lg [--anchor-gap:var(--spacing-1)] empty:hidden focus:outline-none"
                                >
                                  {filteredProducts.map((product) => (
                                    <ComboboxOption
                                      key={product.id}
                                      value={product}
                                      className="group flex cursor-default select-none items-center gap-2 rounded-lg px-3 py-1.5 data-[focus]:bg-white/10"
                                    >
                                      <CheckIcon className="invisible size-4 fill-[#3C7179] group-data-[selected]:visible" />

                                      <div className="text-sm/6 text-[#3C7179]">
                                        {product.name}
                                      </div>
                                    </ComboboxOption>
                                  ))}
                                </ComboboxOptions>
                              </Transition>
                              {productQuery.length > 0 && (
                                <button
                                  type="button"
                                  className="w-full justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 shadow transition-all hover:bg-green-200 hover:shadow-none focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                                  onClick={() => {
                                    setSearchParameters("");
                                  }}
                                >
                                  Valider la sélection
                                </button>
                              )}
                            </Combobox>
                          }
                        />
                        <div className="mt-5"></div>
                      </CardWithData>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
        )}
      </>
    </PageLayout>
  );
};

export default SingleCouponScreen;
