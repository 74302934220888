import { XMarkIcon, CheckIcon } from "@heroicons/react/20/solid";
import { PencilIcon } from "@heroicons/react/24/outline";
import { useEffect, useRef, useState } from "react";
// TODO : Implement linkAction
interface SingleOrderInfoCardsItemDataProps {
  title?: string;
  value?: any;
  linkAction?: () => void;
  link?: string;
  isEditable?: boolean;
  setValue?: (value: any) => void;
}

// Check if 'title' includes email and if so return the <a> with the mailto
const checkIfEmailOrWebsite = (title: string | undefined, value: string) => {
  if (title) {
    if (title.toLowerCase().includes("email") && value.includes("@")) {
      return (
        <a className="underline" href={`mailto:${value}`}>
          {value}
        </a>
      );
    }
    if (title.toLowerCase().includes("site") && value !== "Non renseigné") {
      let formattedWebsite = "";
      if (value.includes("http://") || value.includes("https://")) {
        formattedWebsite = value;
      } else {
        formattedWebsite = `http://${value}`;
      }
      return (
        <a
          className="underline"
          href={`${formattedWebsite}`}
          target="_blank"
          rel="noreferrer"
        >
          {value}
        </a>
      );
    }
    // Phone
    if (title.toLowerCase().includes("téléphone")) {
      return (
        <a className="underline" href={`tel:${value}`}>
          {value}
        </a>
      );
    }
  }
  return value;
};

const SingleOrderInfoCardsItemData = ({
  title,
  value,
  link,
  linkAction,
  isEditable = true,
  setValue,
}: SingleOrderInfoCardsItemDataProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  return link ? (
    <div className="flex flex-col items-start">
      <button
        type="button"
        disabled={link === "submitting"}
        className={`${
          link === "submitting" && "cursor-not-allowed"
        } border-none bg-none`}
        onClick={() => linkAction && linkAction()}
      >
        <span className={`${link !== "submitting" && "underline"}`}>
          {title}
        </span>
      </button>
    </div>
  ) : (
    <div className="flex flex-col">
      {title && value && (
        <div className="flex flex-row items-center gap-x-4">
          <span className="font-bold text-[#3C7179]">{title}</span>
          {isEditable && (
            <div
              className={`flex cursor-pointer flex-row gap-x-2 ${
                isEditing && "cursor-auto opacity-0"
              }`}
            >
              <div
                className="p-[6px] text-gray-400 transition hover:text-gray-600"
                onClick={() => {
                  setIsEditing(true);
                  inputRef.current?.focus();
                }}
              >
                <PencilIcon className="w-5" />
              </div>
            </div>
          )}
        </div>
      )}
      <div className="flex flex-row items-center gap-x-4">
        {/* {value && (
          <div className={`${isEditing ? "bg-gray-50" : ""} grow rounded`}>
            <input
              // disabled={!isEditing}
              readOnly={!isEditing}
              ref={inputRef}
              type="text"
              className={`${
                isEditing && "w-full"
              } rounded border-none bg-transparent p-1 focus:outline-none`}
              defaultValue={value !== "Non renseigné" ? value : ""}
              placeholder={value === "Non renseigné" ? value : ""}
              autoFocus={isEditing}
            />
          </div>
        )} */}
        {value && (
          <div className={`${isEditing ? "bg-gray-50" : ""} grow rounded`}>
            {!isEditing && <span>{checkIfEmailOrWebsite(title, value)}</span>}
            {isEditing && (
              <textarea
                // disabled={!isEditing}
                readOnly={!isEditing}
                rows={6}
                ref={inputRef}
                className={`${
                  isEditing && "w-full"
                } w-full rounded border-none bg-transparent p-1 focus:outline-none`}
                defaultValue={value !== "-" ? value : ""}
                placeholder={value === "Non renseigné" ? value : ""}
                autoFocus={isEditing}
              />
            )}
          </div>
        )}
        {title && !value && (
          <span className="font-bold text-[#3C7179]">{title}</span>
        )}
        {/* Edit icons */}
        {isEditable && (
          <div>
            {/*  */}
            {/* {!isEditing && (
              <div className="flex flex-row gap-x-2">
                <div
                  className="cursor-pointer p-[6px] text-gray-400 transition hover:text-gray-600"
                  onClick={() => {
                    setIsEditing(true);
                    inputRef.current?.focus();
                  }}
                >
                  <PencilIcon className="w-5" />
                </div>
              </div>
            )} */}
            {/*  */}
            {isEditing && (
              <div className="flex flex-row items-center gap-x-2">
                <div
                  onClick={() => {
                    setIsEditing(false);
                    inputRef.current?.value &&
                      setValue &&
                      setValue(inputRef.current?.value);
                  }}
                  className="p-1 text-green-400 transition rounded-full cursor-pointer hover:bg-green-100 hover:shadow"
                >
                  <CheckIcon className="w-6" />
                </div>
                <div className="p-1 text-red-400 transition rounded-full cursor-pointer hover:bg-red-100 hover:shadow">
                  <XMarkIcon
                    className="w-6"
                    onClick={() => setIsEditing(false)}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleOrderInfoCardsItemData;

// Path: src/features/orders/components/SingleOrderInfoCardsItemData.tsx
