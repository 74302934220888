import { render } from "@react-email/render";
import { StyleSheet, pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import Email from "../../../emails/index";
//
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Fragment, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { orderStatuses } from "../../../../../shared/constants/orderStatuses";
import CardWithData from "../../../../../shared/ui/components/CardWithData";
import {
  clickedDownloadInvoiceAtom,
  clickedGenerateInvoiceAtom,
  isCreatingNewOrderAtom,
  ordersDataAtom,
  selectedOrderAtom,
  selectedOrderAtomUntouched,
} from "../../../atoms/orders-atoms";
import { OrderData, PaymentAndInvoiceInfoType } from "../../../types/orders";
// import { generateSingleInvoice } from "../../../utils/invoices/invoicesGeneration";
import JSZip from "jszip";
import {
  Invoice,
  updateOrderStatusAndSendEmailWithInvoice,
} from "../../../utils/invoices/invoicesGeneration";
import SingleOrderInfoCardsItemData from "./SingleOrderInfoCardsItemData";
import { sendOrderEmail, sendOrderPayReminderEmail, sendOrderValidatedEmail } from "../../../api/sendOrderEmail";
import axios from "axios";
import {
  returnInvoiceName,
} from "../../../screens/SingleOrderScreen";
import { verifyIfFileExists } from "../../../api/verifyIfFileExists";
//
const logo = require("../../../assets/logo-grand-format-min.png");
// const exhibitionsLogos = require("../assets/exhibitions-buttons.png");
// const exhibitionsLogos = require("../../assets/exhibitions-buttons-min.png");
const exhibitionsLogos = require("../../../assets/logos-salons.png");
// const flowerInvoice = require("../assets/flower-invoice.png");
const flowerInvoice = require("../../../assets/flower-invoice-min.png");
const qrCh = require("../../../assets/qr-ch.png");
//

interface generateSingleInvoiceWithFirebaseProps {
  order: OrderData;
  openAfterGeneration?: boolean;
}
export const generateSingleInvoiceWithFirebase = async ({
  order,
  openAfterGeneration = true,
}: generateSingleInvoiceWithFirebaseProps) => {
  if (!order) return;

  const body = {
    orders: [order],
  };

  try {
    const res = await axios.post(
      "https://europe-west1-satn-ad75c.cloudfunctions.net/generateAndStorePdf",
      body
    );
    console.log(res.data);
    if (openAfterGeneration) {
      if (res.data.success) {
        if (res.data.success == true) {
          console.log("res.data.success == true");
          if (res.data.downloadURL) {
            window.open(res.data.downloadURL, "_blank");
          } else {
            alert("Une erreur est survenue, veuillez contacter le support");
          }
        } else {
          console.log("res.data.success == false");
          alert("Une erreur est survenue, veuillez contacter le support");
        }
      }
    }
    // setIsUpdatingOrder(false);
    // setSelectedOrderHasBeenUpdated(true);
  } catch (error) {
    if (openAfterGeneration) {
      alert("Une erreur est survenue, veuillez contacter le support");
    }
    console.log(error);
  }
};

const PaymentAndInvoiceInfo = ({
  status,
  paymentMethod,
  invoiceNumber,
  actions,
}: PaymentAndInvoiceInfoType) => {
  // const people = [
  //   { id: 1, name: "Durward Reynolds", unavailable: false },
  //   { id: 2, name: "Kenton Towne", unavailable: false },
  //   { id: 3, name: "Therese Wunsch", unavailable: false },
  //   { id: 4, name: "Benedict Kessler", unavailable: true },
  //   { id: 5, name: "Katelyn Rohan", unavailable: false },
  // ];
  const [selected, setSelected] = useState(status);
  const [selectedOrderData, setSelectedOrderData] =
    useRecoilState(selectedOrderAtom);
  const [orders, setOrders] = useRecoilState(ordersDataAtom);
  const [clickedGenerateInvoice, setClickedGenerateInvoice] = useRecoilState(
    clickedGenerateInvoiceAtom
  );
  const [selectedOrderUntouched, setSelectedOrderUntouched] = useRecoilState(
    selectedOrderAtomUntouched
  );
  const [isGeneratingInvoice, setIsGeneratingInvoice] = useState(false);
  const [clickedDownloadInvoice, setClickedDownloadInvoice] = useRecoilState(
    clickedDownloadInvoiceAtom
  );
  const [isCreatingANewOrder, setIsCreatingANewOrder] = useRecoilState(
    isCreatingNewOrderAtom
  );

  const [isSendingInvoice, setIsSendingInvoice] = useState(false);
  const [sendingInvoiceText, setSendingInvoiceText] = useState(
    "Renvoyer la facture"
  );

  const [isSendingPayReminder, setIsSendingPayReminder] = useState(false);
  const [sendingPayReminderText, setSendingPayReminderText] =
    useState("Rappel de paiement");

  const resendInvoice = async (order: OrderData) => {
    if (!order) return;
    setIsSendingInvoice(true);
    setSendingInvoiceText("Envoi en cours...");
    const invoicePath = returnInvoiceName({
      exhibitionName: order.exhibition.name,
      orderId: order.orderId.toString(),
      year: order.exhibition.year,
    });
    const fileName = `invoices/${order.customer.id}/${order.orderId}/${invoicePath}.pdf`;
    console.log("fileName ", fileName);

    verifyIfFileExists(fileName).then((data) => {
      if (data) {
        sendOrderValidatedEmail(order).finally(() => {
          setIsSendingInvoice(false);
          setTimeout(() => {
            setSendingInvoiceText("Envoyé !");
          }, 1000);
          setTimeout(() => {
            setSendingInvoiceText("Renvoyer la facture");
          }, 2000);
        });
      } else {
        generateSingleInvoiceWithFirebase({
          order: order,
          openAfterGeneration: false,
        }).then(() => {
          // console.log("generateSingleInvoiceWithFirebase");
          sendOrderValidatedEmail(order).finally(() => {
            setIsSendingInvoice(false);
            setTimeout(() => {
              setSendingInvoiceText("Envoyé !");
            }, 1000);
            setTimeout(() => {
              setSendingInvoiceText("Renvoyer la facture");
            }, 2000);
          });
        });
      }
    });
  };

  const sendOrderPayReminder = async (order: OrderData) => {
    setIsSendingPayReminder(true);
    setSendingPayReminderText("Envoi en cours...");
    order &&
      sendOrderPayReminderEmail(order).finally(() => {
        setIsSendingPayReminder(false);
        setTimeout(() => {
          setSendingPayReminderText("Envoyé !");
        }, 1000);
        setTimeout(() => {
          setSendingPayReminderText("Rappel de paiement");
        }, 2000);
      });
  };

  const [isSendingOrderEmailForCustomer, setIsSendingOrderEmailForCustomer] =
    useState(false);
  const [isSendingOrderEmailForAdmin, setIsSendingOrderEmailForAdmin] =
    useState(false);
  const [sendOrderEmailTextForAdmin, setSendOrderEmailTextForAdmin] = useState(
    "Renvoyer mail de commande à l'admin"
  );
  const [sendOrderEmailTextForCustomer, setSendOrderEmailTextForCustomer] =
    useState("Renvoyer mail de commande");

  const [isDownloadingInvoice, setIsDownloadingInvoice] = useState(false);

  function pxCm(px: number): string {
    const dpi = 300;
    return (px / dpi) * 2.54 + "cm";
  }

  interface InvoiceWithDataProps {
    pdf: JSX.Element;
    exhibition: string;
    year: string;
    orderId: OrderData["orderId"];
  }

  // Create styles
  const styles = StyleSheet.create({
    page: {
      // flexDirection: "row",
      // backgroundColor: "#fff",
      // paddingTop: 55,
      // paddingLeft: 100,
      // paddingRight: 100,
      fontFamily: "Poppins",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      paddingBottom: 100,
    },
    pageFooter: {
      fontSize: pxCm(30),
      textAlign: "center",
      color: "#3D7077",
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      marginBottom: pxCm(50),
    },
    section: {
      // margin: 10,
      // padding: 10,
      flexGrow: 1,
      color: "#3D7077",
    },
    header: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      margin: "0 auto",
      width: pxCm(2240),
      marginTop: pxCm(110),
    },
    logo: {
      width: pxCm(900),
      textAlign: "left",
    },
    // Right
    rightHeader: {
      textAlign: "center",
      width: pxCm(900),
    },
    exhibitionsLogos: {
      marginBottom: pxCm(50),
    },
    headerCompany: {
      fontSize: pxCm(45),
      color: "#3B7079",
      fontWeight: "bold",
    },
    headerAddress: {
      fontSize: pxCm(36),
      color: "#77ABAB",
    },
    // Content
    invoiceInfo: {
      width: pxCm(2240),
      margin: "0 auto",
      marginTop: pxCm(100),
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "stretch",
    },
    customerInfo: {
      backgroundColor: "#F7F5EF",
      width: pxCm(900),
      height: pxCm(535),
      fontSize: pxCm(36),
      paddingVertical: pxCm(32),
      paddingHorizontal: pxCm(48),
    },
    customerInfoTitle: {
      // fontWeight: 700,
      fontWeight: "bold",
      fontSize: pxCm(36),
      marginBottom: pxCm(23),
    },
    customerInfoText: {
      fontSize: pxCm(40),
      color: "#1e293b",
    },
    generalInfo: {
      width: pxCm(900),
      flexDirection: "column",
      justifyContent: "space-between",
      fontSize: pxCm(36),
      // paddingTop: pxCm(18),
      // backgroundColor: "#F7F5EF",
    },
    generalInfoCard: {
      backgroundColor: "#F7F5EF",
      width: pxCm(900),
      paddingVertical: pxCm(32),
      paddingHorizontal: pxCm(26),
    },
    generalInfoCardData: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    generalInfoCardDataMt: {
      marginTop: pxCm(4),
    },
    generalInfoCardTitle: {
      fontWeight: 700,
    },
    generalInfoCardText: {},
    // Table
    table: {
      fontSize: pxCm(36),
      fontWeight: "bold",
      margin: "0 auto",
      width: pxCm(2240),
      marginTop: pxCm(100),
    },
    tableHeader: {
      backgroundColor: "#F7F5EF",
      flexDirection: "row",
      paddingVertical: pxCm(32),
      paddingHorizontal: pxCm(26),
    },
    tableBody: {},
    tableHeaderOtherCol: {
      flexDirection: "row",
      flexGrow: 1,
    },
    tableHeaderOtherColItem: {
      // paddingHorizontal: pxCm(44),
      width: "100%",
    },
    tableRow: {
      flexDirection: "row",
      paddingVertical: pxCm(40),
      paddingHorizontal: pxCm(26),
      borderBottom: "0.1px solid rgba(71, 112, 120)",
    },
    tableRowProductName: {
      width: pxCm(660),
      // paddingHorizontal: pxCm(50),
      paddingRight: pxCm(50),
    },
    tableRowOtherCol: {
      flexDirection: "row",
      flexGrow: 1,
    },
    tableHeaderItem: {
      paddingHorizontal: pxCm(50),
    },
    tableHeaderQty: {
      textAlign: "center",
      // width: pxCm(100),
    },
    tableHeaderProducts: {
      width: pxCm(660),
      paddingHorizontal: pxCm(0),
      paddingRight: pxCm(50),
      paddingLeft: pxCm(0),
    },
    tableRowOtherColItem: {
      width: "100%",
      paddingHorizontal: pxCm(50),
      textAlign: "right",
      // fontSize: pxCm(33),
    },
    tableRowOtherColItemQty: {
      // width: pxCm(100),
    },
    tableHeaderHT: {
      width: "133%",
    },
    tableRowHT: {
      width: "133%",
    },
    tableFooter: {
      flexDirection: "row",
      justifyContent: "flex-end",
      marginTop: pxCm(44),
    },
    tableFooterDiscounts: {
      color: "#fff",
      backgroundColor: "#2E9499",
      width: pxCm(680),
      paddingVertical: pxCm(23),
      paddingHorizontal: pxCm(16),
    },
    tableFooterCard: {
      backgroundColor: "#F7F5EF",
      width: pxCm(1010),
      paddingTop: pxCm(4),
      // paddingVertical: pxCm(23),
      // paddingHorizontal: pxCm(16),
    },
    tableFooterCardData: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      paddingHorizontal: pxCm(26),
    },
    tableFooterCardDataMt: {
      marginTop: pxCm(12),
    },
    tableFooterDiscount: {
      backgroundColor: "#8AA9AE",
      color: "#fff",
      paddingVertical: pxCm(4),
    },
    tableFooterCardTitle: {
      fontWeight: 700,
      // width: "70%",
      flexGrow: 1,
      textAlign: "right",
      marginRight: pxCm(30),
      // textTransform: "uppercase",
    },
    tableFooterCardText: {
      // width: pxCm(1010),
      // marginLeft: pxCm(28),
      fontWeight: 300,
    },
    tableFooterTotalPrice: {
      fontWeight: 700,
    },
    howToPay: {
      // backgroundColor: "#8AA9AE",
      borderColor: "#477078",
      borderWidth: pxCm(5),
      borderStyle: "solid",
      // color: "#8AA9AE",
      color: "#1e293b",
      margin: "0 auto",
      width: pxCm(2240),
      marginTop: pxCm(192),
      paddingHorizontal: pxCm(72),
      paddingVertical: pxCm(36),
    },
    howToPayTitle: {
      fontSize: pxCm(36),
      fontWeight: "bold",
      textTransform: "uppercase",
      marginBottom: pxCm(16),
    },
    howToPayTextBold: {
      fontWeight: "bold",
    },
    howToPayTextUnderline: {
      // textDecoration: "underline",
      fontWeight: 700,
      fontSize: pxCm(38),
    },
    howToPayTextP1: {
      fontSize: pxCm(36),
    },
    howToPayTextP2: {
      marginTop: pxCm(40),
      fontSize: pxCm(36),
    },
    howToPayTextP3: {
      marginTop: pxCm(40),
      fontSize: pxCm(40),
    },
    flower: {
      position: "absolute",
      bottom: 0,
      right: 0,
      width: pxCm(1760),
    },
    qrPage: {
      flexDirection: "column",
      justifyContent: "space-between",
      // justifyContent: "flex-end",
      alignItems: "center",
      paddingBottom: 0,
    },
    tableFooterTotal: {
      backgroundColor: "#ffffff",
      borderColor: "#477078",
      borderWidth: pxCm(5),
      borderStyle: "solid",
      paddingVertical: pxCm(16),
      paddingHorizontal: pxCm(16),
      fontSize: pxCm(36),
    },
  });

  const handleStatusChange = (status: string) => {
    setSelected(status);
    selectedOrderData &&
      setSelectedOrderData({
        ...selectedOrderData,
        status,
        paymentAndInvoiceInfo: {
          ...selectedOrderData.paymentAndInvoiceInfo,
          status,
        },
      });
    console.log("selectedOrderData", selectedOrderData?.status);
  };

  let token = "";
  if (localStorage.getItem("token-dashboard")) {
    token = JSON.parse(localStorage.getItem("token-dashboard") as string);
  }

  // const Invoice = (order: OrderData) => {
  //   let rabais = 0;
  //   let frais = 0;

  //   // Loop on order fees and if the fee amount is positive add it to frais variable else add it to rabais variable
  //   order.fees.forEach((fee) => {
  //     const numberFeeAmount = Number(fee.amount);
  //     if (numberFeeAmount > 0) {
  //       frais += numberFeeAmount;
  //     } else {
  //       rabais += numberFeeAmount;
  //     }
  //   });

  //   let fees = 0;
  //   order.fees.forEach((fee) => {
  //     const numberFeeAmount = Number(fee.amount);
  //     fees += numberFeeAmount;
  //   });
  //   // let year = order.date ? order.date.match(/\d{4}/) : "";
  //   let year = order.exhibition.name
  //     ? order.exhibition.name.match(/\d{4}/)
  //     : "";
  //   year = year ? year[0] : "";

  //   // Exhibition name without only the two first characters
  //   let exhibition =
  //     order.exhibition.name !== "-" && order.exhibition.name !== ""
  //       ? order.exhibition.name.split(" ")[0].slice(0, 2).toUpperCase()
  //       : "CDE";

  //   let discounts = 0;
  //   let priceBeforeDiscounts = 0;

  //   // const products = [
  //   //   ...order.products,
  //   //   ...order.fees,
  //   // ]

  //   // Get the number of products in the order to check if the number is odd or even
  //   const numberOfProductsIsEven = order.products.length % 2 === 0 ? 2 : 1;

  //   const invoiceIdString = `${exhibition}${year}-${order.orderId}`;
  //   return (
  //     <Document>
  //       <Page size="A4" style={styles.page} wrap>
  //         <View style={styles.flower}>
  //           <Image
  //             fixed
  //             src={{
  //               uri: flowerInvoice,
  //               method: "GET",
  //               headers: { "Cache-Control": "no-cache" },
  //               body: "",
  //             }}
  //           />
  //         </View>
  //         <View>
  //           {/* HEADER */}
  //           <View style={styles.header}>
  //             <View style={styles.logo}>
  //               <Image
  //                 src={{
  //                   uri: logo,
  //                   method: "GET",
  //                   headers: { "Cache-Control": "no-cache" },
  //                   body: "",
  //                 }}
  //               />
  //             </View>
  //             <View style={styles.rightHeader}>
  //               <Image
  //                 style={styles.exhibitionsLogos}
  //                 src={{
  //                   uri: exhibitionsLogos,
  //                   method: "GET",
  //                   headers: { "Cache-Control": "no-cache" },
  //                   body: "",
  //                 }}
  //               />
  //               <View>
  //                 {/* <Text style={styles.headerCompany}>ST Organisation Sàrl</Text> */}
  //                 {/* <Text style={styles.headerAddress}>
  //                   Rue de Jérusalem 1 | CH- 1860 Aigle
  //                 </Text>
  //                 <Text style={styles.headerAddress}>
  //                   info@sdtn.ch | Tél : +41 24/466.12.89
  //                 </Text> */}
  //               </View>
  //             </View>
  //           </View>
  //           {/* BODY */}
  //           <View style={styles.section}>
  //             {/* Invoice info */}
  //             <View style={styles.invoiceInfo}>
  //               <View style={styles.generalInfo}>
  //                 {/* Invoice and date */}
  //                 <View style={styles.generalInfoCard}>
  //                   <View
  //                     style={[
  //                       styles.generalInfoCardData,
  //                       styles.generalInfoCardDataMt,
  //                     ]}
  //                   >
  //                     <Text style={styles.generalInfoCardTitle}>Facture </Text>
  //                     <Text style={styles.generalInfoCardText}>
  //                       {invoiceIdString}
  //                     </Text>
  //                   </View>
  //                   <View style={styles.generalInfoCardData}>
  //                     <Text style={styles.generalInfoCardTitle}>
  //                       Date de commande
  //                     </Text>
  //                     <Text style={styles.generalInfoCardText}>
  //                       {/* 22 décembre 2022 */}
  //                       {order.formattedDate}
  //                     </Text>
  //                   </View>
  //                   <View style={styles.generalInfoCardData}>
  //                     <Text style={styles.generalInfoCardTitle}>
  //                       Date de facture
  //                     </Text>
  //                     <Text style={styles.generalInfoCardText}>
  //                       {order.invoiceGenerationDateFormatted}
  //                     </Text>
  //                   </View>
  //                 </View>
  //                 <View
  //                   style={{
  //                     height: pxCm(16),
  //                   }}
  //                 ></View>
  //                 <View
  //                   style={{
  //                     backgroundColor: "#8AA9AE",
  //                     paddingLeft: pxCm(16),
  //                     paddingRight: pxCm(16),
  //                   }}
  //                 >
  //                   <View
  //                     style={{
  //                       display: "flex",
  //                       flexDirection: "row",
  //                       justifyContent: "space-between",
  //                       alignItems: "center",
  //                       padding: pxCm(16),
  //                       marginTop: pxCm(16),
  //                       marginBottom: pxCm(16),
  //                       color: "#ffffff",
  //                     }}
  //                   >
  //                     <Text style={{ fontWeight: 700 }}>Salon</Text>
  //                     <Text style={{ fontWeight: 700, fontSize: pxCm(50) }}>
  //                       {order.exhibition.name}
  //                     </Text>
  //                   </View>
  //                 </View>
  //                 <View
  //                   style={{
  //                     height: pxCm(16),
  //                   }}
  //                 ></View>
  //                 {/* Email and telephone */}
  //                 <View style={styles.generalInfoCard}>
  //                   <View style={styles.generalInfoCardData}>
  //                     <Text style={styles.generalInfoCardTitle}>Email</Text>
  //                     <Text style={styles.generalInfoCardText}>
  //                       {/* fpiller@akyado.com */}
  //                       {order.mainOrderInfo.email}
  //                     </Text>
  //                   </View>
  //                   <View
  //                     style={[
  //                       styles.generalInfoCardData,
  //                       styles.generalInfoCardDataMt,
  //                     ]}
  //                   >
  //                     <Text style={styles.generalInfoCardTitle}>Téléphone</Text>
  //                     <Text style={styles.generalInfoCardText}>
  //                       {order.mainOrderInfo.phone}
  //                     </Text>
  //                   </View>
  //                 </View>
  //               </View>
  //               <View style={styles.customerInfo}>
  //                 {/* <Text style={styles.customerInfoTitle}>
  //                   Adresse de facturation
  //                 </Text> */}
  //                 <Text
  //                   style={[
  //                     styles.customerInfoText,
  //                     styles.generalInfoCardDataMt,
  //                   ]}
  //                 >
  //                   {/* Frédéric Piller */}
  //                   {order.invoiceAddress.firstNameInvoice}{" "}
  //                   {order.invoiceAddress.lastNameInvoice}
  //                 </Text>
  //                 <Text
  //                   style={[
  //                     styles.customerInfoText,
  //                     styles.generalInfoCardDataMt,
  //                   ]}
  //                 >
  //                   {/* Deep Services SA / Akyado */}
  //                   {order.invoiceAddress.companyInvoice}
  //                 </Text>
  //                 <Text
  //                   style={[
  //                     styles.customerInfoText,
  //                     styles.generalInfoCardDataMt,
  //                   ]}
  //                 >
  //                   {/* Route de Tatrel 39 Remaufens 1617 */}
  //                   {order.invoiceAddress.addressInvoice}
  //                 </Text>
  //                 {order.invoiceAddress.address2Invoice && (
  //                   <Text
  //                     style={[
  //                       styles.customerInfoText,
  //                       styles.generalInfoCardDataMt,
  //                     ]}
  //                   >
  //                     {/* {invoiceAddress.address2Invoice} */}
  //                     {order.invoiceAddress.address2Invoice}
  //                   </Text>
  //                 )}
  //                 <Text
  //                   style={[
  //                     styles.customerInfoText,
  //                     styles.generalInfoCardDataMt,
  //                   ]}
  //                 >
  //                   {/* Suisse */}
  //                   {order.invoiceAddress.zipCodeInvoice}{" "}
  //                   {order.invoiceAddress.cityInvoice}
  //                 </Text>
  //                 <Text
  //                   style={[
  //                     styles.customerInfoText,
  //                     styles.generalInfoCardDataMt,
  //                   ]}
  //                 >
  //                   {/* Suisse */}
  //                   {order.invoiceAddress.countryInvoice}
  //                 </Text>
  //               </View>
  //             </View>
  //             {/* Invoice table */}
  //             <View style={styles.table}>
  //               {/* Table header */}
  //               <View style={styles.tableHeader}>
  //                 <View
  //                   style={[styles.tableHeaderProducts, styles.tableHeaderItem]}
  //                 >
  //                   <Text>Produits/Services</Text>
  //                 </View>
  //                 <View style={styles.tableHeaderOtherCol}>
  //                   <View
  //                     style={[
  //                       styles.tableHeaderOtherColItem,
  //                       styles.tableHeaderHT,
  //                       styles.tableHeaderItem,
  //                     ]}
  //                   >
  //                     <Text style={{ textAlign: "right" }}>Prix unit. HT</Text>
  //                   </View>
  //                   <View
  //                     style={[
  //                       styles.tableHeaderOtherColItem,
  //                       styles.tableHeaderItem,
  //                       styles.tableHeaderQty,
  //                     ]}
  //                   >
  //                     <Text>Qté</Text>
  //                   </View>
  //                   {/* Rabais */}
  //                   {/* <View
  //                     style={[
  //                       styles.tableHeaderOtherColItem,
  //                       styles.tableHeaderItem,
  //                     ]}
  //                   >
  //                     <Text style={{ textAlign: "right" }}>Rabais</Text>
  //                   </View> */}
  //                   {/* Rabais */}
  //                   <View
  //                     style={[
  //                       styles.tableHeaderOtherColItem,
  //                       styles.tableHeaderItem,
  //                     ]}
  //                   >
  //                     <Text style={{ textAlign: "right" }}>Total HT</Text>
  //                   </View>
  //                   <View
  //                     style={[
  //                       styles.tableHeaderOtherColItem,
  //                       styles.tableHeaderItem,
  //                     ]}
  //                   >
  //                     <Text style={{ textAlign: "right" }}>TVA</Text>
  //                   </View>
  //                   {/* TTC */}
  //                   <View
  //                     style={[
  //                       styles.tableHeaderOtherColItem,
  //                       styles.tableHeaderItem,
  //                     ]}
  //                   >
  //                     <Text style={{ textAlign: "right" }}>Total TTC</Text>
  //                   </View>
  //                   {/* TTC */}
  //                 </View>
  //               </View>
  //               {/* Table body */}
  //               <View style={styles.tableBody}>
  //                 {order.products.map((product, index) => {
  //                   discounts +=
  //                     product.regularPrice * product.quantity - product.price;
  //                   priceBeforeDiscounts +=
  //                     product.regularPrice * product.quantity;
  //                   return (
  //                     <View
  //                       style={{
  //                         flexDirection: "row",
  //                         paddingVertical: pxCm(40),
  //                         paddingHorizontal: pxCm(26),
  //                         borderBottom: "0.1px solid rgba(71, 112, 120)",
  //                         // backgroundColor:
  //                         //   index % 2 === 0 ? "#ffffff" : "#f8f8f8",
  //                       }}
  //                     >
  //                       <View style={styles.tableRowProductName}>
  //                         <Text>
  //                           {/* Stand - 9m² - Ouvert sur 2 côtés */}
  //                           {product.name}
  //                         </Text>
  //                       </View>
  //                       <View style={styles.tableRowOtherCol}>
  //                         {/* Prix unitaire HT */}
  //                         <View
  //                           style={[
  //                             styles.tableRowOtherColItem,
  //                             styles.tableRowHT,
  //                           ]}
  //                         >
  //                           {/* <Text>CHF 1’197,77</Text> */}
  //                           {/* <Text>CHF {product.price}</Text> */}
  //                           {/* <Text>{returnPriceFormattedCHF(product.price)}</Text> */}
  //                           <Text>
  //                             {/* {returnPriceFormattedCHF(product.regularPrice)} */}
  //                             {returnPriceFormattedCHFForInvoice(
  //                               product.regularPrice
  //                             )}
  //                           </Text>
  //                         </View>
  //                         {/* Quantity */}
  //                         <View
  //                           style={[
  //                             styles.tableRowOtherColItem,
  //                             styles.tableRowOtherColItemQty,
  //                           ]}
  //                         >
  //                           <Text style={{ textAlign: "center" }}>
  //                             {/* 1 */}
  //                             {product.quantity}
  //                           </Text>
  //                         </View>
  //                         {/* TOTAL HT */}
  //                         <View style={styles.tableRowOtherColItem}>
  //                           {/* <Text>CHF 1’197,77</Text> */}
  //                           {/* <Text>CHF {product.regularPrice}</Text> */}
  //                           <Text>
  //                             {/* {returnPriceFormattedCHF(product.regularPrice)} */}
  //                             <Text>
  //                               {/* {returnPriceFormattedCHF(product.price)} */}
  //                               {returnPriceFormattedCHFForInvoice(
  //                                 product.price
  //                               )}
  //                             </Text>
  //                           </Text>
  //                         </View>
  //                         {/* TVA */}
  //                         <View style={styles.tableRowOtherColItem}>
  //                           {/* <Text>CHF 92,23</Text> */}
  //                           {/* <Text>CHF {product.vat}</Text> */}
  //                           {/* <Text>{returnPriceFormattedCHF(product.vat)}</Text> */}
  //                           <Text>
  //                             {returnPriceFormattedCHFForInvoice(product.vat)}
  //                           </Text>
  //                         </View>
  //                         {/* TTC */}
  //                         <View style={styles.tableRowOtherColItem}>
  //                           <Text>
  //                             {/* 1 */}
  //                             {/* {product.quantity} */}
  //                             {returnPriceFormattedCHFForInvoice(
  //                               product.price + product.vat
  //                             )}
  //                             {/* {returnPriceFormattedCHF(
  //                               product.price * product.quantity + product.vat
  //                             )} */}
  //                           </Text>
  //                         </View>
  //                       </View>
  //                     </View>
  //                   );
  //                 })}
  //                 {/* Fees */}
  //                 {order.fees.map((fee, index) => (
  //                   <View
  //                     style={{
  //                       flexDirection: "row",
  //                       paddingVertical: pxCm(40),
  //                       paddingHorizontal: pxCm(26),
  //                       borderBottom: "0.1px solid rgba(71, 112, 120)",
  //                       // backgroundColor:
  //                       //   index === 0
  //                       //     ? numberOfProductsIsEven
  //                       //       ? "#ffffff"
  //                       //       : "#f8f8f8"
  //                       //     : index % 2 === 0
  //                       //     ? "#ffffff"
  //                       //     : "#f8f8f8",
  //                     }}
  //                   >
  //                     <View style={styles.tableRowProductName}>
  //                       <Text>
  //                         {/* If fee.name inclues 'Frais' and fee.amount includes '-' then replace 'Frais' by 'Rabais' in fee.name */}
  //                         {fee.name
  //                           ? fee.name
  //                           : fee.amount < 0
  //                             ? "Rabais"
  //                             : "Frais"}
  //                         {/* {fee.name.includes("Frais") &&
  //                         fee.amount.toString().includes("-")
  //                           ? fee.name.replace("Frais", "Rabais")
  //                           : fee.name} */}
  //                       </Text>
  //                     </View>
  //                     <View style={styles.tableRowOtherCol}>
  //                       {/* Prix unitaire HT */}
  //                       <View
  //                         style={[
  //                           styles.tableRowOtherColItem,
  //                           styles.tableRowHT,
  //                         ]}
  //                       >
  //                         {/* <Text>CHF 1’197,77</Text> */}
  //                         {/* <Text>CHF {product.price}</Text> */}
  //                         {/* <Text>{returnPriceFormattedCHF(product.price)}</Text> */}
  //                         {/* <Text>{returnPriceFormattedCHF(fee.amount)}</Text> */}
  //                         <Text>
  //                           {returnPriceFormattedCHFForInvoice(fee.amount)}
  //                         </Text>
  //                       </View>
  //                       {/* Quantity */}
  //                       <View
  //                         style={[
  //                           styles.tableRowOtherColItem,
  //                           styles.tableRowOtherColItemQty,
  //                         ]}
  //                       >
  //                         <Text style={{ textAlign: "center" }}>1</Text>
  //                       </View>
  //                       {/* TOTAL HT */}
  //                       <View style={styles.tableRowOtherColItem}>
  //                         {/* <Text>CHF 1’197,77</Text> */}
  //                         {/* <Text>CHF {product.regularPrice}</Text> */}
  //                         <Text>
  //                           {/* {returnPriceFormattedCHF(product.regularPrice)} */}
  //                           {/* {returnPriceFormattedCHF(fee.amount)} */}
  //                           {returnPriceFormattedCHFForInvoice(fee.amount)}
  //                         </Text>
  //                         {/* {fee.amount} */}
  //                       </View>
  //                       {/* TVA */}
  //                       <View style={styles.tableRowOtherColItem}>
  //                         {/* <Text>CHF 92,23</Text> */}
  //                         {/* <Text>CHF {product.vat}</Text> */}
  //                         {/* <Text>{returnPriceFormattedCHF(fee.vat)}</Text> */}
  //                         <Text>
  //                           {returnPriceFormattedCHFForInvoice(fee.vat)}
  //                         </Text>
  //                         {/* <Text>{fee.vat}</Text> */}
  //                       </View>
  //                       {/* TTC */}
  //                       <View style={styles.tableRowOtherColItem}>
  //                         <Text>
  //                           {/* 1 */}
  //                           {/* {product.quantity} */}
  //                           {/* {returnPriceFormattedCHF(fee.amount + fee.vat)} */}
  //                           {returnPriceFormattedCHFForInvoice(
  //                             fee.amount + fee.vat
  //                           )}
  //                         </Text>
  //                       </View>
  //                     </View>
  //                   </View>
  //                 ))}
  //               </View>
  //               {/* Table footer */}
  //               <View style={styles.tableFooter}>
  //                 <View>
  //                   {/* Discounts */}
  //                   {/* {fees !== 0 && (
  //                     <View style={styles.tableFooterDiscounts}>
  //                       <View
  //                         style={[
  //                           styles.tableFooterCardData,
  //                           styles.tableFooterCardDataMt,
  //                         ]}
  //                       >
  //                         <Text style={styles.tableFooterCardTitle}>
  //                           RABAIS / code promo
  //                         </Text>
  //                         <Text style={styles.tableFooterCardText}>
  //                           {returnPriceFormattedCHF(fees)}
  //                         </Text>
  //                       </View>
  //                     </View>
  //                   )} */}
  //                   <View style={styles.tableFooterCard}>
  //                     {/* Sous total */}
  //                     <View
  //                       style={[
  //                         styles.tableFooterCardData,
  //                         styles.tableFooterCardDataMt,
  //                       ]}
  //                     >
  //                       <Text style={styles.tableFooterCardTitle}>
  //                         SOUS-TOTAL (Hors taxes)
  //                       </Text>
  //                       <Text style={styles.tableFooterCardText}>
  //                         {/* CHF 1’197,77 */}
  //                         {/* {returnPriceFormattedCHF( */}
  //                         {returnPriceFormattedCHF(priceBeforeDiscounts)}
  //                       </Text>
  //                     </View>
  //                     {/* RAbais */}
  //                     {rabais <= -0.01 && (
  //                       <View
  //                         style={[
  //                           styles.tableFooterCardData,
  //                           styles.tableFooterCardDataMt,
  //                           styles.tableFooterDiscount,
  //                         ]}
  //                       >
  //                         <Text style={styles.tableFooterCardTitle}>
  //                           RABAIS (Hors taxes)
  //                         </Text>
  //                         <Text style={styles.tableFooterCardText}>
  //                           {/* CHF 1’197,77 */}
  //                           {/* {returnPriceFormattedCHF(discounts)} */}
  //                           {returnPriceFormattedCHF(rabais)}
  //                           {/* {rabais <= 0 ? "true" : "false"} */}
  //                           {/* {rabais < 0.01 ? "< 0" : "> 0"} */}
  //                         </Text>
  //                       </View>
  //                     )}
  //                     {frais >= 0.01 && (
  //                       <View
  //                         style={[
  //                           styles.tableFooterCardData,
  //                           styles.tableFooterCardDataMt,
  //                         ]}
  //                       >
  //                         <Text style={styles.tableFooterCardTitle}>
  //                           FRAIS SUPP. (Hors taxes)
  //                         </Text>
  //                         <Text style={styles.tableFooterCardText}>
  //                           {/* {returnPriceFormattedCHF(fees)} */}
  //                           {returnPriceFormattedCHF(frais)}
  //                         </Text>
  //                       </View>
  //                     )}
  //                     {/*  */}
  //                     <View
  //                       style={[
  //                         styles.tableFooterCardData,
  //                         styles.tableFooterCardDataMt,
  //                       ]}
  //                     >
  //                       <Text style={styles.tableFooterCardTitle}>
  //                         TOTAL (Hors taxes)
  //                       </Text>
  //                       <Text style={styles.tableFooterCardText}>
  //                         {/* CHF 1’197,77 */}
  //                         {returnPriceFormattedCHF(order.totalWithoutVat)}
  //                       </Text>
  //                     </View>
  //                     <View
  //                       style={[
  //                         styles.tableFooterCardData,
  //                         styles.tableFooterCardDataMt,
  //                       ]}
  //                     >
  //                       <Text style={styles.tableFooterCardTitle}>
  //                         TOTAL TVA (7,7%)
  //                       </Text>
  //                       {/* <Text style={styles.tableFooterCardText}>CHF 92,23</Text> */}
  //                       <Text style={styles.tableFooterCardText}>
  //                         {returnPriceFormattedCHF(order.vat)}
  //                       </Text>
  //                     </View>
  //                     <View
  //                       style={[
  //                         styles.tableFooterCardData,
  //                         styles.tableFooterCardDataMt,
  //                         styles.tableFooterTotal,
  //                       ]}
  //                     >
  //                       <Text style={styles.tableFooterCardTitle}>
  //                         MONTANT NET DE LA COMMANDE
  //                       </Text>
  //                       <Text
  //                         style={[
  //                           styles.tableFooterCardText,
  //                           styles.tableFooterTotalPrice,
  //                         ]}
  //                       >
  //                         {returnPriceFormattedCHF(order.total)}
  //                         {/* {returnPriceFormattedCHF(order.total + order.vat)} */}
  //                       </Text>
  //                     </View>
  //                   </View>
  //                 </View>
  //               </View>
  //             </View>
  //             {/* Comments */}
  //             {order.invoiceComments && (
  //               <View
  //                 style={{
  //                   width: pxCm(2240),
  //                   marginHorizontal: "auto",
  //                   marginTop: pxCm(100),
  //                 }}
  //               >
  //                 <Text style={{ fontSize: 12, fontWeight: 700 }}>
  //                   Commentaires
  //                 </Text>
  //                 <Text style={{ fontSize: pxCm(36) }}>
  //                   {order.invoiceComments}
  //                   {/* Lorem ipsum dolor sit amet consectetur adipisicing elit.
  //                   Dolorum quae eaque at qui soluta ex itaque quos minus
  //                   molestiae nesciunt, sequi aspernatur, architecto numquam magni
  //                   voluptates necessitatibus accusamus culpa officiis. Lorem
  //                   ipsum dolor sit amet consectetur adipisicing elit. Dolorum
  //                   quae eaque at qui soluta ex itaque quos minus molestiae
  //                   nesciunt, sequi */}
  //                 </Text>
  //               </View>
  //             )}
  //             {/* Invoice `How to pay` */}
  //             {/* <View style={styles.howToPay}>
  //               <Text style={styles.howToPayTitle}>
  //                 Comment et quand régler ma facture ?
  //               </Text>
  //               <View style={[styles.howToPayTextP1]}>
  //                 <Text style={styles.howToPayTextBold}>
  //                   Votre facture doit être réglée dans les 10 jours.
  //                 </Text>
  //                 <Text>
  //                   En cas de non-paiement dans les délais, votre inscription ne
  //                   sera pas validée.
  //                 </Text>
  //               </View>
  //               <View style={styles.howToPayTextP2}>
  //                 <Text>Vous pouvez payer votre facture soit :</Text>
  //                 <Text>
  //                   <Text style={styles.howToPayTextBold}>
  //                     - par virement bancaire
  //                   </Text>{" "}
  //                   à l’aide du QR Code présent sur cette facture.{" "}
  //                   <Text style={styles.howToPayTextUnderline}>
  //                     Merci de préciser le numéro de facture lors {"   "} de votre
  //                     règlement.
  //                   </Text>
  //                 </Text>
  //                 <Text>
  //                   <Text style={styles.howToPayTextBold}>
  //                     - par carte bancaire
  //                   </Text>{" "}
  //                   via le lien de paiement reçu dans l’e-mail contenant cette
  //                   facture
  //                 </Text>
  //                 <Text style={[styles.howToPayTextP3, styles.howToPayTextBold]}>
  //                   Pour toutes questions concernant la facturation, merci
  //                   d’adresser un e-mail à info@satn.ch
  //                 </Text>
  //               </View>
  //             </View> */}
  //           </View>
  //         </View>
  //         <View style={styles.pageFooter} fixed>
  //           <View
  //             style={{
  //               display: "flex",
  //               alignItems: "center",
  //               flexDirection: "row",
  //               justifyContent: "space-between",
  //               // width: pxCm(1000),
  //               width: 240,
  //               margin: "0 auto",
  //             }}
  //           >
  //             <View
  //               style={{
  //                 textAlign: "right",
  //                 display: "flex",
  //                 flexDirection: "column",
  //                 alignItems: "flex-end",
  //               }}
  //             >
  //               <Text style={{ fontWeight: 700, fontSize: 12 }}>
  //                 ST Organisation Sàrl
  //               </Text>
  //               <Text style={{ fontSize: 9.8 }}> Rue de Jérusalem</Text>
  //               <Text style={{ fontSize: 9.8 }}>1 CH- 1860 Aigle</Text>
  //             </View>
  //             <View>
  //               <Text style={{ fontSize: 9.8 }}>info@satn.ch</Text>
  //               <Text style={{ fontSize: 9.8 }}>Tél : +41 24 466 12 89</Text>
  //               <Text style={{ fontSize: 9.8 }}>www.satn.ch</Text>
  //             </View>
  //           </View>
  //           <View style={{ marginTop: pxCm(10) }}>
  //             <Text style={{ fontSize: 10 }}>CHE-480.316.854 TVA</Text>
  //           </View>
  //           {/* <Text>
  //             ST Organisation Sàrl - Rue de Jérusalem 1, CH- 1860 Aigle | N° TVA
  //             CHE-480.316.854 TVA
  //           </Text>
  //           <Text>www.satn.ch | E-mail : info@satn.ch | Tél : 024/466.12.89</Text> */}
  //         </View>
  //       </Page>
  //       <Page size="A4" style={[styles.page, styles.qrPage]} wrap>
  //         <View style={styles.howToPay} wrap={false}>
  //           <Text style={styles.howToPayTitle}>
  //             Comment et quand régler ma facture ?
  //           </Text>
  //           <View style={[styles.howToPayTextP1]}>
  //             <Text style={styles.howToPayTextBold}>
  //               Votre facture doit être réglée dans les 10 jours.
  //             </Text>
  //             <Text>
  //               En cas de non-paiement dans les délais, votre inscription ne
  //               sera pas validée.
  //             </Text>
  //           </View>
  //           <View style={styles.howToPayTextP2}>
  //             <Text>Vous pouvez payer votre facture soit :</Text>
  //             <Text>
  //               <Text style={styles.howToPayTextBold}>
  //                 - par virement bancaire
  //               </Text>{" "}
  //               à l’aide du QR Code présent sur cette facture.{" "}
  //               <Text style={styles.howToPayTextUnderline}>
  //                 Merci de préciser le numéro de facture lors {"   "} de votre
  //                 règlement.
  //               </Text>
  //             </Text>
  //             <Text>
  //               <Text style={styles.howToPayTextBold}>
  //                 - par carte bancaire
  //               </Text>{" "}
  //               via le lien de paiement reçu dans l’e-mail contenant cette
  //               facture
  //             </Text>
  //             <Text style={[styles.howToPayTextP3, styles.howToPayTextBold]}>
  //               Pour toutes questions concernant la facturation, merci
  //               d’adresser un e-mail à info@satn.ch
  //             </Text>
  //           </View>
  //         </View>
  //         <Image
  //           src={{
  //             uri: qrCh,
  //             method: "GET",
  //             headers: { "Cache-Control": "no-cache" },
  //             body: "",
  //           }}
  //         />
  //       </Page>
  //     </Document>
  //   );
  // };

  useEffect(() => {
    if (isCreatingANewOrder) {
      setSelected("Attente acceptation");
    }
  }, []);

  const generateInvoice = () => {
    setClickedGenerateInvoice(true);
  };

  const generateSingleInvoice = (
    order: OrderData,
    sendEmail: boolean,
    updateOrderStatus: boolean
  ) => {
    sendEmail && setIsSendingInvoice(true);
    sendEmail && setSendingInvoiceText("Envoi en cours...");
    // Generate a single invoice and save it to the user's computer
    // Date conversion to only year
    // let year = order.date ? order.date.match(/\d{4}/) : "";
    let year = order.exhibition?.year;
    // year = year ? year[0] : "";

    // Exhibition name without only the two first characters
    let exhibition =
      order.exhibition.name !== "-" && order.exhibition.name !== ""
        ? order.exhibition.name.split(" ")[0].slice(0, 2).toUpperCase()
        : "CDE";

    // console.log(order.exhibition);
    const documentWithData: InvoiceWithDataProps = {
      pdf: (
        <Invoice
          originalStatus={order.originalStatus}
          company={order.company}
          activityAddress={order.activityAddress}
          customer={order.customer}
          date={order.date}
          formattedDate={order.formattedDate ? order.formattedDate : ""}
          exhibition={order.exhibition}
          fees={order.fees}
          invoiceAddress={order.invoiceAddress}
          mainOrderInfo={order.mainOrderInfo}
          notes={order.notes}
          orderId={order.orderId}
          paymentAndInvoiceInfo={order.paymentAndInvoiceInfo}
          products={order.products}
          standNumber={order.standNumber}
          status={order.status}
          total={order.total}
          totalWithoutVat={order.totalWithoutVat}
          vat={order.vat}
          invoiceGenerationDateFormatted={order.invoiceGenerationDateFormatted}
          invoiceComments={order.invoiceComments}
        />
      ),
      exhibition: exhibition,
      year: year,
      orderId: order.orderId,
    };

    // console.log(
    //   render(
    //     <Email
    //       previewText={`Votre inscription pour le salon a été acceptée par les organisateurs`}
    //       title="Votre inscription pour le Salon est acceptée"
    //       orderData={order}
    //     />
    //   )
    // );

    // return;
    const email = render(
      <Email
        previewText={`Votre inscription pour le salon a été validé par les organisateurs`}
        title="Votre inscription pour le Salon est validée"
        orderData={order}
      />
    );

    // console.log(email);

    // return;

    pdf(documentWithData.pdf)
      .toBlob()
      .then((blob) => {
        // Convert the blob of a pdf to a base64 string
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result;
          console.log(base64data);
          sendEmail &&
            updateOrderStatusAndSendEmailWithInvoice({
              status: order.status,
              orderId: order.orderId,
              pdf: base64data as string,
              // invoiceName: `${documentWithData.exhibition}${documentWithData.year}-${documentWithData.orderId}`,
              invoiceName: `${documentWithData.exhibition}${year}-${documentWithData.orderId}`,
              html: email,
              updateStatus: updateOrderStatus,
              customerEmail: order.customer.email,
            })
              .then((res) => {
                console.log("done");
                sendEmail && setSendingInvoiceText("Envoyé !");
              })
              .catch((err) => {
                console.log(err);
                sendEmail && setSendingInvoiceText("Une erreur est survenue");
              })
              .finally(() => {
                sendEmail && setIsSendingInvoice(false);
                setTimeout(() => {
                  sendEmail && setSendingInvoiceText("Renvoyer la facture");
                }, 2000);
              });
        };
        !sendEmail &&
          saveAs(
            blob,
            `${documentWithData.exhibition}${year}-${documentWithData.orderId}.pdf`
          );
      });
  };

  interface clickedOnDownloadInvoiceProps {
    order: OrderData;
  }
  const clickedOnDownloadInvoice = async ({
    order,
  }: clickedOnDownloadInvoiceProps) => {
    if (!order) return;
    const invoicePath = returnInvoiceName({
      exhibitionName: order.exhibition.name,
      orderId: order.orderId.toString(),
      year: order.exhibition.year,
    });
    const fileName = `invoices/${order.customer.id}/${order.orderId}/${invoicePath}.pdf`;
    setIsDownloadingInvoice(true);

    verifyIfFileExists(fileName)
      .then(async (data) => {
        if (data) {
          if (data != true && data != false) {
            // If the file exists, open it in a new tab as it is a url
            window.open(data, "_blank");
            setIsDownloadingInvoice(false);
          }
        } else {
          generateSingleInvoiceWithFirebase({ order: order }).finally(() => {
            setIsDownloadingInvoice(false);
          });
        }
      })
      .finally(() => {
        // setIsDownloadingInvoice(false);
      });
  };

  const generateAllInvoices = (orders: OrderData[]) => {
    console.log("GENERATING");

    const zip = new JSZip();

    //   For each order, create a pdf document and add it to the zip
    let documentsWithData: InvoiceWithDataProps[] = [];

    orders.forEach((order) => {
      // Date conversion to only year
      // let year = order.date ? order.date.match(/\d{4}/) : "";
      let year = order.exhibition?.year;
      // let year = order.exhibition.name
      //   ? order.exhibition.name.match(/\d{4}/)
      //   : "";
      // year = year ? year[0] : "";

      // Exhibition name without only the two first characters
      let exhibition =
        order.exhibition.name !== "-" && order.exhibition.name !== ""
          ? order.exhibition.name.split(" ")[0].slice(0, 2).toUpperCase()
          : "CDE";

      // console.log(order.exhibition);

      documentsWithData.push({
        // pdf: <Invoice order={order} />,
        pdf: (
          <Invoice
            originalStatus={order.originalStatus}
            company={order.company}
            activityAddress={order.activityAddress}
            customer={order.customer}
            date={order.date}
            formattedDate={order.formattedDate ? order.formattedDate : ""}
            exhibition={order.exhibition}
            fees={order.fees}
            invoiceAddress={order.invoiceAddress}
            mainOrderInfo={order.mainOrderInfo}
            notes={order.notes}
            orderId={order.orderId}
            paymentAndInvoiceInfo={order.paymentAndInvoiceInfo}
            products={order.products}
            standNumber={order.standNumber}
            status={order.status}
            total={order.total}
            totalWithoutVat={order.totalWithoutVat}
            vat={order.vat}
            invoiceGenerationDateFormatted={
              order.invoiceGenerationDateFormatted
            }
            invoiceComments={order.invoiceComments}
          />
        ),
        exhibition: exhibition,
        year: year,
        orderId: order.orderId,
      });
    });

    documentsWithData.forEach((document, index) => {
      zip.file(
        `${document.exhibition}${document.year}-${document.orderId}.pdf`,
        pdf(document.pdf).toBlob()
      );
    });

    zip.generateAsync({ type: "blob" }).then((blob) => {
      saveAs(blob, "factures.zip");
      console.log("DONE");
    });
  };

  const downloadInvoice = () => {
    setClickedDownloadInvoice(true);
  };

  const compareDifferencesBetweenSelectedOrderAndUntouched = () => {
    // Compare products length
    if (
      selectedOrderData?.products.length !==
      selectedOrderUntouched?.products.length
    ) {
      return true;
    }
    // Compare products to JSON.stringify
    if (
      JSON.stringify(selectedOrderData?.products) !==
      JSON.stringify(selectedOrderUntouched?.products)
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    console.log("selectedOrderData", selectedOrderData);
  }, [selectedOrderData]);

  return (
    <CardWithData>
      {/* Status */}
      <span className="font-bold text-[#3C7179]">Statut</span>
      <Listbox value={selected} onChange={handleStatusChange}>
        <div className="relative">
          <Listbox.Button className="relative w-full pr-10 text-left bg-white rounded-lg cursor-pointer focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300">
            <span className="block truncate">{selected}</span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <ChevronUpDownIcon
                className="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none">
              {orderStatuses.map((status, index) => (
                <Listbox.Option
                  key={index}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? "bg-amber-100 text-amber-900" : "text-gray-900"
                    }`
                  }
                  value={status}
                >
                  {({ selected }) => {
                    return (
                      <>
                        <span
                          className={`block truncate ${selected ? "font-medium" : "font-normal"
                            }`}
                        >
                          {status}
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                            <CheckIcon className="w-5 h-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    );
                  }}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
      {/* Payment method */}
      <SingleOrderInfoCardsItemData
        title="Méthode de paiement"
        value={paymentMethod || "Non renseigné"}
        isEditable={false}
      />
      {/* Invoice number */}
      {actions &&
        selectedOrderData?.invoiceGenerationDate !== "-" &&
        !isCreatingANewOrder && (
          <SingleOrderInfoCardsItemData
            title="Numéro de facture"
            isEditable={false}
            value={invoiceNumber || "Non renseigné"}
          />
        )}
      <div>
        {/* Download invoice */}
        {actions &&
          selectedOrderData?.invoiceGenerationDate !== "-" &&
          !isCreatingANewOrder && (
            <>
              <SingleOrderInfoCardsItemData
                title={
                  isDownloadingInvoice
                    ? "Téléchargement en cours..."
                    : "Télécharger la facture"
                }
                link="#"
                linkAction={
                  () => {
                    console.log(selectedOrderData);
                    // console.log("DL FACTURE");
                    selectedOrderData &&
                      clickedOnDownloadInvoice({
                        order: selectedOrderData,
                      });

                    // selectedOrderData &&
                    //   generateSingleInvoice(selectedOrderData, false, false);
                  }
                  // () => downloadInvoice()
                  // selectedOrderData &&
                  // generateSingleInvoice(selectedOrderData, false, false)
                }
              />
              {/* <SingleOrderInfoCardsItemData
                title="Télécharger la facture FIREBASE"
                link="#"
                linkAction={
                  () => {
                    console.log(selectedOrderData);

                    // selectedOrderData &&
                    //   generateSingleInvoiceWithFirebase(selectedOrderData);
                  }
                  // () => downloadInvoice()
                  // selectedOrderData &&
                  // generateSingleInvoice(selectedOrderData, false, false)
                }
              /> */}
            </>
          )}
        {/* {
          orders && <SingleOrderInfoCardsItemData
            title="Télécharger toutes les factures"
            link="#"
            linkAction={
              () => {
                generateAllInvoices(orders);
              }
              // () => downloadInvoice()
              // selectedOrderData &&
              // generateSingleInvoice(selectedOrderData, false, false)
            }
          />
        } */}
        {actions && selectedOrderData?.invoiceGenerationDate === "-" && (
          <SingleOrderInfoCardsItemData
            title={
              isGeneratingInvoice
                ? "Génération en cours..."
                : "Générer la facture"
            }
            link="#"
            linkAction={() =>
            // generateSingleInvoice(selectedOrderData, false, false)
            {
              // setClickedGenerateInvoice(true);
              generateInvoice();
            }
            }
          />
        )}
        <div className="flex flex-col gap-3 my-3 items-left">
          {!isCreatingANewOrder && (
            <button
              disabled={
                isSendingOrderEmailForCustomer ||
                sendOrderEmailTextForCustomer === "Envoyé !"
              }
              style={{
                cursor:
                  isSendingOrderEmailForCustomer ||
                    sendOrderEmailTextForCustomer === "Envoyé !"
                    ? "not-allowed"
                    : "",
              }}
              className="text-left"
              title=""
              onClick={() => {
                console.log("Resend order email for customer");
                if (selectedOrderData) {
                  setIsSendingOrderEmailForCustomer(true);
                  setSendOrderEmailTextForCustomer("Envoi en cours...");
                  sendOrderEmail({
                    order_id: selectedOrderData.orderId,
                    forAdmin: "false",
                  }).finally(() => {
                    setIsSendingOrderEmailForCustomer(false);
                    setSendOrderEmailTextForCustomer("Envoyé !");
                    setTimeout(() => {
                      setSendOrderEmailTextForCustomer(
                        "Renvoyer mail de commande"
                      );
                    }, 2000);
                  });
                }
              }}
            >
              <span className="underline">{sendOrderEmailTextForCustomer}</span>
            </button>
          )}
          {/* <SingleOrderInfoCardsItemData
            title={sendOrderEmailTextForAdmin}
            link="#"
            linkAction={() => {
              console.log("Resend order email for admin");
              if (selectedOrderData) {
                setIsSendingOrderEmailForAdmin(true);
                setSendOrderEmailTextForAdmin("Envoi en cours...");
                sendOrderEmail({
                  order_id: selectedOrderData.orderId,
                  forAdmin: "true",
                }).finally(() => {
                  setIsSendingOrderEmailForAdmin(false);
                  setSendOrderEmailTextForAdmin("Envoyé !");
                  setTimeout(() => {
                    setSendOrderEmailTextForAdmin(
                      "Renvoyer mail de commande à l'admin"
                    );
                  }, 2000);
                });
              }
            }}
          /> */}
        </div>

        {/* Payment link */}
        {actions && selectedOrderData?.status === "Attente paiement" && (
          <div>
            <div className="my-3">
              <SingleOrderInfoCardsItemData
                title="Lien de paiement"
                //   link={actions.paymentLink.onClick}
                link={actions.paymentLink?.link}
                linkAction={
                  // Open link in new tab
                  actions.paymentLink?.link
                    ? () => window.open(actions.paymentLink?.link, "_blank")
                    : undefined
                }
              />
            </div>
            {selectedOrderData?.invoiceGenerationDate !== "-" &&
              !isCreatingANewOrder && (
                <div className="my-3">
                  <SingleOrderInfoCardsItemData
                    // title={`${
                    //   isSendingInvoice ? "Envoi en cours..." : "Renvoyer la facture"
                    // }`}
                    title={sendingPayReminderText}
                    //   link={actions.resendEmail.onClick}
                    // link="#"
                    link={
                      sendingPayReminderText !== "Rappel de paiement"
                        ? "submitting"
                        : "#"
                    }
                    linkAction={() => {
                      if (!isSendingPayReminder) {
                        selectedOrderData &&
                          sendOrderPayReminder(selectedOrderData);
                      }
                    }}
                  />
                </div>
              )}
          </div>
        )}
        {/* Resend email */}
        {actions &&
          selectedOrderData?.invoiceGenerationDate !== "-" &&
          !isCreatingANewOrder && (
            <SingleOrderInfoCardsItemData
              // title={`${
              //   isSendingInvoice ? "Envoi en cours..." : "Renvoyer la facture"
              // }`}
              title={sendingInvoiceText}
              //   link={actions.resendEmail.onClick}
              // link="#"
              link={
                sendingInvoiceText !== "Renvoyer la facture"
                  ? "submitting"
                  : "#"
              }
              linkAction={() => {
                if (!isSendingInvoice) {
                  selectedOrderData && resendInvoice(selectedOrderData);
                }
              }}
            />
          )}
      </div>
    </CardWithData>
  );
};

export default PaymentAndInvoiceInfo;

// Path: src/features/orders/components/PaymentAndInvoiceInfo.tsx
