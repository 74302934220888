import axios from "axios";
import { useState } from "react";
import { returnRootUrlBasedOnAppLocation } from "../../shared/utils/checkIfIsInlocal";

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(`${returnRootUrlBasedOnAppLocation()}jwt-auth/v1/token`, {
        username: username,
        password: password,
      })
      .then((response) => {
        const token = response.data.token;
        // Remove first and last character of the token
        // Store the token in local storage or cookies for subsequent requests
        // Store the token in local storage
        localStorage.setItem("token-dashboard", JSON.stringify(token));
        // localStorage.setItem("token-dashboard", token);
        localStorage.setItem("selectedMenuIndex", "0");
        // Remove first and last character of the token
        // Redirect to the home page
        window.location.href = "/";
        // setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        alert("Identifiant ou mot de passe incorrect");
      })
      .finally(() => {});
  };

  return (
    <div className="flex h-screen items-center justify-center bg-[#b1c6c9] p-4">
      <div className="flex w-full max-w-[450px] flex-col gap-y-6">
        <div className="px-4">
          <img
            src="https://salontherapiesnaturelles.ch/wp-content/uploads/2022/10/logo-grand-format-1536x411.png.webp"
            alt="logo satn"
          />
        </div>
        <div className="rounded-lg bg-white p-6 shadow-xl">
          <form onSubmit={handleSubmit}>
            <h2 className="mb-4 text-center text-2xl">Connexion</h2>
            <div className="mb-4">
              <label
                className="mb-2 block font-medium text-gray-700"
                htmlFor="username"
              >
                Email
              </label>
              <input
                className="w-full border border-gray-400 p-2"
                id="username"
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label
                className="mb-2 block font-medium text-gray-700"
                htmlFor="password"
              >
                Mot de passe
              </label>
              <input
                className="w-full border border-gray-400 p-2"
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button
              className="mt-4 w-full rounded-full bg-[#3c7179] px-4 py-2 text-white"
              type="submit"
            >
              {loading ? "Connexion..." : "Se connecter"}
            </button>
          </form>
        </div>
        <p className="text-center text-[#94a3b8]">v. 2.0.18</p>
      </div>
    </div>
  );
};

export default LoginPage;
